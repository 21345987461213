import { Box, styled } from '@mui/system';
import React from 'react';
import { useEditor } from '../../../hooks/useEditor';
import { LabelProps } from './types';

const LabelContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'background' })<any>(({ textAlign, background, textBackgroundColor }: any) => ({
  position: 'absolute',
  display: 'grid',
  placeItems: 'center',

  textAlign,
  backgroundColor: background,

  cursor: 'default !important',

  '& p': {
    backgroundColor: textBackgroundColor,
  },

}));

function Label({
  value,
  height,
  width,
  x,
  y,
  font,
  color,
  backgroundColor,
  textBackgroundColor,
  textAlign,
  styleMap,
}: LabelProps) {
  const { handleSelectEl } = useEditor();

  return (
    <LabelContainer
      height={height}
      width={width}
      top={y}
      left={x}
      color={color ?? '#000'}
      textAlign={textAlign ?? 'center'}
      background={backgroundColor ?? 'transparent'}
      textBackgroundColor={textBackgroundColor ?? 'transparent'}
      style={{ ...styleMap[font] }}
      onClick={() => handleSelectEl('board')}
    >
      <p>{value}</p>
    </LabelContainer>
  );
}

export default Label;
