import {
  Grid,
  Box, Button, MenuItem, Select, Paper,
} from '@mui/material';
import React, { useState } from 'react';
import DocumentThumbnail from 'src/components/DocumentThumbnail';
import { PaperButton } from 'src/components/AssignmentAssistant/PaperButton';
import { WordChip } from 'src/components/WordChip';
import { useApplyDocumentOperation } from 'src/hooks/document';
import { getWritingPlan } from 'src/configuration/writing-plans';
import {
  InitializeVersionOperation,
  SetImportantWordsOperation,
  SetSignalWordsOperation,
  SetWordCountOperation,
  SetWritingBuddyOperation,
  SetWritingPlanOperation,
} from 'src/store/document/operations';
import { DocumentFormat, DocumentTemplateSystem } from 'src/types/DocumentSettings';
import { SelectableDocumentFormats } from 'src/configuration/documents';
import {
  Assignment,
  Block, Document, isBlockPage,
} from 'src/types/models';
import WritingPlanDialog from 'src/views/document-v2/dialogs/WritingPlanDialog';
import ImportantWordsDialog from 'src/views/document-v2/dialogs/ImportantWordsSelectionDialog';
import { useUserStore } from 'src/zustand/user';
import InfoText from 'src/components/InfoText';
import FeedbackConfiguration from './FeedbackConfiguration';
import { FormSection } from '../FormSection';
import WritingPlanBlockTypes from '../WritingPlanBlockTypes';
import { FormatLabel } from './FormatLabel';

/**
 * The fields that control how the document will be displayed to students
 * are grouped together. This component is responsible for rendering
 * and updating the configuration of the base document.
 *
 * It does not need an onChange or onSubmit function because the document
 * is stored in global state. The parent component is notified of changes
 * using a hook.
 */
export default function BaseDocumentForm(props: {
  document: Partial<Document> | null | undefined;
  assignment: Assignment;
  isEditable?: boolean;
}) {
  const { document, assignment, isEditable = false } = props;
  const [isWritingPlanOpen, setIsWritingPlanOpen] = useState(false);
  const [isImportantWordsOpen, setIsImportantWordsOpen] = useState(false);
  const applyOperation = useApplyDocumentOperation();
  const user = useUserStore((state) => state.user);

  if (!document) {
    return null;
  }

  const isOwner = user.id && user.id === document.user?.id;
  const isAssigned = !!(assignment?.classrooms?.length || assignment?.students?.length);

  // The document format can be changed only there is no more than one block
  // (the starting block) and it has no content.
  // That is, if it is composed of only empty pages. In which case, the number
  // of pages and grid structure of the pages can be reset.
  const allBlocks = document.version?.pages?.reduce((blocks, page) => {
    if (isBlockPage(page)) {
      return blocks.concat(page.grid.blocks);
    }
    return blocks;
  }, [] as Block[]) ?? [];

  const hasContent = (block: Block) => block.properties.plainText || block.properties.imageUrl;
  const canChangeFormat = (
    document.templateSystem === DocumentTemplateSystem.BLOCKS
    && allBlocks.length <= 1
    && allBlocks.filter(hasContent).length === 0
  );

  const deferredApply: typeof applyOperation = async (operation) => {
    if (document?.id) {
      return applyOperation(operation);
    }
    return 'queued';
  };

  const importantWords = document.meta?.importantWords ?? [];
  const writingPlan = document.meta?.writingPlan
    ? getWritingPlan(document.meta?.writingPlan)
    : null;
  const signalWords = writingPlan?.signalWords || [];

  return (
    <div>
      <FormSection
        name="Starting Document"
        description={
          isEditable ? 'Select a document as a starting point for students.' : ''
        }
      >
        {isAssigned && isOwner && (
          <InfoText>
            <b>Note:</b>
            {' '}
            Changes to your starting document will only affect students
            who have not yet started the assignment. Students who have already started
            will continue to work with their most recent copy.
          </InfoText>
        )}

        <Grid container spacing={3}>
          <Grid
            item
            xs="auto"
          >
            <Box
              sx={{
                minHeight: '110px',
                minWidth: '80px',
              }}
            >
              <Paper
                elevation={13}
                sx={{
                  display: 'inline-block',
                }}
              >
                {document.format && document.templateSystem && document.version && (
                  <DocumentThumbnail
                    document={{
                      format: document.format,
                      templateSystem: document.templateSystem,
                      version: document.version,
                    }}
                    scale={{
                      [DocumentFormat.BOOKLET]: 0.08,
                      [DocumentFormat.STORY]: 0.08 / 1.6,
                      [DocumentFormat.DOC]: 0.08 / 1.6,
                    }[document.format]}
                  />
                )}
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={8}>
            {isEditable ? (
              <Select
                value={document?.format || DocumentFormat.BOOKLET}
                disabled={!canChangeFormat}
                onChange={(e) => {
                  if (canChangeFormat && e.target.value !== document?.format) {
                    deferredApply(
                      new InitializeVersionOperation(
                        document.id!,
                        e.target.value as DocumentFormat,
                      ),
                    );
                  }
                }}
              >
                {SelectableDocumentFormats.map((format) => (
                  <MenuItem value={format} key={format}>
                    <FormatLabel format={format} />
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <FormatLabel
                format={document.format || DocumentFormat.BOOKLET}
                hideImage
              />
            )}
          </Grid>
        </Grid>
      </FormSection>

      <FormSection
        name="Planning Blocks"
        description={
          isEditable ? 'Help students learn text structure by asking them to compose with these blocks.' : ''
        }
      >
        {writingPlan && (
          <PaperButton
            isSelected
            sx={{
              fontWeight: 'bold',
            }}
          >
            {writingPlan.title}
          </PaperButton>
        )}

        {isEditable && (
          <Button
            style={{
              fontWeight: 'bold',
            }}
            onClick={
              () => setIsWritingPlanOpen(true)
            }
          >
            +
            {' '}
            {document?.meta?.writingPlan ? 'Edit' : 'Add'}
            {' '}
            Writing Plan
          </Button>
        )}

        {writingPlan && (
          <WritingPlanBlockTypes writingPlan={writingPlan} />
        )}
      </FormSection>

      <FormSection
        name="Writing Assistance for Students"
        description={
          isEditable ? "Toggle which tools appear to the right of your students' documents." : ''
        }
      >
        <FeedbackConfiguration
          name="Writing Buddy"
          description={
            "Writing Buddy is an AI-powered resource designed to provide facilitative feedback and support for students' writing."
          }
          isEditable={isEditable}
          configuration={{
            isEnabled: document?.meta?.hasWritingBuddy !== false,
          }}
          onChange={(configuration) => {
            deferredApply(
              new SetWritingBuddyOperation(document.id!, configuration.isEnabled),
            );
          }}
        >
          <InfoText>
            <b>Note:</b>
            {' '}
            When enabled, Writing Buddy sends student writing to OpenAI ChatGPT API to provide
            real-time writing feedback. Read about OpenAI&apos;s
            {' '}
            <a
              href="https://openai.com/safety-standards"
              target="_blank"
              rel="noreferrer"
            >
              safety standards
            </a>
            {' '}
            and
            {' '}
            <a
              href="https://platform.openai.com/docs/chatgpt-education"
              target="_blank"
              rel="noreferrer"
            >
              educator considerations
            </a>
            .
          </InfoText>
        </FeedbackConfiguration>

        <FeedbackConfiguration
          name="Signal Words"
          description="Include a list of of transition words and phrases based on the writing plan."
          isEditable={isEditable}
          configuration={{
            isEnabled: document?.meta?.hasSignalWords !== false,
          }}
          onChange={(configuration) => {
            deferredApply(
              new SetSignalWordsOperation(document.id!, configuration.isEnabled),
            );
          }}
        >
          {signalWords.map((word: string) => (
            <WordChip
              key={word}
              word={word}
              color="#163567"
              backgroundColor="#F6F6FA"
            />
          ))}
        </FeedbackConfiguration>

        <FeedbackConfiguration
          name="Important Words"
          description="Include a list of vocabulary words and phrases based on the assignment topic."
          isEditable={isEditable}
          configuration={{
            isEnabled: document?.meta?.hasImportantWords !== false,
          }}
          onChange={(configuration) => (
            deferredApply(
              new SetImportantWordsOperation(
                document.id!,
                document?.meta?.importantWords || [],
                configuration.isEnabled,
              ),
            )
          )}
        >
          <div style={{ marginBottom: '.5em' }}>
            {importantWords.map((word: string) => (
              <WordChip
                key={word}
                word={word}
                color="#DF8C2A"
                backgroundColor="#FFFCF8"
              />
            ))}
          </div>
          <ImportantWordsDialog
            open={isImportantWordsOpen}
            initialImportantWords={importantWords.join(', ')}
            onClose={() => {
              setIsImportantWordsOpen(false);
            }}
            onAdd={(words: string) => {
              deferredApply(
                new SetImportantWordsOperation(
                  document.id!,
                  words.split(',').map((word) => word.trim()).filter(Boolean),
                  true,
                ),
              );
              setIsImportantWordsOpen(false);
            }}
          />
          {isEditable && (
            <Button
              style={{
                fontWeight: 'bold',
                fontSize: '.8rem',
                padding: '2px',
                margin: 0,
                minWidth: 'unset',
              }}
              onClick={() => {
                setIsImportantWordsOpen(true);
              }}
            >
              Edit
            </Button>
          )}
        </FeedbackConfiguration>

        <FeedbackConfiguration
          name="Word Count"
          description="Show the number of words written."
          isEditable={isEditable}
          configuration={{
            isEnabled: document?.meta?.hasWordCount !== false,
          }}
          onChange={(configuration) => {
            deferredApply(
              new SetWordCountOperation(document.id!, configuration.isEnabled),
            );
          }}
        >
          <div />
        </FeedbackConfiguration>
      </FormSection>

      <WritingPlanDialog
        open={isWritingPlanOpen}
        onClose={() => setIsWritingPlanOpen(false)}
        initialWritingPlan={document?.meta?.writingPlan || undefined}
        onAdd={(plan) => {
          setIsWritingPlanOpen(false);
          deferredApply(
            new SetWritingPlanOperation(document.id!, plan),
          );
        }}
      />
    </div>
  );
}
