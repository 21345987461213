import { Box, styled } from '@mui/system';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100vw',
}));

export const Content = styled(Box)(() => ({
  display: 'block',
  height: 'max-content',
  width: 'fill-available',
}));
