import { SelectedPresstoDefinition } from '../../types/Gallery';

import {
  // BOOKLET
  BCover1Img,
  BCover2Img,
  BCover3Img,
  BCover4Img,
  BImage1Img,
  BImage2Img,
  BImage3Img,
  BMixed2Img,
  BMixed1Img,
  BQuote1Img,
  BQuote2Img,
  BQuote3Img,
  BText1Img,
  BText2Img,
  // STORIES
  BodyImg,
  CaptionHeadlineBodyImg,
  Headline4ImagesImg,
  HeadlineImage1Img,
  HeadlineImage2Img,
  HeadlineImage3Img,
  Image3TextImg,
  ImageCaptionHeadlineImg,
  ImageHeadlineBodyImg,
  ImageQuoteImg,
  // PRESENTATION
  PCover1Img,
  PCover2Img,
  PCover3Img,
  PImage1Img,
  PImage2Img,
  PMixed1Img,
  PMixed2Img,
  PQuote1Img,
  PQuote2Img,
  PText1Img,
  PText2Img,
  PText3Img,
  PText4Img,
  // SHEET
  Quote2ImageImg,
  Quote4ImageImg,
  QuoteCaptionImg,
  QuoteImg,
  Text2ImageImg,
  Text4ImageImg,
  TextImg,
  ImageTextImg,
  ThreeImageImg,
  Title2ParagraphImg,
  TitleImageQuoteImg,
  TitleParagraphImg,
  TwoBodyTwoImagesImg,
  TwoImagesHeadlineImg,
  TwoTextTwoImageImg,
} from '../../assets/layouts';

import BCover1 from './Booklet/Cover1';
import BCover2 from './Booklet/Cover2';
import BCover3 from './Booklet/Cover3';
import BCover4 from './Booklet/Cover4';
import BImage1 from './Booklet/Image1';
import BImage2 from './Booklet/Image2';
import BImage3 from './Booklet/Image3';
import BMixed1 from './Booklet/Mixed1';
import BMixed2 from './Booklet/Mixed2';
import BQuote1 from './Booklet/Quote1';
import BQuote2 from './Booklet/Quote2';
import BQuote3 from './Booklet/Quote3';
import BText1 from './Booklet/Text1';
import BText2 from './Booklet/Text2';

import Body from './Story/Body';
import CaptionHeadlineBody from './Story/CaptionHeadlineBody';
import Headline2Images from './Story/Headline2Images';
import Headline4Images from './Story/Headline4Images';
import HeadlineImage1 from './Story/HeadlineImage1';
import HeadlineImage2 from './Story/HeadlineImage2';
import HeadlineImage3 from './Story/HeadlineImage3';
import ImageCaptionHeadline from './Story/ImageCaptionHeadline';
import ImageHeadlineBody from './Story/ImageHeadlineBody';
import TwoBodyTwoImages from './Story/2Body2Images';
import Quote from './Story/Quote';
import QuoteCaption from './Story/QuoteCaption';

import PCover1 from './Presentation/Cover1';
import PCover2 from './Presentation/Cover2';
import PCover3 from './Presentation/Cover3';
import PImage1 from './Presentation/Image1';
import PImage2 from './Presentation/Image2';
import PMixed1 from './Presentation/Mixed1';
import PMixed2 from './Presentation/Mixed2';
import PQuote1 from './Presentation/Quote1';
import PQuote2 from './Presentation/Quote2';
import PText1 from './Presentation/Text1';
import PText2 from './Presentation/Text2';
import PText3 from './Presentation/Text3';
import PText4 from './Presentation/Text4';

import TwoTextTwoImage from './Sheet/2Text2Image';
import ThreeImage from './Sheet/3Image';
import Image3Text from './Sheet/Image3Text';
import ImageQuote from './Sheet/ImageQuote';
import Quote2Image from './Sheet/Quote2Image';
import Quote4Image from './Sheet/Quote4Image';
import Text from './Sheet/Text';
import Text2Image from './Sheet/Text2Image';
import Text4Image from './Sheet/Text4Image';
import Title2Paragraph from './Sheet/Title2Paragraph';
import TitleImageQuote from './Sheet/TitleImageQuote';
import TitleParagraph from './Sheet/TitleParagraph';
import ImageText from './Sheet/ImageText';
import { DocumentFormatSettings } from '../documents';

export default async function getLayout(boardType: string, layoutType: string) {
  const board = DocumentFormatSettings[boardType as keyof typeof DocumentFormatSettings];
  const layoutElements = await import(`./${boardType}/${layoutType}`);

  return {
    board,
    pages: [{
      id: 'layout',
      position: 0,
      content: layoutElements.default,
      meta: '{}',
    }],
  };
}

export const BOOKLET_LAYOUTS = [
  {
    key: 'BCover1',
    label: 'Cover 1',
    img: BCover1Img,
    template: BCover1,
  },
  {
    key: 'BCover2',
    label: 'Cover 2',
    img: BCover2Img,
    template: BCover2,
  },
  {
    key: 'BCover3',
    label: 'Cover 3',
    img: BCover3Img,
    template: BCover3,
  },
  {
    key: 'BCover4',
    label: 'Cover 4',
    img: BCover4Img,
    template: BCover4,
  },
  {
    key: 'BImage1',
    label: 'Image 1',
    img: BImage1Img,
    template: BImage1,
  },
  {
    key: 'BImage2',
    label: 'Image 2',
    img: BImage2Img,
    template: BImage2,
  },
  {
    key: 'BImage3',
    label: 'Image 3',
    img: BImage3Img,
    template: BImage3,
  },
  {
    key: 'BMixed1',
    label: 'Mixed 1',
    img: BMixed1Img,
    template: BMixed1,
  },
  {
    key: 'BMixed2',
    label: 'Mixed 2',
    img: BMixed2Img,
    template: BMixed2,
  },
  {
    key: 'BQuote1',
    label: 'Quote 1',
    img: BQuote1Img,
    template: BQuote1,
  },
  {
    key: 'BQuote2',
    label: 'Quote 2',
    img: BQuote2Img,
    template: BQuote2,
  },
  {
    key: 'BQuote3',
    label: 'Quote 3',
    img: BQuote3Img,
    template: BQuote3,
  },
  {
    key: 'BText1',
    label: 'Text 1',
    img: BText1Img,
    template: BText1,
  },
  {
    key: 'BText2',
    label: 'Text 2',
    img: BText2Img,
    template: BText2,
  },
];

export const PRESENTATION_LAYOUTS = [
  {
    key: 'PCover1',
    label: 'Cover 1',
    img: PCover1Img,
    template: PCover1,
  },
  {
    key: 'PCover2',
    label: 'Cover 2',
    img: PCover2Img,
    template: PCover2,
  },
  {
    key: 'PCover3',
    label: 'Cover 3',
    img: PCover3Img,
    template: PCover3,
  },
  {
    key: 'PImage1',
    label: 'Image 1',
    img: PImage1Img,
    template: PImage1,
  },
  {
    key: 'PImage2',
    label: 'Image 2',
    img: PImage2Img,
    template: PImage2,
  },
  {
    key: 'PMixed1',
    label: 'Mixed 1',
    img: PMixed1Img,
    template: PMixed1,
  },
  {
    key: 'PMixed2',
    label: 'Mixed 2',
    img: PMixed2Img,
    template: PMixed2,
  },
  {
    key: 'PQuote1',
    label: 'Quote 1',
    img: PQuote1Img,
    template: PQuote1,
  },
  {
    key: 'PQuote2',
    label: 'Quote 2',
    img: PQuote2Img,
    template: PQuote2,
  },
  {
    key: 'PText1',
    label: 'Text 1',
    img: PText1Img,
    template: PText1,
  },
  {
    key: 'PText2',
    label: 'Text 2',
    img: PText2Img,
    template: PText2,
  },
  {
    key: 'PText3',
    label: 'Text 3',
    img: PText3Img,
    template: PText3,
  },
  {
    key: 'PText4',
    label: 'Text 4',
    img: PText4Img,
    template: PText4,
  },
];

export const SHEET_LAYOUTS = [
  {
    key: 'TWOTEXTTWOIMAGE',
    label: '2 Text + 2 Images',
    img: TwoTextTwoImageImg,
    template: TwoTextTwoImage,
  },
  {
    key: 'THREEIMAGE',
    label: '3 Images',
    img: ThreeImageImg,
    template: ThreeImage,
  },
  {
    key: 'IMAGETEXT',
    label: 'Image + Text',
    img: ImageTextImg,
    template: ImageText,
  },
  {
    key: 'IMAGE3TEXT',
    label: 'Image + 3 Texts',
    img: Image3TextImg,
    template: Image3Text,
  },
  {
    key: 'IMAGEQUOTE',
    label: 'Image + Quote',
    img: ImageQuoteImg,
    template: ImageQuote,
  },
  {
    key: 'QUOTE2IMAGE',
    label: 'Quote + 2 Images',
    img: Quote2ImageImg,
    template: Quote2Image,
  },
  {
    key: 'QUOTE4IMAGE',
    label: 'Quote + 4 Images',
    img: Quote4ImageImg,
    template: Quote4Image,
  },
  {
    key: 'TEXT',
    label: 'Text',
    img: TextImg,
    template: Text,
  },
  {
    key: 'TEXT2IMAGE',
    label: 'Text + 2 Images',
    img: Text2ImageImg,
    template: Text2Image,
  },
  {
    key: 'TEXT4IMAGE',
    label: 'Text + 4 Images',
    img: Text4ImageImg,
    template: Text4Image,
  },
  {
    key: 'TITLE2PARAGRAPH',
    label: 'Title + 2 Paragraphs',
    img: Title2ParagraphImg,
    template: Title2Paragraph,
  },
  {
    key: 'TITLEIMAGEQUOTE',
    label: 'Title + Image + Quote',
    img: TitleImageQuoteImg,
    template: TitleImageQuote,
  },
  {
    key: 'TITLEPARAGRAPH',
    label: 'Title + Paragraph',
    img: TitleParagraphImg,
    template: TitleParagraph,
  },
];

export const STORY_LAYOUTS = [
  {
    key: 'HEADLINEIMAGE1',
    label: 'Headline + Image',
    img: HeadlineImage1Img,
    template: HeadlineImage1,
  },
  {
    key: 'HEADLINEIMAGE2',
    label: 'Headline + Image',
    img: HeadlineImage2Img,
    template: HeadlineImage2,
  },
  {
    key: 'HEADLINEIMAGE3',
    label: 'Headline + Image',
    img: HeadlineImage3Img,
    template: HeadlineImage3,
  },
  {
    key: 'IMAGECAPTIONHEADLINE',
    label: 'Image + Caption + Headline',
    img: ImageCaptionHeadlineImg,
    template: ImageCaptionHeadline,
  },
  {
    key: 'HEADLINE4IMAGES',
    label: 'Headline + 4 Image',
    img: Headline4ImagesImg,
    template: Headline4Images,
  },
  {
    key: 'BODY',
    label: 'Body',
    img: BodyImg,
    template: Body,
  },
  {
    key: 'CAPTIONHEADLINEBODY',
    label: 'Caption + Headline + Body',
    img: CaptionHeadlineBodyImg,
    template: CaptionHeadlineBody,
  },
  {
    key: 'HEADLINE2IMAGES',
    label: '2 Images + Headline',
    img: TwoImagesHeadlineImg,
    template: Headline2Images,
  },
  {
    key: 'IMAGEHEADLINEBODY',
    label: 'Image + Headline + Body',
    img: ImageHeadlineBodyImg,
    template: ImageHeadlineBody,
  },
  {
    key: 'TWOBODYTWOIMAGES',
    label: '2 Body + 2 Images',
    img: TwoBodyTwoImagesImg,
    template: TwoBodyTwoImages,
  },
  {
    key: 'QUOTE',
    label: 'Quote',
    img: QuoteImg,
    template: Quote,
  },
  {
    key: 'QUOTECAPTION',
    label: 'Quote + Caption',
    img: QuoteCaptionImg,
    template: QuoteCaption,
  },
];

export const layoutsByFormat = {
  booklet: BOOKLET_LAYOUTS,
  presentation: PRESENTATION_LAYOUTS,
  sheet: SHEET_LAYOUTS,
  story: STORY_LAYOUTS,
  doc: [],
};

export function getTemplateContent(format: string, layoutId: string) {
  const layoutsArray = layoutsByFormat[format as keyof typeof layoutsByFormat];

  return layoutsArray.find((el) => el.key.toUpperCase() === layoutId.toUpperCase())?.template ?? [];
}

export function getDemoDocument(format: 'booklet' | 'story'): SelectedPresstoDefinition {
  const pagesSize = format === 'booklet' ? 8 : 1;

  return {
    format,
    id: '',
    meta: '{"writingPlan":"","signalWords":{},"importantWords":{},"wordCount":0}',
    name: 'My First Pressto Zine',
    updated_at: new Date(),
    isTryIt: true,
    isOwner: true,
    user: {
      id: '',
      name: '',
      surname: '',
      email: '',
      role: '',
    },
    version: {
      id: '',
      status: 'DRAFT',
      pages: [...Array(pagesSize)].map((e, index) => ({
        id: `page-${index}`,
        documentId: '',
        position: index,
        template: layoutsByFormat[format][0].key,
        content: getTemplateContent(format, layoutsByFormat[format][3].key),
        meta: '{}',
      })),
    },
  };
}
