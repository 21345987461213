import { styled } from '@mui/system';

export const LinkBoxContainer = styled('div')(() => ({
  position: 'relative',
  paddingBottom: '.25rem',

  '.link-box': {
    display: 'flex',
    boxShadow: '0px 0px 50px #52509a33',
    borderRadius: '.25rem',
    background: '#F6F6FA',
    border: '1px solid #596F93',
    padding: '.5rem',
    height: '4rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    p: {
      textAlign: 'center',
      width: 'fill-available',
      fontFamily: 'Inter',
      color: '#163567',
      fontWeight: 700,
      fontSize: '.875rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: 'auto',
    },
  },

}));
