import React, { useState, useEffect } from 'react';

import { ArtboardProps } from './types';
import { Container } from './styles';
import NavigationBar from './NavigationBar';
import Loader from './Loader';
import Editor from './Editor';
import { useEditor } from '../../hooks/useEditor';
import UploadDialog from '../dialogs/UploadDialog';
import LayoutDialog from '../dialogs/LayoutDialog';
import BoardToolbar from './Toolbar/BoardToolbar';

function Artboard({ isLoading, animateLoading }: ArtboardProps) {
  const {
    board,
    selectedEl,
    changeLayout,
    handleSelectEl,
  } = useEditor();

  const [animateFadeIn, setAnimateFadeIn] = useState(true);
  const [isLayoutDialogOpen, setIsLayoutDialogOpen] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const [uploadImageElement, setUploadImageElement] = useState('');

  useEffect(() => {
    if (!isLoading) {
      setAnimateFadeIn(true);
    }
  }, [isLoading]);

  const handleOpenMenu = () => setIsLayoutDialogOpen(true);

  const handleUploadImage = (element: string) => {
    setUploadImageElement(element);
    setIsUploadDialogOpen(true);
  };

  return (
    <Container>
      {isLoading ? (
        <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
          <Loader animate={animateLoading} />
        </div>
      ) : (
        <div
          className={`content-wrapper ${animateFadeIn ? 'animate' : ''}`}
          onAnimationEnd={() => setAnimateFadeIn(false)}
        >
          {selectedEl?.type === 'Board' && <BoardToolbar />}
          {board && (
            <Editor handleUploadImage={handleUploadImage} />
          )}
          <NavigationBar anchor="bottom" onWandClick={handleOpenMenu} />
          <UploadDialog
            currentElement={uploadImageElement}
            isOpen={isUploadDialogOpen}
            onClose={() => { setUploadImageElement(''); setIsUploadDialogOpen(false); }}
          />
          <LayoutDialog
            handleLayoutSelect={changeLayout}
            isOpen={isLayoutDialogOpen}
            onOpen={() => handleSelectEl(null)}
            onClose={() => setIsLayoutDialogOpen(false)}
          />
        </div>
      )}
    </Container>
  );
}

export default Artboard;
