import React from 'react';
import NewBlock from 'src/components/Block/NewBlock';
import Thumbnail from 'src/components/Block/Thumbnail';
import { getBlockType } from 'src/configuration/blocks';
import { Grid } from 'src/types/models';
import Block from '../../Block';
import PageGridCell from './Cell';

import GridBox from './styles';
import { getBlockByCoords, getCells } from './utils';

export default function FixedGrid(props: {
  grid: Grid;
  isEditable: boolean;
  isThumbnail: boolean;
  showBlockLabels: boolean;
}) {
  const {
    grid,
    isEditable = false,
    isThumbnail = false,
    showBlockLabels = false,
  } = props;
  const cells = getCells(grid.rows, grid.columns, grid.blocks);
  const occupiedCellMatrix: boolean[][] = [];
  for (let i = 0; i < grid.rows; i += 1) {
    occupiedCellMatrix[i] = [];
    for (let j = 0; j < grid.columns; j += 1) {
      occupiedCellMatrix[i][j] = false;
    }
  }
  cells.forEach((cell) => {
    occupiedCellMatrix[cell.y - 1][cell.x - 1] = !!cell.block;
  });

  return (
    <GridBox
      rows={grid.rows}
      columns={grid.columns}
    >
      {cells.map((cell) => {
        const below = getBlockByCoords(grid.blocks, cell.x, cell.y + 1) || null;
        const above = getBlockByCoords(grid.blocks, cell.x, cell.y - 1) || null;
        return (
          <PageGridCell
            key={`cell-${cell.x}-${cell.y}`}
            x={cell.x}
            y={cell.y}
            width={cell.width}
            height={cell.height}
            isEmptyBelow={
              isEditable && cell.y < grid.rows && !occupiedCellMatrix[cell.y][cell.x - 1]
            }
            isEmptyToTheRight={
              isEditable && cell.x < grid.columns && !occupiedCellMatrix[cell.y - 1][cell.x]
            }
          >
            {
              (isThumbnail && <Thumbnail block={cell.block || null} />)
              || (cell.block
                // TODO:
                // The key=block.id value papers over some wacky state-stuff
                // in the <Text> component related to editorState. The key
                // forces each block to have its own component instance.
                // Putting a key in a non-iterative component seems like a hack.
                // The Text component needs to do a better job of managing its
                // state.
                && (
                  <Block
                    key={cell.block.id}
                    block={cell.block}
                    blockType={getBlockType(cell.block)}
                    isEditable={isEditable}
                    isResizable={isEditable}
                    isFixedSize
                    alwaysShowLabels={showBlockLabels}
                    neighbors={{
                      above,
                      below,
                    }}
                  />
                )
              )
              || (!cell.block && isEditable
                && (
                  <NewBlock
                    x={cell.x}
                    y={cell.y}
                    width={cell.width}
                    height={cell.height}
                  />
                ))
              || undefined
            }
          </PageGridCell>
        );
      })}
    </GridBox>
  );
}
