/* eslint-disable @typescript-eslint/naming-convention */
export const create_pressto_dialog = {
  choose_format: {
    title: {
      line_1: 'Choose a',
      line_2: 'Format',
    },
  },

  writing_prompt: {
    title: {
      line_1: 'Create a',
      line_2: 'Writing Prompt',
    },
  },

  writing_plan: {
    title: {
      line_1: 'Choose a',
      line_2: 'Writing Plan',
    },

    no_plan_selected: 'Pick a writing plan for more support, including blocks and signal words',

    use_words_message: 'Practice with these signal words',
    use_blocks_message: 'Compose with these blocks:',
    show_fewer: 'show fewer',
  },

  important_words: {
    title: {
      line_1: 'Add a list of',
      line_2: 'Important Words',
    },

    field_label: 'When students use these words, they demonstrate their understanding of the topic.',
    field_placeholder: 'Click to add a comma separated list of words (e.g. Free speech, Constitution, Individual Rights)',
  },

  go_back: 'Go back',
};

export const choose_layout_dialog = {
  title: {
    line_1: 'Choose a',
    line_2: 'Layout',
  },
};

export const choose_blocktype_dialog = {
  title: {
    line_1: 'Choose a',
    line_2: 'Block',
  },
};

export const publish_dialog = {
  publish: {
    title: 'Publish',
    subtitle: 'Who can see your Pressto?',

    friends_and_family: 'Friends, family, and teachers with a custom link',
    description: 'Your PRESSTO can only be found with a custom link. You choose who to share this PRESSTO with and you can unpublish it any time.',
  },

  published: {
    title: 'Published!',

    share_link: 'Share this custom link',
    description: 'Your PRESSTO can be found with this custom link. You choose who to share this PRESSTO with or unpublish it at any time.',

    unpublish: 'Un-Publish',
  },
};

export const upload_dialog = {
  title: {
    line_1: 'Search & Choose an',
    line_2: 'Image',
  },

  search_placeholder: 'Search for a resource',
  upload_message: {
    line_1: 'You can also',
    line_2: 'upload an image from your computer (5mb)',
  },

  image_tooltip: {
    line_1: 'Photo by',
    line_2: 'on',
  },
};

export const warn_page_dialog = {
  title: ({ isDelete }: { isDelete: boolean }) => `Are you sure you want to ${isDelete ? 'delete this page' : 'add more than 8 pages'}?`,
  description: ({ isDelete }: { isDelete: boolean }) => `You are using the Pressto Booklet format which is optimized for printing with exactly 8 pages. ${isDelete
    ? 'Having less than 8 pages will result in a blank page in your printed format.'
    : 'Having more than 8 pages will not print in the booklet format. '}`,

  add: 'Add page',
  delete: 'Delete page',
};

export const top_bar = {
  export: {
    take_a_while: 'It might take a while',

    share: 'Share',
    print: 'Print',
    printing: 'Printing',
    publish: 'Publish',
    stop: 'Edit',
    view: 'View',
    turn_in: 'Turn in',
    assign: 'Assign',

    view_mode: 'View Pressto',
    stop_view_mode: 'Stop View Mode',
  },

  project_info: {
    last_saved: 'Last saved',
    saving: 'Saving...',
  },
};

export const side_bar = {
  writing_plan: {
    title: 'Signal Words',
    customize: 'Customize',
    description: 'Click to add your writing plan',

    active_message: 'YES! You used at least four signal word to help your reader know what\'s coming next. Keep it up!',
    default_message: {
      line_1: 'These are some words you can use for',
      line_2: 'writing.',
    },
  },

  important_words: {
    title: 'Important Words',
    customize: 'Customize',
    description: 'Click to add important words',
  },

  word_count: {
    title: 'Words Written',
    active_message: 'Nice going - You have written over 5 words. Keep it going!',

    words: ({ count }: { count: number }) => `word${count !== 1 ? 's' : ''}`,
  },

  title_length: {
    title: 'Title Length',

    default_message: 'We recommend a title that is around 20 characters.',
    good_message: 'Nice going. Your title seems like the right length. Check to make sure it captures your "big idea".',
    too_much_message: 'Uh oh. Your title seems a little long. Try to use just a few words to state your "big idea".',
  },

  image_count: {
    title: 'Image',
    message: 'Is there a powerful image to grab to your reader\'s interest?',
  },
};

export const artboard = {
  change_layout: 'Change Layout',

  toolbar: {
    adjust: 'Adjust',
    flip: 'Rotate',
    replace: 'Replace',
    image: 'Image',

    background_color: 'Background Color',
  },

  elements: {
    image_placeholder: '"Click to add an image"',
    text_placeholder: '"Click to start writing"',
  },
};
