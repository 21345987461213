/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useState,
} from 'react';
import { styled } from '@mui/system';
import { ButtonUnstyled } from '@mui/base';
import {
  Checkbox, Collapse, FormGroup,
  FormControlLabel,
} from '@mui/material';

import { Chevron } from 'src/assets/icons/variantComponents';
import { APIResourceType } from 'src/api';
import { PlusImg } from '../../../assets/icons';
import { Button } from '../../buttons';
import { theme } from '../../../utils';
import { GoogleClassroomImg } from '../../../assets/images';
import CreateClassroomDialog from '../CreateClassroomDialog';
import * as ClassroomAPI from '../../../api/Classrooms';
import { lang } from '../../../lang';
import { DialogProps } from '../types';
import BaseDialog, {
  DialogActions, DialogContent, DialogSubTitle, DialogTitle,
} from '../BaseDialog';

const AddClassesButton = styled(ButtonUnstyled)(() => ({
  left: 1,
  bottom: 1,
  width: '150px',
  display: 'flex',
  fontWeight: 600,
  color: theme.palette.primary.main,
  alignItems: 'center',
  cursor: 'pointer',
  background: 'white',
  border: 'none',
  marginTop: '1em',
}));

export default function SelectClassroomsAndStudentsDialog({
  isOpen, onClose, onSelect, selectedClassrooms, selectedStudents, openClassroomsSelection,
}: DialogProps & {
  selectedClassrooms: { id: string }[];
  selectedStudents: { id: string }[];
  onSelect: (
    classrooms: { id: string }[],
    students: { id: string }[],
  ) => Promise<void>;
  openClassroomsSelection: () => void,
}) {
  const [classrooms, setClassrooms] = useState<APIResourceType<typeof ClassroomAPI.getAll>>([]);
  const [isCreateClassroomDialogOpen, setIsCreateClassroomDialogOpen] = useState(false);
  const [openClassroomIdsMenu, setOpenClassroomIdsMenu] = useState(
    selectedClassrooms.map((classroom) => classroom.id),
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedClassroomIds, setSelectedClassroomIds] = useState(
    selectedClassrooms.map((classroom) => classroom.id),
  );
  const [selectedStudentIds, setSelectedStudentIds] = useState(
    selectedStudents.map((student) => student.id),
  );

  const isClassSelected = (id: string) => selectedClassroomIds.includes(id);
  const isStudentSelected = (id: string) => selectedStudentIds.includes(id);
  const isClassIndeterminate = (id: string) => {
    if (isClassSelected(id)) {
      return false;
    }
    const classroom = classrooms.find((c) => c.id === id);
    return classroom?.students.some((student) => isStudentSelected(student.id));
  };

  const selectClass = (id: string) => {
    setSelectedClassroomIds((prev) => (!prev.includes(id) ? [...prev, id] : prev));
  };
  const deselectClass = (id: string) => {
    setSelectedClassroomIds((prev) => prev.filter((classroomId) => classroomId !== id));
  };
  const selectStudent = (id: string) => {
    setSelectedStudentIds((prev) => (!prev.includes(id) ? [...prev, id] : prev));
  };
  const deselectStudent = (id: string) => {
    setSelectedStudentIds((prev) => prev.filter((studentId) => studentId !== id));
  };

  const loadClassrooms = async () => {
    try {
      const response = await ClassroomAPI.getAll();
      if (response.status === 200) {
        setClassrooms(response.data.data);
      }
    } catch {
      // This action doesn't have any specific error exibition.
    }
  };

  useEffect(() => {
    loadClassrooms();
  }, []);

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <BaseDialog
        onClose={handleClose}
        open={isOpen}
        showControls
        size="large"
      >
        <DialogContent>
          <DialogSubTitle>Choose</DialogSubTitle>
          <DialogTitle>Classes or Individuals</DialogTitle>
          <form
            className="assignment-content"
            onSubmit={async (event) => {
              event.preventDefault();
              setIsSubmitting(true);
              await onSelect(
                selectedClassroomIds.map((id) => ({ id })),
                selectedStudentIds.map((id) => ({ id })),
              );
              setIsSubmitting(false);
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              overflowY: 'auto',
            }}
          >
            <FormGroup sx={{
              border: '1px solid #E0E0E0',
              padding: '1rem',
              overflowY: 'auto',
              height: '45vh',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
            }}
            >
              {classrooms?.map((classroom) => {
                const isExpanded = openClassroomIdsMenu.includes(classroom.id);
                return (
                  <div key={classroom.id}>
                    <div
                      key={classroom.id}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <Chevron
                        direction={isExpanded ? 'down' : 'right'}
                        onClick={() => {
                          if (isExpanded) {
                            setOpenClassroomIdsMenu(
                              openClassroomIdsMenu.filter((id) => id !== classroom.id),
                            );
                          } else {
                            setOpenClassroomIdsMenu(
                              openClassroomIdsMenu.concat(classroom.id),
                            );
                          }
                        }}
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                      <FormControlLabel
                        control={(
                          <Checkbox
                            value={classroom.id}
                            checked={isClassSelected(classroom.id)}
                            indeterminate={isClassIndeterminate(classroom.id)}
                          />
                        )}
                        onChange={() => {
                          if (
                            isClassSelected(classroom.id)
                            || isClassIndeterminate(classroom.id)
                          ) {
                            deselectClass(classroom.id);
                          } else {
                            selectClass(classroom.id);
                          }

                          // Any interaction with the classroom checkbox deselects all students.
                          // They may receive the assignment through the classroom,
                          // not directly.
                          classroom.students.forEach((student) => deselectStudent(student.id));
                        }}
                        label={(
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <span>{classroom.name}</span>
                            {classroom.provider === 'google' && (
                              <img src={GoogleClassroomImg} alt="" height={18} />
                            )}
                          </div>
                        )}
                      />
                    </div>
                    <Collapse in={isExpanded}>
                      <div style={{ marginLeft: '59px', display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel
                          control={<Checkbox />}
                          checked={isClassSelected(classroom.id)}
                          label={<b>All current and future students</b>}
                          onChange={() => {
                            if (isClassSelected(classroom.id)) {
                              // Deselect the classroom.
                              deselectClass(classroom.id);

                              // Select all the students.
                              classroom.students.forEach((student) => selectStudent(student.id));
                            } else {
                              // Select the classroom.
                              selectClass(classroom.id);
                            }
                          }}
                        />

                        {classroom.students.map((student) => (
                          <FormControlLabel
                            key={student.id}
                            control={<Checkbox />}
                            value={student.id}
                            disabled={isClassSelected(classroom.id)}
                            checked={
                              isClassSelected(classroom.id) || isStudentSelected(student.id)
                            }
                            onChange={() => {
                              if (isStudentSelected(student.id)) {
                                // Deselect the student.
                                deselectStudent(student.id);
                              } else {
                                // Select the student.
                                selectStudent(student.id);
                              }
                            }}
                            label={`${student.name} ${student.surname}`}
                          />
                        ))}
                      </div>
                    </Collapse>
                  </div>
                );
              })}

              <AddClassesButton onClick={() => setIsCreateClassroomDialogOpen(true)}>
                <img src={PlusImg} alt="" style={{ marginRight: '8px' }} />
                {lang('assignment.create_assignment_dialog.step_1.add_classes')}
              </AddClassesButton>
            </FormGroup>

            <DialogActions>
              <Button
                label={lang('general.cancel')}
                large
                onClick={handleClose}
                outline
              />

              <Button
                label={
                  isSubmitting
                    ? 'Assigning...'
                    : lang('assignment.create_assignment_dialog.step_1.assign_to_class')
                }
                large
                disabled={!selectedClassroomIds && !isSubmitting}
                type="submit"
              />
            </DialogActions>
          </form>
        </DialogContent>
      </BaseDialog>

      <CreateClassroomDialog
        hasGoogleRedirect
        fromAssignment
        isOpen={isCreateClassroomDialogOpen}
        onOpen={() => setIsCreateClassroomDialogOpen(true)}
        onClose={() => setIsCreateClassroomDialogOpen(false)}
        onCloseCallback={() => {
          // Finish the assignment creation flow after importing the new classrooms.
          loadClassrooms();
          openClassroomsSelection();
        }}
      />
    </>
  );
}
