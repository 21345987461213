import { ImageBoxType, TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'TITLE100',
    defaultTextAlign: 'ALIGN_CENTER',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 128,
    height: 386,
    width: 984,
  } as TextBoxType,
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 536,
    height: 512,
    width: 534,
  } as ImageBoxType,
  {
    key: 'imagebox2',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 546,
    y: 536,
    height: 860,
    width: 534,
  } as ImageBoxType,
  {
    key: 'imagebox3',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 1060,
    height: 860,
    width: 534,
  } as ImageBoxType,
  {
    key: 'imagebox4',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 546,
    y: 1408,
    height: 512,
    width: 534,
  } as ImageBoxType,
];
