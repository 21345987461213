import { styled } from '@mui/system';

const Form = styled('form')(() => ({
  padding: 0,
  gridGap: '15px',
  display: 'grid',
  flexDirection: 'column',
}));

export default Form;
