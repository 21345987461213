import { Box, styled } from '@mui/system';
import { theme } from '../../utils';
import { CircleProps } from './types';

const Circle: any = styled(Box)<CircleProps>(({ alignSelf, size }) => ({
  backgroundColor: theme.palette.secondary.light,
  borderRadius: '100%',
  height: size ?? '65px',
  width: size ?? '65px',
  alignSelf: alignSelf ?? 'center',
}));

export default Circle;
