import React from 'react';
import { Description } from '../typography';

import { LoaderImg } from '../../assets/images';
import { LoaderContainer } from './styles';
import { LoaderProps } from './types';
import { lang } from '../../lang';

function Loader({ message, animate, size }: LoaderProps) {
  return (
    <LoaderContainer animate={animate}>
      <img src={LoaderImg} alt="pressto logo" style={size ? { height: size } : {}} />
      <Description style={{ marginTop: '2rem' }}>
        {message ?? (
          <>
            {lang('general.loading')}
            {' '}
            <span>...</span>
          </>
        )}
      </Description>
    </LoaderContainer>
  );
}

Loader.defaultProps = {
  message: undefined,
  size: undefined,
};

export default Loader;
