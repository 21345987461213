import React, { ComponentProps } from 'react';
import AssignmentAssistant from 'src/components/AssignmentAssistant';
import BaseDialog, { DialogContent } from '../BaseDialog';

export default function AssignmentAssistantDialog(
  props: {
    open: boolean;
    onClose: () => void;
    showControls?: boolean;
  } & ComponentProps<typeof AssignmentAssistant>,
) {
  const {
    open,
    onClose,
    showControls = true,
    ...assignmentAssistantProps
  } = props;

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      size="extra-large"
      showControls={showControls}
    >
      <DialogContent sx={{ height: '90vh' }}>
        <AssignmentAssistant
          {...assignmentAssistantProps}
        />
      </DialogContent>
    </BaseDialog>
  );
}
