import React from 'react';
import { Container, ThemeProvider } from '@mui/material';
import AssignmentAssistant from 'src/components/AssignmentAssistant';
import { theme } from 'src/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TopBar from 'src/components/shared/TopBar';
import { Logo } from 'src/components';

export default function AssignmentAssistantScreen() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const assignmentId = searchParams.get('assignment') || 'new';

  return (
    <ThemeProvider theme={theme}>
      <TopBar>
        <TopBar.Section>
          <a href="https://joinpressto.com/">
            <Logo height="2.25rem" variant="responsive" />
          </a>
        </TopBar.Section>
        <TopBar.Spacer />
      </TopBar>

      <Container
        sx={{
          marginTop: '3rem',
        }}
      >
        <AssignmentAssistant
          initialTopic={searchParams.get('name') || ''}
          initialWritingPlanId={searchParams.get('writingPlan') || null}
          onSelect={({
            topic,
            prompt,
            writingPlanId,
            importantWords,
          }) => {
            navigate(`/assignment/${assignmentId}?${new URLSearchParams({
              name: topic,
              writingPlan: writingPlanId || '',
              importantWords: importantWords.join(','),
            })}`, {
              state: {
                prompt,
              },
            });
          }}
        />
      </Container>
    </ThemeProvider>
  );
}
