import { Area } from 'react-easy-crop';

export const stringCharCodeSum = (text: string) => {
  let sum = 0;
  let i = text.length;
  while (i) {
    i -= 1;
    sum += text.charCodeAt(i);
  }
  return sum;
};

export const createImage = (
  url: string,
) => new Promise<HTMLImageElement>((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => resolve(image));
  image.addEventListener('error', (error) => reject(error));
  image.setAttribute('crossOrigin', 'anonymous');

  if (url.includes('base64')) {
    image.src = url;
  } else {
    // TODO: Fix "chapuza"
    image.src = `${url}?${(new Date()).getTime()}`;
  }
});

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * Get fitting CropSize
 *
 * @param mediaWidth number
 * @param mediaHeight number
 * @param containerWidth number
 * @param containerHeight number
 * @param aspect number
 * @returns Area
 */
export function getFittingCropSize(
  mediaWidth: number,
  mediaHeight: number,
  containerWidth: number,
  containerHeight: number,
  aspect: number,
): Area {
  const fittingWidth = Math.max(mediaWidth, containerWidth);
  const fittingHeight = Math.max(mediaHeight, containerHeight);

  if (fittingWidth > fittingHeight * aspect) {
    const newWidth = fittingHeight * aspect;

    return {
      width: newWidth,
      height: fittingHeight,
      x: (mediaWidth - newWidth) / 2,
      y: 0,
    };
  }

  const newHeight = fittingWidth / aspect;

  return {
    width: fittingWidth,
    height: newHeight,
    x: 0,
    y: (mediaHeight - newHeight) / 2,
  };
}

/**
 * Normalizes CropSize for the given image
 *
 * @param pixelCrop: Area|null
 * @param image HTMLImageElement
 * @param containerWidth number
 * @param containerHeight number
 * @param rotation number
 * @returns Area
 */
export function normalizeCropSize(
  pixelCrop: Area | null,
  image: HTMLImageElement,
  containerWidth: number,
  containerHeight: number,
  rotation: number,
): Area {
  if (pixelCrop) return pixelCrop;

  const isRotated = rotation === 90 || rotation === 270;

  const mediaWidth = isRotated ? image.height : image.width;
  const mediaHeight = isRotated ? image.width : image.height;

  const boxAspect = containerWidth / containerHeight; // > 1 = horizontal

  return getFittingCropSize(mediaWidth, mediaHeight, containerWidth, containerHeight, boxAspect);
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc: string,
  boxWidth: number,
  boxHeight: number,
  pixelCrop: Area | null,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const normalizedPixelCrop = normalizeCropSize(pixelCrop, image, boxWidth, boxHeight, rotation);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    normalizedPixelCrop.x,
    normalizedPixelCrop.y,
    normalizedPixelCrop.width,
    normalizedPixelCrop.height,
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = normalizedPixelCrop.width;
  canvas.height = normalizedPixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/png');

  return new Promise<string>((resolve) => {
    canvas.toBlob((file: any) => {
      resolve(URL.createObjectURL(file));
    }, 'image/png');
  });
}

interface Dim {
  height: number;
  width: number;
}

export function calculateObjectFit(containerDim: Dim, imageDim: Dim) {
  if (containerDim.height < containerDim.width) {
    return imageDim.height - containerDim.height < imageDim.width - containerDim.width ? 'vertical-cover' : 'horizontal-cover';
  }

  return imageDim.width < containerDim.width ? 'horizontal-cover' : 'vertical-cover';
}

// Calculate min zoom to display full image when cropping
export function getMinZoom(height: number, width: number) {
  return Math.min(height, width) / Math.max(height, width);
}
