import * as UUID from 'uuid';
import create from 'zustand';
import * as UserAPI from '../api/User';

export interface UserState {
  user: {
    id?: string;
    actions: string[];
    name: string;
    surname: string;
    role?: 'unselected' | 'individual' | 'student' | 'teacher' | 'admin' | 'superadmin';
    username: string | null;
    email: string | null;
    providers?: string[];
    isNewAccount?: boolean,
    createdAt: Date | null,
    flags: string[];
    masqueradingAdminId?: string | null;

    /** These are the classrooms the student is in. It is empty for teachers. */
    classrooms?: {
      id: string;
      name: string;
      joinCode: string | null;
    }[];

    /* These are the classrooms the user teaches. It is empty for students. */
    teacherClassrooms?: {
      id: string;
      name: string;
      joinCode: string | null;
    }[];
  };
  anonymousId: string,
  userError: { error: boolean; message: string; };
  setUserError: (p: any) => void;
  setUser: (u: any) => void;
  fetchCsrfToken: () => void;
  authCheck: () => Promise<boolean>;
  logout: (d?: boolean) => Promise<void>;
}

export const DEFAULT_USER = {
  actions: [],
  name: '',
  surname: '',
  username: '',
  email: '',
  createdAt: null,
  flags: [],
};

// Check localStorage for an anonymous user ID. This is used to
// identify users before they are logged in. It is similar to a session key,
// but does not need to be kept secret.
function getOrCreateAnonymousId() {
  let anonymousId: string | null;
  try {
    anonymousId = localStorage.getItem('anonymousId');
  } catch (e) {
    anonymousId = null;
  }

  if (!anonymousId) {
    // Generate a new random value.
    anonymousId = UUID.v4();

    try {
      localStorage.setItem('anonymousId', anonymousId);
    } catch (e) {
      // If we can't store it, we'll just have to generate a new one next time.
      // Since the application uses client-side routing, this value will still
      // last for a little while.
    }
  }
  return anonymousId;
}

export const useUserStore = create<UserState>((set, get) => ({
  user: {
    actions: [],
    name: '',
    surname: '',
    username: '',
    email: '',
    flags: [],
    createdAt: null,
  },
  anonymousId: getOrCreateAnonymousId(),
  userError: { error: false, message: '' },
  setUser: (data) => set(() => ({
    user: {
      ...data,
      name: data.name || '',
      surname: data.surname || '',
    },
  })),
  setUserError: (data) => set(() => ({ userError: data })),
  fetchCsrfToken: async () => {
    try {
      const response: any = await UserAPI.csrfToken();
      if (response) {
        const auth = await get().authCheck();
        return auth;
      }
      return false;
    } catch (error) {
      return false;
    }
  },
  authCheck: async () => {
    try {
      const response = await UserAPI.authCheck();
      if (response.status === 200) {
        const { data: { data } } = response;
        set({
          user: {
            ...data,
            name: data.name || '',
            surname: data.surname || '',
            classrooms: data.classrooms?.map((c) => ({
              id: c.id,
              name: c.name,
              joinCode: c.join_code,
            })),
            createdAt: new Date(data.created_at),
            masqueradingAdminId: data.masquerading_admin_id,
            teacherClassrooms: data.teacher_classrooms?.map((c) => ({
              id: c.id,
              name: c.name,
              joinCode: c.join_code,
            })),
          },
        });
        return true;
      }
      set({ user: DEFAULT_USER });
      return true;
    } catch (error) {
      set({ user: DEFAULT_USER });
      return false;
    }
  },
  logout: async (deleted) => {
    if (!deleted) {
      await UserAPI.logout();
    }

    if (get().user.masqueradingAdminId) {
      await get().authCheck();
    } else {
      set({
        user: DEFAULT_USER,
        anonymousId: getOrCreateAnonymousId(),
      });
    }
  },
}));
