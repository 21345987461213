import { Theme, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { lang } from '../../lang';
import { Button } from '../buttons';
import { Description } from '../typography';
import { LinkBoxContainer } from './styles';

function LinkBox({ text }: { text: string }) {
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);

    setTimeout(() => { setIsCopied(false); }, 5000);
  };

  return (
    <LinkBoxContainer>
      <Box className="link-box" onClick={mobileMQ ? copyToClipboard : undefined}>
        <p>
          {text}
        </p>
        {!mobileMQ && <Button outline label={lang(isCopied ? 'general.copied' : 'general.copy')} onClick={copyToClipboard} />}
      </Box>
      {mobileMQ && isCopied && (
        <div className="copied-message">
          <Description className="long-description">
            {lang('document.publish_dialog.published.copied')}
          </Description>
        </div>
      )}
    </LinkBoxContainer>
  );
}

export default LinkBox;
