import API from './config';

export async function suggestImportantWordsForPrompt(
  text: string,
  topic: string,
  writingPlan: string,
  gradeLevel: string = '4th',
) {
  return API.post<{
    data: {
      words: string[];
    }[];
  }>('/ai/important-words', {
    text,
    topic,
    grade_level: gradeLevel,
    writing_plan: writingPlan,
  });
}

export async function suggestWritingPrompt(
  topic: string,
  gradeLevel: string = '4th',
  skills: string[] = [],
) {
  return API.post<{
    data: {
      text: string;
    }[];
  }>('/ai/writing-prompts', {
    topic,
    grade_level: gradeLevel,
    writing_plan: skills[0],
  });
}

export async function askWritingBuddy(
  paragraphs: {
    text: string,
    blockName: string,
  }[],
  currentBlockName: string,
  writingPlanTitle?: string,
  availableBlockNames?: string[],
  instructions?: string,
) {
  return API.post<{
    data: {
      text: string;
      prompt?: string;
      entities: {
      }[];
    }[];
  }>('/ai/writing-hints', {
    paragraphs,
    current_block_name: currentBlockName,
    writing_plan: writingPlanTitle,
    available_block_names: availableBlockNames,
    instructions: instructions || undefined,
  });
}
