import React from 'react';

import { FlexBox, GradientContainer, Logo } from '../../components';
import { Button } from '../../components/buttons';
import { Title, Description, Link } from '../../components/typography';
import { lang } from '../../lang';

function SchoolInformation() {
  return (
    <GradientContainer justifyContent="center">
      <Logo />

      <FlexBox marginTop={10} textAlign="center">
        <Title>
          {lang('auth.signup.school.title')}
        </Title>
        <Description align="center">
          {lang('auth.signup.school.subtitle')}
        </Description>
      </FlexBox>
      <FlexBox marginTop={5}>
        <Description>
          {lang('auth.signup.school.name')}
        </Description>
        <Title size="small">
          CANYON CREST ACADEMY
        </Title>

        <Description>
          {lang('auth.signup.school.website')}
        </Description>
        <Link href="#school" fontSize="16px">cc.sduhsd.net</Link>

        <Description>
          {lang('auth.signup.school.location')}
        </Description>
        <Title size="small" fontWeight="500" lineHeight="30px">
          5951 Village Center Loop Rd,
          <br />
          San Diego, CA 92130
          <br />
          United States
        </Title>

        <FlexBox textAlign="center" marginTop={5}>
          <Button label={lang('auth.signup.school.confirm_information')} large />
          <Button label={lang('auth.signup.school.edit')} large outline />
        </FlexBox>
      </FlexBox>

    </GradientContainer>
  );
}

export default SchoolInformation;
