import React, { useEffect, useState } from 'react';

import { getImage, getImageData } from 'src/api/Image';
import * as ImageAPI from 'src/api/Image';
import { useEditor } from 'src/hooks/useEditor';
import { useDocumentStore } from 'src/zustand/documents';
import { debounce, theme } from 'src/utils';
import { lang } from 'src/lang';
import SearchInput from 'src/components/inputs/SearchInput';

import BaseDialog, {
  DialogContent,
  DialogTitle,
  DialogSubTitle,
} from '../BaseDialog';
import UploadFileMessage from './UploadFileMessage';
import ImageGrid from './ImageGrid';

function UploadDialog({
  currentElement,
  isOpen,
  onClose,
}: {
  currentElement: string,
  isOpen: boolean,
  onClose: (...args: any[]) => void,
}) {
  const currentDocument = useDocumentStore((state) => state.currentDocument);

  const {
    handleElChange, setIsBusy,
  } = useEditor();

  const [searchValue, setSearchValue] = useState('');
  const [imagesArray, setImagesArray] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');

  const loadPageData = async () => {
    if (!currentDocument?.id && !currentDocument?.isTryIt) return;
    const { data } = await getImage(currentDocument?.id || 'try-it', {
      text: searchValue,
      page: currentPage,
    });
    if (data?.data) {
      setImagesArray((prev) => prev
        .concat(data?.data)
        .filter((v, i, a) => a.findIndex((v2) => v2.provider_id === v.provider_id) === i));
      setCurrentPage((prev) => prev + 1);
      setTimeout(() => setIsLoading(false), 200);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (isProcessing) return;
      if (imagesArray.length === 0 || isLoading) loadPageData();
    }
  }, [isOpen, isLoading]);

  useEffect(() => {
    debounce(() => {
      setIsLoading(true);
      setCurrentPage(1);
      setImagesArray([]);
    }, 1000);
  }, [searchValue]);

  const handleClose = () => {
    setSearchValue('');
    setIsBusy(false);
    setIsProcessing(false);

    if (searchValue) {
      setImagesArray([]);
    }

    onClose();
  };

  const readFile = async (file: File) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

  const handleSubmit = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !currentElement) return;
    const file = event.target.files[0];
    if (!currentDocument) return;

    if (currentDocument?.isTryIt) {
      const imageDataUrl = await readFile(file);

      handleElChange(currentElement, 'originalImage', imageDataUrl);
      handleClose();
      return;
    }

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await ImageAPI.upload(
        currentDocument.id,
        formData,
      );

      handleElChange(currentElement, 'originalImage', response.data.data.url);
      handleClose();
    } catch (err: any) {
      setError(err.response.data.message);
    }
  };

  const handleImageChosen = async (image: string) => {
    if (!currentElement || !currentDocument) return;
    setIsProcessing(true);
    try {
      const { data } = await getImageData(currentDocument?.id || 'try-it', { image_id: image });
      if (data.data) {
        handleElChange(currentElement, 'originalImage', data.data.url);
        handleClose();
      } else {
        setIsProcessing(false);
      }
    } catch {
      setIsProcessing(false);
    }
  };

  const handleScroll = (e: any) => {
    const section = e.target.firstChild;
    const scrollHeight = e.target.clientHeight + e.target.scrollTop;
    if (isLoading || scrollHeight < section.clientHeight * 0.9) return;
    setIsLoading(true);
  };

  return (
    <BaseDialog size="medium" open={isOpen} onClose={handleClose}>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DialogSubTitle>{lang('document.upload_dialog.title.line_1')}</DialogSubTitle>
        <DialogTitle>{lang('document.upload_dialog.title.line_2')}</DialogTitle>
        <SearchInput
          value={searchValue}
          onChange={setSearchValue}
          placeholder={lang('document.upload_dialog.title.search_placeholder')}
          disabled={isProcessing}
        />
        <UploadFileMessage
          id={`image-document-${currentDocument?.id || 'try-it'}`}
          error={error}
          isProcessing={isProcessing}
          handleSubmit={handleSubmit}
        />
        <ImageGrid
          images={imagesArray}
          isProcessing={isProcessing}
          onScroll={handleScroll}
          onImageChosen={handleImageChosen}
          sx={{
            marginTop: '24px',
            [theme.breakpoints.up('sm')]: {
              marginTop: '40px',
            },
          }}
        />
      </DialogContent>
    </BaseDialog>
  );
}

export default UploadDialog;
