import { Slider } from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Cropper, { Area, Size } from 'react-easy-crop';
import { useEditor } from '../../../hooks/useEditor';
import ImageBoxToolbar from '../../Artboard/Toolbar/ImageBoxToolbar';
import { Description } from '../../typography';
import { CropComponentProps } from './types';
import getCroppedImg, { calculateObjectFit, getMinZoom } from './utils';

function CropComponent(
  {
    name,
    originalImage,
    width,
    height,
    cropArea,
    zoom,
    rotation,
    html,
    loadStep,
    setLoadStep,
    isCropping,
    setIsCropping,
    isRotating,
    setIsRotating,
    isSettingUp,
    setIsSettingUp,
    onImageLoadComplete,
    handleUploadImage,
  }: CropComponentProps,
) {
  const {
    selectedPage: page,
    selectedEl,
    handleElChange,
    boardLoading,
  } = useEditor();

  const [loading, setLoading] = useState(false);

  const [refreshCropper, setRefreshCropper] = useState(false);

  const [imageDimensions, setImageDimensions] = useState<Size | null>(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [imgRotation, setImgRotation] = useState<number>(rotation);
  // This will store a minimun value needed to display the full image if
  // dimensions are different, otherwise it will cut out part of the image
  const [minZoom, setMinZoom] = useState(1);
  const [zoomValue, setZoom] = useState(zoom ?? 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(cropArea);

  useEffect(() => {
    setZoom(zoom ?? 1);
  }, [zoom, originalImage]);

  useEffect(() => {
    if (imageDimensions) {
      // If the image is not 1:1 this will set a minimun zoom value that allow the whole image
      // to appear in the box, leaving the rest of the space empty
      let customZoom = getMinZoom(imageDimensions.height, imageDimensions.width);

      // This will keep the same behavior when selecting mininum zoom for images with
      // width < height and width > height. Otherwise one would have a white space around it
      // and the other would not
      if ((imageDimensions.height > imageDimensions.width && height < width)) {
        customZoom -= Math.min(height, width) / Math.max(height, width) / 4;
      } else if ((imageDimensions.width > imageDimensions.height && height > width)) {
        customZoom -= Math.min(height, width) / Math.max(height, width) / 2;
      }
      setMinZoom(customZoom);
    }
  }, [imageDimensions]);

  const objectFit = useMemo(() => {
    if (imageDimensions) {
      return calculateObjectFit({ height, width }, imageDimensions);
    }

    return undefined;
  }, [height, width, imageDimensions]);

  const updateElementHTML = (reloadImage = false) => {
    const element = document.querySelector(`#${name} .crop-container`);
    const imgElement = element?.querySelector('img');
    const imageBox = page?.content?.find((el: any) => el.key === name);

    if (!element || !imgElement || !imageBox) return;
    const imageTag = element?.innerHTML.match(/<img [^>]*>/);

    let parsedHTML = element?.innerHTML.replace(
      '<div data-testid="container" class="reactEasyCrop_Container">',
      `<div style="height: ${imageBox.height}px !important; width: ${imageBox.width}px !important; position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden; display: flex; justify-content: center; align-items: center;">`,
    ).replace(
      /<div data-testid="cropper" class="reactEasyCrop_CropArea" [^>]*><\/div>/,
      '',
    );

    if (imageTag) {
      // cleaning html
      let parsedImg = imageTag[0].replace(/alt="Loading" class="reactEasyCrop_Image reactEasyCrop_Cover_((Vertical)|(Horizontal))" /, '');
      // getting translate original values
      const translateData = parsedImg.match(/translate\((-?[\d.]*)px(, (-?[\d.]*)px)?\)/);

      if (translateData) {
        // calculating ratio between container size and original container size
        const dimRatio = imageBox.height / element.clientHeight;
        parsedImg = parsedImg.replace(
          /translate\(-?[\d.]*px, -?[\d.]*px\)/,
          `translate(${parseFloat(translateData[1]) * dimRatio}px${translateData.length > 2 ? `, ${parseFloat(translateData[3]) * dimRatio}px` : ''})`,
        );

        const isRotated = (rotation / 90) % 2;

        parsedImg = parsedImg.replace('style="', objectFit === 'vertical-cover' ? 'style="width: auto; height: 100%; ' : 'style="width: 100%; height: auto; ');

        if (isRotated) {
          if (width > height && imgElement.height < width) {
            const ratio = width / imgElement.height;

            parsedImg = parsedImg.replace('transform: ', `transform: scale(${ratio}) `);
          }

          if (width < height && imgElement.width < height) {
            const ratio = height / imgElement.width;

            parsedImg = parsedImg.replace('transform: ', `transform: scale(${ratio}) `);
          }
        }
      }

      parsedHTML = parsedHTML.replace(imageTag[0], parsedImg);
    }

    if (reloadImage && html !== parsedHTML) { setLoadStep(1); }

    handleElChange(name, 'html', parsedHTML);
  };

  const loadCroppedImage = useCallback(async () => {
    if (!originalImage) {
      handleElChange(name, 'loaded', true);
      setLoadStep(0);
      return;
    }

    setLoading(true);

    const croppedImageResult = await getCroppedImg(
      originalImage,
      width,
      height,
      isSettingUp && cropArea ? cropArea : croppedAreaPixels,
      imgRotation,
    );

    onImageLoadComplete(croppedImageResult ?? '');

    handleElChange(name, 'loaded', true);

    setLoadStep(cropArea || croppedAreaPixels ? 2 : 4);

    if (isCropping) setIsCropping(false);
    setLoading(false);
  }, [originalImage, cropArea, croppedAreaPixels, imgRotation, isCropping, zoom, zoomValue]);

  useEffect(() => {
    if (loadStep === 1 || loadStep === 4) {
      setRefreshCropper(true);
    }
  }, [loadStep]);

  useEffect(() => {
    if (!loading) loadCroppedImage();
    setRefreshCropper(false);
  }, [refreshCropper]);

  useEffect(() => {
    setIsRotating(true);
    setIsCropping(true);
  }, [imgRotation]);

  useEffect(() => {
    if (!cropArea) {
      setCrop({ x: 0, y: 0 });
      setZoom(minZoom);
      setCroppedAreaPixels(null);
      setImgRotation(0);
      setRefreshCropper(true);
      setIsSettingUp(true);
    }
  }, [originalImage]);

  useEffect(() => {
    const isDifferent = cropArea !== croppedAreaPixels
      || rotation !== imgRotation
      || zoom !== zoomValue;

    if (!isSettingUp && isDifferent && !isCropping && loadStep !== 1) {
      handleElChange(name, 'cropArea', croppedAreaPixels);
      handleElChange(name, 'rotation', imgRotation);
      handleElChange(name, 'zoom', zoomValue);

      updateElementHTML(true);
    }

    if (isSettingUp) {
      setLoadStep(1);
      updateElementHTML();
    }
  }, [isCropping, imgRotation, croppedAreaPixels, zoomValue]);

  useEffect(() => {
    if (isSettingUp && zoom !== zoomValue) {
      setZoom(zoom ?? minZoom);
    }
  }, [zoomValue]);

  const onCropComplete = useCallback((_: Area, newCroppedArea: Area) => {
    setCroppedAreaPixels(newCroppedArea);
  }, []);

  // Calculate coords when image is been moved to avoid leaving most of
  // the content outside the image element
  const calculateCoords = (x: number, y: number) => {
    if (imageDimensions) {
      if (imageDimensions.height > imageDimensions.width) {
        // Check if image is passing boundaries of the image editor,
        // considering the zoom applied, to not allow image to be moved too far away
        const yBondaries = ((imageDimensions.height - height) / 4) * zoomValue;
        if (y > yBondaries) {
          y = yBondaries;
        } else if (y < (yBondaries * -1)) {
          y = yBondaries * -1;
        }
        x = 0;
      } else if (imageDimensions.width > imageDimensions.height) {
        const xBondaries = ((imageDimensions.width - width) / 4) * zoomValue;
        if (x > xBondaries) {
          x = xBondaries;
        } else if (x < (xBondaries * -1)) {
          x = xBondaries * -1;
        }
        y = 0;
      }
    }
    return { x, y };
  };

  return (
    <>
      {selectedEl?.key === name && (
        <ImageBoxToolbar
          selectedEl={selectedEl}
          handleUploadImage={handleUploadImage}
          setImgRotation={setImgRotation}
          setCroppedAreaPixels={setCroppedAreaPixels}
          setIsCropping={setIsCropping}
          setIsSettingUp={setIsSettingUp}
          isCropping={isCropping}
          isRotating={isRotating}
        />
      )}
      <div style={isCropping && !isRotating ? { position: 'absolute' } : { position: 'absolute', visibility: 'hidden' }}>
        <div className="crop-container">
          {!refreshCropper && (
            <Cropper
              image={originalImage ?? undefined}
              crop={crop}
              rotation={imgRotation}
              zoom={zoomValue ?? zoom}
              aspect={width / height}
              onCropChange={({ x, y }) => {
                if (zoomValue >= 1) {
                  setCrop({ x, y });
                  return;
                }

                setCrop(calculateCoords(x, y));
              }}
              onRotationChange={setImgRotation}
              onCropComplete={onCropComplete}
              onZoomChange={(z: number) => {
                // For some reason when applied zoom >= 100% the value is correctly saved,
                // but when zoom < 100% it is saved and then reseted to 100% in the component,
                // so this condition should avoid reseting
                if (z === 1 && !isCropping) return;
                setZoom(z);
              }}
              showGrid={false}
              mediaProps={{ alt: 'Loading' }}
              objectFit={objectFit}
              restrictPosition={Math.round(zoomValue * 100) >= 100}
              initialCroppedAreaPixels={cropArea}
              onMediaLoaded={(media) => {
                setImageDimensions({ height: media.height, width: media.width });
              }}
            />
          )}
        </div>
        {!boardLoading && isCropping && (
          <div className="crop-control">
            <div className="crop-slider-container">
              <Slider
                value={zoomValue}
                min={minZoom}
                // The "|| 1" is needed in case minZoom < 0.5
                max={(Math.round(minZoom) || 1) * 3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: 'crop-slider' }}
                onChange={(e, val) => setZoom(val as number)}
                size="small"
              />
              <Description size="small">{`${Math.round(zoomValue * 100)}%`}</Description>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CropComponent;
