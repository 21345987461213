import * as UUID from 'uuid';

export type OperationState = 'queued' | 'merged' | 'saving' | 'saved' | 'failed';

// TODO: Separate into separate operation types or classes?
export default abstract class DocumentOperation {
  readonly id: string = UUID.v1();

  clientCreatedAt: Date = new Date();

  savedAt: Date | null = null;

  abstract type: string;

  // The document this operation is being applied to.
  documentId: string;

  constructor(documentId: string) {
    this.documentId = documentId;
  }

  /**
   * Attempt to merge the next operation into this one.
   * If the operations should be merged, return the merged operation.
   * If the operation should not be merged, return the false.
   *
   * The primary intent of this feature is to pick reasonable undo points.
   * Additionally, it may reduce operation storage requirements or reduce
   * API chatter.
   */
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  mergeNext(next: DocumentOperation): DocumentOperation | false {
    return false;
  }
}
