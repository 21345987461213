/**
 * This component presents a series of screens that, together, walk teachers
 * through the steps required to create a lesson. The wizard helps refine
 * the purpose of the lesson, suggesting instructions and Pressto features to
 * support instruction.
 */
import {
  Box,
  Button, Fade, FormControl, Grid, Input, lighten,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import * as AssistantAPI from 'src/api/Assistant';
import { TransitionGroup } from 'react-transition-group';
import { getWritingPlan } from 'src/configuration/writing-plans';
import { DialogSubTitle, DialogTitle } from 'src/components/dialogs/BaseDialog';
import { theme } from 'src/utils';
import QuillAnimated from 'src/assets/icons/animated/quill';
import { Quill } from 'src/assets/icons';
import { WordChip } from 'src/components/WordChip';
import { useUserStore } from 'src/zustand/user';
import { useSelectableWritingPlans } from 'src/hooks/writing-plans';
import { ChooseOne } from './ChooseOne';

type Prompt = {
  text: string;
  tags: string[];
  grade: string;
  topic: string;
  plan: string | null;
  importantWords: string[];
};

function PromptBox(props: {
  prompt: Prompt;
  isSelected?: boolean;
  onPress?: () => void;
}) {
  const {
    prompt, isSelected = false, onPress,
  } = props;

  return (
    <div>
      <Box
        key={prompt.text}
        onClick={onPress}
        sx={[
          {
            fontSize: '1rem',
            textAlign: 'left',
            lineHeight: '150%',
            p: 2,
            my: 1,
            cursor: !isSelected ? 'pointer' : 'initial',

            '&:hover': {
              backgroundColor: lighten('#596F93', 0.9),
            },
          },
          isSelected && {
            backgroundColor: lighten('#596F93', 0.9),
            color: 'black',
            border: '1px solid',
            borderColor: 'primary.main',
            boxShadow: theme.shadows[16],
          },
        ]}
      >
        <div style={{
          marginBottom: '0.5em',
          lineHeight: '80%',
        }}
        >
          <span
            style={{
              fontSize: '0.7em',
              color: isSelected ? theme.palette.primary.main : '#596F93',
              fontWeight: 'bold',
              marginRight: '0.5em',
            }}
          >
            {prompt.tags.join(', ')}
          </span>
        </div>
        {prompt.text}

        {isSelected && (
          <div
            style={{
              marginTop: '1em',
              minHeight: '80px',
            }}
          >
            <b
              style={{
                fontSize: '.85em',
              }}
            >
              Important Words
            </b>
            <div style={{
              marginTop: '.3em',
              minHeight: '4rem',
            }}
            >
              <TransitionGroup>
                {prompt.importantWords.map((word, i) => (
                  <Fade
                    key={prompt + word}
                    timeout={{
                      enter: 200 * i,
                    }}
                  >
                    <span>
                      <WordChip
                        key={word}
                        word={word}
                        color={theme.palette.primary.main}
                        backgroundColor="white"
                      />
                    </span>
                  </Fade>
                ))}
              </TransitionGroup>
            </div>
          </div>
        )}
      </Box>
    </div>
  );
}

export default function AssignmentAssistant(props: {
  initialTopic?: string;
  initialWritingPlanId?: string | null;
  onSelect: (data: {
    topic: string;
    prompt: string;
    writingPlanId: string | null;
    importantWords: string[];
  }) => void;
}) {
  const {
    onSelect,
    initialTopic = '',
    initialWritingPlanId = null,
  } = props;
  const user = useUserStore((state) => state.user);
  const writingPlans = useSelectableWritingPlans();

  const topicPlaceholder = useMemo(() => {
    const options = [
      'Where the Mountain Meets the Moon by Grace Lin',
      'Properties of Matter',
      'Civil Rights Movement',
      'Engineering',
      'Friendship',
      'Ecosystems',
      'Because of Winn-Dixie by Kate Dicamillo',
      'Video Game Design',
      'Problem-Solving',
      'Greta Thunberg',
      'Lebron James',
      'Bill of Rights',
      'Community Changemakers',
      'Brown Girl Dreaming by Jaqueline Woodson',
      'FDR’s First Fireside Chat',
      'Minecraft',
      'History of Jazz Music',
      'Conflict Resolution',
      'Estimation',
      'Mindfulness Check-In',
    ];
    return options[Math.floor(Math.random() * options.length)];
  }, []);

  const [grade, setGrade] = useState<string>('4th');
  const [topic, setTopic] = useState<string>(initialTopic);
  const [plan, setPlan] = useState<string | null>(initialWritingPlanId);

  const [selectedPrompts, setSelectedPrompts] = useState<Prompt[]>([]);
  const [suggestedPrompts, setSuggestedPrompts] = useState<Prompt[]>([]);

  const [isGenerating, setIsGenerating] = useState(false);
  const [generatingWordsForPrompt, setGeneratingWordsForPrompt] = useState<Prompt | null>(null);

  const generateSuggestions = async () => {
    if (topic) {
      setIsGenerating(true);
      const planTitle = (plan && getWritingPlan(plan)?.title) || 'open ended';
      try {
        const suggestions = await AssistantAPI.suggestWritingPrompt(
          topic,
          grade,
          planTitle ? [planTitle] : [],
        );
        setSuggestedPrompts([
          ...suggestions.data.data.map(({ text }) => ({
            text,
            tags: [topic, grade].concat(planTitle ? [planTitle] : []),
            importantWords: [],
            grade,
            topic,
            plan: planTitle,
          })),
          ...suggestedPrompts,
        ]);
      } finally {
        setTimeout(() => {
          setIsGenerating(false);
        }, 10);
      }
    }
  };

  const generateImportantWords = async (prompt: Prompt) => {
    setGeneratingWordsForPrompt(prompt);
    try {
      const response = await AssistantAPI.suggestImportantWordsForPrompt(prompt.text, topic, plan || 'open ended', prompt.grade);
      const { words } = response.data.data[0];

      setSelectedPrompts((prompts) => prompts.map((p) => {
        if (p === prompt) {
          return {
            ...p,
            importantWords: words,
          };
        }
        return p;
      }));
    } catch (e) {
      setSelectedPrompts((prompts) => prompts.map((p) => {
        if (p === prompt) {
          return {
            ...p,
            importantWords: [],
          };
        }
        return p;
      }));
    } finally {
      setGeneratingWordsForPrompt(null);
    }
  };

  return (
    <Grid
      container
    >
      <Grid
        item
        sm={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <div>
          <DialogSubTitle>
            Generate
          </DialogSubTitle>
          <DialogTitle>
            Writing Prompt
          </DialogTitle>
        </div>

        <Grid container rowSpacing={8} columnSpacing={6}>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <FormControl fullWidth>
              <p
                style={{ fontSize: '0.8em', color: 'rgba(0, 0, 0, 0.6)' }}
              >
                Writing Topic
              </p>
              <Input
                type="text"
                multiline
                fullWidth
                placeholder={topicPlaceholder}
                value={topic}
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
                onKeyUp={(event) => {
                  event.stopPropagation();
                }}
                onChange={(event) => {
                  setTopic(event.target.value);
                }}
                autoFocus
                sx={{
                  fontSize: '2rem',
                  color: 'black',
                  fontWeight: 'bold',
                  fontFamily: 'Inter',

                  'textarea::placeholder': {
                    color: 'rgba(0, 0, 0, 0.3)',
                  },
                }}
              />
            </FormControl>
          </Grid>

          <Grid item sm={6}>
            <ChooseOne
              label="Grade Level"
              isRequired
              options={[
                '2nd', '3rd', '4th', '5th', '6th',
                '7th', '8th', '9th', '10th', '11th', '12th',
              ]}
              onChange={setGrade}
              value={grade || undefined}
            />
          </Grid>
          <Grid item sm={6}>
            <ChooseOne
              label="Writing Plan"
              onChange={setPlan}
              options={writingPlans.map((p) => ({
                label: p.title,
                value: p.id,
              }))}
              value={plan}
              isRequired={false}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              sx={{
                my: 5,
                minHeight: '3.5rem',
                minWidth: '220px',
                textTransform: 'none',
                transition: 'all 0.2s ease-in-out',
                // Set a thicker border
                '&.MuiButton-root': {
                  border: '2px solid',
                  fontWeight: 'bold',
                },
                width: 255,
                justifyContent: 'flex-start',
              }}
              onClick={generateSuggestions}
              disabled={topic === '' || isGenerating}
            >
              {isGenerating ? (
                'Generating...'
              ) : (
                <>
                  <span
                    style={{
                      height: '40px',
                      width: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '20px',
                    }}
                  >
                    <img
                      style={{
                        marginLeft: '4px',
                        height: '18px',
                        width: '16px',
                      }}
                      src={Quill}
                      alt="quill"
                    />
                  </span>
                  Generate Writing Prompts
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        sm={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          height: 'auto',
          px: 2,
          justifyContent: isGenerating ? 'center' : 'initial',
        }}
      >
        {selectedPrompts.length > 0 && (
          <div>
            <p
              style={{
                fontSize: '0.8em',
                color: 'rgba(0, 0, 0, 0.6)',
                marginTop: '2rem',
                marginLeft: '16px',
              }}
            >
              Selected Prompt
            </p>

            {selectedPrompts.map((prompt) => (
              <PromptBox
                prompt={prompt}
                isSelected
              />
            ))}
            <div style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                sx={{
                  mt: 1,
                  textTransform: 'none',
                  minWidth: '250px',
                }}
                disabled={generatingWordsForPrompt === selectedPrompts[0]}
                onClick={() => {
                  onSelect({
                    topic,
                    writingPlanId: plan || null,
                    importantWords: selectedPrompts[0].importantWords,
                    prompt: selectedPrompts[0].text,
                  });
                }}
              >
                {generatingWordsForPrompt === selectedPrompts[0] && (
                  'Generating Important Words...'
                )}
                {generatingWordsForPrompt !== selectedPrompts[0] && (
                  user.id
                    ? 'Use selected writing prompt'
                    : 'Register or log in to share with your class'
                )}
              </Button>
            </div>
          </div>
        )}

        <div>
          <p
            style={{
              fontSize: '0.8em',
              color: 'rgba(0, 0, 0, 0.6)',
              marginTop: '2rem',
              marginLeft: '16px',
              visibility: suggestedPrompts.length === 0 ? 'hidden' : 'visible',
            }}
          >
            Suggested Prompts
          </p>
          <div style={{
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          >
            <TransitionGroup enter={isGenerating} exit={false}>
              {isGenerating && (
                <Fade>
                  <Grid
                    sm={3}
                    sx={{
                      marginLeft: '15%',
                      opacity: 0.9,
                    }}
                  >
                    <QuillAnimated />
                  </Grid>
                </Fade>
              )}

              {suggestedPrompts.map((prompt, index) => (
                <Fade key={prompt.text}>
                  <div>
                    <PromptBox
                      prompt={prompt}
                      onPress={() => {
                        // Select the prompt.
                        setSuggestedPrompts([
                          ...suggestedPrompts.filter((p) => p !== prompt),
                          ...selectedPrompts,
                        ]);
                        setSelectedPrompts([prompt]);

                        if (!prompt.importantWords.length) {
                          generateImportantWords(prompt);
                        }
                      }}
                    />
                    {suggestedPrompts.length > 3 && index % 3 === 2 && (
                      <hr style={{ width: '95%', opacity: 0.3, margin: '0 auto' }} />
                    )}
                  </div>
                </Fade>
              ))}
            </TransitionGroup>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
