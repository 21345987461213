import React from 'react';
import { HeaderBox } from './styles';
import { HeaderContainerProps } from './type';

function HeaderContainer({ children, hideTabs }: HeaderContainerProps) {
  return (
    <HeaderBox hideTabs={hideTabs}>
      {children}
    </HeaderBox>
  );
}

HeaderContainer.defaultProps = {
  hideTabs: false,
};

export default HeaderContainer;
