import { lighten, Paper } from '@mui/material';
import React from 'react';

export function PaperButton(props: {
  isSelected?: boolean;
  elevation?: number;
  children: React.ReactNode;
  onPress?: (...args: any[]) => Promise<void> | void;
  fullWidth?: boolean;
  sx?: object;
}) {
  const {
    isSelected = false, elevation = 14, children, onPress, fullWidth = false, sx = null,
  } = props;
  return (
    <Paper
      tabIndex={onPress ? 0 : undefined}
      role="button"
      component="button"
      onClick={onPress}
      onKeyDown={onPress ? (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Enter') {
          onPress();
        }
      } : undefined}
      elevation={elevation}
      sx={[
        {
          mr: 1,
          mb: 1,
          p: 1,
          display: 'inline-block',
          fontSize: '0.8rem',
          transition: 'all 0.2s',
        },

        fullWidth && {
          width: '100%',
        },

        !!onPress && {
          cursor: 'pointer',
        },

        isSelected && {
          borderColor: 'primary.main',
          backgroundColor: lighten('#596F93', 0.9),
          color: 'primary.main',
        },

        // By default, the usual border and drop shadow of Paper is hidden.
        !isSelected && {
          borderColor: '#fff',
          boxShadow: 'none',
          backgroundColor: 'grey.A100',
          color: 'secondary.main',
        },

        sx,
      ]}
    >
      {children}
    </Paper>
  );
}
