import * as models from 'src/types/models';
import create from 'zustand';

type Index<T extends { 'id': string | number }> = Record<T['id'], T | null>;

export const useResourceStore = create<{
  assignmentById: Index<models.Assignment & { 'id': string }>;
  setAssignment:(assignment: models.Assignment & { 'id': string }) => void;
}>()((set) => ({
  assignmentById: {},
  setAssignment: (assignment) => {
    set((state) => ({
      assignmentById: {
        ...state.assignmentById,
        [assignment.id]: assignment,
      },
    }));
  },
}));
