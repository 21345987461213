import {
  Document,
} from '../../../types/models';
import SynchronousDocumentOperation from './synchronous';

export default class SetWritingPlanOperation extends SynchronousDocumentOperation {
  readonly type = 'set-writing-plan';

  writingPlan: string;

  constructor(documentId: string, writingPlan: string) {
    super(documentId);
    this.writingPlan = writingPlan;
  }

  apply(document: Document): Document {
    return {
      ...document,
      meta: {
        ...document.meta,
        writingPlan: this.writingPlan,
      },
    };
  }
}
