import React, { ReactChild } from 'react';

import {
  DialogProps,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { DialogBackImg, DialogCloseImg } from 'src/assets/icons';

import {
  Dialog,
  DialogTitle,
  DialogSubTitle,
  DialogContent,
  DialogActions,
  DialogImage,
  DialogControls,
} from './styles';

type DialogSize = 'small' | 'medium' | 'large' | 'extra-large' | 'full-screen';

/**
 * Converts BaseDialog size into MUI Dialog maxWidth
 *
 * @param size DialogSize
 * @returns DialogProps.maxWidth
 */
function getMuiWidth(size: DialogSize): DialogProps['maxWidth'] {
  if (size === 'full-screen') return false;
  if (size === 'extra-large') return 'xl';
  if (size === 'large') return 'lg';
  if (size === 'medium') return 'md';
  return 'sm';
}

/**
 * Gets MUI Dialog props to control size
 *
 * @param size DialogSize
 * @returns Partial of DialogProps
 */
function getDialogSizeProps(
  size: DialogSize,
  isMobile: boolean,
): Pick<DialogProps, 'fullScreen' | 'fullWidth' | 'maxWidth' | 'scroll'> {
  return {
    fullScreen: isMobile || size === 'full-screen',
    fullWidth: true,
    maxWidth: isMobile ? false : getMuiWidth(size),
    scroll: 'paper',
  };
}

function BaseDialog({
  children,
  open,
  onClose,
  size = 'small',
  showControls = true,
  showBackButton = false,
  textBackButton = 'Go back',
  onBackButton,
}: {
  children?: ReactChild | ReactChild[];
  open: boolean;
  onClose: (...args: any[]) => void;
  size?: DialogSize;
  showControls?: boolean;
  showBackButton?: boolean;
  textBackButton?: string;
  onBackButton?: (...args: any[]) => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...getDialogSizeProps(size, isMobile)}
    >
      {showControls && (
        <DialogControls>
          {showBackButton && onBackButton && (
            <IconButton className="dialog-control-left" onClick={onBackButton}>
              <img src={DialogBackImg} alt={textBackButton} />
              &nbsp;
              {textBackButton}
            </IconButton>
          )}
          <IconButton className="dialog-control-right" onClick={onClose}>
            <img src={DialogCloseImg} alt="Close dialog" />
          </IconButton>
        </DialogControls>
      )}
      {children}
    </Dialog>
  );
}

export {
  DialogTitle,
  DialogSubTitle,
  DialogContent,
  DialogControls,
  DialogActions,
  DialogImage,
};

export default BaseDialog;
