import React, { useCallback, useState } from 'react';
import * as UUID from 'uuid';
import SelectBlockTypeDialog from 'src/components/dialogs/SelectBlockTypeDialog';
import { useApplyDocumentOperation, useDocument, useSelectedBlock } from 'src/hooks/document';
import { AddBlockOperation } from 'src/store/document/operations';
import { BlockType, BlockTypesEnum } from 'src/types/models';
import { usePageContext } from 'src/views/document-v2/PageContext';
import { getBlockType } from 'src/configuration/blocks';
import { useDrop } from 'react-dnd';
import { useAvailableWritingPlans } from 'src/hooks/writing-plans';
import { getWritingPlan } from 'src/configuration/writing-plans';
import { NewBlockBox } from './styles';

export default function NewBlockFluid(props: {
  x: number;
  y: number;
  width: number;
  height: number;
}) {
  const {
    x, y, width, height,
  } = props;
  const { page } = usePageContext();
  const applyOperation = useApplyDocumentOperation();
  const document = useDocument(page!.documentId);
  const [, setSelectedBlockId] = useSelectedBlock();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onAddBlock = useCallback(async (blockTypeId: BlockTypesEnum) => {
    const newBlockId = UUID.v1();
    applyOperation(new AddBlockOperation(
      page!.documentId,
      page!.id,
      newBlockId,
      getBlockType(blockTypeId),
      {
        x, y, width, height,
      },
    ));

    setSelectedBlockId(newBlockId);
  }, [page, x, y, width, height]);

  const isSpaceToTheRight = x - 1 + width < page!.grid.columns;
  const isSpaceBelow = y - 1 + height < page!.grid.rows;

  // If there is a writing plan assigned to the document, then limit block
  // selection to those in that plan. Otherwise, allow selection of any block
  // in any plan.
  let writingPlans = useAvailableWritingPlans();
  if (document?.meta.writingPlan) {
    const documentPlan = getWritingPlan(document.meta.writingPlan);
    if (documentPlan) {
      writingPlans = [documentPlan];
    }
  }

  // New block spaces are drop targets.
  const [{ isOver, item }, drop] = useDrop({
    accept: 'blockType',
    drop: (blockType: BlockType) => {
      onAddBlock(blockType.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      item: monitor.getItem(),
    }),
  });

  return (
    <>
      <NewBlockBox
        ref={drop}
        onClick={() => setIsDialogOpen(true)}
        role="button"
        aria-hidden="true"
        sx={[
          isSpaceToTheRight && {
            borderRightWidth: '1px',
          },
          isSpaceBelow && {
            borderBottomWidth: '1px',
          },
        ]}
      >
        <span style={{ fontSize: '1.5em', marginRight: '6px' }}>+</span>
        Add
        {' '}
        {isOver ? item.label : 'Block'}
      </NewBlockBox>
      <SelectBlockTypeDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSelect={onAddBlock}
        availablePlans={writingPlans}
      />
    </>
  );
}
