import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setUserProperties, useTrackEvent } from 'src/hooks/analytics';
import { useUserStore } from 'src/zustand/user';

export default function DataCollector() {
  const location = useLocation();
  const user = useUserStore((state) => state.user);
  const trackEvent = useTrackEvent();

  useEffect(() => {
    // Track the change to the user's role. It may be undefined if the
    // user is not logged in.
    setUserProperties({
      user_role: user.role,
    });
  }, [user.role]);

  useEffect(() => {
    // This tracks every page navigation, even quick redirects.
    // In the future, we can control how much redundant data is being
    // collected by debouncing this to track on the page on which the user
    // settles.
    trackEvent('page-viewed');
  }, [location.pathname, location.search, location.hash]);

  return null;
}
