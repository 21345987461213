import { ButtonUnstyled } from '@mui/base';
import { Box, styled } from '@mui/system';
import { theme } from 'src/utils';

export const CreateClassroomBox = styled(Box)(() => ({
  marginTop: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1.2rem',
  '> *': {
    width: '100%',
    maxWidth: '18rem',
    '&:last-child': {
      marginTop: '1.4rem',
    },
  },
}));

export const JoinCodeBoxHead = styled(Box)(() => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  background: '#F6F6FA',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '700',
  color: '#163567',
  letterSpacing: 'initial',
  fontSize: '14px',
  position: 'absolute',
  top: '-17.5px',
  height: '35px',
  padding: '0px 45px',
  lineHeight: 'initial',
}));

export const FullScreenButton = styled(ButtonUnstyled)(() => ({
  background: '#F6F6FA',
  borderRadius: '100%',
  padding: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  position: 'absolute',
  top: '15px',
  right: '15px',
  cursor: 'pointer',
}));

export const JoinCodeBox = styled(Box)(() => ({
  position: 'relative',
  margin: '2rem auto',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '4rem',
  },
  fontWeight: '700',
  letterSpacing: '15px',
  padding: '1rem 1.5rem',
  lineHeight: '150%',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  boxShadow: `4px 4px 0 ${theme.palette.primary.light}`,
  height: '11.25rem',
  display: 'grid',
  placeItems: 'center',
  color: '#163567',
}));

export const OptionImport = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }: { disabled: boolean }) => ({
  border: `1px solid ${theme.palette.secondary.light}`,
  flexDirection: 'column',
  gap: '15px',
  height: '300px',
  width: '300px',
  padding: '40px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 150ms ease-in-out',

  '*': { textAlign: 'center' },

  '&:hover': !disabled && {
    backgroundColor: 'white',
    border: '1px solid #163567',
    boxShadow: '4px 4px 0px #163567',
  },

  img: {
    marginBlock: '20px',
  },
}));

export const OptionImportList = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, auto)',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  gridGap: '10px',
}));
