import React from 'react';
import { ReactComponent as ChevronImg } from './chevron.svg';
import { ReactComponent as ChevronsImg } from './chevrons.svg';

export interface VariantComponentProps extends React.SVGProps<any> {
  direction: 'up' | 'down' | 'right' | 'left'
}

export function Chevron({ direction, style, ...rest }: VariantComponentProps) {
  let transform = '';
  switch (direction) {
    case 'up':
      transform = 'rotate(-180deg)';
      break;
    case 'right':
      transform = 'rotate(-90deg)';
      break;
    case 'left':
      transform = 'rotate(90deg)';
      break;
    default:
  }
  return <ChevronImg style={{ transition: 'transform .15s ease-in-out', transform, ...style }} {...rest} />;
}

export function Chevrons({ direction, style, ...rest }: VariantComponentProps) {
  let transform = '';
  switch (direction) {
    case 'up':
      transform = 'rotate(-90deg)';
      break;
    case 'right':
      transform = 'rotate(180deg)';
      break;
    case 'down':
      transform = 'rotate(90deg)';
      break;
    default:
  }
  return <ChevronsImg style={{ transition: 'transform .15s ease-in-out', transform, ...style }} {...rest} />;
}
