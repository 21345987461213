import React, { ReactElement } from 'react';
import { Wrapper } from './styles';

function DraggableContainer({ children }: { children: ReactElement }) {
  return (
    <Wrapper className="sidebar-draggable">
      {children}
    </Wrapper>
  );
}

export default DraggableContainer;
