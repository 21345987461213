import JoinCode from 'src/views/register/JoinCode';
import CoteacherInvitation from 'src/views/register/CoteacherInvitation';
import SelectRole from '../views/register/SelectRole';
import BirthdayForm from '../views/register/BirthdayForm';
import CreateAccount from '../views/register/CreateAccount';
import SchoolForm from '../views/register/SchoolForm';
import StudentSignUp from '../views/register/StudentSignUp';

const ACTION_ROUTES = [
  {
    path: '/register-student',
    name: 'Register Student',
    action: ['verify_email', 'select_role'],
    component: StudentSignUp,
  },
  {
    path: '/create-account',
    name: 'Create Account',
    action: ['verify_email', 'complete_user_data'],
    component: CreateAccount,
  },
  {
    path: '/code/:joinCode?',
    name: 'Join Code',
    action: ['verify_email', 'select_role', 'complete_user_data'],
    component: JoinCode,
  },
  {
    path: '/coteacher-invitation/:coteacherInvitationId?',
    name: 'Coteacher Invitation',
    action: ['verify_email', 'select_role', 'complete_user_data'],
    component: CoteacherInvitation,
  },
  {
    path: '/select-role',
    name: 'Select Role',
    action: ['select_role'],
    component: SelectRole,
  },
  {
    path: '/school-information-form',
    name: 'School Information Form',
    action: ['add_school', 'select_role'],
    component: SchoolForm,
  },
  {
    path: '/birthday-form',
    name: 'Birthday Form',
    action: ['add_birthdate'],
    component: BirthdayForm,
  },
];

export default ACTION_ROUTES;
