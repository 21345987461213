import { DocumentFormat } from 'src/types/DocumentSettings';
import { DocumentFormatSettings } from 'src/configuration/documents';
import * as UUID from 'uuid';
import { getWritingPlan } from 'src/configuration/writing-plans';
import { getBlockType } from 'src/configuration/blocks';
import * as models from 'src/types/models';
import { createEmptyPage } from './utils';
import SynchronousDocumentOperation from './synchronous';

/**
 * Use this Operation only at the beginning of a document's life. Otherwise,
 * content will be overwritten.
 */
export default class InitializeVersionOperation extends SynchronousDocumentOperation {
  readonly type = 'initialize-version';

  format: DocumentFormat;

  constructor(documentId: string, format: DocumentFormat = DocumentFormat.BOOKLET) {
    super(documentId);
    this.format = format;
  }

  apply(document: models.Document): models.Document {
    // This resets the version. Any existing information will be lost.
    // However, the server-side ID of the version will remain the same.
    const version: models.DocumentVersion = {
      ...document.version,
      pages: [],
      status: 'DRAFT',
    };

    const writingPlan = getWritingPlan(document.meta.writingPlan || 'news-article');
    const firstBlockType = getBlockType(
      writingPlan?.blockTypeIds[0] || models.BlockTypesEnum.Title,
    );

    const firstPage: models.BlockPage = {
      ...createEmptyPage(this.format),
      id: UUID.v1(),
      position: 0,
      documentId: document.id,
    };
    firstPage.grid.blocks.push({
      documentId: document.id,
      typeId: firstBlockType.id,
      id: UUID.v1(),
      x: 1,
      y: 1,
      width: 1,
      height: 1,
      properties: firstBlockType.defaultProperties,
    });
    version.pages.push(firstPage);

    const pageCount = DocumentFormatSettings[this.format].startingPages;
    for (let i = 1; i < pageCount; i += 1) {
      version.pages.push({
        ...createEmptyPage(this.format),
        id: UUID.v1(),
        position: i,
        documentId: document.id,
      });
    }

    return {
      ...document,
      format: this.format,
      version,
    };
  }
}
