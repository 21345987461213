import { styled, darken } from '@mui/system';
import { Menu as MenuUnstyled, MenuItem as MenuItemUnstyled } from '@mui/material';

export const Menu = styled(MenuUnstyled)(() => ({
  '.MuiPaper-root': {
    border: '1px solid #596F93',
    borderRadius: 0,

    ul: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: 0,
      padding: 0,
    },
  },
}));

export const MenuItem = styled(MenuItemUnstyled)(() => ({
  padding: 0,
  width: 'fill-available',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  cursor: 'pointer',

  '&:hover': { background: darken('#fff', 0.05) },

  div: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '.75rem',
    gap: '.25rem',
    width: '100%',

    'svg, img': {
      width: '25px',
    },

    p: {
      fontWeight: 700,
    },
  },
}));
