import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExitFullScreenImg, FullScreenImg, LinkImg } from 'src/assets/icons';
import { ClassJoinCodeImg } from 'src/assets/images';
import { Button, IconButton } from 'src/components/buttons';
import ClickToCopy from 'src/components/ClickToCopy';
import { Description } from 'src/components/typography';
import { lang } from 'src/lang';
import BaseDialog, { DialogContent, DialogTitle } from '../BaseDialog';
import {
  FullScreenButton, JoinCodeBox, JoinCodeBoxHead,
} from './styles';

export default function JoinCodeDialog(
  {
    onClose,
    isOpen,
    code,
    buttonLabel,
    classroomId,
    showBottomAction,
    fromAssignment,
  }: {
    onClose: () => void,
    isOpen: boolean,
    code: string,
    buttonLabel: string,
    classroomId: string | null,
    showBottomAction?: boolean,
    fromAssignment?: boolean,
  },
) {
  const navigate = useNavigate();
  const [isCodeFullScreen, setIsCodeFullScreen] = useState(false);

  const toggleFullScreen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsCodeFullScreen(!isCodeFullScreen);
  };

  let joinCodeShortcutHost = `${window.location.host}`;
  if (window.location.host === 'app.joinpressto.com') {
    // Remove 'app' from the beginning. There is a hard-wired shortcut from
    // joinpressto.com/code -> app.joinpressto.com/code that is configured
    // using WebFlow; rebeca@joinpressto.com was the last person to touch
    // this.
    joinCodeShortcutHost = 'joinpressto.com';
  }
  const joinCodeShortcutUrl = `${window.location.protocol}//${joinCodeShortcutHost}/code/${code}`;

  return (
    <BaseDialog
      size={isCodeFullScreen ? 'full-screen' : 'medium'}
      onClose={() => {
        if (isCodeFullScreen) setIsCodeFullScreen(false);
        else onClose();
      }}
      showControls={!isCodeFullScreen}
      open={isOpen}
      showBackButton={false}
    >
      <DialogContent
        tabIndex={-1}
        role="none"
        onKeyDown={(event) => {
          if (event.key === 'Escape') setIsCodeFullScreen(false);
        }}
      >
        {!isCodeFullScreen && (
          <Grid container>
            <Grid item sm={7}>
              <DialogTitle>{lang('classroom.create_classroom_dialog.step_2.title')}</DialogTitle>
              <Description>{lang('classroom.create_classroom_dialog.step_2.subtitle')}</Description>
            </Grid>
            <Grid item sm={5} display="flex" justifyContent="flex-end">
              <img src={ClassJoinCodeImg} alt="" />
            </Grid>
          </Grid>
        )}

        <div
          style={
            isCodeFullScreen ? {
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            } : {}
          }
        >
          <ClickToCopy text={code}>
            <JoinCodeBox
              style={{
                width: isCodeFullScreen ? '600px' : 'auto',
                transform: isCodeFullScreen ? 'scale(1.5)' : 'none',
              }}
            >
              <JoinCodeBoxHead>
                {joinCodeShortcutHost}
                /code/
              </JoinCodeBoxHead>
              {code}
              <FullScreenButton onClick={toggleFullScreen}>
                <img src={isCodeFullScreen ? ExitFullScreenImg : FullScreenImg} alt="" />
              </FullScreenButton>
            </JoinCodeBox>
          </ClickToCopy>
          {!isCodeFullScreen && (
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
                <ClickToCopy text={joinCodeShortcutUrl} tooltipPlacement="left">
                  <IconButton
                    src={LinkImg}
                    alt=""
                    style={{ border: 'none', marginRight: '-0.75rem' }}
                    label={lang('classroom.create_classroom_dialog.step_2.copy_link')}
                  />
                </ClickToCopy>
              </div>

              {showBottomAction && (
                <Button
                  label={buttonLabel}
                  onClick={() => {
                    if (classroomId && !fromAssignment) navigate(`/classroom/${classroomId}`);
                    onClose();
                  }}
                  style={{
                    margin: '1rem auto 0',
                    padding: '10px 80px',
                  }}
                />
              )}
            </>
          )}
        </div>
      </DialogContent>
    </BaseDialog>
  );
}
