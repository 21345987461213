import React, { useCallback, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Block from 'src/components/Block';
import FluidResizeHandle, { PagePoint } from 'src/components/Block/FluidResizeHandle';
import { getBlockType } from 'src/configuration/blocks';
import { useApplyDocumentOperation, useSelectedBlock } from 'src/hooks/document';
import { SetBlockOperation } from 'src/store/document/operations';
import * as models from 'src/types/models';

/**
 * Wraps a block in a fluid grid. Is draggable and sometimes resizable.
 */
export default function FluidGridCell(props: {
  block: models.Block;
  index: number;
  isEditable?: boolean;
  pixelsPerEm: number;
}) {
  const {
    block, index, isEditable = false,
  } = props;
  const blockType = getBlockType(block);
  const isResizable = blockType?.configuration.hasAdjustableHeight || false;

  const [selectedBlock] = useSelectedBlock();
  const applyOperation = useApplyDocumentOperation();

  // Some blocks have adjustable heights. Heights are always stored in
  // relative units. Since height is resizable, store the height in local
  // state. If the user resizes the block with the resize handle, then
  // apply the new height.
  const [heightEm, setHeightEm] = useState(Number.parseFloat(block.properties.preferredHeight || '1em'));

  const startingBlockHeightEm = useRef<number>(NaN);
  const onResizeHandleDragStart = useCallback(() => {
    startingBlockHeightEm.current = Number.parseFloat(block.properties.preferredHeight || '1em');
  }, [block.properties.preferredHeight]);

  const onResizeHandleChange = useCallback((current: PagePoint, delta: PagePoint) => {
    if (!startingBlockHeightEm.current) {
      return;
    }
    const newHeightEm = startingBlockHeightEm.current + delta.emY;

    // Clamp to 2em minimum height and 50em maximum height.
    const clampedHeightEm = Math.min(Math.max(newHeightEm, 2), 25);
    setHeightEm(clampedHeightEm);
  }, []);

  const onResizeHandleDragEnd = useCallback((current: PagePoint, delta: PagePoint) => {
    const newHeightEm = startingBlockHeightEm.current + delta.emY;
    applyOperation(new SetBlockOperation(block.documentId, {
      id: block.id,
      properties: {
        preferredHeight: `${newHeightEm}em`,
      },
    }));
  }, [applyOperation, block.id, block.documentId]);

  return (
    <Draggable
      draggableId={`block-${block.id}`}
      index={index}
      isDragDisabled={!isEditable}
    >
      {(dragProvided, snapshot) => (
        <div
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
        >
          <div
            style={{
              paddingBottom: '.5em',
              opacity: snapshot.isDragging ? 0.9 : 1.0,
              height: isResizable ? `${heightEm}em` : undefined,
            }}
          >
            <Block
              key={block.id}
              block={block}
              blockType={getBlockType(block)}
              isEditable={isEditable}
              alwaysShowLabels={false}
              labelPosition="left"
              labelOffset="8px"
              dragHandleProps={dragProvided.dragHandleProps || undefined}
            />
            {isResizable && isEditable && selectedBlock?.id === block.id && (
            <FluidResizeHandle
              onChange={onResizeHandleChange}
              onDragEnd={onResizeHandleDragEnd}
              onDragStart={onResizeHandleDragStart}
            />
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
}
