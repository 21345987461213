import { ImageBoxType, TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 0,
    height: 766,
    width: 1080,
  } as ImageBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'TITLE100',
    defaultColor: 'TEXT_COLOR_NBLACK',
    defaultTextAlign: 'ALIGN_CENTER',
    defaultBackground: '#fff',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 144,
    y: 592,
    height: 346,
    width: 792,
  } as TextBoxType,
  {
    key: 'textbox3',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    defaultTextAlign: 'ALIGN_CENTER',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 970,
    height: 886,
    width: 984,
  } as TextBoxType,
];
