import React from 'react';

import { Description, Title } from '../typography';
import { RegisterCreatorImg, RegisterTeacherImg } from '../../assets/images';
import { CheckList, Option, OptionList } from './styles';
import { lang } from '../../lang';

function RoleSelector(props: {
  onSelect: (role: 'student' | 'teacher') => void
}) {
  const { onSelect } = props;

  return (
    <OptionList>
      <Option onClick={() => onSelect('student')}>
        <img style={{ height: '80px' }} src={RegisterCreatorImg} alt="Student or Creator" />
        <CheckList>
          <Title size="small" className="title">{lang('auth.signup.student.label')}</Title>
          <Description>
            &#10003;
            {' '}
            {lang('auth.signup.student.item_1')}
          </Description>
          <Description>
            &#10003;
            {' '}
            {lang('auth.signup.student.item_2')}
          </Description>
          <Description>
            &#10003;
            {' '}
            {lang('auth.signup.student.item_3')}
          </Description>
        </CheckList>
      </Option>
      <Option onClick={() => onSelect('teacher')}>
        <img style={{ height: '80px' }} src={RegisterTeacherImg} alt="Teacher" />
        <CheckList>
          <Title size="small" className="title">{lang('auth.signup.teacher.label')}</Title>
          <Description>
            &#10003;
            {' '}
            {lang('auth.signup.teacher.item_1')}
          </Description>
          <Description>
            &#10003;
            {' '}
            {lang('auth.signup.teacher.item_2')}
          </Description>
          <Description>
            &#10003;
            {' '}
            {lang('auth.signup.teacher.item_3')}
          </Description>
        </CheckList>
      </Option>
    </OptionList>
  );
}

export default RoleSelector;
