import { styled } from '@mui/system';
import { theme } from '../../../utils';
import { DialogContainer, DialogContent, DialogTitle } from '../styles';

export const TurnAssignmentDialogContainer = styled(DialogContainer)(() => ({
  '& > div > div': {
    minHeight: '22rem',
    height: 'unset',
    width: 'unset',
    maxWidth: '60vw',

    '.close-button': {
      top: '.25rem',
      right: '.25rem',
    },

    padding: '3.375rem 5.125rem',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '2.5rem',
      height: '40rem',
      padding: '7.75rem 6.5rem',
    },

    [theme.breakpoints.down('sm')]: {
      maxHeight: 'unset',
      padding: '2.5rem 1.5rem .75rem 1.5rem',
    },
  },

}));

export const TurnAssignmentDialogTitle = styled(DialogTitle)(() => ({
  padding: '0 !important',

  '& > *': { width: '100%' },

  p: {
    color: '#163567 !important',
    fontSize: '3rem',
    textAlign: 'left !important',
    display: 'block',
    lineHeight: '4.5rem',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.875rem',
    },
  },
}));

export const TurnAssignmentDialogContent = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '1.5rem 0 0 0 !important',

  '.content-wrapper': {
    display: 'flex',
    flexDirection: 'column',

    gap: '1.5rem',

    '.subtitle': {
      fontSize: '0.875rem',
      color: '#999999 !important',
    },
  },

  '.dialog-actions': {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'space-between',

    button: {
      height: '3rem',
    },

    '.red-button': {
      background: '#FF5252',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '.5rem',
    },
  },
}));
