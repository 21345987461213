import Masquerade from 'src/views/admin/Masquerade';

const ADMIN_ROUTES = [
  {
    path: '/masquerade',
    name: 'New Layout Document',
    component: Masquerade,
  },
];

export default ADMIN_ROUTES;
