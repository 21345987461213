import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTrackEvent } from 'src/hooks/analytics';
import * as UserAPI from '../../api/User';
import {
  FlexBox, Form, GoogleButton, GradientContainer, Input, Logo,
} from '../../components';
import PrivacyAndTerms from '../../components/PrivacyAndTerms';
import { Button } from '../../components/buttons';
import { Title, Description, Link } from '../../components/typography';
import { useUserStore } from '../../zustand/user';

import { lang } from '../../lang';

function TeacherSignUp() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const setUser = useUserStore((state) => state.setUser);
  const trackEvent = useTrackEvent();

  const navigate = useNavigate();

  const handleChangeEmail = (e: string) => {
    setEmail(e);
    setError('');
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await UserAPI.signUp({ email }, true);
      if (response.status >= 200 && response.status < 300) {
        setUser(response.data.data);

        // Run tracking asynchronously in order to give the user properties
        // and page location time to update.
        setTimeout(() => {
          trackEvent('account-created', {
            user_role: response.data.data.role,
          });
        }, 100);
      } else {
        throw response.data;
      }
    } catch (err: any) {
      setError(err.response.data?.message);

      let url = '';

      if (err.response.data?.message?.includes(lang('auth.errors.email_is_google_account'))) {
        url += '/google-email';
      } else if (err.response.data?.message.includes(lang('auth.errors.field_required', { field: 'birthdate' }))) {
        url += '/birthday-form';
      }

      const params = new URLSearchParams('');

      params.append('email', email);
      params.append('userType', 'teacher');

      navigate(`${url}?${params}`);
    }
    setLoading(false);
  };

  return (
    <GradientContainer>
      <Logo />

      <FlexBox textAlign="center">
        <Title>{lang('auth.signup.teacher_signup.title')}</Title>
        <div>
          <Description align="center">
            {lang('auth.signup.teacher_signup.subtitle.line_1')}
            <br />
            {lang('auth.signup.teacher_signup.subtitle.line_2')}
          </Description>
        </div>
      </FlexBox>

      <FlexBox flexDirection="row" md="column" sm="column" gap={40}>
        <FlexBox gap={40} maxWidth={310} alignSelf="center">
          <FlexBox>
            {/* <Description size="small">For schools using Google for Education</Description> */}
            <GoogleButton type="signUp" />
          </FlexBox>

          <FlexBox>
            <Description size="small">{lang('auth.commom.email_label')}</Description>
            <Form onSubmit={handleSubmit}>
              <Input
                placeholder={lang('auth.commom.email_placeholder')}
                type="email"
                error={!!error}
                invalidMessage={error}
                value={email}
                onChange={(e: any) => handleChangeEmail(e.target.value)}
              />
              <Button type="submit" label={lang('auth.signup.signup')} disabled={!email || loading} large />
            </Form>
          </FlexBox>

          <Description size="small" align="center">
            {lang('auth.signup.already_have_account')}
            {' '}
            <Link href="/">{lang('auth.signup.login')}</Link>
          </Description>

          <PrivacyAndTerms />

        </FlexBox>
      </FlexBox>

    </GradientContainer>
  );
}

export default TeacherSignUp;
