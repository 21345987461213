import React, { useState } from 'react';
import { Card, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CreatePresstoDialog from 'src/components/dialogs/CreatePresstoDialog';

export default function NewBlankDocumentCard() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isCreatePresstoDialogOpen, setIsCreatePresstoDialogOpen] = useState(false);

  const handleCreate = (format: string, meta?: any) => {
    const metaString = meta ? JSON.stringify(meta) : '{}';
    navigate(`/document/new?format=${format}`, { state: { meta: metaString } });
  };

  return (
    <>
      <Card
        elevation={16}
        sx={{
          cursor: 'pointer',
          position: 'relative',
          border: `1px solid ${theme.palette.primary.light}`,
          backgroundColor: 'unset',
          transition: 'all .15s ease-in-out',

          '&:hover': {
            backgroundColor: 'white',
          },
        }}
        onClick={() => setIsCreatePresstoDialogOpen(true)}
      >
        <div
          style={{
            padding: '1rem',
            position: 'relative',
            height: '130px',
            fontWeight: 600,
            fontSize: '18px',
          }}
        >
          <span
            style={{
              color: theme.palette.primary.main,
              fontSize: '36px',
              fontWeight: 300,
            }}
          >
            +
          </span>
          <br />
          Blank
          {' '}
          <br />
          Pressto
        </div>
      </Card>
      <CreatePresstoDialog
        isAssignmentFlow={false}
        onSubmit={handleCreate}
        isOpen={isCreatePresstoDialogOpen}
        onClose={() => setIsCreatePresstoDialogOpen(false)}
      />
    </>
  );
}
