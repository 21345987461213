export enum DocumentFormat {
  BOOKLET = 'booklet',
  STORY = 'story',
  DOC = 'doc',
}

export function isValidDocumentFormat(format: string | null): format is DocumentFormat {
  return Object.values(DocumentFormat).includes(format as DocumentFormat);
}

export enum DocumentTemplateSystem {
  LAYOUT = 'layout',
  BLOCKS = 'blocks',
}
