import { lighten } from '@mui/material';
import { Box, styled } from '@mui/system';

export const Wrapper = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  padding: `${theme.spacing(3)} 0`,

  borderBottom: `1px solid ${theme.palette.divider}`,

  '&:last-child': {
    borderBottom: 'none',
  },

  'p + p': {
    marginTop: theme.spacing(1),
  },

  blockquote: {
    p: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      backgroundColor: lighten(theme.palette.primary.light, 0.9),
    },

    'p + p': {
      marginTop: theme.spacing(3),
    },
  },
}));
