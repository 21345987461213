export function getBorder(noBorder: boolean, isActive: boolean) {
  if (isActive) {
    return '1px solid #163567';
  }
  if (noBorder) {
    return '1px solid transparent';
  }
  return '1px solid #596F93';
}

// The values displayed in the select element doesn't show
// the actual values used to style the text, so this is used to parse
// the value shown to user to the correct value used in the style
export const FONT_SIZES = [
  { label: 14, em: 0.58 },
  { label: 16, em: 0.66 },
  { label: 18, em: 0.75 },
  { label: 20, em: 0.83 },
  { label: 24, em: 1 },
  { label: 28, em: 1.16 },
  { label: 32, em: 1.33 },
  { label: 36, em: 1.5 },
  { label: 40, em: 1.66 },
  { label: 44, em: 1.83 },
  { label: 48, em: 2 },
  { label: 56, em: 2.33 },
  { label: 64, em: 2.66 },
  { label: 72, em: 3 },
  { label: 80, em: 3.33 },
  { label: 100, em: 4.16 },
  { label: 120, em: 5 },
] as const;

export function findFontSizeIndex(size: number) {
  return FONT_SIZES.findIndex(
    (font: { label: number, em: number }) => size === font.em,
  );
}
