import { ButtonUnstyled } from '@mui/base';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled(ButtonUnstyled)(() => ({
  backgroundColor: 'rgb(66, 133, 244)',
  display: 'inline-flex',
  alignItems: 'center',
  color: 'rgb(255, 255, 255)',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
  padding: 0,
  borderRadius: 2,
  border: '1px solid transparent',
  fontSize: 14,
  fontWeight: 600,
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.9,
  },
}));

export const LogoContainer = styled(Box)(() => ({
  backgroundColor: 'white',
  padding: 10,
  borderRadius: 2,
  marginRight: 10,
}));
