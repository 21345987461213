import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Box,
} from '@mui/material';
import { alpha, styled } from '@mui/system';
import { theme } from 'src/utils';

/**
 * Base Dialog styled component
 */
export const Dialog = styled(MuiDialog)(() => ({
  // Dialog not full-screen
  '.MuiDialog-paper:not(.MuiDialog-paperFullScreen)': {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    boxShadow: `4px 4px 0 ${theme.palette.primary.main}`,
  },
  // Backgdrop
  '.MuiBackdrop-root': {
    backgroundColor: alpha(theme.palette.grey.A100, 0.9),
  },
}));

/**
 * Base Dialog Content styled component
 */
export const DialogContent = styled(MuiDialogContent)(() => ({
  '&.MuiDialogContent-root, &.MuiDialogContent-root:first-of-type': {
    padding: '64px 40px 20px',
    [theme.breakpoints.up('sm')]: {
      padding: '64px 80px 40px',
    },
  },
}));

/**
 * Base Dialog Title styled component
 */
export const DialogTitle = styled(MuiDialogTitle)(() => ({
  '&.MuiDialogTitle-root': {
    padding: '0 0 20px 0',
    color: theme.palette.primary.main,
    fontSize: '36px',
    fontWeight: 'normal',
    lineHeight: 1.2,
    [theme.breakpoints.up('sm')]: {
      padding: '0 0 24px 0',
      fontSize: '48px',
    },
  },
}));

/**
 * Base Dialog SubTitle styled component
 */
export const DialogSubTitle = styled(MuiDialogTitle)(() => ({
  '&.MuiDialogTitle-root': {
    padding: '0',
    color: theme.palette.secondary.main,
    fontSize: '18px',
    lineHeight: 1.2,
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
  },
}));

/**
 * Base Dialog Actions styled component
 */
export const DialogControls = styled(MuiDialogActions)(() => ({
  '&.MuiDialogActions-root': {
    position: 'absolute',
    width: '100%',
    top: 0,
    height: '72px',
    padding: '16px',
    [theme.breakpoints.up('sm')]: {
      padding: '24px',
    },
    // Align styles
    '.dialog-control-left': {
      marginRight: 'auto',
    },
    '.dialog-control-center': {
      margin: '0 auto',
    },
    '.dialog-control-right': {
      marginLeft: 'auto',
    },
    // Font styles
    '> *': {
      fontSize: '12px',
      color: theme.palette.primary.light,
    },
  },
})); // maybe DialogActions

/**
 * Base Dialog Actions styled component
 */
export const DialogActions = styled(MuiDialogActions)(() => ({
  '&.MuiDialogActions-root': {
    display: 'block',
    padding: '16px 40px 24px',
    '&.MuiDialogActions-spacing > *': {
      width: '100%',
      '&:not(:first-of-type)': {
        marginLeft: '0px',
        marginTop: '16px',
      },
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      padding: '16px 80px 40px',
      '&.MuiDialogActions-spacing > *': {
        width: 'auto',
        '&:not(:first-of-type)': {
          marginLeft: '64px',
          marginTop: '0px',
        },
      },
    },
  },
}));

/**
 * Base Dialog Image styled component
 */
export const DialogImage = styled(Box, {
  shouldForwardProp: (prop: string) => ![
    'src',
  ].includes(prop),
})(({
  src,
}: {
  src: string,
}) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    position: 'absolute',
    top: '40px',
    right: '80px',
    backgroundImage: `url("${src}")`,
  },
}));
