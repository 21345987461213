import React, { useContext, useEffect, useState } from 'react';
import {
  CheckImg,
  CropImg,
  FlipImg,
  NoImage,
  PictureFrame as PictureFrameIcon,
} from 'src/assets/icons';
// import { useEditor } from 'src/hooks/useEditor';
import { lang } from 'src/lang';
import { Block, BlockTypesEnum } from 'src/types/models';
import UploadDialog from '../../../views/document-v2/dialogs/UploadDialog';
import { ImageAdjustContext } from '../../../views/document-v2/ImageAdjustContext';
import { ToolbarContainer } from './styles';
import IconButton from './ToolbarComponents/IconButton';
import IconTextButton from './ToolbarComponents/IconTextButton';

function ImageBoxToolbar(props: {
  selectedBlock: Block;
  handleUploadImage: (element: string, orientation: string) => void;
  handleFlipImage?: (degree: 0 | 90 | 180 | 270) => void;
  isExpanded: boolean;
  toggleImageToolbar: () => void;
  handleRemoveImage: () => void;
}) {
  const {
    selectedBlock,
    handleUploadImage,
    handleFlipImage,
    isExpanded,
    toggleImageToolbar,
    handleRemoveImage,
  } = props;

  const { isCropping, setIsCropping } = useContext(ImageAdjustContext);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const handleReplace = () => {
    setIsUploadDialogOpen(true);
  };

  const handleFlip = () => {
    const newValue = ((selectedBlock.properties.rotation || 0) + 90) % 360;
    if (handleFlipImage) handleFlipImage(newValue as 0 | 90 | 180 | 270);
  };

  useEffect(() => {
    setIsCropping(false);
  }, [selectedBlock.id]);

  if (!selectedBlock.properties.imageUrl) {
    return (
      <>
        <ToolbarContainer>
          {
            selectedBlock.typeId === BlockTypesEnum.ImageCaption ? (
              <IconButton
                onClick={handleReplace}
                src={PictureFrameIcon}
                alt="replace"
              />
            ) : (
              <IconTextButton
                onClick={handleReplace}
                src={PictureFrameIcon}
                alt="replace"
                label={
                  selectedBlock.properties.imageUrl
                    ? lang('document.artboard.toolbar.replace')
                    : lang('document.artboard.toolbar.image')
                }
              />
            )
          }
        </ToolbarContainer>
        <UploadDialog
          id={`toolbar-${selectedBlock.id}`}
          onUpload={handleUploadImage}
          documentId={selectedBlock.documentId}
          isOpen={isUploadDialogOpen}
          onClose={() => setIsUploadDialogOpen(false)}
        />
      </>
    );
  }

  const renderButtons = () => {
    // If block has text and image, this will handle when image toolbar should appear
    if (!isExpanded) {
      return (
        <IconTextButton
          onClick={toggleImageToolbar}
          src={PictureFrameIcon}
          alt="image"
          label={lang('document.artboard.toolbar.image')}
          reverse
        />
      );
    }

    return (
      <>
        <ToolbarContainer>
          <IconTextButton
            onClick={() => setIsCropping(!isCropping)}
            src={CropImg}
            alt="adjust"
            label={lang('document.artboard.toolbar.adjust')}
          />
          <IconTextButton
            onClick={handleFlip}
            src={FlipImg}
            alt="flip"
            label={lang('document.artboard.toolbar.flip')}
          />
          <IconTextButton
            onClick={handleReplace}
            src={PictureFrameIcon}
            alt="replace"
            label={lang('document.artboard.toolbar.replace')}
          />
          <IconTextButton
            onClick={handleRemoveImage}
            src={NoImage}
            alt="remove"
            label={lang('general.remove')}
          />
          <IconTextButton
            onClick={toggleImageToolbar}
            src={CheckImg}
            alt="done"
            label={lang('general.done')}
          />
        </ToolbarContainer>
        <UploadDialog
          id={`toolbar-${selectedBlock.id}`}
          onUpload={handleUploadImage}
          documentId={selectedBlock.documentId}
          isOpen={isUploadDialogOpen}
          onClose={() => setIsUploadDialogOpen(false)}
        />
      </>
    );
  };

  return isCropping ? (
    <ToolbarContainer>
      <IconTextButton
        onClick={() => setIsCropping(!isCropping)}
        src={CheckImg}
        alt="done"
        label={lang('general.done')}
      />
    </ToolbarContainer>
  ) : renderButtons();
}

export default ImageBoxToolbar;
