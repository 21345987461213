import { Box, styled } from '@mui/system';
import { theme } from '../../utils';
import { FlexBoxProps } from './types';

const FlexBox = styled(Box)<FlexBoxProps>(({
  textAlign, gap, flexDirection, lg, md, sm,
}) => ({
  textAlign: textAlign ?? 'left',
  minWidth: '310px',
  gridGap: gap ?? '10px',
  display: 'grid',
  [theme.breakpoints.up('lg')]: {
    flexDirection: flexDirection ?? 'column',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: lg ?? flexDirection ?? 'column',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: md ?? flexDirection ?? 'column',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: sm ?? flexDirection ?? 'column',
  },
}));

export default FlexBox;
