import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import { ClassJoinCodeImg } from 'src/assets/images';
import { LinkImg } from 'src/assets/icons';
import { IconButton } from 'src/components/buttons';
import ClickToCopy from 'src/components/ClickToCopy';
import { Description } from 'src/components/typography';
import { lang } from 'src/lang';
import BaseDialog, { DialogContent, DialogTitle } from '../BaseDialog';

export default function InviteCoteacherDialog(
  {
    onClose,
    isOpen,
    coteacherInvitationId,
  }: {
    onClose: () => void,
    isOpen: boolean,
    coteacherInvitationId: string,
  },
) {
  const joinCodeShortcutUrl = `${window.location.protocol}//${window.location.host}/coteacher-invitation/${coteacherInvitationId}`;

  return (
    <BaseDialog
      size="medium"
      onClose={onClose}
      open={isOpen}
      showBackButton={false}
    >
      <DialogContent
        tabIndex={-1}
        role="none"
      >
        <Grid container>
          <Grid item sm={7}>
            <DialogTitle>{lang('classroom.invite_coteacher_dialog.title')}</DialogTitle>
            <Description>{lang('classroom.invite_coteacher_dialog.description')}</Description>
          </Grid>
          <Grid item sm={5} display="flex" justifyContent="flex-end">
            <img src={ClassJoinCodeImg} alt="" />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          {coteacherInvitationId && (
            <ClickToCopy text={joinCodeShortcutUrl} tooltipPlacement="left">
              <IconButton
                src={LinkImg}
                alt=""
                style={{ border: 'none', marginRight: '-0.75rem' }}
                label={lang('classroom.invite_coteacher_dialog.copy_link')}
              />
            </ClickToCopy>
          )}
          {!coteacherInvitationId && (
            <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '30%' }} />
          )}
        </div>
      </DialogContent>
    </BaseDialog>
  );
}
