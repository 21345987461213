import * as models from '../../../types/models';
import SynchronousDocumentOperation from './synchronous';

export default class ReorderPageOperation extends SynchronousDocumentOperation {
  readonly type = 'reorder-page';

  pageId: models.Page['id'];

  newPosition: number;

  constructor(documentId: string, pageId: models.Page['id'], newPosition: number) {
    super(documentId);
    this.pageId = pageId;
    this.newPosition = newPosition;
  }

  apply(document: models.Document): models.Document {
    // Move page with ID to new position.
    const { version } = document;

    const oldPosition = version.pages.findIndex((p) => p.id === this.pageId);
    const newPages = [...version.pages];
    if (oldPosition === -1) {
      return document;
    }

    const page = newPages.splice(oldPosition, 1)[0];
    newPages.splice(this.newPosition, 0, page);

    return {
      ...document,
      version: {
        ...version,
        // Pages know what position they're in. Update that.
        pages: newPages.map((p, i) => ({
          ...p,
          position: i,
        })),
      },
    };
  }
}
