/* eslint-disable react/prop-types */
import React, { forwardRef, useImperativeHandle } from 'react';
import { Box } from '@mui/material';
import { ContextMenuProps, ContextMenuRef, Coords } from './types';
import ContextMenuItem from './ContextMenuItem';
import { Menu } from './styles';

const ContextMenu = forwardRef<ContextMenuRef, ContextMenuProps>(
  ({ children, menuItems, disabled }, ref) => {
    const [contextMenu, setContextMenu] = React.useState<Coords | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
      if (disabled) return;
      event.preventDefault();
      setContextMenu(contextMenu === null ? {
        X: event.clientX - 2,
        Y: event.clientY - 4,
      }
        : null);
    };

    const handleClose = () => {
      setContextMenu(null);
    };

    useImperativeHandle(ref, () => ({
      openMenu: handleContextMenu,
    }));

    return (
      <Box
        onContextMenu={handleContextMenu}
        style={{ cursor: 'context-menu' }}
      >
        {children}
        <Menu
          elevation={4}
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.Y, left: contextMenu.X }
              : undefined
          }
        >
          {menuItems.map(
            ({ label, onClick, ...rest }) => (
              <ContextMenuItem
                label={label}
                key={label}
                onClick={(e) => {
                  if (onClick) { onClick(e); }
                  handleClose();
                }}
                {...rest}
              />
            ),
          )}
        </Menu>
      </Box>
    );
  },
);

export default ContextMenu;
