import { EditorState } from 'draft-js';
import create from 'zustand';
import * as DocumentsAPI from 'src/api/Documents';
import {
  Block,
  Document, DocumentVersion, isBlockPage, Page,
} from '../../types/models';
import {
  SynchronousDocumentOperation,
  AsynchronousDocumentOperation,
} from './operations';
import DocumentOperation, { OperationState } from './operations/abstract';

const DEBOUNCE_MILLISECONDS = 1200;

let debounceTimer: NodeJS.Timeout | null = null;

interface StoreDocument extends Document {
  hasTemporaryId?: boolean;
}

export const useDocumentStore = create<{
  applyDocumentOperation:(operation: DocumentOperation,
    localOnly: boolean) => Promise<OperationState>;
  currentOperationState: OperationState;
  currentOperation: DocumentOperation | null;

  documentsById: Record<Document['id'], StoreDocument | null>;
  versionsById: Record<DocumentVersion['id'], DocumentVersion>;
  pagesById: Record<Page['id'], Page>;
  blocksById: Record<Block['id'], Block>;

  setDocument: (document: Document) => void;
  setVersion: (version: DocumentVersion) => void;
  setPage: (page: Page) => void;
  setBlock: (block: Block) => void;

  selectedBlockId: string | null;
  setSelectedBlockId: (blockId: string | null) => void;
  hoveredBlockId: string | null;
  setHoveredBlockId: (blockId: string | null) => void;

  draftEditorStateById: Record<string, EditorState>;
  setDraftEditorState: (
    id: string,
    newEditorState: EditorState | ((editorState: EditorState) => EditorState)
  ) => void;

  historyPosition: number;
  undo: () => void;
  redo: () => void;
  documentHistory: [
    Document,
    DocumentOperation | null,
  ][];
  addUndoMark: (document: Document, operation: DocumentOperation | null) => void;
}>()((set, get) => ({
  currentVersion: null,
  currentOperationState: 'saved',
  currentOperation: null,

  documentsById: {},
  versionsById: {},
  pagesById: {},
  blocksById: {},

  setDocument: (document: Document) => {
    set((state) => {
      if (state.documentsById[document.id] === document) {
        return {};
      }

      // Cascade set to the version.
      state.setVersion(document.version);

      return {
        documentsById: {
          ...state.documentsById,
          [document.id]: document,
        },
      };
    });
  },

  setVersion: (version: DocumentVersion) => {
    set((state) => {
      if (state.versionsById[version.id] === version) {
        return {};
      }

      // Cascade set down to pages.
      version.pages.forEach(state.setPage);

      return {
        versionsById: {
          ...state.versionsById,
          [version.id]: version,
        },
      };
    });
  },

  setPage: (page: Page) => {
    set((state) => {
      if (state.pagesById[page.id] === page) {
        return {};
      }

      // Cascade set down to blocks.
      if (isBlockPage(page)) {
        page.grid.blocks.forEach(state.setBlock);
      }

      return {
        pagesById: {
          ...state.pagesById,
          [page.id]: page,
        },
      };
    });
  },

  setBlock: (block: Block) => {
    set((state) => {
      if (state.blocksById[block.id] === block) {
        return {};
      }

      return {
        blocksById: {
          ...state.blocksById,
          [block.id]: block,
        },
      };
    });
  },

  selectedBlockId: null,
  setSelectedBlockId: (blockId: string | null) => {
    set({
      selectedBlockId: blockId,
    });
  },

  hoveredBlockId: null,
  setHoveredBlockId: (blockId: string | null) => {
    set({
      hoveredBlockId: blockId,
    });
  },

  draftEditorStateById: {},

  // Instead of setting the editor state directly, return a
  // function that can be used to update the editor state
  // based on the previous value of the editor state. This is
  // similar to how React's setState works.
  setDraftEditorState: (
    id: string,
    newEditorState: EditorState | ((editorState: EditorState) => EditorState),
  ) => {
    set((state) => {
      const editorState = state.draftEditorStateById[id] || EditorState.createEmpty();
      return {
        draftEditorStateById: {
          ...state.draftEditorStateById,
          [id]: newEditorState instanceof EditorState
            ? newEditorState
            : newEditorState(editorState),
        },
      };
    });
  },

  undo(): void {
    const history = get().documentHistory;
    const position = get().historyPosition;
    if (history.length < 2) {
      return;
    }

    const document = history[position - 1][0];

    get().setDocument(document);
    set({
      historyPosition: position - 1,
    });

    // TODO: Undo operation?
  },

  redo(): void {
    const history = get().documentHistory;
    const position = get().historyPosition;
    if (!history || position + 1 === history.length) {
      return;
    }

    const document = history[position + 1][0];

    get().setDocument(document);
    set({
      historyPosition: position + 1,
    });
  },

  historyPosition: -1,
  documentHistory: [],
  addUndoMark(document: Document, operation: DocumentOperation | null) {
    set((state) => ({
      historyPosition: state.historyPosition + 1,
      documentHistory: [
        // Discard any history after the current position.
        ...state.documentHistory.slice(0, state.historyPosition + 1),
        [document, operation],
      ],
    }));
  },

  applyDocumentOperation: async (operation: DocumentOperation, localOnly = false) => {
    const originalDocument = get().documentsById[operation.documentId];
    if (!originalDocument) {
      // console.warn(
      //   `Document ${operation.documentId} does not exist `
      //   + `but operation ${operation.id} was applied to it. `,
      // );
      return 'failed';
    }

    if (operation.documentId === 'try-it') {
      localOnly = true;
    }

    // If there is no undo history for this document, create one.
    if (get().documentHistory.length === 0) {
      get().addUndoMark(originalDocument, null);
    }

    // There are two ways to apply operations. Synchronously and asynchronously.
    // The reason for this madness is that it would be super nice
    // if we were able to apply operations to the document without checking
    // in with the API. That is, if they were more idempotent less sensitive
    // to order. I've made the two cases super explicit in the code in order to
    // draw attention to the type of operation being performed and to, ultimately,
    // dissuade use of asynchronous operations in favor of synchronous ones.
    if (operation instanceof SynchronousDocumentOperation) {
      // In the synchronous case, operations are applied to the document
      // immediately and then the operation may make a request to the API.
      const newDocument = operation.apply(originalDocument);
      get().setDocument(newDocument);

      // Construct a promise to encapsulate the various lines of execution
      // triggered by the debounced timers.
      return new Promise((resolve) => {
        const lastOperation = get().currentOperation;
        const lastOperationState = get().currentOperationState;
        set({
          currentOperation: operation,
          currentOperationState: 'queued',
        });

        // Check to see if the current operation can be "merged" into the last one.
        // The typical case for this is typing text. We don't need to track one
        // operation per character.
        const mergedOperation = (
          lastOperation?.type === operation.type
          && lastOperationState === 'queued'
          && lastOperation.mergeNext(operation)
        );
        if (mergedOperation) {
          // The new operation was successfully merged into the last one.
          // We can replace the last history state with this one instead
          // of adding it. No change to the history position is needed.
          // Additionally, replace the current operation for the purpose of reporting
          // out document save status.
          set((state) => ({
            documentHistory: [
              ...state.documentHistory.slice(0, state.documentHistory.length - 1),
              [newDocument, mergedOperation],
            ],
          }));
        } else {
          // The operations cannot be merged. Add this new operation to the
          // history as usual.
          get().addUndoMark(newDocument, operation);
        }

        // If there is a pending operation, cancel it.
        if (debounceTimer) {
          clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
          set({ currentOperationState: 'saving' });

          if (!localOnly) {
            // TODO: I've introduced a bug here in which an operation that
            // changes both the version and the document objects at the same time
            // will be resolved prematurely. All commits to the server must succeed
            // or fail together.
            if (newDocument.version !== originalDocument.version) {
              // Send the new document version to the server.
              DocumentsAPI.updateVersion(newDocument.id, newDocument.version.id, {
                status: newDocument.version.status,
                content: {
                  ...newDocument.version.content,
                  pages: newDocument.version.pages,
                },
              }).then(() => {
                set({ currentOperationState: 'saved' });
                resolve('saved');
              }).catch(() => {
                // TODO: Handle errors more thoroughly. Perhaps they should be
                // retried? This is a place where the document could get out of sync
                // with the server.
                set({ currentOperationState: 'failed' });
                resolve('failed');
              });
            }

            // Shallow compare editable fields on the Document object.
            if (
              newDocument.name !== originalDocument.name
              || newDocument.meta !== originalDocument.meta
              || newDocument.format !== originalDocument.format
            ) {
              DocumentsAPI.updateDocument(newDocument.id, {
                name: newDocument.name,
                meta: JSON.stringify(newDocument.meta),
                format: newDocument.format,
              }).then(() => {
                set({ currentOperationState: 'saved' });
                resolve('saved');
              }).catch(() => {
                resolve('failed');
              });
            }
          }

          set({ currentOperationState: 'saved' });
          resolve('saved');
        }, DEBOUNCE_MILLISECONDS);
      });
    }

    if (operation instanceof AsynchronousDocumentOperation && !localOnly) {
      // In the asynchronous case, control over state updates are handed to
      // the operation to process. This is more like the 'sagas' pattern
      // in a flux-style app, where operations maintain an ongoing connection
      // to the state.
      const setter = (op: (document: Document) => Document) => {
        const document = get().documentsById[operation.documentId];
        if (document) {
          const newDocument = op(document);
          get().setDocument(newDocument);
          get().addUndoMark(newDocument, operation);
        }
      };
      return operation.postAndApply(originalDocument, setter);
    }

    return 'failed';
  },
}));
