import React from 'react';
import { DocumentFormatSettings, SelectableDocumentFormats } from 'src/configuration/documents';
import { FormatSelectionDialogProps } from './types';
import { FormatButton, FormatSelectionDialogContent } from './styles';
import { Description } from '../../typography';
import { ReactComponent as OkImg } from '../../../assets/icons/ok.svg';
import DialogSharedLayout from '../../shared/Dialogs/Layout';

const FORMATS = SelectableDocumentFormats.map((f) => DocumentFormatSettings[f]);

export default function FormatSelectionDialog({
  onClickNext,
  selectedFormat,
  setSelectedFormat,
}: FormatSelectionDialogProps) {
  return (
    <DialogSharedLayout
      titleLangKey="document.create_pressto_dialog.choose_format.title.line_1"
      subtitleLangKey="document.create_pressto_dialog.choose_format.title.line_2"
      handleNext={onClickNext}
    >
      <FormatSelectionDialogContent>
        {FORMATS.map((el) => (
          <FormatButton
            key={el.title}
            selected={el.format === selectedFormat}
            disabled={el.disabled}
            disableRipple
            disableTouchRipple
            onClick={() => { if (!el.disabled) setSelectedFormat(el.format); }}
          >
            <div className="image-container">
              <img src={el.image} alt={el.title} />
              <div className="selected-check">
                <OkImg stroke="#FFFFFF" strokeWidth={2} height={16} width={16} />
              </div>
            </div>
            <div className="text-container">
              <Description>{el.title}</Description>
              <Description>{el.label}</Description>
            </div>
          </FormatButton>
        ))}
      </FormatSelectionDialogContent>
    </DialogSharedLayout>
  );
}
