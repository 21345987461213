export default {
  pressto_count: ({ count }: { count: number }) => `${count} Pressto${count !== 1 ? 's' : ''}`,

  gallery_tab: {
    create: 'New Pressto',
  },

  sort_select: {
    recent: 'New to Old',
    oldest: 'Old to New',
    az: 'A to Z',
    za: 'Z to A',
  },

  pressto_button: {
    delete_pressto: 'Delete this Pressto?',
    share_link: 'Share link',
  },

  info_container: {
    words_written: 'words written',
    pages_filled: 'pages filled',
    images_added: 'images added',
    presstos_created: 'Presstos created',
    all_achievements: 'See all your achievements',
    ongoing_assignments: 'ongoing assignments',
    assignments_fulfilled: 'assignments fulfilled',
  },

  top_bar: {
    invite: 'Invite Students',
    new_pressto: 'New Pressto',
  },
};
