import React, { ReactNode, useState } from 'react';
import {
  Box,
  Collapse,
  Paper,
  useTheme,
} from '@mui/material';
import { Chevron } from 'src/assets/icons/variantComponents';
import { SyntheticKeyboardEvent } from 'react-draft-wysiwyg';

const PADDING = {
  md: 2,
  sm: 1,
};

/**
 * A panel is designed for use on the Artboard. It contains information
 * or tools pertaining to the current document being edited.
 */
export default function Panel(props: {
  children: React.ReactNode;
}) {
  const { children } = props;

  return (
    <Paper
      elevation={0}
      sx={{
        margin: 3,
        // TODO: Could this calculation be done somewhere else?
        // Could we get away with fewer constants?
        maxHeight: 'calc(100vh - 64px - 24px * 2)',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      {children}
    </Paper>
  );
}

Panel.Section = function Section(props: {
  title?: ReactNode;
  isExpandable?: boolean;
  isExpandedByDefault?: boolean;
  children: ReactNode;
}) {
  const {
    title,
    isExpandable = false,
    isExpandedByDefault = true,
    children,
  } = props;
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(isExpandedByDefault);

  return (
    <Box
      sx={{
        '& + &': {
          borderTop: '1px solid',
          borderColor: 'divider',
        },
      }}
    >
      {title && (
        <Box
          {...(isExpandable ? {
            role: 'button',
            onClick: () => setIsOpen(!isOpen),
            onKeyDown: (event: SyntheticKeyboardEvent) => {
              if (event.key === 'Enter' || event.key === ' ') {
                setIsOpen(!isOpen);
              }
            },
            tabIndex: 0,
          } : {})}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: theme.zIndex.drawer,
            backgroundColor: 'background.paper',
            fontSize: '.9rem',
            color: 'secondary.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: PADDING,
            cursor: isExpandable ? 'pointer' : undefined,
          }}
        >
          {title}
          {isExpandable && <Chevron direction={isOpen ? 'down' : 'right'} />}
        </Box>
      )}
      <Collapse in={isOpen} enter={isExpandable}>
        <Box
          sx={{
            padding: PADDING,
            paddingTop: title ? {
              md: 0,
              sm: 0,
            } : undefined,
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};
