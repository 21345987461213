import { useMemo } from 'react';
import create from 'zustand';

export type Alert = {
  type: | 'dialog';
  text: string;
  secondaryText?: string;
  image?: string;
};

// I could put this into src/store, but it's a tiny store and I would prefer
// to encapsulate it entirely behind a hook.
const useAlertStore = create<{
  alerts: Alert[];
  queue:(alert: Alert) => void;
  shift: () => Alert | undefined;
}>()((set, get) => ({
  alerts: [],
  queue(alert: Alert) {
    set((state) => ({
      alerts: [...state.alerts, alert],
    }));
  },
  shift() {
    const [alert, ...rest] = get().alerts;
    if (alert) {
      set({
        alerts: rest,
      });
      return alert;
    }
    return undefined;
  },
}));

/**
 * Access the global queue of alerts that should be displayed on-screen.
 * An alert is, for example, a modal or snackbar that is displayed once and
 * then dismissed. At this time, the alert queue is managed only client-side
 * and is not persisted across page loads. More advanced functionality can
 * be added behind this interface as needed.
 *
 * The queue object is immutable and will trigger the hook when updated.
 * An effect hook can be used to monitor it for changes and then shift
 * to get the latest alert.
 */
export function useAlertQueue() {
  // I do want this to update whenever state.alerts changes, but I do not
  // want to expose that property directly. So listen for any change, but only
  // return the queue and shift functions.
  const state = useAlertStore((s) => s);
  return useMemo(() => ({
    queue: state.queue,
    shift: state.shift,
  }), [state]);
}
