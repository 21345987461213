import { Box, styled } from '@mui/system';
import { GradientContainerProps } from './types';

const GradientContainer = styled(Box)<GradientContainerProps>(({ justifyContent }) => ({
  minHeight: '100vh',
  height: '100%',
  display: 'flex',
  justifyContent: justifyContent ?? 'space-evenly',
  alignItems: 'center',
  flexDirection: 'column',
}));

export default GradientContainer;
