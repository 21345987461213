import { useEffect, useState, useMemo } from 'react';
import * as AssignmentsAPI from 'src/api/Assignments';
import { useResourceStore } from 'src/store/resources';
import { useDocument } from './document';

export function useAssignment(assignmentId: string | undefined) {
  const assignment = useResourceStore((state) => (
    assignmentId ? state.assignmentById[assignmentId] : undefined));
  const setAssignment = useResourceStore((state) => state.setAssignment);
  const document = useDocument(assignment?.baseDocument?.id);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (assignmentId && assignment === undefined) {
      // The assignment has not been loaded.
      // Fetch the Assignment from the server and map to client-side
      // naming convention.
      setIsLoading(true);
      AssignmentsAPI.getById(assignmentId).then((response) => {
        const { data } = response.data;
        setAssignment({
          id: data.id,
          name: data.name,
          classrooms: data.classrooms,
          students: data.students,
          baseDocument: data['base-document'] ? {
            id: data['base-document'].id,
          } : null,
          teacher: {
            id: data.teacher.id,
            name: data.teacher.name,
            surname: data.teacher.surname,
          },
          createdAt: new Date(data.created_at),
          updatedAt: new Date(data.updated_at),

          // Assignment instructions are no longer stored as Notes.
          // Update the representation to instructions.
          // The note will be ignored if `instructions` is set.
          instructions: (
            data.instructions
            || (data.notes?.length ? data.notes[0].text : '')
          ),
          notes: [],

          // These fields are specific to students:
          submission: data.submission ? {
            id: data.submission?.id,
            documentId: data.submission?.document_id,
            status: data.submission?.status,
          } : null,
        });
      }).catch(() => {
        // Pattern in this repository is to use null to represent a loading error.
        // Add specific error handling here if it should be displayed to the user.
        setIsError(true);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [assignmentId, assignment, setAssignment]);

  return useMemo(() => {
    if (isLoading) {
      return undefined;
    }

    if (isError) {
      return null;
    }

    if (assignment) {
      return {
        ...assignment,
        baseDocument: document || assignment?.baseDocument,
      };
    }

    return undefined;
  }, [assignment, document, isLoading, isError]);
}
