import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Theme, useMediaQuery,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StudentsTableRow from './StudentsTableRow';
import { TableWrapper } from './styles';
import { Column } from './types';

export default function StudentsTable(
  { data, provider, setIsJoinClassDialogOpen }: {
    data: any[],
    provider: string,
    setIsJoinClassDialogOpen: () => void,
  },
) {
  const mobileMQ = useMediaQuery((t: Theme) => t.breakpoints.down('sm'));

  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  useEffect(() => {
    if (!mobileMQ) { setExpandedRow(null); }
  }, [mobileMQ]);

  const columns: Column[] = useMemo(() => {
    const defaultColumns = mobileMQ
      ? [{ id: 'name', label: 'Student' }] as Column[]
      : [
        { id: 'name', label: 'Student' },
        { id: 'email', label: 'Id' },
        { id: 'last_session', label: 'Last Session' },
        { id: 'work', label: 'Published Work', format: (value: unknown) => (value ? 'View' : '-') },
      ] as Column[];

    return [...defaultColumns, { id: provider === 'google' ? '' : 'actions', label: '' }];
  }, [mobileMQ, provider]);

  return (
    <TableWrapper>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <Typography color="primary" variant="h5" sx={{ display: 'inline-block' }}>Students</Typography>
        {provider === 'pressto' && (
          <PersonAddIcon color="primary" onClick={setIsJoinClassDialogOpen} cursor="pointer" role="button" />
        )}
      </div>
      <TableContainer sx={{ overflowX: 'visible' }} classes={{ root: 'table-container' }}>
        <Table
          stickyHeader
          aria-label="students table"
          sx={{ borderCollapse: 'separate', borderSpacing: '0 .5rem' }}
        >
          <TableHead className="table-header">
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className="table-header-cell"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {data.map((row) => (
              <StudentsTableRow
                key={row.id}
                row={row}
                columns={columns}
                expanded={expandedRow === row.id}
                onClick={
                  (id: any) => mobileMQ && setExpandedRow((prev) => (prev === id ? null : id))
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
}
