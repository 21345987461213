import { DocumentFormat } from 'src/types/DocumentSettings';
import { DocumentFormatSettings } from 'src/configuration/documents';

export function createEmptyPage(format: DocumentFormat) {
  return {
    meta: '{}',
    grid: {
      rows: DocumentFormatSettings[format].grid.y,
      columns: DocumentFormatSettings[format].grid.x,
      style: DocumentFormatSettings[format].grid.style,
      blocks: [],
    },
  };
}
