import { BoardDefinition, Element } from '../types/LayoutEditor';
import { LayoutPage as PageType } from '../types/models';

export function parsePageToState(prevPage: PageType | null, newPage: PageType | null) {
  if (!newPage) return null;

  const samePage = prevPage?.id === newPage.id;

  const meta = typeof newPage.meta === 'string' ? JSON.parse(newPage.meta) : newPage.meta ?? { backgroundColor: '#FFFFFF' };

  const content = newPage.content && typeof newPage.content === 'string' ? JSON.parse(newPage.content) : newPage.content;

  if (content && samePage) {
    return {
      ...newPage,
      ...meta,
      content: content.map((el: Element) => {
        if (!prevPage) return { ...el, ref: null, loaded: false };
        const prevElement = prevPage.content?.find((x) => x.key === el.key);
        return { ...el, ref: prevElement?.ref || null, loaded: prevElement?.loaded };
      }),
    };
  }

  return {
    ...newPage,
    ...meta,
    content,
  };
}

export function buildHtml(newPage: PageType, board: BoardDefinition) {
  if (!board) return '';
  const { height, width } = board;
  const { backgroundColor, content: pageContent } = newPage;
  let html = `<div id="board" style="height: ${height}px; width: ${width}px; background-color: ${backgroundColor ?? '#FFFFFF'}; margin: 0; padding: 0; box-sizing: border-box">`;

  if (pageContent) {
    html = pageContent.reduce((prev, el) => {
      if (el.html) {
        return `${prev}<div class="${el.type === 'TextBox' ? 'textbox-wrapper' : 'imagebox-wrapper'}" style="height:${el.height}px;width:${el.width}px;top:${el.y}px;left:${el.x}px">${el.html}</div>`;
      }
      return prev;
    }, html);
  }

  html += '</div>';

  return html;
}

export function parseStateToPage(pageState: PageType, board: BoardDefinition) {
  if (!pageState?.content) return null;

  const parsedPage = {
    ...pageState,

    html: buildHtml(pageState, board),

    meta: JSON.stringify({
      backgroundColor: pageState.backgroundColor,
      predominantColor: pageState.predominantColor,
      wordCount: pageState.wordCount,
      signalWords: pageState.signalWords ?? undefined,
      importantWords: pageState.importantWords ?? undefined,
    }),

    content: pageState.content.map((el) => {
      const newEl = { ...el };
      delete newEl.ref;
      delete newEl.loaded;
      return newEl;
    }),
  };

  delete parsedPage.predominantColor;
  delete parsedPage.signalWords;
  delete parsedPage.wordCount;
  delete parsedPage.thumbnail_url;

  return parsedPage;
}
