import {
  Box,
  IconButton, TableCell, TableRow,
} from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { removeStudent } from '../../api/Classrooms';
import { BinImg, ConfigImg, PencilImg } from '../../assets/icons';
import { lang } from '../../lang';
import { useClassroomStore } from '../../zustand/classrooms';
import DeleteConfirmationDialog from '../dialogs/DeleteConfirmationDialog';
import DropdownMenu from '../DropdownMenu';
import { MenuItemType, MenuRefType } from '../DropdownMenu/types';
import { StudentsTableProps } from './types';

function StudentsTableRow({
  row, columns, expanded, onClick,
}: StudentsTableProps) {
  const menuRef = useRef<MenuRefType>(null);

  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { cid } = useParams();

  const getClassroomData = useClassroomStore((state) => state.getClassroomData);

  const WORK_PROPS = useMemo(() => ({
    style: {
      cursor: 'pointer',
      color: '#163567',
      fontSize: '.75rem',
      fontWeight: 700,
    },
    onClick: () => navigate(`${pathname}/student-work/${row.id}`),
  }), [navigate, pathname, row]);

  const menuItems: MenuItemType[] = useMemo(() => [
    {
      key: 'edit',
      label: 'Edit',
      icon: { src: PencilImg, alt: 'edit' },
      onClick: () => { navigate(`${pathname}/student/${row.id}`, { state: { classroom: cid, user: row } }); },
    },
    {
      key: 'remove',
      label: 'Remove',
      icon: { src: BinImg, alt: 'remove' },
      onClick: () => { menuRef.current?.close(); setIsDeleteConfirmationDialogOpen(true); },
    },
  ], [menuRef]);

  const handleRemoveStudent = async () => {
    menuRef.current?.close();
    if (!cid) return;

    const response = await removeStudent(cid, row.id);
    if (response.status === 200) {
      await getClassroomData(cid!);
    }
  };

  return (
    <>
      <TableRow style={{ height: '3.5rem', transform: expanded ? 'scale(1.05)' : '' }} hover tabIndex={-1} key={row.id} className="data-row" onClick={() => onClick(row.id)}>
        {columns.map((column) => {
          if (column.id === 'actions') {
            return (
              <TableCell sx={{ borderBottom: 'none', padding: '.75rem 1.5rem .75rem 0 !important', width: '2rem' }} key="actions" className="actions">
                <IconButton onClick={(e) => {
                  e.stopPropagation();
                  menuRef.current?.open(e);
                }}
                >
                  <img src={ConfigImg} alt="student menu" />
                </IconButton>
                {expanded && (
                  <Box {...(row.has_documents ? WORK_PROPS : {})} className="words_written-expanded">
                    {row.has_documents
                      ? lang('document.side_bar.image_count.title')
                      : '-'}
                  </Box>
                )}
              </TableCell>
            );
          }

          if (column.id === '') {
            return (
              <TableCell sx={{ borderBottom: 'none', padding: '.75rem 1.5rem .75rem 0 !important', width: '2rem' }} key="placeholder-column" className="placeholder-column">
                {expanded && (
                  <Box
                    style={{ marginTop: '3.65rem', ...(row.has_documents ? WORK_PROPS.style : {}) }}
                    onClick={row.has_documents && WORK_PROPS.onClick}
                    className="words_written-expanded"
                  >
                    {row.has_documents
                      ? lang('document.side_bar.image_count.title')
                      : '-'}
                  </Box>
                )}
              </TableCell>
            );
          }

          if (column.id === 'work') {
            return (
              <TableCell sx={{ borderBottom: 'none' }} key={column.id} className={column.id}>
                {row.has_documents
                  ? (
                    <Box
                      style={{
                        cursor: 'pointer',
                        color: '#163567',
                        fontSize: '.75rem',
                        fontWeight: 700,
                      }}
                      onClick={() => navigate(`${pathname}/student-work/${row.id}`)}
                    >
                      {lang('general.view')}
                    </Box>
                  )
                  : '-'}
              </TableCell>
            );
          }

          const value = column.id === 'name' ? `${row.name ?? ''} ${row.surname ?? ''}` : row[column.id];
          return (
            <TableCell sx={{ borderBottom: 'none' }} key={column.id} align={column.align} className={column.id}>
              {value}
              {expanded && (
                <>
                  <p className="last_session-expanded">
                    {`${lang('classroom.classroom_page.last_session')} ${row.last_session}`}
                  </p>
                  <p className="email-expanded">{row.email}</p>
                </>
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <DropdownMenu
        items={menuItems}
        ref={menuRef}
        ignoreResponsiveness
        buttonStyles={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '.5rem',
        }}
      />
      <DeleteConfirmationDialog
        title={lang('classroom.remove_student_dialog.title')}
        description={lang('classroom.remove_student_dialog.subtitle')}
        onSubmit={handleRemoveStudent}
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
    </>
  );
}

export default StudentsTableRow;
