import React from 'react';
import {
  useNavigate, To, Link, useLocation, Path,
} from 'react-router-dom';
import { Description } from 'src/components/typography';
import { ReactComponent as BackArrow } from 'src/assets/icons/back-arrow.svg';
import { lang } from 'src/lang';

function LinkOrBack(props: {
  to?: To;
  children: React.ReactNode;
  state?: any;
}) {
  const { to, children, state } = props;
  const navigate = useNavigate();

  if (to) {
    return (
      <Link
        to={to}
        state={state}
        style={{ textDecoration: 'none' }}
      >
        {children}
      </Link>
    );
  }

  return (
    <span
      className="assignment-back-button"
      role="button"
      tabIndex={-1}
      onClick={() => navigate(-1)}
      onKeyDown={() => navigate(-1)}
      style={{
        cursor: 'pointer',
        strokeWidth: 0,
      }}
    >
      {children}
    </span>
  );
}

/**
 * A back button that goes back to the previous page.
 * The component determines the previous page in this order:
 *
 * 1. Check `location.state.referrer`.
 * 2. If that is empty, then use `props.fallbackTo`.
 * 3. If that is also empty, call `navigate(-1)` to go back. (In which case,
 *    `props.state` is ignored.)
 */
export default function BackButton(props: {
  label?: string;
  fallbackTo?: To;
  fallbackState?: any;
}) {
  const {
    label = lang('general.go_back'),
    fallbackTo,
    fallbackState,
  } = props;
  const location = useLocation();

  // Look at the state of the location to determine where to go back to.
  const referrer: Path | undefined = location.state?.referrer || fallbackTo;
  const referrerLabel: string = location.state?.referrerLabel || label;

  return (
    <LinkOrBack to={referrer} state={fallbackState}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <BackArrow
          style={{
            marginRight: 8,
          }}
          height={16}
          width={16}
          stroke="#999999"
        />

        <Description
          size="small"
          as="span"
        >
          {referrerLabel}
        </Description>
      </div>
    </LinkOrBack>
  );
}
