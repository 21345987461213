import React, { useLayoutEffect, useState } from 'react';
import { BlockType, WritingPlan } from 'src/types/models';
import { getBlockType } from 'src/configuration/blocks';
import {
  WrapperInfo,
  WritingPlanSection,
  SignalWordsItem,
} from './styles';
import { Description } from '../../typography';
import { lang } from '../../../lang';
import CollapsableList from '../../shared/CollapsableList';
import BlocksItem from '../../BlockType';

function WritingPlanInfo(props: {
  writingPlan: WritingPlan
}) {
  const { writingPlan } = props;
  const [wordsIsCollapsed, setWordsIsCollapsed] = useState(true);
  const [blocksIsCollapsed, setBlocksIsCollapsed] = useState(false);

  const blockTypes = writingPlan.blockTypeIds.map(getBlockType);

  useLayoutEffect(() => {
    setWordsIsCollapsed(true);
    setBlocksIsCollapsed(false);
  }, []);

  return (
    <WrapperInfo>
      <Description
        className="description"
        fontWeight={400}
        dangerouslySetInnerHTML={{
          __html: writingPlan.description,
        }}
      />

      <WritingPlanSection className="words-section">
        <Description size="small" className="title-section">
          {lang('document.create_pressto_dialog.writing_plan.use_words_message')}
        </Description>

        <CollapsableList
          list={writingPlan.signalWords}
          isCollapsed={wordsIsCollapsed}
          handleCollapse={() => {
            setWordsIsCollapsed(!wordsIsCollapsed);
            setBlocksIsCollapsed(!blocksIsCollapsed);
          }}
          fontSize=".75rem"
          showLess
          renderItem={(word) => (
            <SignalWordsItem key={word}>
              {word}
            </SignalWordsItem>
          )}
        />
      </WritingPlanSection>

      <WritingPlanSection className="blocks-section">
        <Description size="small" className="title-section">
          {lang('document.create_pressto_dialog.writing_plan.use_blocks_message')}
        </Description>

        <CollapsableList
          list={blockTypes}
          gapItems=".5rem"
          defaultAmountItemsToShow={4}
          isCollapsed={blocksIsCollapsed}
          handleCollapse={() => {
            setBlocksIsCollapsed(false);
            setWordsIsCollapsed(true);
          }}
          renderItem={(blockType: BlockType) => (
            <BlocksItem
              key={blockType.title}
              blockType={blockType}
            />
          )}
        />
      </WritingPlanSection>
    </WrapperInfo>
  );
}

export default WritingPlanInfo;
