import { Switch } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../utils';

const SwitchComponent = styled(Switch)(() => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.secondary.dark}`,
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.secondary.dark,
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 3,
  },
  '& .MuiSwitch-switchBase': {
    transitionDuration: '300ms',
    '&.Mui-checked': {
      color: '#fff',
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.primary.main,
      },
      '& + .MuiSwitch-track': {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.primary.main}`,
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.secondary.main,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
}));

export default SwitchComponent;
