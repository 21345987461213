import React from 'react';
import { IconButtonProps } from './types';
import { IconButtonContainer } from './styles';

function IconButton({
  src, alt, label, ...rest
}: IconButtonProps) {
  return (
    <IconButtonContainer hasLabel={!!label} {...rest}>
      <img src={src} alt={alt} style={{ maxHeight: '1.75rem' }} />
      {!!label && <span>{label}</span>}
    </IconButtonContainer>
  );
}

export default IconButton;
