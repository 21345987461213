import React from 'react';
import { Box } from '@mui/material';

/**
 * Display a block element with a light background that distinguishes the
 * text inside as informational. Use interleaved with other text or form
 * elements.
 */
export default function InfoText(props: {
  children: React.ReactNode
}) {
  const { children } = props;

  return (
    <Box
      sx={{
        backgroundColor: 'grey.A100',
        color: 'primary.dark',
        padding: 1,
        my: 1.5,
        fontSize: '.8rem',

        '& a': {
          color: 'primary.dark',
        },
      }}
    >
      {children}
    </Box>
  );
}
