import React, { useEffect, useState } from 'react';

import {
  FlexBox, GoogleButton, GradientContainer, Logo,
} from '../../components';
import { Title, Description } from '../../components/typography';
import { lang } from '../../lang';

function GoogleEmail() {
  const [email, setEmail] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setEmail(urlParams.get('email') || '');
  }, []);

  return (
    <GradientContainer justifyContent="center">
      <Logo />

      <FlexBox marginTop={10} width="80%">
        <FlexBox textAlign="center">
          <Title>
            {lang('auth.signup.greeting', { name: email })}
          </Title>
          <Description align="center">
            {lang('auth.errors.account_already_exists_google.line_1')}
            <br />
            {lang('auth.errors.account_already_exists_google.line_2')}
          </Description>

          <FlexBox justifySelf="center" marginTop={5} width="310px">
            {/* <Button label="Sign up with Google" large /> */}
            <GoogleButton type="signUp" />
          </FlexBox>
        </FlexBox>
      </FlexBox>

    </GradientContainer>
  );
}

export default GoogleEmail;
