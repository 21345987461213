import React, {
  KeyboardEvent, useCallback, useMemo, useRef, useState,
} from 'react';
import { useTheme } from '@mui/material';
import { usePageContext } from '../../../views/document-v2/PageContext';

export type PagePoint = {
  pxX: number,
  pxY: number,
  emX: number,
  emY: number,
  pagePercentX: number,
  pagePercentY: number,
};

function delta(a: PagePoint, b: PagePoint): PagePoint {
  return {
    pxX: b.pxX - a.pxX,
    pxY: b.pxY - a.pxY,
    emX: b.emX - a.emX,
    emY: b.emY - a.emY,
    pagePercentX: b.pagePercentX - a.pagePercentX,
    pagePercentY: b.pagePercentY - a.pagePercentY,
  };
}

/**
 * A resize handle that is part of page flow. It is full-width and reports
 * its dragged position relative to its starting position. The resize handle
 * does some of the math to convert between screen position (pixels) and
 *
 */
export default function FluidResizeHandle(props: {
  onChange: (currentPoint: PagePoint, delta: PagePoint) => void,
  onDragStart?: (currentPoint: PagePoint) => void,
  onDragEnd?: (currentPoint: PagePoint, delta: PagePoint) => void,
}) {
  const { onChange, onDragStart, onDragEnd } = props;
  const theme = useTheme();
  const {
    pageRef,
    pixelsPerEm,
  } = usePageContext();

  // I have heard getBoundingClientRect() is expensive. Memoize it.
  const pageRect = useMemo(
    () => pageRef?.current?.getBoundingClientRect(),
    [pageRef?.current, pixelsPerEm],
  );

  const makePagePoint = useCallback((clientX: number, clientY: number): PagePoint => {
    if (!pageRect) {
      return {
        pxX: NaN, pxY: NaN, emX: NaN, emY: NaN, pagePercentX: NaN, pagePercentY: NaN,
      };
    }
    return {
      pxX: clientX - pageRect.left,
      pxY: clientY - pageRect.top,
      emX: (clientX - pageRect.left) / pixelsPerEm,
      emY: (clientY - pageRect.top) / pixelsPerEm,
      pagePercentX: (clientX - pageRect.left) / pageRect.width,
      pagePercentY: (clientY - pageRect.top) / pageRect.height,
    };
  }, [pageRect, pixelsPerEm]);

  const [isDragging, setIsDragging] = useState(false);

  // The onChange function is called by a global move event listener.
  // The listener closes over onChange when the drag starts. Wrap it in a ref
  // so that the move event listener always calls the latest instance of onChange.
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const onPointerDown = useCallback((downEvent: React.PointerEvent<HTMLDivElement>) => {
    // Begin the drag action.
    downEvent.preventDefault();
    downEvent.stopPropagation();

    // Note the starting position of the pointer.
    const initialPagePoint = makePagePoint(downEvent.clientX, downEvent.clientY);
    onDragStart?.(initialPagePoint);
    setIsDragging(true);

    // Attach a global listener to mouse move events.
    const onMove = (moveEvent: globalThis.PointerEvent) => {
      // Upon pointer movement, calculate the delta between where the handle
      // started and where it is now.
      const currentPagePoint = makePagePoint(moveEvent.clientX, moveEvent.clientY);
      onChangeRef.current(currentPagePoint, delta(initialPagePoint, currentPagePoint));
    };
    window.addEventListener('pointermove', onMove);

    // When the drag stops, clear the move listener.
    window.addEventListener('pointerup', (upEvent) => {
      window.removeEventListener('pointermove', onMove);
      const currentPagePoint = makePagePoint(upEvent.clientX, upEvent.clientY);
      onDragEnd?.(currentPagePoint, delta(initialPagePoint, currentPagePoint));
      setIsDragging(false);
    }, { once: true });
  }, [makePagePoint, onDragStart, onDragEnd]);

  // Also handle keyboard events to the handler. It resizes in increments
  // of 10px.
  const onKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    const handleRect = event.currentTarget.getBoundingClientRect();
    const handleCenterX = handleRect.left + handleRect.width / 2;
    const handleCenterY = handleRect.top + handleRect.height / 2;

    const handlePagePoint = makePagePoint(handleCenterX, handleCenterY);

    let deltaY = 0;
    if (event.key === 'ArrowUp') {
      deltaY = -10;
    } else if (event.key === 'ArrowDown') {
      deltaY = 10;
    }

    if (deltaY) {
      const newPagePoint = makePagePoint(handleCenterX, handleCenterY + deltaY);
      onDragStart?.(handlePagePoint);
      onChangeRef.current(handlePagePoint, delta(handlePagePoint, newPagePoint));
      onDragEnd?.(newPagePoint, delta(handlePagePoint, newPagePoint));
    }
  }, [makePagePoint, onDragStart, onDragEnd]);

  return (
    // Creates a space around the handle so the mouse can move
    // without losing focus of the element while holding down the button
    <div
      style={{
        position: 'relative',
        zIndex: 5,
      }}
      tabIndex={0}
      role="button"
      onKeyDown={onKeyDown}
      onPointerDown={onPointerDown}
    >
      <div
        aria-label="handle"
        role="button"
        style={{
          height: '2px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          alignItems: 'center',
          cursor: 'ns-resize',
          backgroundColor: isDragging ? theme.palette.primary.light : theme.palette.primary.main,
        }}
      >
        <span
          style={{
            borderRadius: '100%',
            height: 20,
            width: 20,
            backgroundColor: theme.palette.primary.main,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </div>
    </div>
  );
}
