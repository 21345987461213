import React, { useMemo, useState } from 'react';

import { Description } from '../../typography';
import { ImportantWordsSelectionDialogProps } from './types';
import DialogSharedLayout from '../../shared/Dialogs/Layout';
import { lang } from '../../../lang';
import { ImportantWordsField, ImportantWordsCounter, ImportantWordsWrapper } from './styles';

function ImportantWordsSelectionDialog({
  onSkip,
  onAdd,
  initialImportantWords = '',
  onClickBack,
}: ImportantWordsSelectionDialogProps) {
  const [words, setWords] = useState(initialImportantWords);
  const counter = useMemo(() => words.split(',').filter((w: string) => w !== '').length, [words]);

  const handleSubmitImportantWords = () => {
    onAdd(words);
  };

  return (
    <DialogSharedLayout
      titleLangKey="document.create_pressto_dialog.important_words.title.line_1"
      subtitleLangKey="document.create_pressto_dialog.important_words.title.line_2"
      handleSkip={onSkip}
      handleSave={() => handleSubmitImportantWords()}
      handleBack={onClickBack}
    >
      <>
        <Description sx={{
          marginBottom: '1rem',
        }}
        >
          {lang('document.create_pressto_dialog.important_words.field_label')}
        </Description>
        <ImportantWordsWrapper>
          <ImportantWordsField
            value={words}
            style={{ minWidth: '34rem' }}
            placeholder={lang('document.create_pressto_dialog.important_words.field_placeholder')}
            onChange={(e) => setWords(e.target.value)}
          />
          <ImportantWordsCounter>{counter}</ImportantWordsCounter>
        </ImportantWordsWrapper>
      </>
    </DialogSharedLayout>
  );
}

export default ImportantWordsSelectionDialog;
