import { ButtonUnstyled } from '@mui/base';
import { styled } from '@mui/system';

export const CleanButton = styled(ButtonUnstyled)(() => ({
  border: 0,
  height: '40px',
  width: '40px',
  borderRadius: '100%',
  padding: 0,
  background: '#8DC9C3',
  transition: 'all .2s ease-in-out',

  '#avatar': {
    boxSizing: 'border-box',
    cursor: 'pointer',
    color: 'white',
    fontSize: '1.2rem',
    padding: '.375rem',
  },

  ':hover': {
    transform: 'scale(1.1)',
  },
}));
