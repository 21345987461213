import { BlockTypesEnum, WritingPlan } from 'src/types/models';

const plans: WritingPlan[] = [
  {
    id: 'basic',
    title: 'Basic',
    description: '',
    signalWords: [],
    blockTypeIds: [
      BlockTypesEnum.Text,
      BlockTypesEnum.Image,
    ],
  },
  {
    id: 'personal-narrative',
    title: 'Personal Narrative',
    description: 'A <span>Personal Narrative</span> describes an important event or experience in the writer’s life.',
    signalWords: ['at first', 'before', 'during', 'while', 'meanwhile', 'next', 'following', 'later', 'after', 'after awhile', 'all of a sudden', 'suddenly', 'as soon as', 'immediately'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.PeopleAndSetting,
      BlockTypesEnum.Details,
      BlockTypesEnum.Conclusion,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
  },
  {
    id: 'news-article',
    title: 'News Article',
    description: 'A <span>News Article</span> recounts events and provides important information.',
    signalWords: ['before', 'during', 'after', 'later', 'for instance', 'for example', 'furthermore', 'to illustrate', 'specifically', 'such as', 'moreover', 'in addition', 'consequently'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.Lead,
      BlockTypesEnum.MainIdea,
      BlockTypesEnum.SupportingDetails,
      BlockTypesEnum.Conclusion,
      BlockTypesEnum.CallOut,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
  },
  {
    id: 'story',
    title: 'Story',
    description: '<span>Story</span> gives a true or fictional account of characters and their experiences.',
    signalWords: ['at first', 'before', 'during', 'while', 'meanwhile', 'next', 'following', 'later', 'after', 'after awhile', 'all of a sudden', 'suddenly', 'as soon as', 'immediately', 'then', 'finally'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.SettingAndCharacters,
      BlockTypesEnum.ProblemOrGoal,
      BlockTypesEnum.Attempts,
      BlockTypesEnum.StorySolution,
      BlockTypesEnum.Consequence,
      BlockTypesEnum.Resolution,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
  },
  {
    id: 'op-ed',
    title: 'Opinion',
    description: 'An <span>Opinion</span> provides strong evidence to support a writer’s opinion about a topic or idea.',
    signalWords: ['opinion', 'believe', 'think', 'agree', 'disagree', 'best', 'worst', 'most', 'least', 'first', 'second', 'third', 'lastly', 'reason', 'for example', 'for instance', 'in addition to', 'finally', 'evidence', 'significant', 'important'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.ClaimOrOpinion,
      BlockTypesEnum.Evidence,
      BlockTypesEnum.Conclusion,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
  },
  {
    id: 'sequence',
    title: 'Sequence',
    description: '<span>Sequence</span> explains events or steps of a procedure in chronological order.',
    signalWords: ['first', 'second', 'third', 'later', 'next', 'before', 'then', 'finally', 'after', 'when', 'meanwhile', 'since', 'now', 'previously'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.Event,
      BlockTypesEnum.Conclusion,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
  },
  {
    id: 'cause-effect',
    title: 'Cause and Effect',
    description: '<span>Cause and Effect</span> explains the causes of an event and the resulting effects.',
    signalWords: ['as a result', 'because', 'hence', 'thus', 'consequently', 'due to', 'since', 'therefore', 'for this reason', 'in order to', 'on account of'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.Cause,
      BlockTypesEnum.Effect,
      BlockTypesEnum.Conclusion,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
  },
  {
    id: 'compare-contrast',
    title: 'Compare and Contrast',
    description: '<span>Compare and Contrast</span> examines how two or more things are similar and different.',
    signalWords: ['in comparison', 'by contrast', 'similarly', 'but', 'on the other hand', 'on the contrary', 'yet', 'however', 'despite', 'as opposed to'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.Compare,
      BlockTypesEnum.Contrast,
      BlockTypesEnum.Conclusion,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
  },
  {
    id: 'problem-solution',
    title: 'Problem and Solution',
    description: '<span>Problem and Solution</span> describes a problem and explains how it was or could be solved.',
    signalWords: ['problem', 'solution', 'because', 'cause', 'since', 'as a result', 'in order to', 'the problem is', 'a difficulty is', 'to solve', 'to fix', 'to correct'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.Problem,
      BlockTypesEnum.Solution,
      BlockTypesEnum.Conclusion,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
  },
  {
    id: 'description',
    title: 'Description',
    description: '<span>Description</span> explains a topic by stating a claim or big idea and supporting it with important details.',
    signalWords: ['for instance', 'for example', 'furthermore', 'to illustrate', 'specifically', 'such as', 'moreover', 'in addition'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.MainIdea,
      BlockTypesEnum.SupportingDetails,
      BlockTypesEnum.Conclusion,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
  },

  // Custom Writing Plans:
  {
    id: 'melelcon-persuasion',
    title: 'Persuasive Writing',
    description: '<span>Persuasion</span> convinces the reader to agree with the writer’s opinion.',
    signalWords: ['opinion', 'believe', 'think', 'agree', 'disagree', 'best', 'worst', 'most', 'least', 'first', 'second', 'third', 'lastly', 'reason', 'for example', 'for instance', 'in addition to', 'finally', 'evidence', 'significant', 'important'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.Hook,
      BlockTypesEnum.Background,
      BlockTypesEnum.Thesis,
      BlockTypesEnum.MainIdea,
      BlockTypesEnum.EvidenceAndLinks,
      BlockTypesEnum.ConcludingSentence,
      BlockTypesEnum.ConcludingParagraph,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
    showForEmailDomains: ['rlas-116.org', 'ocsb.ca', 'joinpressto.com', 'example.com'],
  },
  {
    id: 'melelcon-analysis',
    title: 'Analysis Writing',
    description: '<span>Analysis</span> examines texts for theme and other meaning.',
    signalWords: ['first', 'second', 'next', 'finally', 'because', 'thus', 'consequently', 'due to', 'since', 'therefore', 'in order to', 'for instance', 'for example', 'furthermore', 'to illustrate', 'such as', 'in addition', 'similarly', 'however', 'on the other hand'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.Hook,
      BlockTypesEnum.Background,
      BlockTypesEnum.Thesis,
      BlockTypesEnum.MainIdea,
      BlockTypesEnum.EvidenceAndLinks,
      BlockTypesEnum.ConcludingSentence,
      BlockTypesEnum.ConcludingParagraph,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
    showForEmailDomains: ['rlas-116.org', 'ocsb.ca', 'joinpressto.com', 'example.com'],
  },
  {
    id: 'retell-the-narrative',
    title: 'Retell the Narrative',
    description: '<span>Retell the Narrative</span> summarizes and retells a story from a new point of view.',
    signalWords: ['first', 'before', 'during', 'while', 'meanwhile', 'next', 'following', 'later', 'after', 'after awhile', 'all of a sudden', 'suddenly', 'as soon as', 'immediately', 'then', 'last'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.Body,
      BlockTypesEnum.Closing,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
    showForEmailDomains: ['rlas-116.org', 'joinpressto.com', 'example.com'],
  },
  {
    id: 'toulmin-method-argument',
    title: 'Toulmin Method Argument',
    description: 'A <span>Toulmin Method Argument</span> is used to convince the reader of the author’s claim.',
    signalWords: ['for example', 'for instance', 'in addition', 'also', 'finally', 'therefore', 'moreover', 'because', 'consequently', 'however', 'in contrast', 'although'],
    blockTypeIds: [
      BlockTypesEnum.Title,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.Claim,
      BlockTypesEnum.Grounds,
      BlockTypesEnum.Warrant,
      BlockTypesEnum.Qualifier,
      BlockTypesEnum.Rebuttal,
      BlockTypesEnum.Backing,
      BlockTypesEnum.Conclusion,
      BlockTypesEnum.Image,
      BlockTypesEnum.ImageCaption,
    ],
    showForEmailDomains: ['joinpressto.com', 'gsv.com'],
  },
];

export function getWritingPlan(planId: string) {
  return plans.find((plan) => plan.id === planId);
}

export default plans;
