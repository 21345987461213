import { Box, Menu } from '@mui/material';
import { styled } from '@mui/system';
import React, {
  forwardRef, useImperativeHandle, useMemo,
} from 'react';
import { useEditor } from '../../../../hooks/useEditor';
import COLORS from '../../../../utils/colors';
import { Description } from '../../../typography';

const PRESSTO_COLORS = Object.keys(COLORS).filter((key) => key.startsWith('P')).reduce((prev, key) => ({ ...prev, [key]: COLORS[key as keyof typeof COLORS] }), {});
const VIBRANT_COLORS = Object.keys(COLORS).filter((key) => key.startsWith('V')).reduce((prev, key) => ({ ...prev, [key]: COLORS[key as keyof typeof COLORS] }), {});
const SOFT_COLORS = Object.keys(COLORS).filter((key) => key.startsWith('S')).reduce((prev, key) => ({ ...prev, [key]: COLORS[key as keyof typeof COLORS] }), {});
const NEUTRAL_COLORS = Object.keys(COLORS).filter((key) => key.startsWith('N')).reduce((prev, key) => ({ ...prev, [key]: COLORS[key as keyof typeof COLORS] }), {});

const ColorPickerContainer = styled(Menu)(() => ({
  marginTop: '.5rem',
  '.MuiList-root': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '.75rem',

    background: '#fff',
    padding: '.5rem',

    border: '0.5px solid #596F93',
    boxShadow: '0px 0px 8px rgba(82, 80, 154, 0.4)',
    borderRadius: '.125rem',

    p: {
      fontSize: '.625rem',
      fontWeight: 700,
    },

    '.color-picker-group': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gap: '.25rem',
    },
  },
}));

const ColorBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'boxColor' })(({ boxColor }: { boxColor: string }) => ({
  height: '1.125rem !important',
  width: '1.125rem !important',

  backgroundColor: boxColor,
  borderRadius: '.125rem',
  cursor: 'pointer',

  padding: 0,
}));

const ColorPicker = forwardRef(
  ({ prefix, onClick }: {
    prefix: string | null,
    onClick: (color: string) => void
  }, ref) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const { setIsBusy } = useEditor();

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setIsBusy(true);
    };

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(null);
      setIsBusy(false);
    };

    useImperativeHandle(ref, () => ({
      open: handleClick,
      isOpen: open,
    }));

    const preventDefault = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };

    function generateColorBoxComponent(colorArray: { [key: string]: string }, p: string | null) {
      return Object.entries<string>(colorArray).map(
        ([key, color]) => {
          const parsedColor = p ? p + key : color;
          return (
            <ColorBox
              key={key}
              boxColor={color}
              onClick={(e: any) => {
                preventDefault(e);
                onClick(parsedColor);
                handleClose(e);
              }}
              onMouseDown={preventDefault}
            />
          );
        },
      );
    }

    const presstoColors = useMemo(
      () => generateColorBoxComponent(PRESSTO_COLORS, prefix),
      [prefix],
    );

    const softColors = useMemo(
      () => generateColorBoxComponent(SOFT_COLORS, prefix),
      [prefix],
    );

    const vibrantColors = useMemo(
      () => generateColorBoxComponent(VIBRANT_COLORS, prefix),
      [prefix],
    );

    const neutralColors = useMemo(
      () => generateColorBoxComponent(NEUTRAL_COLORS, prefix),
      [prefix],
    );

    return (
      <ColorPickerContainer
        id="color-picker"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onChange={preventDefault}
        onAbort={preventDefault}
        onBlur={preventDefault}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div>
          <Description>PRESSTO</Description>
          <div className="color-picker-group">
            {presstoColors}
          </div>
        </div>
        <div>
          <Description>SOFT</Description>
          <div className="color-picker-group">
            {softColors}
          </div>
        </div>
        <div>
          <Description>VIBRANT</Description>
          <div className="color-picker-group">
            {vibrantColors}
          </div>
        </div>
        <div>
          <Description>NEUTRAL</Description>
          <div className="color-picker-group">
            {neutralColors}
          </div>
        </div>
      </ColorPickerContainer>
    );
  },
);

export default ColorPicker;
