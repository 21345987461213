import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LinkComponent } from './styles';

function Link(props: {
  underline?: boolean;
  href: string;
  children: any;
  fontSize?: string;
  fontWeight?: string;
  target?: string;
}) {
  const { href, children } = props;
  const navigate = useNavigate();
  const goTo = () => {
    navigate(href);
  };

  return (
    <LinkComponent {...props} onClick={goTo}>
      {children}
    </LinkComponent>
  );
}

Link.defaultProps = {
  underline: false,
  fontSize: '12px',
  fontWeight: '500',
  target: '',
};

export default Link;
