import { styled } from '@mui/system';

export const ReactionChip = styled('span')(({ theme }) => ({
  display: 'inline-block',
  backgroundColor: theme.palette.grey.A100,
  fontSize: '1.3rem',
  width: '2em',
  height: '2em',
  borderRadius: '100%',
  textAlign: 'center',
  lineHeight: '2em',
  cursor: 'pointer',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: 'transparent',

  // Do some specific things to force the emoji to render as such.
  // This is supported in Firefox.
  fontVariantEmoji: 'emoji',

  // Override the default font family. This works on Macs, at least.
  // Our font, Inter, does not seem to honor the emoji variant selector.
  fontFamily: '--apple-system, emoji, sans-serif',

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));
