import { Grid, Typography, Switch } from '@mui/material';
import React from 'react';

type Configuration = {
  isEnabled: boolean;
};

/**
 * Manage a feedback configuration. Elsewhere, this is called document "meta".
 * This is a presentational component that acts as an input. It renders
 * a toggle, name, and label for a generic configuration. Customization is
 * is done by providing child components.
 */
export default function FeedbackConfiguration(props: {
  configuration: Configuration;
  name: string;
  description: string;
  children: React.ReactNode;
  onChange(configuration: Configuration): void;
  isEditable?: boolean;
}) {
  const {
    name, description, children, onChange,
    configuration: { isEnabled },
    isEditable = false,
  } = props;

  return (
    <Grid container sx={{ my: 3 }}>
      <Grid item xs="auto">
        <Switch
          checked={isEnabled}
          onChange={(e) => onChange({ isEnabled: e.target.checked })}
          readOnly={!isEditable}
          disabled={!isEditable}
          sx={{
            position: 'relative',
            top: '-8px',
            left: '-5px',
          }}
        />
      </Grid>

      <Grid item xs>
        <Typography
          fontWeight={700}
          fontSize=".9rem"
          color={isEnabled ? 'black' : 'secondary'}
        >
          {name}
        </Typography>
        <Typography
          fontSize=".8rem"
          color="secondary"
          sx={{
            marginBottom: 1,
          }}
        >
          {(isEnabled || isEditable) && description}
        </Typography>

        <div
          style={isEnabled ? {} : {
            opacity: 0.5,
            filter: 'grayscale(100%)',
          }}
        >
          {isEditable || isEnabled ? children : null}
        </div>
      </Grid>
    </Grid>
  );
}
