import { styled } from '@mui/system';

export const CollapsableListWrapper = styled('ul')((
  { gap, fontSize }: { gap: string, fontSize?: string },
) => ({
  listStyle: 'none',

  display: 'flex',
  flexWrap: 'wrap',

  gap: gap || '.25rem',
  fontSize: fontSize || '1rem',
}));

export const CollapseButton = styled('li')(() => ({
  cursor: 'pointer',
  fontWeight: 'bold',
}));
