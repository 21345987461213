import { Box, styled } from '@mui/system';

export const NewBlockBox: any = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: '8px',

  color: theme.palette.primary.light,

  '&:hover': {
    color: theme.palette.primary.main,
  },
}));
