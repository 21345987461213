import React from 'react';
import { WordCountImg } from '../../../assets/images';
import { useEditor } from '../../../hooks/useEditor';
import { lang } from '../../../lang';
import { CardContainer } from '../styles';
import { CardProps } from '../types';

function WordCountCard({ expanded }: CardProps) {
  const { selectedPage: page, documentWordCount } = useEditor();

  const renderWordCount = () => {
    const pageCounter = page?.wordCount ?? 0;

    return (
      <div className="word-count-content">
        <div>
          <div className="content-title">Page</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={`content-value ${pageCounter > 0 && 'colored-text'}`}>
              {pageCounter}
            </span>
            {lang('document.side_bar.word_count.words', { count: pageCounter })}
          </div>
        </div>
        <div>
          <div className="content-title">Document</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={`content-value ${documentWordCount > 0 && 'colored-text'}`}>
              {documentWordCount}
            </span>
            {lang('document.side_bar.word_count.words', { count: documentWordCount })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <CardContainer
      backgroundColor="#F4FAF9"
      mainTextColor="#35645E"
    >
      <div className="header">
        <img
          className={`card-icon ${expanded ? 'expanded' : 'visible'}`}
          src={WordCountImg}
          alt="Words Written"
        />
        <div className={expanded ? 'title-open' : ''}>
          {lang('document.side_bar.word_count.title')}
        </div>
      </div>
      {
        expanded && documentWordCount >= 5 && (
          <div className="card-description">
            {lang('document.side_bar.word_count.active_message')}
          </div>
        )
      }
      {expanded && renderWordCount()}
    </CardContainer>
  );
}

export default WordCountCard;
