import { DocumentFormat, DocumentTemplateSystem } from '../types/DocumentSettings';
import { Page } from '../types/models';
import API from './config';
import { DocumentVersionResource, NoteResource } from './types';

export function getAll(params: { order: string; search?: string, type?: string }) {
  return API.get<{
    data: {
      id: string;
      format: string;
      thumbnail_url?: string;
      name: string;
      updated_at: Date;
      user: {
        id: string;
        name: string;
        surname: string;
        email: string;
        role: string
      };
      version: {
        id: string;
        pages: {
          content: any;
        }[];
        status?: string;
        notes?: {
          id: string;
          text: string;
          user: {
            id: string;
            name: string;
            surname: string;
            fullName: string;
          };
        }[];

        // For blocks
        content?: any;
      };
      meta: any;
      submission?: {
        id: string;
        status: string;
        assignment_id: string;
        document_id: string;
        student_id: string;
        assignment?: {
          id: string;
          name: string;
        };
      };
      template_system?: DocumentTemplateSystem;
      created_at: string;
    }[]
  }>('documents', { params });
}

export function getById(id: string) {
  return API.get<{
    // The schema returned by the API (v1.5).
    data: {
      id: string,
      name: string,
      format: DocumentFormat,
      template_system: DocumentTemplateSystem,
      meta: any,
      version: DocumentVersionResource,
      is_public: boolean,
      is_demo: boolean,
      word_count: number,
      created_at: string,
      updated_at: string,
      user: {
        id: string,
      } | null,
      thumbnail_url: string | null,
      submission?: {
        id: string,
        status: 'new' | 'draft' | 'turnedin' | 'returned';
        document_id: string,
        user_id: string,
        classroom_name: string | null,
        assignment_id: string,
        assignment: {
          id: string;
          name: string;
          notes: NoteResource[],
          instructions: string;
          created_at: string;
          updated_at: string;
          teacher: {
            id: string,
            name: string,
            surname: string,
          },
        },
      } | null
    }
  }>(`documents/${id}`);
}

export function getPublished(id: string) {
  return API.get(`documents/${id}/view`);
}

export function setInfo(id: string, data: { name: string; }) {
  return API.put(`documents/${id}`, data);
}

export function create(data: {
  name: string;
  template_system: DocumentTemplateSystem;
  format: string;
  meta: string;
}) {
  return API.post('documents', data);
}

export function createVersion(id: string) {
  return API.post(`documents/${id}/versions`);
}

export function updateVersion(id: string, versionId: string, data: {
  status?: string;
  content?: any;
}) {
  return API.patch<{
    data: DocumentVersionResource,
  }>(`documents/${id}/versions/${versionId}`, data);
}

export function createPage(id: string, versionId: string, data: any) {
  return API.post<{
    // TODO: move this type in a PageResource?
    data: {
      id: string;
      position: number;
      template: string;
      word_count: number;
      meta: any;
      content: string;
      thumbnail_url: string;
    };
  }>(`documents/${id}/versions/${versionId}/pages`, data);
}

interface UpdatePageData extends Omit<Page, 'position'> {
  position: undefined;
  content: string;
}

export function updatePage(id: string, versionId: string, data: UpdatePageData) {
  return API.put(`documents/${id}/versions/${versionId}/pages/${data.id}`, data);
}

export function getPages(id: string, versionId: string) {
  return API.get(`documents/${id}/versions/${versionId}/pages`);
}

export function getPage(id: string, versionId: string, pageId: string) {
  return API.get<{
    data: {
      id: string;
      position: number;
      template: string;
      word_count: number;
      meta: any;
      content: string;
      thumbnail_url: string;
    }
  }>(`documents/${id}/versions/${versionId}/pages/${pageId}`);
}

export function deletePage(id: string, versionId: string, pageId: string) {
  return API.delete(`documents/${id}/versions/${versionId}/pages/${pageId}`);
}

export function updateStatus(id: string, data: { status: string; is_public: boolean; }) {
  return API.post(`documents/${id}/versions/${id}/update-status`, data);
}

export function deleteDocument(id: string) {
  return API.delete(`documents/${id}`);
}

export function duplicateDocument(id: string) {
  return API.post(`documents/${id}/duplicate`);
}

export function duplicatePage(id: string) {
  return API.post(`pages/${id}/duplicate`);
}

export function print(id: string, versionId: string) {
  return API.get(`documents/${id}/versions/${versionId}/print`);
}

export function updateDocument(id: string, data: {
  name?: string;
  format?: DocumentFormat;
  meta?: string;
}) {
  return API.put(`/documents/${id}`, data);
}

export function reorderPage(pageId: string, position: number) {
  return API.post(`/pages/${pageId}/reorder/${position}`);
}
