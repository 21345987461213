import {
  Document, ChatMessage,
} from 'src/types/models';
import SynchronousDocumentOperation from './synchronous';

type PartialChatMessage = Omit<ChatMessage, 'sequence' | 'clientCreatedAt'>;

/**
 * Save a message to the çhat history of the document. This operation does not
 * generate any messages. The source of the message must be supplied in addition
 * to the message itself.
 */
export default class AddChatMessageOperation extends SynchronousDocumentOperation {
  readonly type = 'add-chat-message';

  message: PartialChatMessage;

  constructor(
    documentId: string,
    message: PartialChatMessage,
  ) {
    super(documentId);
    this.message = message;
  }

  apply(document: Document): Document {
    const { version } = document;
    const messages = version.content!.messages || [];

    return {
      ...document,
      version: {
        ...version,
        content: {
          ...version.content!,
          messages: [
            ...messages,
            {
              ...this.message,
              clientCreatedAt: this.clientCreatedAt,
              sequence: messages.length + 1,
            },
          ],
        },
      },
    };
  }
}
