import { Tooltip } from '@mui/material';
import React, {
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import { lang } from 'src/lang';

export default function ClickToCopy(props: {
  text: string;
  children: React.ReactNode;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
}) {
  const { text, children, tooltipPlacement = 'bottom' } = props;
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = useCallback(async (e: SyntheticEvent) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
  }, [text]);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2500);
    }
  }, [isCopied]);

  return (
    <Tooltip
      open={isCopied}
      title={lang('general.copied')}
      arrow
      placement={tooltipPlacement}
    >
      <span
        onClick={handleCopy}
        onKeyUp={handleCopy}
        role="button"
        tabIndex={0}
        style={{
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
}
