import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Theme, useMediaQuery,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { createCoteacherInvitation } from 'src/api/Classrooms';
import TeachersTableRow from './TeachersTableRow';
import { TableWrapper } from './styles';
import { Column } from './types';

export default function TeachersTable(
  {
    classroomId,
    data,
    provider, /* updateClassroom */
    setIsCoteacherInvitationDialogOpen,
    setCoteacherInvitationId,
    canInviteCoteachers,
  }: {
    classroomId: string,
    data: any[],
    provider: string,
    setIsCoteacherInvitationDialogOpen: (isOpen: boolean) => void,
    setCoteacherInvitationId: (coteacherInvitationId: string) => void,
    canInviteCoteachers: boolean,
  },
) {
  const mobileMQ = useMediaQuery((t: Theme) => t.breakpoints.down('sm'));

  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  useEffect(() => {
    if (!mobileMQ) { setExpandedRow(null); }
  }, [mobileMQ]);

  const addTeacher = async () => {
    setIsCoteacherInvitationDialogOpen(true);
    const coteacherInvitationData = classroomId
      ? await createCoteacherInvitation({ classroomId })
      : null;
    if (coteacherInvitationData?.data.data.id) {
      // TODO: Do we have a concept of loading spinners/animations? Might be good here.
      setCoteacherInvitationId(coteacherInvitationData.data.data.id);
    } else {
      // TODO: error here?
    }
  };

  const columns: Column[] = useMemo(() => {
    const defaultColumns = mobileMQ
      ? [{ id: 'name', label: 'Teacher' }] as Column[]
      : [
        { id: 'name', label: 'Teacher' },
        { id: 'email', label: 'Id' },
        // These spacers are questionable.
        // Is there a better way to get the ID columns to align between
        // this table and the students table?
        { id: 'spacer', label: '' },
        { id: 'spacer', label: '' },
      ] as Column[];

    return [...defaultColumns, { id: provider === 'google' ? '' : 'actions', label: '' }];
  }, [mobileMQ, provider]);

  return (
    <TableWrapper style={{ marginBottom: '2rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <Typography color="primary" variant="h5" sx={{ display: 'inline-block' }}>Teachers</Typography>
        {provider === 'pressto' && canInviteCoteachers && (
          <PersonAddIcon color="primary" onClick={addTeacher} cursor="pointer" role="button" />
        )}
      </div>
      <TableContainer sx={{ overflowX: 'visible' }} classes={{ root: 'table-container' }}>
        <Table
          stickyHeader
          aria-label="teachers table"
          sx={{ borderCollapse: 'separate', borderSpacing: '0 .5rem' }}
        >
          <TableHead className="table-header">
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className="table-header-cell"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {data.map((row) => (
              <TeachersTableRow
                key={row.id}
                row={row}
                columns={columns}
                expanded={expandedRow === row.id}
                onClick={
                  (id: any) => mobileMQ && setExpandedRow((prev) => (prev === id ? null : id))
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
}
