import React from 'react';

import { ReactComponent as TextColorImg } from 'src/assets/icons/text-color.svg';
import { ReactComponent as TextBackgroundImg } from 'src/assets/icons/text-background.svg';
import { ReactComponent as BrushImg } from 'src/assets/icons/brush.svg';
import { Tooltip } from '@mui/material';
import { IconButtonProps } from '../types';
import { Button } from '../styles';

function IconButton(
  {
    src, alt, toggle, isActive, variant, onClick, noBorder, id,
    isSmall = false,
  }: IconButtonProps,
) {
  const preventDefault = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const renderSpecificSVG = ['TextColorImg', 'TextBackgroundImg', 'ContainerBackgroundImg'].includes(src);

  return (
    <Tooltip title={alt}>
      <Button
        id={id}
        type="button"
        className="icon-button"
        onClick={onClick}
        noBorder={toggle || noBorder}
        isActive={isActive}
        isSmall={isSmall}
        onMouseDown={preventDefault}
        sx={{
          backgroundColor: 'background.paper',
        }}
      >
        <div className="button-icon" title={alt}>
          {src === 'TextColorImg' && <TextColorImg stroke={variant} />}
          {src === 'TextBackgroundImg' && <TextBackgroundImg stroke={variant === 'transparent' ? 'white' : variant} />}
          {src === 'ContainerBackgroundImg' && <BrushImg stroke="#2C2C2C" />}
          {!renderSpecificSVG && <img src={src} alt={alt} />}
        </div>
      </Button>
    </Tooltip>
  );
}

export default IconButton;
