import React, { useEffect } from 'react';
import { InputProps } from './types';
import { ErrorMessage } from '../typography';
import { BaseInput } from './styles';

function Input(props: InputProps) {
  const {
    invalidMessage, error, ...rest
  } = props;

  useEffect(() => {
    document.addEventListener('invalid', () => (e: any) => e.preventDefault(), true);
  }, []);

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <BaseInput {...rest} error={error} />
      </div>
      {error && invalidMessage && <ErrorMessage>{invalidMessage}</ErrorMessage>}
    </div>
  );
}

export default Input;
