import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../utils';
import { DescriptionProps, LinkProps, TitleProps } from './types';

export const Description = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'hideOnMobile' && prop !== 'highlight',
})<DescriptionProps>(({
  size, align, hideOnMobile, weight, highlight,
}) => ({
  fontSize: size === 'small' ? '0.75rem' : '0.875rem',
  fontWeight: weight ? `${weight} !important` : 400,
  color: highlight ? theme.palette.secondary.dark : theme.palette.secondary.main,
  lineHeight: '1.3125rem',
  textAlign: align ?? 'left',
  [theme.breakpoints.down('sm')]: {
    display: hideOnMobile ? 'none' : 'block',
  },
  a: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));

export const ErrorMessage: any = styled(Typography)(() => ({
  color: theme.palette.error.main,
  fontWeight: 700,
  fontSize: 12,
}));

export const LinkComponent = styled('span', {
  shouldForwardProp: (prop) => prop !== 'underline',
})<LinkProps>(({ underline, fontSize, fontWeight }) => ({
  color: theme.palette.primary.main,
  textDecoration: underline ? 'underline' : 'none',
  fontSize: fontSize ?? '12px',
  fontWeight: fontWeight ?? 500,
  cursor: 'pointer',
}));

export const Title = styled(Typography)<TitleProps>(({ size, lineHeight, fontWeight }) => ({
  fontSize: size === 'small' ? '1.125rem' : '2rem',
  fontWeight: fontWeight ?? 700,
  lineHeight: lineHeight ?? '48px',
}));
