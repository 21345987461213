import React, { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DocumentTemplateSystem } from 'src/types/DocumentSettings';

import * as DocumentsAPI from '../../api/Documents';
import { Artboard } from '../../components';
import { Container, Content } from './styles';

function CreateDocument() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { state } = useLocation() as { state: any };

  const createDocument = async () => {
    const format = searchParams.get('format');
    if (!format) { navigate('/gallery'); return; }

    try {
      const response = await DocumentsAPI.create({
        name: 'Untitled',
        template_system: DocumentTemplateSystem.LAYOUT,
        format,
        meta: state?.meta ?? '',
      });
      if (response.status !== 200 && response.status !== 201) throw response.data;
      navigate(`/document/${response.data.data.id}`, { replace: true });
    } catch (error) {
      navigate('/gallery');
    }
  };

  useEffect(() => {
    createDocument();
  }, []);

  return (
    <Container>
      <Content>
        <Artboard isLoading animateLoading />
      </Content>
    </Container>
  );
}

export default CreateDocument;
