import { Card } from '@mui/material';
import { styled } from '@mui/system';

export const ButtonContainer = styled(Card)<any>(() => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  background: '#F6F6FA',
  position: 'relative',
  minHeight: '20rem',

  '&:hover': {
    '.info-container': {
      backgroundColor: '#FFF',
    },
  },

  '.user-info-container': {
    display: 'flex',
    padding: '.75rem',

    p: {
      color: '#163567',
      fontWeight: 700,
    },

    img: {
      height: '1.25rem',
      marginRight: '.5rem',
    },
  },

  '.image-container': {
    height: '11rem',
    overflow: 'hidden',
    position: 'relative',

    '& > img': {
      position: 'absolute',
      height: '100%',
      top: '1.875rem',
      left: '50%',
      transform: 'translate(-50%, 0%)',
    },

    '& > div': {
      position: 'absolute',
      height: '100%',
      top: '1.875rem',
      left: '50%',
      transform: 'translate(-26%, 0%)',
    },
  },

  '.tags-list': {
    display: 'flex',
    gap: '.25rem',
    height: '1.5rem',
  },

  '.info-container': {
    position: 'absolute',
    bottom: 0,
    backgroundColor: '#F6F6FA',
    transition: 'background-color .15s ease-out',
    borderTopRightRadius: '50px',
    minHeight: '8.75rem',
    padding: '.75rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '.pressto-title': {
      lineHeight: '21px',
      minHeight: '42px',
    },

    p: {
      color: '#000000',
      fontWeight: '700',
      fontFamily: 'Inter !important',
      lineHeight: '150%',
    },

    '.word-count': {
      color: '#999999',
      fontWeight: 400,
      fontSize: '.75rem',
    },
  },
}));
