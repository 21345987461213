import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const TabContainer = styled(Box)(() => ({
  alignSelf: 'end',
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  height: '100%',
  alignItems: 'flex-end',
  position: 'relative',

  '.tab-indicator': {
    background: '#163567',
    height: '.25rem',
  },

  '.MuiTabs-root': {
    maxHeight: '3rem',
    minWidth: '60%',
    position: 'relative',

    '.MuiTab-root': {
      paddingLeft: '2em',
      paddingRight: '2em',
    },

    button: {
      fontFamily: 'Roboto !important',
      fontSize: '.875rem',
      lineHeight: '1.5rem',
      color: '#596F93',
    },

    '.Mui-selected': {
      color: '#163567 !important',
      fontWeight: 700,
    },
  },
}));
