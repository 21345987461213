import React, { useState } from 'react';

import { lang } from 'src/lang';
import { Description } from 'src/components/typography';
import { Button } from 'src/components/buttons';

import BaseDialog, { DialogActions, DialogContent, DialogTitle } from '../BaseDialog';

function DeleteConfirmationDialog({
  title,
  description,
  onSubmit,
  isOpen,
  onClose,
}: {
  title: string,
  description?: string,
  onSubmit: () => void | Promise<void>,
  isOpen: boolean;
  onClose: (...args: any[]) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
    onClose();
  };

  return (
    <BaseDialog
      onClose={onClose}
      open={isLoading || isOpen}
    >
      <DialogContent>
        <DialogTitle>{title}</DialogTitle>
        <Description className="subtitle">
          {
            description ?? lang('general.delete_account_default_message')
          }
        </Description>
      </DialogContent>
      <DialogActions>
        <Button
          label={lang('general.close')}
          outline
          onClick={onClose}
          disabled={isLoading}
        />
        <Button
          label={lang('general.delete')}
          error
          onClick={handleDelete}
          disabled={isLoading}
        />
      </DialogActions>
    </BaseDialog>
  );
}

export default DeleteConfirmationDialog;
