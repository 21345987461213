import React, { useMemo } from 'react';

import { ImageFeedbackImg } from '../../../assets/images';
import { useEditor } from '../../../hooks/useEditor';
import { lang } from '../../../lang';
import { BorderLinearProgress, CardContainer } from '../styles';
import { CardProps } from '../types';

function ImageFeedbackCard({ expanded }: CardProps) {
  const { numberOfImages, numberOfImageSlots } = useEditor();

  const getProgressValue = useMemo(
    () => (100 * numberOfImages) / numberOfImageSlots,
    [numberOfImages, numberOfImageSlots],
  );

  const renderProgress = () => (
    <div className="progress-wrapper">
      <BorderLinearProgress
        variant="determinate"
        value={getProgressValue <= 100 ? getProgressValue : 100}
      />
    </div>
  );

  return (
    <CardContainer
      backgroundColor="#EAF5D9"
      mainTextColor="#39540B"
    >
      <div className="header">
        <img
          className={`card-icon ${expanded ? 'expanded' : 'visible'}`}
          src={ImageFeedbackImg}
          alt=""
        />
        <div className={expanded ? 'title-open' : ''}>
          {lang('document.side_bar.image_count.title')}
        </div>
      </div>
      {
        expanded && (
          <div className="card-description">
            {lang('document.side_bar.image_count.message')}
          </div>
        )
      }
      {expanded && renderProgress()}
    </CardContainer>
  );
}

export default ImageFeedbackCard;
