import { createContext, useContext } from 'react';
import { BlockPage } from 'src/types/models';

interface PageContextType {
  page: BlockPage | null,
  pageRef: React.RefObject<HTMLDivElement> | null,
  scale: number,
  pixelsPerEm: number,
}

export const PageContext = createContext<PageContextType>({
  page: null,
  pageRef: null,
  scale: 1.0,
  pixelsPerEm: 16.0,
});

export const PageContextProvider = PageContext.Provider;

export function usePageContext(): PageContextType {
  return useContext(PageContext);
}
