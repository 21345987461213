import * as AssignmentsAPI from 'src/api/Assignments';
import { Document } from '../../../types/models';
import { OperationState } from './abstract';
import AsynchronousDocumentOperation from './asynchronous';

export default class TurnInSubmission extends AsynchronousDocumentOperation {
  readonly type = 'turn-in-submission';

  // eslint-disable-next-line class-methods-use-this
  async postAndApply(
    document: Document,
    set: (fn: (document: Document) => Document) => void,
  ): Promise<OperationState> {
    if (document.submission?.id) {
      await AssignmentsAPI.submit(document.submission.assignmentId, document.submission.id);
      set((doc) => ({
        ...doc,
        submission: doc.submission ? {
          ...doc.submission,
          status: 'turnedin',
        } : null,
      }));
      return 'saved';
    }
    return 'saved';
  }
}
