import { AppBar, Box, Toolbar } from '@mui/material';
import React from 'react';
import AvatarMenu from 'src/components/AvatarMenu';
import { Button } from 'src/components/buttons';
import { useRedirectToLogin } from 'src/hooks/authorization';
import { lang } from 'src/lang';
import { useUserStore } from 'src/zustand/user';

export default function TopBar(props: {
  children: React.ReactNode;
}) {
  const { children } = props;
  const user = useUserStore((state) => state.user);
  const redirectToLogin = useRedirectToLogin();
  const redirectToRegistration = useRedirectToLogin({ loginUrl: '/register' });

  return (
    <>
      <AppBar
        color="inherit"
        elevation={0}
        position="fixed"
        sx={{
          borderWidth: 0,
          borderBottomWidth: 1,

          '.ButtonUnstyled-root': {
            span: {
              display: {
                xs: 'none',
                sm: 'unset',
              },
            },
          },
        }}
      >
        <Toolbar sx={{
          gap: 4,
        }}
        >
          {children}

          <TopBar.Section>
            {
              user?.id ? (
                <AvatarMenu />
              ) : (
                <>
                  <Button
                    outline
                    label={lang('auth.signin.login')}
                    onClick={() => redirectToLogin()}
                    style={{ height: '2.5rem' }}
                  />
                  <Button
                    label={lang('auth.signin.register')}
                    onClick={() => redirectToRegistration()}
                    style={{ height: '2.5rem' }}
                  />
                </>
              )
            }
          </TopBar.Section>
        </Toolbar>
      </AppBar>

      {/*
      This second toolbar exists only to offset the top of the document
      by the height of one toolbar. This is suggested by the MUI documentation:
      https://mui.com/material-ui/react-app-bar/#fixed-placement
      */}
      <Toolbar />
    </>
  );
}

TopBar.Section = function Section(props: {
  children: React.ReactNode;
}) {
  const { children } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: 1,
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
};

TopBar.Spacer = function Spacer() {
  return (
    <Box flexGrow={1} />
  );
};
