import { useTheme } from '@mui/material';
import React from 'react';

/**
 * Indicate the persistance state of an object.
 */
export default function SaveStatus(props: {
  status: null | 'queued' | 'merged' | 'saving' | 'saved' | 'failed';
}) {
  const { status } = props;
  const theme = useTheme();

  return (
    <span
      style={{
        fontSize: '.75rem',
        color: theme.palette.secondary.main,
      }}
    >
      {status && {
        queued: 'Saved •',
        merged: 'Saved •',
        saving: 'Saving',
        saved: [
          'Saved',
          <span key="saved" style={{ color: '#9EC264' }}> ✓</span>,
        ],
        failed: (
          <span
            key="failed"
            style={{ color: theme.palette.error.main }}
          >
            Error
          </span>
        ),
      }[status]}
    </span>
  );
}
