import React, { useCallback, useEffect, useState } from 'react';
import { Alert, useAlertQueue } from 'src/hooks/alerts';
import Dialog from './Dialog';

type AlertComponent = (props: {
  alert: Alert,
  onClose: () => void,
}) => JSX.Element;

// Components must accept an alert property and an onClose callback.
const COMPONENT_BY_TYPE: Record<Alert['type'], AlertComponent> = {
  dialog: Dialog,
  // Add new alert types to Alert and define a component for it here.
};

/**
 * Listen to the global alert queue for the application and display any
 * queued alerts one at a time. This is designed to be used once per page,
 * but it could be adapted to use a context provider for the alert queue
 * instead of a global store, if local use is desired.
 */
export default function AlertQueue() {
  const queue = useAlertQueue();
  const [alert, setAlert] = useState<Alert>();

  useEffect(() => {
    // Whenever the queue changes or there is not already an alert
    // being displayed, take the next alert off the queue and display it.
    setAlert((previousAlert) => {
      if (!previousAlert) {
        return queue.shift();
      }
      return previousAlert;
    });
  }, [queue, alert]);

  const onClose = useCallback(() => {
    // Hide the current alert and then check to see if there are any new alerts
    // to display.
    setAlert(undefined);
  }, [alert]);

  if (alert) {
    const Component = COMPONENT_BY_TYPE[alert.type];
    return (
      <Component alert={alert} onClose={onClose} />
    );
  }
  return null;
}
