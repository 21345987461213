import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { DocumentFormat, DocumentTemplateSystem } from 'src/types/DocumentSettings';
import { documentWordCount } from 'src/utils/documents';
import { Description } from '../typography';
import { PersonImg } from '../../assets/icons';
import { lang } from '../../lang';
import { AssignmentDocumentButtonProps } from './types';
import StatusTag from '../StatusTag';
import { ButtonContainer } from './styles';
import getPlaceholderDocumentByFormat from '../../utils/getPlaceholderDocumentByFormat';
import { getPresstoCardPredominantColor } from '../../utils';
import Page from '../Page';

export default function AssignmentDocumentButton({ pressto: document = {}, user, status = 'new' }: AssignmentDocumentButtonProps) {
  const {
    name, thumbnail_url: thumbnailUrl,
    id, version, format,
    template_system: templateSystem,
  } = document;

  const navigate = useNavigate();
  const location = useLocation();

  const predominantColor = useMemo(() => (
    getPresstoCardPredominantColor(version)
  ), [version]);

  const wordCount = useMemo(() => (
    // This code is writting against something like the API-representation
    // of a document. Map the fields to those expected by the function.
    documentWordCount({
      ...document,
      templateSystem: document.template_system,
      version: document.version ? {
        ...document.version,
        // Test for the newest representation of pages, then fall back to the
        // original.
        pages: document.version.content?.pages || document.version.pages,
      } : undefined,
    })
  ), [document]);

  return (
    <ButtonContainer
      className="pressto-button"
      onClick={() => status !== 'new' && navigate(`/document/${id}`, { state: { prev: location.pathname } })}
      elevation={16}
    >
      <div className="user-info-container">
        <img src={user?.avatar ?? PersonImg} alt={`${user.name} ${user.surname}`} />
        <Description>{`${user.name || ''} ${user.surname || ''}`}</Description>
      </div>
      <div className="image-container" style={{ backgroundColor: predominantColor }}>
        {templateSystem === DocumentTemplateSystem.BLOCKS ? (
          // Be extra-guarded here because so many Presstos are displayed
          // at once. Better to fail silently than to crash the gallery.
          document.version?.content?.pages?.length && (
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Page
                format={format as DocumentFormat}
                page={document.version.content.pages[0]}
                scale={0.14}
                isEditable={false}
                isThumbnail
              />
            </Box>
          )
        ) : (
          <img src={thumbnailUrl || getPlaceholderDocumentByFormat(format as string)} alt={name} />
        )}
      </div>
      <div className="info-container">
        <div className="tags-list">
          <StatusTag status={status} isTeacher />
        </div>
        <Description className="pressto-title">{name}</Description>
        <Description className="word-count">
          {wordCount ?? 0}
          {' '}
          {lang('gallery.info_container.words_written')}
        </Description>
      </div>
    </ButtonContainer>
  );
}
