import React, { useState } from 'react';
import { ButtonComponent } from './styles';
import { ButtonProps } from './types';

function Button({
  label,
  large,
  outline,
  error,
  image,
  ...rest
}: ButtonProps) {
  const [clicking, setClicking] = useState(false);

  return (
    <ButtonComponent
      {...rest}
      large={large}
      outline={outline}
      error={error}
      clicking={clicking}
      onMouseDownCapture={() => { setClicking(true); }}
      onMouseUpCapture={() => { setClicking(false); }}
    >
      {image}
      {label}
    </ButtonComponent>
  );
}

export default Button;
