import { styled } from '@mui/system';
import { Card } from '@mui/material';
import { ButtonContainerProps } from './types';

export const ButtonContainer = styled(Card)<ButtonContainerProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  minHeight: '18rem',
  position: 'relative',

  '&:hover': {
    '.info-container': {
      backgroundColor: '#FFF',
    },
  },

  '.image-container': {
    minHeight: '14rem',
    overflow: 'hidden',
    position: 'relative',

    '& > img': {
      position: 'absolute',
      top: '1.875rem',
      height: '100%',
      left: '50%',
      transform: 'translate(-50%, 0%)',
    },

    '& > div': {
      transform: 'translate(-25%, 0%)',
      position: 'absolute',
      top: '1.875rem',
      height: '100%',
      left: '50%',
    },
  },

  '.tags-list': {
    display: 'flex',
    gap: '.25rem',
    height: '1.5rem',
  },

  '.info-container': {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: '#F0F0F4',
    transition: 'all .15s ease-out',
    minHeight: '8.5rem',
    gap: '.5rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    p: {
      color: '#000',
      fontWeight: '700',
      fontFamily: 'Inter !important',
    },

    '.secondary-info': {
      display: 'flex',
      justifyContent: 'space-between',

      p: {
        color: '#999',
        fontWeight: 'normal',
      },
    },
  },

  '.pressto-button-actions': {
    position: 'absolute',
    top: '.5rem',
    right: '.5rem',
    display: 'grid',
    cursor: 'pointer',
  },
}));
