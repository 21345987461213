import { darken, styled } from '@mui/system';
import { theme } from '../../../utils';
import { DialogContainer, DialogContent, DialogTitle } from '../styles';

export const LayoutDialogContainer = styled(DialogContainer)(() => ({
  '& > div > div': {
    width: '53.125rem',
    height: '40rem',
  },
}));

export const LayoutDialogTitle = styled(DialogTitle)(() => ({
  '& p': {

    '&:last-of-type': {
      fontSize: '2rem',
      color: '#163567 !important',
      display: 'contents',
      lineHeight: '2rem',
    },

    textAlign: 'center',
  },
}));

export const LayoutDialogContent = styled(DialogContent)(() => ({
  '.layouts-grid': {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridAutoRows: '7.5rem',
    gap: '.25rem',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },

    '.layout-item': {
      display: 'flex',
      alignItems: 'center',
      background: '#F6F6FA',

      '&:hover': {
        cursor: 'pointer',
        background: darken('#F6F6F6', 0.1),
      },

      div: {
        flexBasis: '50%',
        display: 'grid',
        placeItems: 'center',

        '&:first-of-type': {
          paddingLeft: '1.25rem',

          '& > p': {
            width: '100%',
            color: '#163567',

          },

          [theme.breakpoints.down('sm')]: {
            paddingLeft: '.8rem',
            '& > p': {
              fontSize: '.75rem',
            },
          },
        },

        '&:last-of-type': {
          paddingRight: '1.25rem',
          paddingTop: '.875rem',
          paddingBottom: '.875rem',

          '& > img': {
            filter: 'drop-shadow(0px 0px 8px rgba(82, 80, 154, 0.4))',
            maxHeight: '5.75rem',
            maxWidth: '95%',

          },

          [theme.breakpoints.down('sm')]: {
            paddingRight: '.8rem',
            '& > img': {
              maxHeight: '4rem',
              maxWidth: '95%',
            },
          },
        },

      },
    },
  },
}));
