import React, { useState } from 'react';

import { useApplyDocumentOperation } from 'src/hooks/document';
import { PublishOperation, UnpublishOperation } from 'src/store/document/operations';
import { Document } from 'src/types/models';
import { theme } from 'src/utils';
import { lang } from 'src/lang';
import { PublishImg } from 'src/assets/images';
import { Description } from 'src/components/typography';
import { Button } from 'src/components/buttons';
import LinkBox from 'src/components/LinkBox';

import BaseDialog, {
  DialogActions,
  DialogContent,
  DialogImage,
  DialogTitle,
} from 'src/components/dialogs/BaseDialog';

function PublishDialog({
  document,
  isOpen,
  onClose,
}: {
  document: Document;
  isOpen: boolean;
  onClose: (...args: any[]) => void;
}) {
  const [loading, setLoading] = useState(false);
  const isPublished = document.version.status === 'PUBLISHED';
  const applyOperation = useApplyDocumentOperation();

  const handlePublish = async () => {
    setLoading(true);
    await applyOperation(new PublishOperation(document.id));
    setLoading(false);
  };

  const handleUnpublish = async () => {
    setLoading(true);
    await applyOperation(new UnpublishOperation(document.id));
    setLoading(false);
  };

  return (
    <BaseDialog size="medium" onClose={onClose} open={isOpen}>
      <DialogImage src={PublishImg} width="254px" height="170px" />
      <DialogContent
        sx={{
          [theme.breakpoints.up('sm')]: {
            marginTop: `${130 - 64}px`,
          },
        }}
      >
        <DialogTitle>
          {lang(
            `document.publish_dialog.${
              isPublished ? 'published' : 'publish'
            }.title`,
          )}
        </DialogTitle>
        {!isPublished && (
          <Description
            className="subtitle"
            weight={700}
          >
            {lang('document.publish_dialog.publish.subtitle')}
          </Description>
        )}
        {isPublished && (
          <LinkBox text={`${window.location.origin}/publish/${document.id}`} />
        )}
        <Description
          className="long-description"
          sx={{
            marginTop: '1.2rem',
          }}
        >
          {lang(
            `document.publish_dialog.${
              isPublished ? 'published' : 'publish'
            }.description`,
          )}
        </Description>
      </DialogContent>
      <DialogActions>
        {
          isPublished
            ? (
              <>
                <Button
                  label={lang('document.publish_dialog.published.unpublish')}
                  error
                  onClick={handleUnpublish}
                  disabled={loading}
                />
                <Button
                  label={lang('general.close')}
                  onClick={onClose}
                  disabled={loading}
                />
              </>
            )
            : (
              <>
                <Button
                  label={lang('general.close')}
                  outline
                  onClick={onClose}
                  disabled={loading}
                />
                <Button
                  label={lang('document.publish_dialog.publish.title')}
                  onClick={handlePublish}
                  disabled={loading}
                />
              </>
            )
        }
      </DialogActions>
    </BaseDialog>
  );
}

export default PublishDialog;
