import { ImageBoxType, TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'QUOTE100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 16,
    height: 466,
    width: 579,
  } as TextBoxType,
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 498,
    height: 458,
    width: 675,
  } as ImageBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'CAPTION100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 972,
    height: 137,
    width: 579,
  } as TextBoxType,
];
