import React, { useMemo, useState } from 'react';
import {
  IconButton, Tab, Tabs, Theme, useMediaQuery,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../buttons';
import { TabContainer } from './styles';
import { LogoImg, LogoSmallImg } from '../../assets/images';
import { LargeBackArrowImg } from '../../assets/icons';
import CreatePresstoDialog from '../dialogs/CreatePresstoDialog';
import CreateClassroomDialog from '../dialogs/CreateClassroomDialog';
import { useUserStore } from '../../zustand/user';
import { lang } from '../../lang';
import TopBar from '../shared/TopBar';

function GalleryTopBar(props: {
  tab: string;
  hideTabs?: boolean;
}) {
  const {
    tab, hideTabs,
  } = props;
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const tabletMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const user = useUserStore((state) => state.user);

  const [isCreatePresstoDialogOpen, setIsCreatePresstoDialogOpen] = useState(false);
  const [isCreateClassroomDialogOpen, setIsCreateClassroomDialogOpen] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newTab: string) => {
    navigate(`/gallery/${newTab}`);
  };

  const createPressto = (format: string, meta: any) => {
    const metaString = meta ? JSON.stringify(meta) : '{}';
    navigate(`/document/new?format=${format}`, { state: { meta: metaString } });
  };

  const renderButton = () => {
    if (user.role === 'teacher') {
      return <Button label={lang('gallery.top_bar.invite')} onClick={() => setIsCreateClassroomDialogOpen(true)} />;
    }
    return <Button label={lang('gallery.top_bar.new_pressto')} onClick={() => setIsCreatePresstoDialogOpen(true)} />;
  };

  const renderTabs = () => (tab !== null && user.role !== 'individual' ? (
    <TabContainer>
      <Tabs
        value={tab}
        variant="fullWidth"
        aria-label="gallery tabs"
        classes={{ indicator: 'tab-indicator' }}
      >
        <Tab
          label={lang('general.gallery')}
          id="gallery-tab"
          aria-controls="gallery-tabpanel"
          onClick={(e) => handleChange(e, 'documents')}
          value="documents"
        />
        {!['student'].includes(user.role!) && (
          <Tab
            label={lang('general.classroom')}
            id="classrooms-tab"
            aria-controls="classrooms-tabpanel"
            onClick={(e) => handleChange(e, 'classrooms')}
            value="classrooms"
          />
        )}
      </Tabs>
    </TabContainer>
  ) : null);

  const { pathname } = useLocation();

  const { showBackButton, prevRoute } = useMemo(() => {
    if (!['classroom', 'profile'].some((el) => pathname.includes(el))) {
      return { showBackButton: false, prevRoute: null };
    }

    if (!pathname.includes('classroom')) {
      return { showBackButton: true, prevRoute: '/gallery' };
    }

    const prev = pathname.split('/student/')[0];
    return { showBackButton: true, prevRoute: prev };
  }, [pathname]);

  const showTabs = !hideTabs && user.role !== 'individual';

  return (
    <>
      <TopBar>
        <TopBar.Section>
          {showBackButton && mobileMQ && (
            <IconButton onClick={() => navigate(prevRoute ?? '/gallery')}>
              <img
                src={LargeBackArrowImg}
                alt="return"
              />
            </IconButton>
          )}
          <img src={mobileMQ ? LogoSmallImg : LogoImg} alt="pressto logo" style={{ maxHeight: '2.25rem' }} />
        </TopBar.Section>
        <TopBar.Spacer />
        {!hideTabs && !tabletMQ && (
          renderTabs()
        )}
        <TopBar.Spacer />
        {!tabletMQ && (
          <TopBar.Section>
            {renderButton()}
          </TopBar.Section>
        )}
        {tabletMQ && showTabs && (
          renderTabs()
        )}
      </TopBar>

      <CreatePresstoDialog
        onSubmit={createPressto}
        isOpen={isCreatePresstoDialogOpen}
        isAssignmentFlow={false}
        onClose={() => setIsCreatePresstoDialogOpen(false)}
      />
      <CreateClassroomDialog
        isOpen={isCreateClassroomDialogOpen}
        onOpen={() => setIsCreatePresstoDialogOpen(true)}
        onClose={() => setIsCreateClassroomDialogOpen(false)}
      />
    </>
  );
}

export default GalleryTopBar;
