import { styled, Box } from '@mui/system';

export const DocumentInformation = styled(Box)(({ theme }) => ({
  h1: {
    fontSize: '1em',
    fontWeight: 'bold',
    color: 'black',
  },

  small: {
    color: theme.palette.secondary.main,
    fontSize: '0.8em',
  },
}));
