import { TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'TITLE100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 128,
    y: 160,
    height: 344,
    width: 2294,
  } as TextBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 128,
    y: 568,
    height: 2572,
    width: 1099,
  } as TextBoxType,
  {
    key: 'textbox3',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 1323,
    y: 568,
    height: 2572,
    width: 1099,
  } as TextBoxType,
];
