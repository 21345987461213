import { styled, Box } from '@mui/system';

export const Wrapper = styled(Box)(() => ({
  overflow: 'overlay',

  '&::-webkit-scrollbar': {
    width: '.25rem',
  },

  '&::-webkit-scrollbar-track': {
    display: 'none',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#ccc4',
  },

}));
