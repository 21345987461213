import { TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'TITLE100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 112,
    y: 112,
    height: 226,
    width: 1696,
  } as TextBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 112,
    y: 370,
    height: 598,
    width: 800,
  } as TextBoxType,
  {
    key: 'textbox3',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 1008,
    y: 370,
    height: 598,
    width: 800,
  } as TextBoxType,
];
