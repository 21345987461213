import React, { useState } from 'react';

import * as UserAPI from 'src/api/User';
import { useUserStore } from 'src/zustand/user';
import { theme } from 'src/utils';
import { lang } from 'src/lang';
import { Description } from 'src/components/typography';
import Button from 'src/components/buttons/Button';
import { DeleteAccountImg } from 'src/assets/images';

import BaseDialog, {
  DialogActions,
  DialogContent,
  DialogImage,
  DialogTitle,
} from '../BaseDialog';

function DeleteAccountDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (...args: any[]) => void;
}) {
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (!user.id) return;
    setLoading(true);

    const response = await UserAPI.deleteUser(user.id);
    if (response.status === 200) {
      setUser({
        actions: [],
        name: '',
        surname: '',
        username: '',
        email: '',
      });
    }
    setLoading(false);
  };

  return (
    <BaseDialog onClose={onClose} open={isOpen}>
      <DialogImage src={DeleteAccountImg} width="214px" height="130px" />
      <DialogContent
        sx={{
          [theme.breakpoints.up('sm')]: {
            marginTop: `${100}px`,
          },
        }}
      >
        <DialogTitle>{lang('auth.delete_account_dialog.title')}</DialogTitle>
        <Description
          className="subtitle"
          weight={700}
        >
          {lang('auth.delete_account_dialog.subtitle')}
        </Description>
        <Description
          className="long-description"
          sx={{
            marginTop: '1.2rem',
          }}
        >
          {lang('auth.delete_account_dialog.description')}
        </Description>
      </DialogContent>
      <DialogActions>
        <Button
          label={lang('general.close')}
          outline
          onClick={onClose}
          disabled={loading}
        />
        <Button
          label={lang('general.delete')}
          onClick={handleDelete}
          disabled={loading}
        />
      </DialogActions>
    </BaseDialog>
  );
}

export default DeleteAccountDialog;
