import { styled } from '@mui/system';
import { theme } from 'src/utils';
import { MoveArrow } from 'src/assets/icons';

export const HandleElement = styled('span')(({ isDragging }: { isDragging: boolean }) => ({
  height: '2px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: isDragging ? 'grabbing' : 'grab',
  backgroundColor: isDragging ? theme.palette.primary.main : 'initial',
}));

export const HandleIcon = styled('span')(({ isDragging }: { isDragging: boolean }) => ({
  borderRadius: '100%',
  height: 20,
  width: 20,
  backgroundColor: isDragging ? 'transparent' : theme.palette.primary.main,
  backgroundImage: isDragging ? `url('${MoveArrow}')` : '',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));
