import * as models from '../../../types/models';
import SynchronousDocumentOperation from './synchronous';

export default class DeletePageOperation extends SynchronousDocumentOperation {
  readonly type = 'delete-page';

  pageId: models.Page['id'];

  constructor(documentId: string, pageId: string) {
    super(documentId);
    this.pageId = pageId;
  }

  apply(document: models.Document): models.Document {
    const { version } = document;
    return {
      ...document,
      version: {
        ...version,
        // Pages know what position they're in. Update that.
        pages: version.pages.filter((p) => p.id !== this.pageId).map((p, i) => ({
          ...p, position: i,
        })),
      },
    };
  }
}
