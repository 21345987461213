import React, { ComponentProps } from 'react';

import { WritingPlanSelectionDialogProps } from 'src/components/dialogs/CreatePresstoDialog/types';
import WritingPlanSelectionDialog from 'src/components/dialogs/CreatePresstoDialog/WritingPlanSelectionDialog';
import BaseDialog, { DialogContent } from 'src/components/dialogs/BaseDialog';

export default function WritingPlanDialog(
  props: ComponentProps<typeof BaseDialog>
  & WritingPlanSelectionDialogProps
  & {
    onClose: (...args: any[]) => void;
  },
) {
  const {
    onAdd, initialWritingPlan, size = 'large',
  } = props;

  return (
    <BaseDialog
      showControls
      {...props}
      size={size}
    >
      <DialogContent
        sx={{
          minHeight: '80vh',
        }}
      >
        <WritingPlanSelectionDialog
          onAdd={onAdd}
          initialWritingPlan={initialWritingPlan}
        />
      </DialogContent>
    </BaseDialog>
  );
}
