import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import React from 'react';

export function ChooseOne(props: {
  label: string;
  options: (
    string | {
      value: string;
      label: React.ReactNode;
    })[];
} & ({
  isRequired?: true;
  onChange: (value: string) => void;
  value?: string;
} | {
  isRequired?: false;
  onChange: (value: string | null) => void;
  value?: string | null;
})) {
  const {
    label, options, value, onChange, isRequired = false,
  } = props;

  return (
    <FormControl variant="standard" sx={{ width: '100%' }}>
      <InputLabel
        shrink
      >
        {label}
      </InputLabel>
      <Select
        fullWidth
        value={value}
        onChange={(e) => {
          onChange(e.target.value as any);
        }}
        required={isRequired}
        sx={{
          borderWidth: 0,
          borderBottomWidth: 1,
        }}
      >
        {isRequired ? null : (
          <MenuItem value="">
            None
          </MenuItem>
        )}
        {options.map((option) => {
          const optionValue = typeof option === 'string' ? option : option.value;
          const optionLabel = typeof option === 'string' ? option : option.label;
          return (
            <MenuItem
              key={optionValue}
              value={optionValue}
            >
              {optionLabel}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
