import API from './config';

type NoteResource = {
  id: string;
  text: string;
  user: {
    id: string;
    name: string;
    surname: string;
    full_name: string;
  };
  document_version_id: string | null;
  assignment_id: string | null;
  created_at: string;
  updated_at: string;
};

export function create(data: {
  text: string;
  document_version_id?: string;
  assignment_id?: string;
} & ({ document_version_id: string } | { assignment_id: string })) {
  return API.post<{
    data: NoteResource
  }>('notes', {
    text: data.text,
    document_version_id: data.document_version_id,
    assignment_id: data.assignment_id,
  });
}

export function update(id: string, data: { text: string }) {
  return API.patch<{
    data: NoteResource
  }>(`notes/${id}`, data);
}

export function deleteNote(id: string) {
  return API.delete(`notes/${id}`);
}
