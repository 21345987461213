import { TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'QUOTE100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 16,
    height: 924,
    width: 579,
  } as TextBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'CAPTION100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 972,
    height: 137,
    width: 579,
  } as TextBoxType,
];
