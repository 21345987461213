import { Paper } from '@mui/material';
import React, { useLayoutEffect } from 'react';

import { BlockType } from 'src/types/models';
import { useDrag } from 'react-dnd';
import { getBlockSVG } from 'src/configuration/blocks';
import { Title } from '../typography';

import {
  BlocksItemCard,
  BlockIcon,
} from './styles';

function BlocksItem(props: {
  blockType: BlockType;
  children?: React.ReactElement;
  elevation?: number;
  isDraggable?: boolean;
}) {
  const {
    blockType,
    elevation = 2,
    isDraggable = false,
    children,
  } = props;

  const [{ isDragging }, drag] = useDrag({
    type: 'blockType',
    item: blockType,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const IconSVG = getBlockSVG(blockType.id);
  const svgRef = React.useRef<SVGSVGElement>(null);
  useLayoutEffect(() => {
    if (svgRef.current) {
      // Match viewport to size of content, exactly.
      const bbox = svgRef.current.getBBox();
      svgRef.current.setAttribute('viewBox', `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`);
    }
  }, [svgRef.current]);

  return (
    <BlocksItemCard
      sx={{
        opacity: isDragging ? 0.5 : 1,
        cursor: isDraggable ? 'grab' : 'default',
      }}
    >
      { children ? (
        <BlockIcon
          ref={isDraggable ? drag : null}
        >
          {children}
        </BlockIcon>
      ) : (
        <>
          <Paper
            ref={isDraggable ? drag : null}
            elevation={elevation}
            sx={{ marginBottom: 0.5 }}
          >
            <BlockIcon>
              <IconSVG
                ref={svgRef}
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </BlockIcon>
          </Paper>
          <Title className="block-title" fontWeight="500">
            {blockType.title}
          </Title>
        </>
      )}
    </BlocksItemCard>
  );
}

export default BlocksItem;
