import { createContext } from 'react';

export interface ImageAdjust {
  isCropping: boolean,
  setIsCropping: (val: boolean) => void,
}

export const ImageAdjustContext = createContext<ImageAdjust>({
  isCropping: false, setIsCropping: () => { },
});
