import React, { useRef } from 'react';
import { BrushImg } from '../../../assets/icons';
import { useEditor } from '../../../hooks/useEditor';
import { lang } from '../../../lang';
import { MenuRefType } from '../../DropdownMenu/types';
import { ToolbarContainer } from './styles';
import ColorPicker from './ToolbarComponents/ColorPicker';
import IconTextButton from './ToolbarComponents/IconTextButton';

function BoardToolbar() {
  const colorPickerRef = useRef<MenuRefType>();

  const { changeBackgroundColor } = useEditor();

  return (
    <ToolbarContainer>
      <IconTextButton src={BrushImg} alt="brush" label={lang('document.artboard.toolbar.background_color')} onClick={(e: any) => { colorPickerRef.current?.open(e); }} />
      <ColorPicker ref={colorPickerRef} prefix={null} onClick={changeBackgroundColor} />
    </ToolbarContainer>
  );
}

export default BoardToolbar;
