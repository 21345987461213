import React from 'react';
import { Description } from 'src/components/typography';
import { IconTextButtonContainer } from '../styles';

function IconTextButton(
  {
    onClick, src, alt, label, reverse,
  }: {
    onClick: (...args: any[]) => void;
    src: string;
    alt: string;
    label: string;
    reverse?: boolean;
  },
) {
  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(e);
  };

  return (
    <IconTextButtonContainer
      onClick={handleClick}
      onMouseDown={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}
    >
      <img src={src} alt={alt} />
      <Description size="small" weight={700} style={{ color: '#333' }}>{label}</Description>
    </IconTextButtonContainer>
  );
}

export default IconTextButton;
