import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../utils';

export default styled(Box)(() => ({
  '.content-title': {
    fontSize: '1.5rem',
    color: '#2C2C2C',
    fontFamily: 'Inter',

    marginBottom: '2.5rem',
  },

  '.main-content': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    gridGap: '6rem',

    '.info-column': {
      width: '20rem',
    },

    '.info-container': {
      marginTop: '2.5rem',

      button: {
        marginLeft: '.5rem',
        height: '2.875rem',
      },
    },

    '.name': {
      '.user-name': {
        color: '#2C2C2C',
        fontSize: '1.125rem',
      },

      input: {
        fontWeight: 700,
        width: '8rem',
        paddingRight: '1.5rem',
      },

      'p:last-of-type': {
        color: '#596F93',
        marginTop: '.5rem',
      },
    },

    '.mail p:last-of-type, .password p:not(:first-of-type)': {
      color: '#2C2C2C',
      marginTop: '.25rem',
    },

    '.password': {
      marginTop: '1.25rem',
    },

    '.remove-account-button': {
      marginTop: '5rem',
      fontSize: '.75rem',
      color: '#FF5252',
      fontFamily: 'Inter',
      cursor: 'pointer',
    },
  },

  '.default-avatar-pic': {
    height: '7.5rem',
    width: '7.5rem',
    borderRadius: '100%',
    background: '#8DC9C3',
    color: 'white',
    fontSize: '3.2rem',
    display: 'grid',
    placeItems: 'center',
  },

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '.content-title': {
      marginTop: '2.5rem',
    },

    '.main-content': {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      alignItems: 'center',
      justifyContent: 'center',

      'p, .remove-account-button': {
        textAlign: 'center',
      },
    },
  },
}));
