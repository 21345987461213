import { ImageBoxType, TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 0,
    height: 1615,
    width: 2550,
  } as ImageBoxType,
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 128,
    y: 1679,
    height: 1461,
    width: 2294,
  } as TextBoxType,
];
