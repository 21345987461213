import React from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { ClearImg, SearchImg } from '../../assets/icons';
import { SearchInputBar } from './styles';

function SearchInput({ value, onChange, ...rest }: any) {
  return (
    <div>
      <SearchInputBar
        {...rest}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        startAdornment={<InputAdornment position="start"><img src={SearchImg} alt="looking glass" style={{ padding: '.5rem 2px' }} /></InputAdornment>}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton disabled={!value} onClick={() => onChange('')}>
              <img src={ClearImg} alt="looking glass" style={{ visibility: value ? 'visible' : 'hidden' }} />
            </IconButton>
          </InputAdornment>
        )}
      />
    </div>
  );
}

export default SearchInput;
