import React from 'react';
import { Tag } from './styles';

function StatusTag({ status, isTeacher = false, icon }: {
  status: string,
  isTeacher?: boolean,
  icon?: string,
}) {
  let statusText;
  switch (status) {
    case 'turnedin': {
      statusText = 'Turned In';
      break;
    }
    case 'new': {
      statusText = isTeacher ? 'Not Started' : status;
      break;
    }
    default: {
      statusText = status;
    }
  }

  return (
    <Tag status={status.toLowerCase()}>
      {icon && (
        <img
          src={icon}
          alt=""
        />
      )}
      {statusText}
    </Tag>
  );
}

export default StatusTag;
