import React from 'react';
import { FlexBox, GradientContainer, Logo } from '../../components';
import { Title, Description, Link } from '../../components/typography';
import { lang } from '../../lang';

function NotFound() {
  return (
    <GradientContainer>
      <Logo />

      <FlexBox textAlign="center">
        <Title>{lang('general.errors.not_found.code')}</Title>
        <Description align="center">{lang('general.errors.not_found.message')}</Description>
      </FlexBox>

      <Description size="small">
        {lang('auth.signup.already_have_account')}
        {' '}
        <Link underline href="/">{lang('auth.signup.login')}</Link>
      </Description>

    </GradientContainer>
  );
}

export default NotFound;
