import { styled } from '@mui/system';
import { DialogContainer, DialogTitle } from '../styles';

export const Container = styled(DialogContainer)(() => ({
  '& > div > div': {
    width: '53.125rem',
    height: '40rem',
  },
}));

export const Title = styled(DialogTitle)(() => ({
  '& p': {
    fontSize: '1.4rem',
    color: '#999',
    margin: 0,

    '&:last-of-type': {
      fontSize: '2rem',
      color: '#163567 !important',
      display: 'contents',
    },

    textAlign: 'center',
  },
}));
