import React from 'react';

import {
  Dialog, DialogContent, DialogProps, IconButton,
} from '@mui/material';
import ImportantWordsSelectionDialog from 'src/components/dialogs/CreatePresstoDialog/ImportantWordsSelectionDialog';
import { ImportantWordsSelectionDialogProps } from 'src/components/dialogs/CreatePresstoDialog/types';
import { DialogCloseImg } from 'src/assets/icons';

export default function ImportantWordsDialog(
  props: (DialogProps & ImportantWordsSelectionDialogProps & {
    onClose: (...args: any[]) => void;
  }),
) {
  const {
    open, onAdd, initialImportantWords, onClose,
  } = props;

  return (
    <Dialog
      open={open}
      PaperProps={{ elevation: 5 }}
      onClose={onClose}
      sx={{
        '.close-button': {
          position: 'absolute',
          top: '.25rem',
          right: '.25rem',
        },
      }}
    >
      <DialogContent>
        <ImportantWordsSelectionDialog
          onAdd={onAdd}
          initialImportantWords={initialImportantWords}
        />
      </DialogContent>

      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
    </Dialog>
  );
}
