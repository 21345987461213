import React, { useState } from 'react';
import Button from '../buttons/Button';
import FlexBox from '../FlexBox';
import { Description, Link, Title } from '../typography';
import { VerificationEmailSentProps } from './types';
import * as UserAPI from '../../api/User';
import { lang } from '../../lang';

function VerificationEmailSent({ email }: VerificationEmailSentProps) {
  const [loading, setIsLoading] = useState(false);

  const handleResendEmail = async () => {
    setIsLoading(true);
    try {
      await UserAPI.sendVerificationEmail({ email });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FlexBox textAlign="center">
        <Title>
          {lang('auth.signup.verification.title')}
        </Title>
      </FlexBox>
      <FlexBox textAlign="center" marginTop={5}>
        <Description align="center">
          {lang('auth.signup.verification.send_message')}
        </Description>
        <Link href="#email" fontSize="18px" fontWeight="bold">{email}</Link>
        <Description align="center">
          {lang('auth.signup.verification.description.line_1')}
          <br />
          {lang('auth.signup.verification.description.line_2')}
        </Description>
      </FlexBox>
      <FlexBox textAlign="center" marginTop={10} width={310} justifySelf="center">
        <Description align="center">
          {lang('auth.signup.verification.cant_find_email')}
        </Description>
        <Button onClick={handleResendEmail} label={lang('auth.signup.verification.resend')} large disabled={loading} outline />
      </FlexBox>
      <FlexBox textAlign="center" marginTop={10}>
        <Description align="center">
          {lang('auth.signup.verification.help')}
          {' '}
          <Link href="#help">{lang('auth.signup.verification.contact_us')}</Link>
        </Description>
      </FlexBox>
    </>
  );
}

export default VerificationEmailSent;
