import React, { useMemo, useState } from 'react';

import { useDocumentStore } from 'src/zustand/documents';
import * as DocumentsAPI from 'src/api/Documents';
import { theme } from 'src/utils';
import { lang } from 'src/lang';
import { PublishImg } from 'src/assets/images';
import { Description } from 'src/components/typography';
import { Button } from 'src/components/buttons';
import LinkBox from 'src/components/LinkBox';

import BaseDialog, {
  DialogActions,
  DialogContent,
  DialogImage,
  DialogTitle,
} from '../BaseDialog';

function PublishDialog({
  currentDocumentId,
  isOpen,
  onClose,
}: {
  currentDocumentId?: string;
  isOpen: boolean;
  onClose: (...args: any[]) => void;
}) {
  const documents = useDocumentStore((state) => state.documents);
  const currentDocument = useDocumentStore((state) => state.currentDocument);
  const changeDocumentStatus = useDocumentStore(
    (state) => state.changeDocumentStatus,
  );
  const loadDocuments = useDocumentStore((state) => state.loadDocuments);
  const sortedBy = useDocumentStore((state) => state.sortDocuments);

  const { documentId, isPublished } = useMemo(() => {
    if (currentDocument) {
      return {
        documentId: currentDocument.id,
        isPublished: currentDocument.version?.status === 'PUBLISHED',
      };
    }

    const document = documents.find((el) => el.id === currentDocumentId);
    return {
      documentId: document?.id ?? '',
      isPublished: document?.version?.status === 'PUBLISHED' ?? false,
    };
  }, [currentDocument, documents, currentDocumentId]);

  const [loading, setLoading] = useState(false);

  const handlePublish = async () => {
    setLoading(true);
    await DocumentsAPI.updateStatus(documentId, {
      status: 'published',
      is_public: true,
    });
    if (currentDocument) {
      changeDocumentStatus('PUBLISHED');
    } else {
      loadDocuments({ order: sortedBy });
    }
    setLoading(false);
  };

  const handleUnpublish = async () => {
    setLoading(true);
    await DocumentsAPI.updateStatus(documentId, {
      status: 'unpublished',
      is_public: false,
    });
    if (currentDocument) {
      changeDocumentStatus('DRAFT');
    } else {
      loadDocuments({ order: sortedBy });
    }
    setLoading(false);
  };

  return (
    <BaseDialog size="medium" onClose={onClose} open={isOpen}>
      <DialogImage src={PublishImg} width="254px" height="170px" />
      <DialogContent
        sx={{
          [theme.breakpoints.up('sm')]: {
            marginTop: `${130 - 64}px`,
          },
        }}
      >
        <DialogTitle>
          {lang(
            `document.publish_dialog.${
              isPublished ? 'published' : 'publish'
            }.title`,
          )}
        </DialogTitle>
        {!isPublished && (
          <Description
            className="subtitle"
            weight={700}
          >
            {lang('document.publish_dialog.publish.subtitle')}
          </Description>
        )}
        {isPublished && (
          <LinkBox text={`${window.location.origin}/publish/${documentId}`} />
        )}
        <Description
          className="long-description"
          sx={{
            marginTop: '1.2rem',
          }}
        >
          {lang(
            `document.publish_dialog.${
              isPublished ? 'published' : 'publish'
            }.description`,
          )}
        </Description>
      </DialogContent>
      <DialogActions>
        {
          isPublished
            ? (
              <>
                <Button
                  label={lang('document.publish_dialog.published.unpublish')}
                  error
                  onClick={handleUnpublish}
                  disabled={loading}
                />
                <Button
                  label={lang('general.close')}
                  onClick={onClose}
                  disabled={loading}
                />
              </>
            )
            : (
              <>
                <Button
                  label={lang('general.close')}
                  outline
                  onClick={onClose}
                  disabled={loading}
                />
                <Button
                  label={lang('document.publish_dialog.publish.title')}
                  onClick={handlePublish}
                  disabled={loading}
                />
              </>
            )
        }
      </DialogActions>
    </BaseDialog>
  );
}

export default PublishDialog;
