import { Dialog } from '@mui/material';
import { styled } from '@mui/system';

export const DialogWrapper = styled(Dialog)(() => ({
  '& > div > div': {
    width: '37.25rem',
    padding: '3.125rem 3.75rem',

    '.dialog-title': {
      fontFamily: 'Inter',

      fontWeight: 700,
      color: '#163567',
      fontSize: '1.125rem',
    },

    '.dialog-text': {
      fontFamily: 'Inter',

      margin: '.5rem 0 2.5rem 0',
    },

    '.dialog-actions': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}));
