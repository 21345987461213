import React from 'react';

import { FlexBox, GradientContainer, Logo } from '../../components';
import { Button } from '../../components/buttons';
import { Title, Description, Link } from '../../components/typography';
import { lang } from '../../lang';

function SchoolDetected() {
  return (
    <GradientContainer justifyContent="center">
      <Logo />

      <FlexBox marginTop={10} textAlign="center">
        <Title>
          {lang('auth.signup.school.title')}
        </Title>
        <Description align="center">
          {lang('auth.signup.school.confirmation')}
        </Description>
      </FlexBox>
      <FlexBox marginTop={5} textAlign="center">
        <Title size="small">
          CANYON CREST ACADEMY
        </Title>
        <Description align="center">
          5951 Village Center Loop Rd,
          <br />
          San Diego, CA 92130, United States
        </Description>

        <FlexBox textAlign="center" marginTop={5}>
          <Button label={lang('auth.signup.school.confirm_school')} large />
        </FlexBox>

        <Description align="center">
          {lang('auth.signup.school.refuse.line_1')}
          {' '}
          <Link href="/find-school" fontSize="14px" underline>
            {lang('auth.signup.school.refuse.line_2')}
          </Link>
        </Description>
      </FlexBox>

    </GradientContainer>
  );
}

export default SchoolDetected;
