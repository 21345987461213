import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../utils';

export const CheckList = styled(Box)(() => ({
  textAlign: 'center',
  p: {
    textAlign: 'center',
  },
}));

export const Option = styled(Box)(() => ({
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
    gap: '15px',
    height: '300px',
    width: '300px',
    justifyContent: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    height: '150px',
    width: '350px',
    justifyContent: 'space-evenly',
  },

  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 150ms ease-in-out',

  '.title': {
    color: theme.palette.secondary.main,
  },

  '&:hover': {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.main}`,

    '.title': {
      color: 'black',
    },
  },
}));

export const OptionList = styled(Box)(() => ({
  display: 'grid',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  gridGap: '10px',
  gridTemplateColumns: 'repeat(2, auto)',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'unset',
  },
}));
