import React from 'react';

import CellBox from './styles';
import { IPageGridCellProps } from './types';

function PageGridCell({
  x,
  y,
  width,
  height,
  children,
  isEmptyBelow,
  isEmptyToTheRight,
}: IPageGridCellProps) {
  return (
    <CellBox
      x={x}
      y={y}
      width={width}
      height={height}
      isEmptyBelow={isEmptyBelow}
      isEmptyToTheRight={isEmptyToTheRight}
    >
      {children}
    </CellBox>
  );
}

export default PageGridCell;
