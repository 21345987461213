import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FistBumpImg, JoinCodeImg } from 'src/assets/images';
import { useAlertQueue } from 'src/hooks/alerts';
import { teacherDisplayName, theme } from 'src/utils';
import { useUserStore } from 'src/zustand/user';
import { Box, Grid } from '@mui/material';
import { useRedirectToLogin } from 'src/hooks/authorization';
import * as ClassroomsAPI from '../../api/Classrooms';
import * as UserAPI from '../../api/User';
import {
  FlexBox, Form, GradientContainer, Input, Logo,
} from '../../components';
import { Button } from '../../components/buttons';
import { Description, Title } from '../../components/typography';
import { lang } from '../../lang';

function JoinCodeMessage(props: {
  classroom: {
    id: string;
    name: string;
    teachers: {
      name: string;
      surname: string;
    }[];
  }
  isLoggedIn: boolean;
  joinCode: string;
  handleJoinClass?: () => void;
}) {
  const {
    classroom, isLoggedIn, joinCode, handleJoinClass,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const user = useUserStore((state) => state.user);

  // These hooks redirect to authentication pages and then on to the provided
  // next URL. Logging in redirects the student back here, but the registration
  // path uses the join code to enroll the student implicitly upon sign-up.
  const redirectToLogin = useRedirectToLogin();
  const redirectToRegistrationWithCode = useRedirectToLogin({
    loginUrl: {
      pathname: '/register-student',
      search: `?join_code=${joinCode}`,
    },
  });

  let isInClassroom = false;
  if (user.classrooms?.find((c) => c.id === classroom.id)) {
    isInClassroom = true;
  }

  const teacher = classroom.teachers[0];
  const displayName = teacher ? teacherDisplayName(teacher) : 'your teacher';

  return (
    <Grid
      container
      style={{
        alignItems: 'center',
        flexDirection: 'column',
        gridGap: '50px',
        textAlign: 'center',
      }}
    >
      <Box>
        <Box marginBottom="50px">
          <img src={JoinCodeImg} alt="" />
        </Box>
        <Box>
          <Title>Join Class</Title>
          <Title style={{ color: theme.palette.primary.main }}>{classroom.name}</Title>
        </Box>
        <Box marginTop="20px">
          <Description>
            You will be added as a student to
            {' '}
            <span style={{ color: theme.palette.primary.main }}>{displayName}</span>
            &apos;s class
          </Description>
        </Box>
      </Box>
      <Box display="grid" gap="20px" gridTemplateColumns="auto auto">
        {isLoggedIn && !isInClassroom && (
          <Button
            label="Join Class"
            onClick={handleJoinClass ? async () => {
              setIsLoading(true);
              await handleJoinClass();
              setIsLoading(false);
            } : undefined}
            disabled={isLoading}
          />
        )}

        {isLoggedIn && isInClassroom && (
          <Button
            label="You are in this class"
            disabled
          />
        )}

        {!isLoggedIn && (
          <>
            <Button
              label="I have an account"
              outline
              onClick={() => redirectToLogin({
                pathname: `/code/${joinCode}`,
              })}
            />
            <Button
              label="Create an account"
              onClick={() => redirectToRegistrationWithCode('/')}
            />
          </>
        )}
      </Box>
    </Grid>
  );
}

export default function JoinCode() {
  const navigate = useNavigate();

  // The user may be authenticated or unauthenticated.
  const user = useUserStore((state) => state.user);
  const authCheck = useUserStore((state) => state.authCheck);

  // The join code may be provided as a route parameter. The behavior
  // of the page is slightly different if the join code is provided in the
  // URL on first load. If the join code is not provided, then show
  // the input.
  const { joinCode } = useParams();
  const [showJoinCodeInput, setShowJoinCodeInput] = useState(!joinCode);
  const [joinCodeInput, setJoinCodeInput] = useState(joinCode || '');

  // The join code may be valid or invalid. Or it could be in the process of
  // being validated.
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Get the alert queue so that we can show messages after joining a class.
  const { queue: queueAlert } = useAlertQueue();

  // If the join code is valid, then a classroom is set.
  const [classroom, setClassroom] = useState<{
    id: string;
    name: string;
    teachers: {
      name: string;
      surname: string;
    }[]
  } | null | undefined>(undefined);

  const handleJoinCodeInputSubmission = async (e: any) => {
    const code = e.target[0].value;
    e.preventDefault();

    // This navigates back to this page with a join code set.
    navigate(`/code/${code}`);
  };

  const loadClassroom = async () => {
    try {
      setLoading(true);
      const response = await ClassroomsAPI.validateJoinCode(joinCode!);
      if (response.status >= 200 && response.status < 300) {
        setClassroom(response.data.data);

        // If the input had been shown, hide it on successful validation.
        setShowJoinCodeInput(false);
      }
    } catch (err) {
      setError(lang('auth.errors.invalid_joincode'));
      setShowJoinCodeInput(true);
    } finally {
      setLoading(false);
    }
  };

  const joinClass = async () => {
    await ClassroomsAPI.joinWithCode({
      join_code: joinCode!,
    });
    queueAlert({
      text: lang('classroom.joincode_success_dialog.title.type_1'),
      type: 'dialog',
      image: FistBumpImg,
      secondaryText: lang('classroom.joincode_success_dialog.description', {
        className: classroom!.name,
        teacherName: classroom!.teachers[0] ? teacherDisplayName(classroom!.teachers[0]) : '',
      }),
    });
    await authCheck();
    navigate('/');
  };

  useEffect(() => {
    if (joinCode) {
      loadClassroom();
    }
    setJoinCodeInput(joinCode || '');
    setClassroom(undefined);
  }, [joinCode]);

  const handleNoJoinCode = async () => {
    if (!user.id) {
      navigate('/register-student');
    } else {
      // The user is already logged in.
      if (user.role === 'unselected') {
        // Set the user to be an individual if they have not already selected
        // a role. Reauthorize with the new role.
        await UserAPI.updateUser({
          userId: user.id,
          role: 'individual',
        });
        await authCheck();
      }

      navigate('/');
    }
  };

  useEffect(() => {
    document.title = `Join ${classroom ? classroom.name : 'Class'}`;
  }, [classroom]);

  return (
    <GradientContainer>
      <Logo to="/" />

      {joinCode && classroom && (
        <JoinCodeMessage
          classroom={classroom}
          isLoggedIn={!!user.id}
          joinCode={joinCode}
          handleJoinClass={joinClass}
        />
      )}

      {showJoinCodeInput && (
        <FlexBox textAlign="center">
          <Title>{lang('auth.signup.joincode_title')}</Title>

          <Form onSubmit={handleJoinCodeInputSubmission} style={{ marginBottom: '8rem' }}>
            <Input
              required
              name="joinCode"
              type="text"
              value={joinCodeInput}
              placeholder={lang('auth.signup.joincode_placeholder')}
              onChange={(e) => {
                setJoinCodeInput(e.target.value);
                setError('');
              }}
              error={!!error}
              invalidMessage={error}
            />
            <Button
              style={{ margin: '1rem 0rem 0rem' }}
              label={lang('general.next')}
              type="submit"
              disabled={loading}
              large
            />
          </Form>

          <Button
            onClick={handleNoJoinCode}
            label={lang('auth.signup.no_joincode')}
            type="button"
            disabled={loading}
            large
            outline
          >
            {lang('auth.signup.no_joincode')}
          </Button>
        </FlexBox>
      )}
    </GradientContainer>
  );
}
