import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { Link, To } from 'react-router-dom';
import { LogoImg, LogoSmallImg } from 'src/assets/images';

export default function Logo(props: {
  to?: To;
  height?: string;
  variant?: 'icon' | 'regular' | 'responsive';
}) {
  const {
    to,
    height = 'auto',
    variant = 'regular',
  } = props;

  let logo: ReactNode;
  if (variant === 'responsive') {
    logo = (
      <>
        <Box display={{ xs: 'none', sm: 'block' }}>
          <img src={LogoImg} alt="Pressto" style={{ height }} />
        </Box>
        <Box display={{ xs: 'block', sm: 'none' }}>
          <img src={LogoSmallImg} alt="Pressto" style={{ height }} />
        </Box>
      </>
    );
  } else {
    logo = (
      <img
        src={variant === 'regular' ? LogoImg : LogoSmallImg}
        alt="Pressto"
        style={{ height }}
      />
    );
  }

  return (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      {to ? <Link to={to}>{logo}</Link> : logo}
    </div>
  );
}
