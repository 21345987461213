/* eslint-disable react/prop-types */
import React from 'react';

import LinkBox from 'src/components/LinkBox';
import { Assignment } from 'src/types/models';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Button } from '../../buttons';
import { lang } from '../../../lang';
import { DialogProps } from '../types';
import BaseDialog, {
  DialogActions, DialogContent, DialogTitle,
} from '../BaseDialog';

export default function AssignmentLinkDialog({
  isOpen, onClose, assignment,
}: DialogProps & {
  assignment: Assignment,
}) {
  const handleClose = () => {
    onClose();
  };

  const navigate = useNavigate();

  return (
    <BaseDialog
      onClose={handleClose}
      open={isOpen}
      showControls
      size="large"
    >
      <DialogContent>
        <DialogTitle>Assigned to your class!</DialogTitle>

        <Typography
          variant="body2"
          color="GrayText"
          sx={{
            marginBottom: 4,
          }}
        >
          Students will see this assignment in their Assignments Tab.
          You can also copy the link below to share it with them directly or
          through Google Classroom.
        </Typography>

        <LinkBox
          text={`${window.location.origin}/assignment/${assignment.id}${(
            (assignment.notes.length || assignment.instructions) ? '?notes=1' : ''
          )}`}
        />

        <DialogActions>
          <Button
            label={lang('general.edit')}
            large
            onClick={handleClose}
            outline
          />

          <Button
            outline
            label={lang('assignment.create_assignment_dialog.step_2.view_assignment')}
            onClick={() => navigate(`/assignment/${assignment.id}/review`)}
            style={{ height: '3rem' }}
          />
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
}
