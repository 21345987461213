import { capitalize } from '../../../utils';

export const getAnchorProps = (anchor?: 'top' | 'right' | 'bottom' | 'left') => {
  if (!anchor) {
    return {
      '&:first-of-type': {
        transformOrigin: 'right',
      },

      '&:last-of-type': {
        transformOrigin: 'left',
      },

      '&:only-of-type': {
        transformOrigin: 'center',
      },
    };
  }

  const returnProps = { [`border${capitalize(anchor)}`]: 0 } as any;

  switch (anchor) {
    case 'top':
      returnProps['&:first-of-type'] = {
        transformOrigin: 'right',
      };

      returnProps['&:last-of-type'] = {
        transformOrigin: 'left',
      };

      returnProps['&:only-of-type'] = {
        transformOrigin: 'top',
      };

      break;
    case 'bottom':
      returnProps['&:first-of-type'] = {
        transformOrigin: 'right',
      };

      returnProps['&:last-of-type'] = {
        transformOrigin: 'left',
      };

      returnProps['&:only-of-type'] = {
        transformOrigin: 'bottom',
      };

      break;
    case 'left':
      returnProps['&:first-of-type'] = {
        transformOrigin: 'bottom',
      };

      returnProps[':not(&:first-of-type)'] = {
        borderTop: 0,
      };

      returnProps['&:last-of-type'] = {
        transformOrigin: 'top',
      };

      returnProps['&:only-of-type'] = {
        transformOrigin: 'left',
      };

      break;
    case 'right':
      returnProps['&:first-of-type'] = {
        transformOrigin: 'bottom',
      };

      returnProps[':not(&:first-of-type)'] = {
        borderTop: 0,
        borderLeft: '1px solid #163567',
      };

      returnProps['&:last-of-type'] = {
        transformOrigin: 'top',
      };

      returnProps['&:only-of-type'] = {
        transformOrigin: 'right',
      };

      break;
    default:
      break;
  }

  return returnProps;
};

export const getHasLabelProps = (hasLabel: boolean) => {
  if (!hasLabel) return {};
  return {
    width: 'unset',
    display: 'flex',
    gap: '.5rem',
    padding: '.375rem .75rem',

    span: {
      color: '#163567',
      fontSize: '.75rem',
      fontFamily: 'Inter',
    },
  };
};
