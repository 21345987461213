import { BCover4Img, HeadlineImage3Img } from '../assets/layouts';

const getPlaceholderDocumentByFormat = (format: string) => {
  const PlaceholderImages: {
    [key: string]: string
  } = {
    story: HeadlineImage3Img,
    booklet: BCover4Img,
  };

  return PlaceholderImages[format || 'booklet'];
};

export default getPlaceholderDocumentByFormat;
