import { Box } from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { getBlockSVG } from 'src/configuration/blocks';
import { BlockType } from 'src/types/models';

export default function BlockTypeLabel(props: {
  blockType: BlockType;
  isSelected?: boolean;
  isHighlighted?: boolean;
  isBold?: boolean;
  isDarkened?: boolean;
}) {
  const {
    blockType,
    isSelected = false,
    isHighlighted = false,
    isBold = false,
    isDarkened = false,
  } = props;

  const IconSVG = getBlockSVG(blockType.id);
  const svgRef = React.useRef<SVGSVGElement>(null);
  useLayoutEffect(() => {
    if (svgRef.current) {
      // Match viewport to size of content, exactly.
      const bbox = svgRef.current.getBBox();
      svgRef.current.setAttribute('viewBox', `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`);
    }
  }, [svgRef.current]);

  return (
    <Box
      sx={[
        {
          fontSize: '.9rem',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          color: 'primary.light',
          padding: '4px 10px 4px 6px',
        },

        // Set the color to white if the block is selected or highlighted.
        (isSelected || isHighlighted) && {
          color: 'white',
        },

        // Set a background color if highlighted or selected. Selection takes
        // precedence.
        isHighlighted && {
          backgroundColor: 'primary.light',
        },
        isSelected && {
          backgroundColor: 'primary.main',
        },
        isDarkened && {
          color: 'primary.dark',
        },
        isBold && {
          color: 'primary.dark',
          fontWeight: 'bold',
        },
      ]}
    >
      <div
        style={{
          // height: '1em',
          width: '16px',
          flex: '0 0 auto',
        }}
      >
        <IconSVG
          ref={svgRef}
          style={{
            position: 'relative',
            top: '.1em',
            width: '100%',
            height: 'auto',
          }}
        />
      </div>
      <div
        style={{
          width: 'auto',
          flex: '1 1 auto',
          marginLeft: '4px',
          minWidth: 'fit-content',
        }}
      >
        {blockType.label}
      </div>
    </Box>
  );
}
