import React from 'react';
import { MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import { BLOCK_FONTS } from 'src/components/elements/TextBox/utils';

const SelectContainer = styled(Select)(() => ({
  width: '6.25rem',
  fontSize: '.75rem',
  borderRadius: 0,
  backgroundColor: '#FFF',

  div: {
    paddingTop: 0,
    paddingBottom: 0,
  },

  '#font-select': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },

}));

function FontsComboBox(
  { currentFont, handleFontChange }: {
    currentFont: string,
    handleFontChange: (newFont: string) => void
  },
) {
  // const { font, size } = useMemo(() => {
  //   const sufix = getSufix(currentFont);
  //   if (!sufix) return { font: '', size: 0 };
  //   const [fontMatch, sizeMatch] = sufix.match(/\D+|\d+/g)!;
  //   return { font: fontMatch, size: parseInt(sizeMatch, 10) };
  // }, [currentFont]);

  // useEffect(() => {
  //   setCurrentValue(font);
  // }, [font]);

  const handleItemSelection = (event: SelectChangeEvent) => {
    event.preventDefault();
    event.stopPropagation();

    // const newFont = `FONT_STYLE_${event.target.value as string}${size}`;
    handleFontChange(event.target.value);
  };

  const preventDefault = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <SelectContainer
      id="font-select"
      value={currentFont}
      onChange={handleItemSelection as any}
      onClick={preventDefault}
      onOpen={preventDefault}
      style={{
        fontFamily: currentFont,
        minWidth: '120px',
      }}
    >
      {
        Object.values(BLOCK_FONTS).map((config) => (
          <MenuItem
            key={config.styles.fontFamily}
            value={config.styles.fontFamily}
            onMouseDown={preventDefault}
            style={{ fontSize: '.75rem', fontFamily: config.styles.fontFamily }}
          >
            {config.name}
          </MenuItem>
        ))
      }
    </SelectContainer>
  );
}

export default FontsComboBox;
