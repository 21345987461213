import React from 'react';
import { Box } from '@mui/material';

/**
 * A full-width alert for us under the TopBar or at the top of bottom
 * a page. Background color may be a MUI theme color.
 */
export default function AlertBar(props: {
  backgroundColor?: string;
  color?: string;
  children: React.ReactNode;
}) {
  const {
    backgroundColor = 'primary.main',
    color = 'white',
    children,
  } = props;

  return (
    <Box
      sx={{
        color,
        backgroundColor,
        textAlign: 'center',
        padding: 1,
        fontSize: '.75rem',
      }}
    >
      {children}
    </Box>
  );
}
