import React from 'react';
import { Alert } from 'src/hooks/alerts';
import BaseDialog, { DialogContent, DialogTitle } from '../dialogs/BaseDialog';
import { Description } from '../typography';

export default function Dialog(props: {
  alert: Alert,
  onClose: () => void,
}) {
  const { alert, onClose } = props;

  return (
    <BaseDialog
      size="small"
      onClose={onClose}
      open
      showBackButton={false}
    >
      <DialogContent>
        {alert.image && (
          <div style={{ display: 'grid', justifyContent: 'flex-end' }}>
            <img style={{ margin: 'auto' }} src={alert.image} alt="" />
          </div>
        )}

        <DialogTitle style={{ whiteSpace: 'pre-line' }}>
          {alert.text}
        </DialogTitle>

        <Description>
          {alert.secondaryText}
        </Description>
      </DialogContent>
    </BaseDialog>
  );
}
