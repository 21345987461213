import * as auth from './auth';
import gallery from './gallery';
import * as classroom from './classroom';
import * as assignment from './assignment';
import * as document from './document';
import * as notes from './notes';
import general from './general';

export default {
  auth,
  gallery,
  classroom,
  assignment,
  document,
  general,
  notes,
};
