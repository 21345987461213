import { styled } from '@mui/system';
import { Paper } from '@mui/material';

export const TableWrapper = styled(Paper)(() => ({
  border: '0 !important',
  boxShadow: 'none',
  width: '100%',
  overflow: 'visible !important',
  fontFamily: 'Inter !important',

  '.table-header-cell': {
    fontWeight: 400,
    color: '#999999',
    fontSize: '.625rem',
    textTransform: 'uppercase',
    padding: '.625rem .5rem .625rem .5rem',

    '&:first-of-type': {
      paddingLeft: '1.5rem',
    },
  },

  '.data-row': {
    border: '1px solid #E3E3E3',
    background: '#FFFFFF',
    marginBottom: '5rem',
    outline: 'thin solid',
    outlineColor: '#E3E3E3',

    td: {
      padding: '.625rem .5rem .625rem .5rem',
      fontSize: '.75rem !important',
    },

    '.name': {
      paddingLeft: '1.5rem',
      fontWeight: 700,
    },

    '.last_session': {
      color: '#999999',
      textTransform: 'capitalize',
      fontWeight: 400,
    },

    '.last_session-expanded': {
      color: '#999999',
      textTransform: 'capitalize',
      fontWeight: 400,
      margin: '.5rem 0 1.25rem 0 !important',
    },

    '.email-expanded': {
      fontWeight: 400,
    },

    '.words_written-expanded': {
      color: '#163567',
      fontWeight: 700,
      marginTop: '2rem',
    },

  },

}));
