import React from 'react';

import BigEyesImg from './Big eyes 1.png';
import ClickImg from './Click 1.png';
import CoolGuyImg from './Cool Guy 1.png';
import HeartImg from './Heart 1.png';
import NewImg from './New 1.png';
import SmileImg from './Smille 1.png';
import SmileReactionImg from './Smille Reaction 1.png';
import LikeImg from './Like 1.png';

export const PLACEHOLDERS = [
  BigEyesImg,
  ClickImg,
  CoolGuyImg,
  HeartImg,
  NewImg,
  SmileImg,
  SmileReactionImg,
  LikeImg,
];

interface PlaceholderImageProps {
  index: number;
  height?: number;
  width?: number
}

function PlaceholderImage({ index, height, width }: PlaceholderImageProps) {
  return (
    <img
      src={PLACEHOLDERS[index % PLACEHOLDERS.length]}
      alt="placeholder"
      height={height}
      width={width}
      style={{ objectFit: 'cover' }}
    />
  );
}

PlaceholderImage.defaultProps = {
  height: undefined,
  width: undefined,
};

export default PlaceholderImage;
