import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FistBumpImg, JoinCodeImg } from 'src/assets/images';
import { useAlertQueue } from 'src/hooks/alerts';
import { teacherDisplayName, theme } from 'src/utils';
import { useUserStore } from 'src/zustand/user';
import { Box, Grid } from '@mui/material';
import { useRedirectToLogin } from 'src/hooks/authorization';
import * as ClassroomsAPI from '../../api/Classrooms';
import { GradientContainer, Logo } from '../../components';
import { Button } from '../../components/buttons';
import { Description, Title } from '../../components/typography';
import { lang } from '../../lang';

export default function CoteacherInvitation() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  // Get the alert queue so that we can show messages after joining a class.
  const { queue: queueAlert } = useAlertQueue();

  // The user may be authenticated or unauthenticated.
  const user = useUserStore((state) => state.user);
  const authCheck = useUserStore((state) => state.authCheck);

  const coteacherInvitationId = window.location.pathname.replace('/coteacher-invitation/', '');
  const isLoggedIn = !!user.id;

  const [classroom, setClassroom] = useState<{
    id: string;
    name: string;
    teachers: {
      id: string;
      name: string;
      surname: string;
    }[]
  } | null | undefined>(undefined);

  const loadClassroom = async () => {
    const response = await ClassroomsAPI.getCoteacherInvitation(coteacherInvitationId);
    if (response.status >= 200 && response.status < 300) {
      setClassroom(response.data.data.classroom);
    }
  };

  const handleJoinClass = async () => {
    try {
      await ClassroomsAPI.claimCoteacherInvitation(classroom!.id, coteacherInvitationId);
      queueAlert({
        text: lang('classroom.invite_coteacher_success_dialog.title'),
        type: 'dialog',
        image: FistBumpImg,
        secondaryText: lang('classroom.invite_coteacher_success_dialog.description', {
          className: classroom!.name,
          teacherName: classroom!.teachers[0] ? teacherDisplayName(classroom!.teachers[0]) : '',
        }),
      });
      await authCheck();
    } catch (err) {
      queueAlert({
        text: lang('classroom.invite_coteacher_expired_dialog.title'),
        type: 'dialog',
        // We probably don't want a fist bump here.
        image: FistBumpImg,
      });
    }
    navigate(`/classroom/${classroom!.id}`);
  };

  useEffect(() => {
    loadClassroom();
    setClassroom(undefined);
  }, []);

  useEffect(() => {
    document.title = `Join ${classroom ? classroom.name : 'Class'}`;
  }, [classroom]);

  // These hooks redirect to authentication pages and then on to the provided
  // next URL. Logging in redirects the student back here, but the registration
  // path uses the join code to enroll the student implicitly upon sign-up.
  const redirectToLogin = useRedirectToLogin();
  const redirectToRegistrationWithCode = useRedirectToLogin({
    loginUrl: {
      pathname: '/register-teacher',
    },
  });

  let isInClassroom = false;
  if (classroom?.teachers.find((t) => t.id === user.id)) {
    isInClassroom = true;
  }

  return (
    <GradientContainer>
      <Logo to="/" />

      {classroom && (
        <Grid
          container
          style={{
            alignItems: 'center',
            flexDirection: 'column',
            gridGap: '50px',
            textAlign: 'center',
          }}
        >
          <Box>
            <Box marginBottom="50px">
              <img src={JoinCodeImg} alt="" />
            </Box>
            <Box>
              <Title>Co-Teach This Class</Title>
              <Title style={{ color: theme.palette.primary.main }}>{classroom.name}</Title>
            </Box>
            <Box marginTop="20px">
              <Description>
                You will be added as a co-teacher to
                {' '}
                <span style={{ color: theme.palette.primary.main }}>{classroom.name}</span>
              </Description>
            </Box>
          </Box>
          <Box display="grid" gap="20px" gridTemplateColumns="auto auto">
            {isLoggedIn && !isInClassroom && (
              <Button
                label="Join Class as Co-Teacher"
                onClick={handleJoinClass ? async () => {
                  setIsLoading(true);
                  await handleJoinClass();
                  setIsLoading(false);
                } : undefined}
                disabled={isLoading}
              />
            )}

            {isLoggedIn && isInClassroom && (
              <Button
                label="You are in this class"
                disabled
              />
            )}

            {!isLoggedIn && (
              <>
                <Button
                  label="I have an account"
                  outline
                  onClick={() => redirectToLogin()}
                />
                <Button
                  label="Create an account"
                  onClick={() => redirectToRegistrationWithCode()}
                />
              </>
            )}
          </Box>
        </Grid>
      )}
    </GradientContainer>
  );
}
