import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserStore } from 'src/zustand/user';

/**
   * This effect runs when the user changes or is set for the first time.
   * If the user is logged in (that is, the user has an ID) and there is
   * a 'next' path in local storage, then navigate to that path and remove
   * it immediately from local storage. Any actions that need to be taken
   * take precedence over this.
   * (There is no central function that is called to log users in. In the future,
   * this check could be moved if one is defined. Putting it at the top of the router
   * is not an obvious place for it.)
  */
export default function RedirectAfterLogin() {
  const user = useUserStore((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let postLoginPath: string | null = null;
    if (user?.id && !user?.actions?.length) {
      try {
        const postLoginState = JSON.parse(localStorage.getItem('postLoginState') || '{}');
        postLoginPath = postLoginState?.next;
        if (postLoginPath) {
          delete postLoginState.next;
          localStorage.setItem('postLoginState', JSON.stringify(postLoginState));
          navigate(postLoginPath, { replace: true });
        }
      } catch (err) {
      }
    }
  }, [location]);

  return null;
}
