import React, { memo } from 'react';
import { Box } from '@mui/material';
import BlocksItem from 'src/components/BlockType';
import { getBlockType } from 'src/configuration/blocks';
import { WritingPlan } from 'src/types/models';

function WritingPlanBlockTypes(props: {
  writingPlan: WritingPlan;
  isDraggable?: boolean;
}) {
  const { writingPlan, isDraggable = false } = props;
  const blockTypes = writingPlan?.blockTypeIds.map(getBlockType);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        marginTop: 2,
        opacity: 0.7,
      }}
    >
      {blockTypes.map((blockType) => (
        <BlocksItem
          key={blockType.title}
          blockType={blockType}
          isDraggable={isDraggable}
        />
      ))}
    </Box>
  );
}

export default memo(WritingPlanBlockTypes);
