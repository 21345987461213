import { ButtonUnstyled } from '@mui/base';
import { Menu, MenuItem } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useState } from 'react';
import { FontDecreaseImg, FontIncreaseImg } from 'src/assets/icons';
import { BlockProperties } from 'src/types/models';
import { theme } from 'src/utils';
import { FONT_SIZES, findFontSizeIndex } from '../utils';

const FontSizeContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  backgroundColor: '#FFF',

  border: '0.5px solid #596F93',
  cursor: 'pointer',

  div: {
    display: 'flex',
    justifyContent: 'center',
    border: 'none',
    height: '100%',
    img: { paddingInline: 10 },
  },
}));

const FontSizeSelectorContainer = styled(ButtonUnstyled)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',

  width: '3rem',
  height: '2.5rem',
  borderBlock: 'none',
  borderInline: `1px solid ${theme.palette.primary.light}`,
  background: 'transparent',
  fontWeight: 'bold',
  fontSize: '1rem',
}));

const FontSizeSelectorList = styled(Menu)(() => ({
  ul: {
    padding: 0,
    '> div': {
      maxHeight: 190,
      overflow: 'scroll',
      marginBottom: -15,
    },
  },
}));

function FontSizeController(
  {
    currentFontSize,
    handleFontSizeChange,
    isFitFontSize,
    maximumFontSize,
    allowFitFontSize = false,
  }: {
    currentFontSize: BlockProperties['fontSize'],
    handleFontSizeChange: (newFont: BlockProperties['fontSize'], isFitFontSize: boolean) => void
    maximumFontSize: BlockProperties['fontSize'],
    isFitFontSize: boolean,
    allowFitFontSize: boolean,
  },
) {
  // Split the number and unit. For example, 20px -> [20, px]
  const [, value, unit] = currentFontSize?.match(/([.\d]+)(\D+)/) ?? ['', '1', 'em'];
  const size = Number(value);
  const [, maximumValue] = maximumFontSize?.match(/([.\d]+)(\D+)/) ?? ['', '1', 'em'];

  const handleIncrease = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const currentIndex = findFontSizeIndex(size);
    if (currentIndex < FONT_SIZES.length - 1
      && FONT_SIZES[currentIndex + 1].em <= Number(maximumValue)) {
      handleFontSizeChange(`${FONT_SIZES[currentIndex + 1].em}${unit}` as BlockProperties['fontSize'], false);
    }
  };

  const handleDecrease = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const currentIndex = findFontSizeIndex(size);
    if (currentIndex > 0) {
      handleFontSizeChange(`${FONT_SIZES[currentIndex - 1].em}${unit}` as BlockProperties['fontSize'], false);
    }
  };

  const preventDefault = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const toggleFitFontSize = () => {
    handleFontSizeChange(currentFontSize, !isFitFontSize);
    setIsMenuOpen(false);
  };

  return (
    <FontSizeContainer>
      <Box onClick={handleDecrease}><img src={FontDecreaseImg} alt="decrease size" /></Box>
      <FontSizeSelectorContainer
        type="button"
        onClick={(e: any) => {
          preventDefault(e);
          setAnchorEl(e.currentTarget);
          setIsMenuOpen(true);
        }}
      >
        {isFitFontSize ? 'FIT' : FONT_SIZES.find((font) => font.em === size)?.label}
      </FontSizeSelectorContainer>
      <FontSizeSelectorList
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setIsMenuOpen(false)}
        className="teste"
      >
        <div>
          {
            Object.values(FONT_SIZES).map((item) => (
              <MenuItem
                key={item.label}
                value={item.em}
                onMouseDown={(e) => {
                  preventDefault(e);
                  handleFontSizeChange(`${item.em}em`, false);
                  setIsMenuOpen(false);
                }}
                style={{ fontSize: '1rem' }}
                disabled={item.em > Number(maximumValue)}
              >
                {item.label}
              </MenuItem>
            ))
          }
        </div>
        {allowFitFontSize && (
          <MenuItem
            onMouseDown={preventDefault}
            style={{
              borderInline: `1px solid ${theme.palette.primary.main}`,
              borderBlock: `1.5px solid ${theme.palette.primary.main}`,
              color: theme.palette.primary.main,
              background: 'rgb(246, 246, 250)',
              fontWeight: 'bold',
              fontSize: '1rem',
            }}
            onClick={toggleFitFontSize}
          >
            Fit
          </MenuItem>
        )}
      </FontSizeSelectorList>
      <Box onClick={handleIncrease}><img src={FontIncreaseImg} alt="increase size" /></Box>
    </FontSizeContainer>
  );
}

export default FontSizeController;
