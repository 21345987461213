import * as UUID from 'uuid';
import { Document, BlockPage } from 'src/types/models';
import SynchronousDocumentOperation from './synchronous';
import { createEmptyPage } from './utils';

export default class AddPageOperation extends SynchronousDocumentOperation {
  readonly type = 'add-page';

  // eslint-disable-next-line class-methods-use-this
  apply(document: Document): Document {
    const { version } = document;

    const newPage: BlockPage = {
      ...createEmptyPage(document.format),
      id: UUID.v1(),
      position: version.pages.length,
      documentId: document.id,
    };

    return {
      ...document,
      version: {
        ...version,
        pages: [...version.pages, newPage],
      },
    };
  }
}
