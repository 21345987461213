/* eslint-disable @typescript-eslint/naming-convention */
export const classroom_tab = {
  classroom_count: ({ count }: { count: number }) => `${count} Classroom${count !== 1 ? 's' : ''}`,
  students_count: ({ count }: { count: number }) => `${count} Student${count !== 1 ? 's' : ''}`,
  create: 'Invite Students',
};

export const classroom_page = {
  go_back: 'Go back to all classes',

  joincode: 'Student Join Code',
  delete: 'Delete classroom',

  sync: 'Sync with Google Classroom',
  syncing: 'Syncing...',

  delete_classroom_confirm: {
    title: 'Are you sure you want to delete this Classroom?',
    subtitle: ({ name }: { name: string }) => `If you delete ${name}, your students won’t be able to access Pressto again. Are you sure you want to go through this action?`,
  },

  last_session: 'Last session:',
};

export const remove_student_dialog = {
  title: 'Are you sure you want to remove this student?',
  subtitle: 'Removing a student from your class is a permanent action. The only way to get them back in is to have them rejoin your class.',
};

export const student_work = {
  go_back: 'Go back to all students',

  title: 'Published work from',
};

export const create_classroom_dialog = {
  step_0: {
    title: 'How do you want to invite your students?',

    import: 'Import your class from Google classroom',
    sync: 'Automatically sync and import your students',
    create: 'Create a\nnew Pressto class',
    invite: 'Invite your students to join with a Join Code or invitation link',
  },

  step_1: {
    title: 'Create a Join Code',

    classroom_placeholder: 'Classroom name',
    subject_placeholder: 'Subject (optional)',
    section_placeholder: 'Section (optional)',

    submit: 'Create Join Code',
  },

  step_2: {
    title: 'Class Join Code',
    subtitle: 'Share this link and code with students. Students will create an account or log in to join your class.',

    copy_link: 'Give students invitation link',

    submit: {
      from_classroom: 'View your class',
      from_assignment: 'Finish assigning',
    },
  },

  step_3: {
    loading: {
      title: 'Importing your class...',
      description: 'This might take a few seconds.',
    },
    no_classroom: 'No classes found in your Google Classroom account',
    choose_classroom: 'Choose Google Classroom classes to import',

    classroom_count: ({ count }: { count: number }) => `${count} Google Classrooms found`,
    imported: ({ date }: { date: string }) => `Imported ${date}`,

    submit: 'Import',
    submit_from_assignment: 'Finish Assignment',

    create: 'Create them in your account and return here to import.',
  },

  step_4: {
    title: 'Class successfully imported!',
    description: {
      line_1: 'Your students\' Pressto accounts have been created.',
      line_2: {
        part_1: 'Students can visit ',
        part_2: ' and click Sign In with Google to start using Pressto!',
      },
    },
    submit: 'View your class',
  },

  error: {
    title: 'One second, Google Classroom needs your permission to get started',
    subtitle: 'After choosing Google Classroom please read and check off all permissions required for us to import your classroom data!',
  },

  go_back: 'Go back to Classrooms',
};

export const joincode_dialog = {
  title: 'Enter Join Code',
  joincode_placeholder: 'Join Code',
};

export const joincode_success_dialog = {
  title: {
    type_1: 'You joined a class.',
    type_2: 'Welcome back!\nYou joined a class.',
    type_3: 'Welcome to Pressto!\nYou joined a class.',
  },
  description: ({ className, teacherName }: { className: string, teacherName: string }) => `You just joined ${className} with ${teacherName}.`,
};

export const already_in_class = {
  title: 'You\'re already in this class',
  description: {
    line_1: 'No need to join again! If you wanted to get to Pressto, bookmark the link ',
    line_2: ' for next time you\'re ready to write.',
  },
};

export const invite_coteacher_dialog = {
  title: 'Add a Co-Teacher',
  description: 'Share the link and code with your co-teacher. They will be able to create and view assignments for your class, but not delete the class.',
  copy_link: 'Give co-teacher invitation link',
};

export const invite_coteacher_success_dialog = {
  title: 'You joined a class as co-teacher.',
  description: ({ className, teacherName }: { className: string, teacherName: string }) => `You just joined ${className} as a co-teacher with ${teacherName}.`,
};

export const invite_coteacher_expired_dialog = {
  title: 'Your co-teacher invitation code has expired.',
};

export const remove_coteacher_dialog = {
  title: 'Are you sure you want to remove this co-teacher?',
  subtitle: 'Removing a co-teacher from your class is a permanent action. The only way to get them back in is to have them rejoin your class.',
};
