import { ImageBoxType, TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 0,
    height: 918,
    width: 1598,
  } as ImageBoxType,
  {
    key: 'imagebox2',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 1622,
    y: 0,
    height: 2358,
    width: 928,
  } as ImageBoxType,
  {
    key: 'imagebox3',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 952,
    y: 2382,
    height: 918,
    width: 1598,
  } as ImageBoxType,
  {
    key: 'imagebox4',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 942,
    height: 2358,
    width: 928,
  } as ImageBoxType,
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'QUOTE100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 952,
    y: 934,
    height: 1432,
    width: 646,
  } as TextBoxType,
];
