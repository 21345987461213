import {
  DialogContent as DialogContentUnstyled,
} from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../../utils';

export const DialogContainer = styled('div')(() => ({
}));

export const DialogBack = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',

  position: 'absolute',
  top: '1.5rem',
  left: '1.5rem',

  cursor: 'pointer',

  color: '#596F93',

  '> p': {
    color: 'inherit',
  },
}));

export const DialogTitle = styled('div')(() => ({
  display: 'grid',
  flexDirection: 'column',

  marginBottom: '2rem',
  marginLeft: '0.75rem',

  p: {
    fontFamily: 'Inter',
    fontSize: '1.5rem',
    lineHeight: '100%',

    '&:last-child': {
      fontSize: '3rem',
      lineHeight: '3.5rem',

      color: '#163567',
    },
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: '2.5rem',
  },
}));

export const DialogContent = styled(DialogContentUnstyled)(() => ({
  height: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  paddingLeft: '.5rem',
  paddingRight: '.5rem',

  '.content-wrapper': {
    '> div': {
      maxHeight: '100%',
    },
  },

  '.dialog-actions': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.5rem',

    position: 'fixed',
    bottom: '10px',
    left: '30px',
    right: '30px',
    backgroundColor: '#FFF',

    flexDirection: 'row',
    gap: '.5rem',

    button: {
      height: '3rem',
    },
  },

  [theme.breakpoints.up('sm')]: {
    padding: '.5rem 0 !important',

    overflow: 'visible !important',

    '.content-wrapper': {
      maxHeight: 'calc(100% - 3rem)',
    },

    '.dialog-actions': {
      gap: '2.5rem',

      position: 'static',
    },
  },
}));
