import API from './config';

export function getImage(id: string, params: { text?: string, page: number }) {
  return API.get(`/documents/${id}/images/search`, { params });
}

export function getImageData(id: string, params: { image_id: string }) {
  return API.get(`/documents/${id}/images/search-store`, { params });
}

export function upload(id: string, image: FormData) {
  return API.post(`documents/${id}/images`, image, { headers: { 'Content-Type': 'multipart/form-data' } });
}
