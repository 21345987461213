import ImgIcon, { ReactComponent as ImgSVG } from './image.svg';
import TitleIcon, { ReactComponent as TitleSVG } from './title.svg';

import ImgCaptionIcon, { ReactComponent as ImgCaptionSVG } from './image-caption.svg';
import IntroductionIcon, { ReactComponent as IntroductionSVG } from './introduction.svg';
import ConclusionIcon, { ReactComponent as ConclusionSVG } from './conclusion.svg';
import QuoteIcon, { ReactComponent as QuoteSVG } from './quote.svg';
import MainIdeaIcon, { ReactComponent as MainIdeaSVG } from './main-idea.svg';
import MainIdeaLightningIcon, { ReactComponent as MainIdeaLightningSVG } from './main-idea-lightning.svg';
import DetailsIcon, { ReactComponent as DetailsSVG } from './details.svg';
import EyeDetailsIcon, { ReactComponent as EyeDetailsSVG } from './eye-details.svg';
import SettingCharacterIcon, { ReactComponent as SettingCharacterSVG } from './setting-characters.svg';
import ProblemGoalIcon, { ReactComponent as ProblemGoalSVG } from './problem-goal.svg';
import AttemptsIcon, { ReactComponent as AttemptsSVG } from './attempts.svg';
import SolutionIcon, { ReactComponent as SolutionSVG } from './solution.svg';
import ConsequenceIcon, { ReactComponent as ConsequenceSVG } from './consequence.svg';
import PointOfViewIcon, { ReactComponent as PointOfViewSVG } from './point-view.svg';
import EvidencesIcon, { ReactComponent as EvidencesSVG } from './evidence.svg';
import EventIcon, { ReactComponent as EventSVG } from './event.svg';
import CauseIcon, { ReactComponent as CauseSVG } from './cause.svg';
import EffectIcon, { ReactComponent as EffectSVG } from './effect.svg';
import CompareIcon, { ReactComponent as CompareSVG } from './compare.svg';
import ContrastIcon, { ReactComponent as ContrastSVG } from './contrast.svg';
import ProblemIcon, { ReactComponent as ProblemSVG } from './problem.svg';
import HeartSolutionIcon, { ReactComponent as HeartSolutionSVG } from './heart-solution.svg';
import SolutionPuzzleIcon, { ReactComponent as SolutionPuzzleSVG } from './solution-puzzle.svg';
import ResolutionIcon, { ReactComponent as ResolutionSVG } from './resolution.svg';
import ClaimIcon, { ReactComponent as ClaimSVG } from './claim.svg';
import TextIcon, { ReactComponent as TextSVG } from './text.svg';

export const Icons = {
  TitleIcon,
  ImgIcon,
  ImgCaptionIcon,
  IntroductionIcon,
  ConclusionIcon,
  QuoteIcon,
  MainIdeaIcon,
  MainIdeaLightningIcon,
  EyeDetailsIcon,
  SettingCharacterIcon,
  ProblemGoalIcon,
  AttemptsIcon,
  SolutionIcon,
  ConsequenceIcon,
  PointOfViewIcon,
  EvidencesIcon,
  EventIcon,
  CauseIcon,
  EffectIcon,
  CompareIcon,
  ContrastIcon,
  ProblemIcon,
  HeartSolutionIcon,
  SolutionPuzzleIcon,
  TextIcon,
  DetailsIcon,
  ResolutionIcon,
  ClaimIcon,
};

export const SVGs = {
  TitleSVG,
  ImgSVG,
  ImgCaptionSVG,
  IntroductionSVG,
  ConclusionSVG,
  QuoteSVG,
  MainIdeaSVG,
  MainIdeaLightningSVG,
  EyeDetailsSVG,
  SettingCharacterSVG,
  ProblemGoalSVG,
  AttemptsSVG,
  SolutionSVG,
  ConsequenceSVG,
  PointOfViewSVG,
  EvidencesSVG,
  EventSVG,
  CauseSVG,
  EffectSVG,
  CompareSVG,
  ContrastSVG,
  ProblemSVG,
  HeartSolutionSVG,
  SolutionPuzzleSVG,
  TextSVG,
  DetailsSVG,
  ResolutionSVG,
  ClaimSVG,
};
