import { styled } from '@mui/system';
import { theme } from 'src/utils';

export const ZoomSliderContainer = styled('div')(() => ({
  position: 'absolute',
  zIndex: 10,
  width: 200,
  height: 50,
  padding: 10,
  display: 'flex',
  gap: '.75rem',
  alignItems: 'center',
  background: 'white',
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: '.125rem',
}));

export const ImageContainer = styled('div')(() => ({
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  touchAction: 'none',
  position: 'absolute',
}));
