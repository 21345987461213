import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FlexBox, GradientContainer, Logo, RoleSelector,
} from '../../components';
import PrivacyAndTerms from '../../components/PrivacyAndTerms';
import { Title, Description, Link } from '../../components/typography';
import { lang } from '../../lang';

function Register() {
  const navigate = useNavigate();

  return (
    <GradientContainer>
      <Logo />

      <FlexBox textAlign="center">
        <Title>{lang('auth.signup.signup')}</Title>
        <Description align="center">{lang('auth.signup.role_select')}</Description>
      </FlexBox>

      <RoleSelector
        onSelect={(role) => {
          navigate(role === 'student' ? '/code' : '/register-teacher');
        }}
      />

      <Description size="small">
        {lang('auth.signup.already_have_account')}
        {' '}
        <Link underline href="/">{lang('auth.signup.login')}</Link>
      </Description>

      <PrivacyAndTerms />
    </GradientContainer>
  );
}

export default Register;
