import { Box, styled } from '@mui/system';

const GridBox: any = styled(Box, {
  shouldForwardProp: (prop: string) => ![
    'rows',
    'columns',
  ].includes(prop),
})(({
  rows,
  columns,
}: {
  rows: number,
  columns: number,
}) => ({
  display: 'grid',
  gap: 0,
  gridTemplateRows: `${(1 / rows) * 100}% `.repeat(rows),
  gridTemplateColumns: `${(1 / columns) * 100}% `.repeat(columns),
  height: '100%',
  width: '100%',
}));

export default GridBox;
