import React from 'react';
import { Paper } from '@mui/material';
import Page from 'src/components/Page';
import { DocumentFormat } from 'src/types/DocumentSettings';
import * as models from 'src/types/models';
import { useDrag } from 'react-dnd';

// TODO: Update Page thumbnail to account for a Doc page. 30min

export default function PageThumbnail(props: {
  page: models.Page;
  format: DocumentFormat;
  isSelected?: boolean;
  isHovered?: boolean;
}) {
  const {
    page,
    format,
    isSelected = false,
    isHovered = false,
  } = props;

  // Pages can be dragged. Drop-detection is in PageDropTarget.tsx.
  const [{ isDragging }, drag] = useDrag({
    type: 'page',
    item: page,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  let elevation = 1;
  if (isSelected) {
    elevation = 14;
  } else if (isHovered) {
    elevation = 2;
  }

  return (
    <Paper
      ref={drag}
      elevation={elevation}
      sx={{
        borderColor: isSelected ? 'primary.main' : 'grey.300',
        display: 'inline-block',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <Page
        format={format}
        page={page}
        scale={0.065}
        isThumbnail
      />
    </Paper>
  );
}
