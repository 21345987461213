import { DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { lang } from '../../../lang';
import { Button } from '../../buttons';
import { Description } from '../../typography';
import { DialogWrapper } from './styles';
import { WarnPageNumberDialogProps } from './types';

function WarnPageNumberDialog({
  isOpen, isDelete, onSubmit, onClose,
}: WarnPageNumberDialogProps) {
  return (
    <DialogWrapper open={isOpen}>
      <DialogTitle className="dialog-title">
        {lang('document.warn_page_dialog.title', { isDelete })}
      </DialogTitle>
      <DialogContent className="dialog-container">
        <Description className="dialog-text">
          {lang('document.warn_page_dialog.description', { isDelete })}
        </Description>
        <div className="dialog-actions">
          <Button label={lang('general.cancel')} outline onClick={onClose} />
          <Button label={lang(`document.warn_page_dialog.${isDelete ? 'delete' : 'add'}`)} onClick={onSubmit} />
        </div>
      </DialogContent>
    </DialogWrapper>
  );
}

export default WarnPageNumberDialog;
