import React from 'react';
import { ContextMenuItemProps } from './types';
import { Description } from '../typography';

import { ReactComponent as BinImg } from '../../assets/icons/bin.svg';
import { ReactComponent as ShareImg } from '../../assets/icons/share.svg';
import { ReactComponent as CopyImg } from '../../assets/icons/copy.svg';
import { ReactComponent as PencilImg } from '../../assets/icons/pencil.svg';
import { MenuItem } from './styles';

function getIcon(icon: string, label: string) {
  switch (icon) {
    case 'BIN':
      return <BinImg strokeWidth={1} stroke="#FF5252" height="1rem" />;
    case 'SHARE':
      return <ShareImg strokeWidth={1} stroke="#596F93" height="1rem" />;
    case 'DUPLICATE':
      return <CopyImg strokeWidth={1} stroke="#596F93" height="1rem" />;
    case 'EDIT':
      return <PencilImg strokeWidth={0} height="1rem" />;
    default:
      return <img src={icon} alt={label} style={{ maxHeight: '1.125rem' }} />;
  }
}

function ContextMenuItem({
  label, icon, style, ...rest
}: ContextMenuItemProps) {
  return (
    <MenuItem {...rest as any[]}>
      <div style={style}>
        {icon && getIcon(icon, label)}
        <Description highlight style={{ color: label === 'Delete' ? '#FF5252 ' : '#2C2C2C' }}>{label}</Description>
      </div>
    </MenuItem>
  );
}

export default ContextMenuItem;
