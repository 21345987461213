import { Grid } from './Grid';
import { Element } from '../LayoutEditor';

export interface Page {
  id: string;
  documentId?: string;
  position: number;
  thumbnail_url?: string;
  html?: string;
  meta: string;
  template?: string;
  wordCount?: number;
  backgroundColor?: string;
  predominantColor?: string;
  signalWords?: { [key: string]: boolean } | null;
  importantWords?: { [key: string]: boolean } | null;
}

/**
 * BlockPages are composed of a grid that may contain blocks. This is the
 * current default page type.
 */
export interface BlockPage extends Page {
  grid: Grid;

  // DocumentId is required subsequent to blocks feature. Previously, it
  // could be undefined.
  documentId: string;
}

export function isBlockPage(page: Page): page is BlockPage {
  return (page as BlockPage).grid !== undefined;
}

/**
 * LayoutPages are composed of a list of elements and have a page-level template.
 * They are the legacy layout type and are read-only.
 */
export interface LayoutPage extends Page {
  content: Element[];
}

export function isLayoutPage(page: Page): page is LayoutPage {
  return Array.isArray((page as LayoutPage).content);
}
