import React from 'react';
import { lang } from '../../lang';
import { theme } from '../../utils';
import { Description } from '../typography';

function PrivacyAndTerms() {
  return (
    <Description size="small" marginBottom={5} alignSelf="center" align="center">
      <a
        href="https://www.joinpressto.com/privacy-policy"
        target="_blank"
        rel="noreferrer"
        style={{ color: theme.palette.primary.main }}
      >
        {lang('auth.commom.privacy_policy')}
      </a>
      {` ${lang('general.and')} `}
      <a
        href="https://www.joinpressto.com/terms-of-use"
        target="_blank"
        rel="noreferrer"
        style={{ color: theme.palette.primary.main }}
      >
        {lang('auth.commom.terms_of_use')}
      </a>
    </Description>
  );
}

export default PrivacyAndTerms;
