import React, { useMemo } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import { useIntercom } from 'src/hooks/integrations/intercom';
import { useHubspot } from 'src/hooks/integrations/hubspot';
import PUBLIC_ROUTES from './public';
import PRIVATE_ROUTES from './private';
import ACTION_ROUTES from './actions';
import { useUserStore } from '../zustand/user';
import { DataCollector } from '../components';
import RedirectAfterLogin from './RedirectAfterLogin';
import ADMIN_ROUTES from './admin';

function ThirdPartyIntegrations() {
  useIntercom();
  useHubspot();
  return null;
}

function RouterRoot() {
  const user = useUserStore((state) => state.user);

  const nextActionPath = useMemo(() => {
    if (user?.actions) {
      switch (user.actions[0]) {
        case 'verify_email':
          return '/register-student';
        case 'select_role':
          return '/select-role';
        case 'add_birthdate':
          return '/birthday-form';
        case 'complete_user_data':
          return '/create-account';
        case 'add_school':
          return '/school-information-form';
        default:
          return '/gallery';
      }
    }
    return '/gallery';
  }, [user?.actions]);

  return (
    <BrowserRouter>
      <DataCollector />
      <ThirdPartyIntegrations />

      <Routes>
        {user?.actions?.length > 0 && (
          <>
            <Route
              path="/"
              element={<Navigate to={nextActionPath} replace />}
            />
            {
              ACTION_ROUTES.filter((e: any) => user.actions && e.action.includes(user.actions[0]))
                .map(({ component: Element, path, name }: any) => (
                  <Route key={name} path={path} element={<Element action={user.actions ? user.actions[0] : ''} />} />
                ))
            }
          </>
        )}
        {(!user?.actions || user?.actions?.length === 0) && (user?.id
          ? (
            <>
              <Route path="/" element={<Navigate to="/gallery" replace />} />
              {PRIVATE_ROUTES.map(({ component: Element, path, name }) => (
                <Route key={name} path={path} element={<Element />} />
              ))}
            </>
          )
          : PUBLIC_ROUTES.map(({ component: Element, path, name }) => (
            <Route key={name} path={path} element={<Element />} />
          )))}

        {(user.role === 'admin' || user.role === 'superadmin') && ADMIN_ROUTES.map(({
          component: Element, path, name,
        }) => (
          <Route key={name} path={`/admin${path}`} element={<Element />} />
        ))}

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      {/* Check whether the user should be redirected to a post-login path. */}
      <RedirectAfterLogin />
    </BrowserRouter>
  );
}

export default RouterRoot;
