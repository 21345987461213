import { Box, Typography } from '@mui/material';
import React from 'react';

/** A private utility component for rendering a section form. */
export function FormSection(props: {
  name?: string;
  description?: string;
  children: React.ReactNode;
}) {
  const { name, description, children } = props;

  return (
    <Box
      sx={{
        my: 3,
      }}
    >
      <Typography
        fontWeight={700}
        fontSize=".9rem"
        color="secondary"
        className="form-section-name"
      >
        {name}
      </Typography>

      <Typography
        fontSize=".8rem"
        color="secondary"
        sx={{
          marginBottom: 1,
        }}
      >
        {description}
      </Typography>

      <div>
        {children}
      </div>
    </Box>
  );
}
