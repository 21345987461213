import { DocumentTemplateSystem } from 'src/types/DocumentSettings';
import {
  Block, Document, DocumentVersion, isBlockPage,
} from 'src/types/models';

export function allBlocks(version: DocumentVersion): Block[] {
  return version.pages.filter(
    isBlockPage,
  ).flatMap(
    (page) => {
      const gridBlocks = page.grid.blocks;
      gridBlocks.sort((a, b) => (a.y - b.y) * page.grid.rows + (a.y - b.y));
      return gridBlocks;
    },
  );
}

export function wordCount(text: string) {
  return text.split(/\s+/).filter(Boolean).length;
}

export function blockWordCount(block: Block) {
  return wordCount(block.properties?.plainText || '');
}

export function documentWordCount(document: Document): number {
  if (document.templateSystem === DocumentTemplateSystem.BLOCKS) {
    return allBlocks(document.version).reduce(
      (count, b) => blockWordCount(b) + count,
      0,
    );
  } if (document.templateSystem === DocumentTemplateSystem.LAYOUT) {
    try {
      // Handle some older formats that are not strictly typed.
      return (
        (document as any).wordCount
        || JSON.parse(document.meta as any).wordCount
        || 0
      );
    } catch (e) {
      return 0;
    }
  }

  return 0;
}

export function getPageContainingBlock(version: DocumentVersion, blockId: string) {
  for (let i = 0; i < version.pages.length; i += 1) {
    const page = version.pages[i];
    if (!isBlockPage(page)) {
      return null;
    }

    for (let j = 0; j < page.grid.blocks.length; j += 1) {
      if (page.grid.blocks[j].id === blockId) {
        return page;
      }
    }
  }

  return null;
}
