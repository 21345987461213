import axios from 'axios';
import { DEFAULT_USER, useUserStore } from '../zustand/user';

const API = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {},
  withCredentials: true,
});

API.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    useUserStore.setState({ user: DEFAULT_USER });
  }
  return Promise.reject(error);
});

export default API;
