import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../utils';

export default styled(Box)(() => ({
  padding: '2.5rem 2.5rem 0 2.5rem',
  display: 'flex',
  flexDirection: 'row',
  gap: '3.75rem',
  position: 'relative',

  '.assignment-back-button': {
    display: 'flex',
    gap: '.5rem',
    cursor: 'pointer',
    textDecoration: 'none',
    marginBottom: '2.5rem',
  },

  '.classroom-name-info': {
    padding: '2.5rem',

    '.classroom-name': {
      fontSize: '1.5rem',
      color: '#2C2C2C',
      lineHeight: '150%',
    },

    '.classroom-type': {
      fontSize: '.75rem',
      display: 'flex',
      gap: '.5rem',
      alignItems: 'center',
      color: '#999999',
      lineHeight: '150%',
      marginBottom: '.5rem',
    },
  },

  '.turned-in-assignment-info': {
    padding: '2rem 4.375rem',
    background: '#F6F6FA',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '2.5rem',

    '& > div': {
      p: {
        lineHeight: '150%',
      },

      'p:first-of-type': {
        fontSize: '3rem',
        color: '#163567',
      },

      'p:last-of-type': {
        fontSize: '.75rem',
        color: '#999999',
      },
    },
  },

  '.original-document-container': {
    marginTop: '1.25rem',
    width: '100%',
    boxShadow: '0px 0px 50px rgba(82, 80, 154, 0.2)',
    filter: 'drop-shadow(0px 0px 50px rgba(82, 80, 154, 0.2))',
    background: '#F6F6FA',

    textDecoration: 'none',

    '.original-document-header': {
      background: '#163567',
      fontSize: '.75rem',
      color: '#FFFFFF',

      padding: '.375rem 1rem',

      display: 'flex',
      alignItems: 'center',

      img: {
        marginRight: '.5rem',
      },
    },

    '.thumbnail-container': {
      display: 'grid',
      placeItems: 'center',
      padding: '1rem',

      '& > img': {
        height: '8.25rem',
      },
    },
  },

  '.main-content': {
    padding: '0 6.875rem',

    '.student-name': {
      fontSize: '3rem',
      color: '#163567',
      lineHeight: '150%',
    },
  },

  '.assignment-info': {
    marginTop: '30px',
    boxShadow: theme.shadows[14],
    border: `1px solid ${theme.palette.primary.main}`,
  },

  [theme.breakpoints.down('md')]: {
    paddingTop: '1rem',
    flexDirection: 'column',
    gap: 0,

    '.classroom-back-button': {
      marginBottom: '1.5rem',
    },

    '.classroom-name-info': {
      boxShadow: '0px 0px 50px rgba(82, 80, 154, 0.2)',
      marginBottom: 0,
    },

    '.main-content': {
      padding: 0,
    },
  },

  '.delete-assignment': {
    color: '#FF5252',
    fontSize: '.75rem',
    position: 'absolute',
    right: '2.5rem',
    top: '4rem',
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  '.left-column': {
    width: '20rem',
    minWidth: '20rem',
  },

  '.right-column': {
    width: 'fill-available',

    h1: {
      marginBottom: '2rem',
    },
  },

  '.content': {
    display: 'grid',
    maxHeight: 'calc(100vh - 12rem)',
    paddingBottom: '2rem',
    overflowY: 'auto',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, 12.5rem)',
      gap: '1.25rem',
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 'calc(100vh - 15rem)',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'calc(100vh - 16.5rem)',
      gridTemplateColumns: 'repeat(auto-fit, calc(50% - 1rem))',
      gap: '2rem',
    },
  },

  '.extended-container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2.5rem',

    '.classroom-delete-button': {
      display: 'contents',
    },
  },
}));

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100vw',
}));

export const Content = styled(Box)(() => ({
  display: 'block',
  height: 'max-content',
  width: 'fill-available',
}));

export const AssignmentTitle = styled('h2')(() => ({
  marginBottom: '10px',
  color: theme.palette.primary.main,
  fontSize: '36px',
  fontWeight: 'inherit',

  [theme.breakpoints.up('sm')]: {
    fontSize: '48px',
  },
}));

/**
 * Base Dialog SubTitle styled component
 */
export const AssignmentSubTitle = styled('div')(() => ({
  padding: '0',
  color: theme.palette.secondary.main,
  fontSize: '18px',
  lineHeight: 1.2,

  [theme.breakpoints.up('sm')]: {
    fontSize: '24px',
  },
}));
