import { ImageBoxType, TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 112,
    y: 96,
    height: 888,
    width: 562,
  } as ImageBoxType,
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    defaultTextAlign: 'ALIGN_CENTER',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 698,
    y: 112,
    height: 856,
    width: 524,
  } as TextBoxType,
  {
    key: 'imagebox2',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 1246,
    y: 96,
    height: 888,
    width: 562,
  } as ImageBoxType,
];
