import React, { useState } from 'react';
import { useUserStore } from 'src/zustand/user';
import { Document } from 'src/types/models';
import { useSelectedBlock } from 'src/hooks/document';
import WritingPlans from 'src/configuration/writing-plans';
import { Box } from '@mui/material';
import WritingPlanCard from './Cards/WritingPlanCard';
import WordCountCard from './Cards/WordCountCard';
import ImportantWordsCard from './Cards/ImportantWordsCard';

function FeedbackSideBar(props: {
  document: Document;
  isEditable?: boolean;
}) {
  const { document, isEditable } = props;
  const user = useUserStore((state) => state.user);

  const isWritingBuddyEnabled = !!document.meta.hasWritingBuddy;
  const writingPlanId: string | null = document.meta.writingPlan || null;
  const writingPlan = WritingPlans.find((el) => el.id === writingPlanId);

  // Cards default to open if the Writing Buddy is not visible.
  // If the Writing Buddy is visible, then collapse the cards by default.
  // When the Writing Buddy is visible, only one card can be opened at a time.
  const [isCardOpenByName, setIsCardOpenByName] = useState({
    'writing-plan': !isWritingBuddyEnabled,
    'important-words': !isWritingBuddyEnabled,
    'word-count': !isWritingBuddyEnabled,
  });
  const openCard = (name: keyof typeof isCardOpenByName) => {
    if (isWritingBuddyEnabled) {
      setIsCardOpenByName({
        'writing-plan': false,
        'important-words': false,
        'word-count': false,
        [name]: true,
      });
    } else {
      setIsCardOpenByName((prev) => ({
        ...prev,
        [name]: true,
      }));
    }
  };
  const closeCard = (name: keyof typeof isCardOpenByName) => {
    setIsCardOpenByName((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  // Mocked values
  let selectedBlock = useSelectedBlock()[0];
  if (selectedBlock?.documentId !== document.id) {
    // This block isn't part of this document. Don't include it
    // in document feedback.
    selectedBlock = null;
  }

  return (
    <Box
      sx={{
        m: 3,
      }}
    >
      {document.meta.hasSignalWords !== false && (
        isEditable || document.meta.writingPlan
      )
        && (
          <WritingPlanCard
            isOpen={isCardOpenByName['writing-plan']}
            onOpen={() => openCard('writing-plan')}
            onClose={() => closeCard('writing-plan')}
            isEditable={isEditable}
            document={document}
            writingPlan={writingPlan || null}
          />
        )}

      {((user.role === 'teacher' || document.meta.importantWords) && document.meta.hasImportantWords !== false) && (
        <ImportantWordsCard
          isOpen={isCardOpenByName['important-words']}
          onOpen={() => openCard('important-words')}
          onClose={() => closeCard('important-words')}
          isEditable={isEditable}
          document={document}
        />
      )}

      {document.meta.hasWordCount !== false && (
        <WordCountCard
          isOpen={isCardOpenByName['word-count']}
          onOpen={() => openCard('word-count')}
          onClose={() => closeCard('word-count')}
          block={selectedBlock}
          document={document}
        />
      )}
    </Box>
  );
}

export default FeedbackSideBar;
