import { ImageBoxType, TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 128,
    y: 160,
    height: 1454,
    width: 1099,
  } as TextBoxType,
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 1275,
    y: 0,
    height: 1650,
    width: 1275,
  } as ImageBoxType,
  {
    key: 'imagebox2',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 1650,
    height: 1650,
    width: 1275,
  } as ImageBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 1323,
    y: 1678,
    height: 1462,
    width: 1099,
  } as TextBoxType,
];
