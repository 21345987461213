import { ImageBoxType, TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 88,
    y: 136,
    height: 806,
    width: 430,
  } as TextBoxType,
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 542,
    y: 120,
    height: 842,
    width: 474,
  } as ImageBoxType,
  {
    key: 'imagebox2',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 64,
    y: 958,
    height: 842,
    width: 474,
  } as ImageBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 560,
    y: 978,
    height: 806,
    width: 430,
  } as TextBoxType,
];
