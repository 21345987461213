import { Block } from 'src/types/models';

interface Cell {
  x: number,
  y: number,
  width: number,
  height: number,
  block?: Block,
}

/**
 * Returns a block for the given cell coordinates if exists
 * This function takes into consideration block width and height
 *
 * @param blocks Block[]
 * @param x number
 * @param y number
 * @returns Block | undefined
 */
export function getBlockByCoords(blocks: Block[], x: number, y: number): Block | undefined {
  return blocks.find((block) => (
    x >= block.x && x <= (block.x + block.width - 1)
  ) && (
    y >= block.y && y <= (block.y + block.height - 1)
  ));
}

/**
 * Returns an array with all Cells for a Grid
 *
 * @param rows number
 * @param columns number
 * @param blocks Block[]
 * @returns Cell[]
 */
export function getCells(rows: number, columns: number, blocks: Block[]): Cell[] {
  const cells: Cell[] = [];

  // TODO: Improve loop to skip cells when block is found (relevant for big grids only)
  const addedBlocks: Block[] = [];

  // Loop trough each position on the grid
  for (let x = 1; x <= columns; x += 1) {
    for (let y = 1; y <= rows; y += 1) {
      // Get block for position, if any
      const block = getBlockByCoords(blocks, x, y);

      // Check if block in position is already added to a cell
      const isAddedBlock = block && addedBlocks.find((ab) => ab.id === block.id);

      // If no block in coords or not already added block, we add a cell
      if (!isAddedBlock) {
        cells.push({
          x,
          y,
          width: block ? block.width : 1,
          height: block ? block.height : 1,
          block: block || undefined,
        });

        // Add block to addedBlocks list
        if (block) {
          addedBlocks.push(block);
        }
      }
    }
  }

  return cells;
}
