import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { InputProps } from './types';
import { ErrorMessage } from '../typography';
import { BaseInput, PasswordVisibilityToggler } from './styles';

function InputPassword({ invalidMessage, error, ...rest }: InputProps) {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.addEventListener('invalid', () => (e: any) => e.preventDefault(), true);
  }, []);

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <BaseInput
          {...rest}
          error={error}
          type={showPassword ? 'text' : 'password'}
        />
        {
          setShowPassword && (
            <PasswordVisibilityToggler onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </PasswordVisibilityToggler>
          )
        }
      </div>
      {error && invalidMessage && <ErrorMessage>{invalidMessage}</ErrorMessage>}
    </div>
  );
}

export default InputPassword;
