import React from 'react';
import { Box } from '@mui/material';

export default function Artboard(props: {
  backgroundColor: string;
  children: React.ReactNode;
}) {
  const {
    children,
    backgroundColor = 'grey.A100',
  } = props;

  return (
    <Box
      sx={{
        minHeight: '100vh',
        transition: 'background-color .15s ease-out',
        backgroundColor,
      }}
    >
      {children}
    </Box>
  );
}
