import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../utils';

export const WrapperInfo = styled(Box)(() => ({
  paddingTop: '1.5rem',

  '.description': {
    marginBottom: '1.5rem',

    color: '#2C2C2C',

    '> span': {
      color: '#163567',
    },
  },

  [theme.breakpoints.up('sm')]: {
    paddingTop: 0,
  },
}));

export const SignalWordsItem = styled('li')(() => ({
  padding: '.5rem',

  color: '#163567',
  backgroundColor: '#F6F6FA',
}));

export const WritingPlanSection = styled(Box)(() => ({
  marginBottom: '1.5rem',

  '.title-section': {
    marginBottom: '0.75rem',
  },

  '&.words-section': {
    '.collapse-button': {
      padding: '0.375rem .5rem',
      alignItems: 'center',
      display: 'flex',

      color: '#163567',
      backgroundColor: '#F6F6FA',
    },
  },

  '&.blocks-section': {
    '.collapse-button': {
      border: '1px solid #596F9380',
      boxShadow: '2px 2px 0 #596F9380',
      backgroundColor: '#FFF',
      color: '#596F93',

      height: '3.75rem',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      padding: '0.75rem 1.25rem',

      lineHeight: '1.125rem',
      fontSize: '0.75rem',
      fontWeight: 700,
    },
  },

  '&:last-child': {
    marginBottom: 0,
  },
}));
