import { ClickAwayListener } from '@mui/base';
import React, { ReactElement, useState } from 'react';
import { ExtendableContainer } from './styles';

function ExtendableDialog({ children }: { children: (props?: any) => ReactElement }) {
  const [extended, setExtended] = useState(false);

  const extend = () => setExtended(true);

  const retract = () => setExtended(false);

  const toggle = () => setExtended((prev) => !prev);

  return (
    <ClickAwayListener onClickAway={retract}>
      <ExtendableContainer>
        {children({
          extended, extend, retract, toggle,
        })}
      </ExtendableContainer>
    </ClickAwayListener>
  );
}

export default ExtendableDialog;
