import API from './config';

export function csrfToken() {
  return API.get('sanctum/csrf-cookie');
}

export function authCheck() {
  return API.get<{
    data: {
      id: string,
      name: string | null,
      surname: string | null,
      username: string | null,
      email: string | null,
      created_at: string,
      role: 'individual' | 'student' | 'teacher' | 'admin' | 'superadmin';
      providers: string[],
      actions: string[],
      flags: string[],
      classrooms: {
        id: string,
        name: string,
        section: string | null,
        subject: string | null,
        join_code: string | null,
        provider: string,
        students: {
          id: string,
          name: string | null,
          surname: string | null,
          email: string | null,
          last_session: string,
          words_written: number,
          has_documents: boolean,
        }
      }[],
      teacher_classrooms: {
        id: string,
        name: string,
        section: string | null,
        subject: string | null,
        join_code: string | null,
        provider: string,
        students: {
          id: string,
          name: string | null,
          surname: string | null,
          email: string | null,
          last_session: string,
          words_written: number,
          has_documents: boolean,
        }
      }[],
      masquerading_admin_id: string | null,
    }
  }>('auth/user');
}

export function logout() {
  return API.post('auth/logout');
}

export function sendVerificationEmail(data: { email: string }) {
  return API.post('auth/send-verification-email', data);
}

export function signIn(data: { username_or_email: string; password: string; }) {
  return API.post('auth/login', data);
}

export function signUp(data: {
  username?: string; email?: string; birthdate?: string; join_code?: string;
}, isTeacher = false) {
  return API.post(isTeacher ? 'auth/register/teacher' : 'auth/register', data);
}

export function resetPasswordLink(data: { email: string }) {
  return API.post('auth/reset-password-link', data);
}

export function setNewPassword(data: { email: string; password: string; token: string; }) {
  return API.post('auth/reset-password', data);
}

export function googleSignIn(data: { code: string }) {
  return API.post('auth/google/login', data);
}

export function googleSignUp(data: { code: string, join_code?: string }, isTeacher = false) {
  let url = '';
  if (isTeacher) url = 'teacher';
  else if (data.join_code) url = 'student';
  else url = 'individual';

  return API.post(`auth/google/sign-up/${url}`, data);
}

export function googleVerify(data: { birthdate: string; join_code?: string; }) {
  return API.post('auth/google/verify', data);
}

export function updateUser(data: {
  userId: string,
  name?: string,
  surname?: string,
  password?: string,
  birthdate?: string,
  role?: 'individual' | 'student' | 'teacher',
}) {
  return API.patch(`users/${data.userId}`, data);
}

export function completeRegister(data: {
  name?: string, surname?: string, password?: string,
}) {
  return API.post('auth/complete-user-data', data);
}

export function deleteUser(id: string) {
  return API.delete(`users/${id}`);
}

export function getUser(id: string) {
  return API.get(`users/${id}`);
}

export function masquerade(data: {
  usernameOrEmail: string,
  loginReason: string,
  loginNote?: string,
}) {
  return API.post('auth/masquerade-session', {
    username_or_email: data.usernameOrEmail,
    login_reason: data.loginReason,
    login_note: data.loginNote,
  });
}
