import React from 'react';
import { Button } from '../styles';
import { IconButtonProps } from '../types';

import { ReactComponent as TextColorImg } from '../../../../assets/icons/text-color.svg';
import { ReactComponent as TextBackgroundImg } from '../../../../assets/icons/text-background.svg';
import { ReactComponent as BrushImg } from '../../../../assets/icons/brush.svg';

function IconButton(
  {
    src, alt, toggle, isActive, variant, onClick, noBorder, id,
  }: IconButtonProps,
) {
  const preventDefault = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const renderSpecificSVG = ['TextColorImg', 'TextBackgroundImg', 'ContainerBackgroundImg'].includes(src);

  return (
    <Button
      id={id}
      type="button"
      className="icon-button"
      onClick={onClick}
      noBorder={toggle || noBorder}
      isActive={isActive}
      onMouseDown={preventDefault}
    >
      <div className="button-icon">
        {src === 'TextColorImg' && <TextColorImg stroke={variant} />}
        {src === 'TextBackgroundImg' && <TextBackgroundImg stroke={variant === 'transparent' ? 'white' : variant} />}
        {src === 'ContainerBackgroundImg' && <BrushImg stroke="#2C2C2C" />}
        {!renderSpecificSVG && <img src={src} alt={alt} />}
      </div>
    </Button>
  );
}

export default IconButton;
