import { Grid } from '@mui/material';
import React from 'react';
import { WordCountImg } from 'src/assets/images';
import { lang } from 'src/lang';
import { blockWordCount, documentWordCount } from 'src/utils/documents';
import { Block, Document } from 'src/types/models';
import FeedbackCard, { CommonFeedbackCardProps } from '.';

function WordCountCard(props: CommonFeedbackCardProps & {
  document: Document,
  block: Block | null,
}) {
  const { document, block } = props;
  const documentCount = documentWordCount(document);
  const blockCount = block ? blockWordCount(block) : 0;

  return (
    <FeedbackCard
      {...props}
      accentColor="#35645E"
      title="Word Count"
      icon={WordCountImg}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div>Block</div>
          <b style={{ display: 'flex', alignItems: 'center' }}>
            <span className={`content-value ${blockCount > 0 && 'colored-text'}`}>
              {block ? `${blockCount} ${lang('document.side_bar.word_count.words', { count: blockCount })}` : '-'}
            </span>
          </b>
        </Grid>
        <Grid item xs={6}>
          <div>Document</div>
          <b style={{ display: 'flex', alignItems: 'center' }}>
            <span className={`content-value ${documentCount > 0 && 'colored-text'}`}>
              {`${documentCount} ${lang('document.side_bar.word_count.words', { count: documentCount })}`}
            </span>
          </b>
        </Grid>
      </Grid>
    </FeedbackCard>
  );
}

export default WordCountCard;
