import { ImageBoxType, TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 0,
    height: 1920,
    width: 1080,
    noImagePosition: {
      bottom: '20%',
    },
  } as ImageBoxType,
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'TITLE100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 448,
    height: 1024,
    width: 984,
  } as TextBoxType,
];
