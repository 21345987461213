import API from './config';

export function getAll() {
  return API.get<{
    data: {
      id: string;
      name: string;
      section: string;
      join_code?: string;
      subject: string;
      provider: string;
      students: {
        id: string;
        email?: string | null;
        surname: string;
        name: string;
      }[];
      teachers: {
        id: string;
        name: string;
        surname: string;
      }[];
      school: {
        name: string;
      },
      owner_teacher_encoded_ids: string[];
    }[]
  }>('/classrooms');
}

export function getAllGoogle() {
  return API.get('/classrooms/providers/google');
}

export function connectGoogle(data: { code: string; }) {
  return API.post('/classrooms/providers/google/callback', data);
}

export function createClassroom(data: unknown) {
  return API.post('/classrooms', data);
}

export function importStudents(data: { classrooms: string[] }) {
  return API.post('/classrooms/providers/google', data);
}

export function getClassroomData(id: string) {
  return API.get(`/classrooms/${id}`);
}

export function getStudentsWork(id: string) {
  return API.get(`users/${id}/documents`);
}

export function deleteClassroom(id: string) {
  return API.delete(`/classrooms/${id}`);
}

export function syncWithGoogle(id: string) {
  return API.post(`/classrooms/${id}/sync-users`);
}

export function validateJoinCode(joinCode: string) {
  return API.get<{
    data: {
      id: string;
      name: string;
      join_code: string;
      teachers: {
        name: string;
        surname: string;
      }[];
    }
  }>(`classrooms/joincode/${joinCode}/validate`);
}

export function saveFromGoogle(data: any) {
  return API.post('/classrooms/providers/google', data);
}

export function joinWithCode(data: { join_code: string }) {
  return API.post('/classrooms/join-with-code', data);
}

export function removeStudent(classroomId: string, studentId: string) {
  return API.post(`/classrooms/${classroomId}/remove-student/${studentId}`);
}

export function createCoteacherInvitation(data: {
  classroomId: string;
}) {
  return API.post<{
    data: {
      id: string;
      classroom: {
        id: string;
        name: string;
        join_code: string;
      };
      created_at: string;
    }
  }>('/coteacher-invitations', data);
}

export function getCoteacherInvitation(id: string) {
  return API.get<{
    data: {
      id: string;
      classroom: {
        id: string;
        name: string;
        join_code: string;
        teachers: {
          id: string;
          name: string;
          surname: string;
        }[];
      };
    }
  }>(`/coteacher-invitations/${id}`);
}

export function revokeCoteacherInvitation(id: string) {
  return API.delete(`/coteacher-invitations/${id}`);
}

export function claimCoteacherInvitation(classroomId: string, invitationId: string) {
  return API.post(`/classrooms/${classroomId}/teachers/`, {
    invitation: {
      id: invitationId,
    },
  });
}

export function removeCoteacher(classroomId: string, teacherId: string) {
  return API.delete(`/classrooms/${classroomId}/teachers/${teacherId}`);
}
