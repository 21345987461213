/* eslint-disable react/prop-types */
import React from 'react';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Description } from '../../typography';
import { DialogCloseImg } from '../../../assets/icons';
import { Button } from '../../buttons';
import {
  TurnAssignmentDialogContainer,
  TurnAssignmentDialogTitle,
  TurnAssignmentDialogContent,
} from './styles';
import * as AssignmentsAPI from '../../../api/Assignments';
import { TurnAssignmentDialogProps } from './types';

function TurnAssignmentDialog({
  isOpen, onClose, submissionId, assignmentId,
}: TurnAssignmentDialogProps) {
  const navigate = useNavigate();

  const handleSave = async () => {
    const response = await AssignmentsAPI.submit(assignmentId!, submissionId!);
    if (response.status === 200) {
      navigate('/gallery', { state: { tab: 1 } });
    }
  };

  return (
    <TurnAssignmentDialogContainer onClose={onClose} open={isOpen}>
      <TurnAssignmentDialogTitle>
        <Description>Remember to check your work</Description>
      </TurnAssignmentDialogTitle>
      <TurnAssignmentDialogContent>
        <div className="content-wrapper">
          <Description className="subtitle">
            Put yourself in the mind of your audience and read your work out loud.
            Editing is an essential part of writing.
          </Description>
        </div>
        <div className="dialog-actions">
          <Button
            label="Go back and review"
            outline
            onClick={onClose}
          />
          <Button
            label="Turn in"
            onClick={handleSave}
          />
        </div>
      </TurnAssignmentDialogContent>

      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
    </TurnAssignmentDialogContainer>
  );
}

export default TurnAssignmentDialog;
