import { useCallback } from 'react';
import DocumentOperation, { OperationState } from 'src/store/document/operations/abstract';
import { SynchronousDocumentOperation } from 'src/store/document/operations';
import { useDocumentStore } from '../../store/document';

/**
 * Return a function for applying the given operation to the document
 * in the global store. The operation knows the document ID to which it
 * must be applied. No document is returned; it should be accessed with
 * useDocument.
 */
export function useApplyDocumentOperation(localOnly = false) {
  const apply = useDocumentStore((state) => state.applyDocumentOperation);
  return useCallback(
    (operation: DocumentOperation): Promise<OperationState> => apply(operation, localOnly),
    [apply, localOnly],
  );
}

/**
 * Return a function that synchronously applies the given operation
 * and returns the new document without updating the global store.
 * This is useful for previewing the result of an operation in a local part of the
 * UI before applying it, such when dragging and dropping blocks into a new
 * order. We want to preview the new order when dragging but not commit it
 * until dropped.
 *
 * The document against which the operation is applied must be in the global store
 * or the function returns undefined.
 */
export function usePreviewDocumentOperation() {
  return useCallback((operation: SynchronousDocumentOperation) => {
    const document = useDocumentStore.getState().documentsById[operation.documentId];
    if (!document) {
      return undefined;
    }

    return operation.apply(document);
  }, []);
}
