import { Document } from '../../../types/models';
import DocumentOperation, { OperationState } from './abstract';

/**
 * Prefer SynchronousDocumentOperation when the operation does not rely on
 * API interaction to compute its value. In general, idempotent API operations
 * should use SynchonousDocumentOperation.
 *
 * This method exists for document updates that require API interaction in
 * order to know what updates to the state are needed. This can slow down
 * the user experience and should be avoid if possible.
 */
export default abstract class AsynchronousDocumentOperation extends DocumentOperation {
  // Given a document version, apply the operation to it and
  // return the new version asynchronously.
  abstract postAndApply(
    document: Document,
    set: (fn: (document: Document) => Document) => void,
  ): Promise<OperationState>;
}
