import { Box } from '@mui/material';
import React from 'react';

export function WordChip(props: {
  word: string,
  color: string,
  borderColor?: string,
  backgroundColor?: string,
}) {
  const {
    word,
    color,
    borderColor = color,
    backgroundColor = 'transparent',
  } = props;

  return (
    <Box
      style={{
        display: 'inline-block',
        padding: '4px 8px',
        borderColor,
        backgroundColor,
        color,
        fontSize: '10px',
        lineHeight: '130%',
        marginRight: '4px',
        marginBottom: '2px',
      }}
    >
      {word}
    </Box>
  );
}
