import React, {
  useCallback, useState, useRef, useEffect,
} from 'react';
import {
  Grid, Paper, Toolbar,
} from '@mui/material';
import { PageContextProvider } from 'src/views/document-v2/PageContext';
import PageNavigation from 'src/components/PageNavigation';
import { PageCounter } from 'src/components/PageNavigation/styles';
import { IconButton } from 'src/components/buttons';
import { LargeBinImg } from 'src/assets/icons';
import { DocumentFormatSettings } from 'src/configuration/documents';
import Page from '../../components/Page';
import { useApplyDocumentOperation } from '../../hooks/document';
import { DeletePageOperation } from '../../store/document/operations';
import * as models from '../../types/models';
import BlockToolbar from './BlockToolbar';
import { ImageAdjustContext } from './ImageAdjustContext';

// TODO: To support Docs, the page must scroll up and down. Right now, it
// stays in plays. This may be a big change. Ideally, all formats would be
// able to scroll up and down, with the paginated formats snapping to the middle
// of the viewport. Scrolling should be smooth. 1H
export default function PageWrapper(props: {
  page: models.Page,
  document: models.Document,
  isEditable: boolean,
  showBlockLabels?: boolean,
}) {
  const {
    page,
    document,
    isEditable,
    showBlockLabels = false,
  } = props;
  const applyOperation = useApplyDocumentOperation();

  const onDelete = useCallback(() => {
    applyOperation(new DeletePageOperation(document.id, page.id));
  }, [document.id, page?.id]); // TODO: Shouldn't need a ? here.

  const numberOfPages = document.version.pages.length;

  // Handle image cropping state across components for showing the proper toolbar.
  const [isCropping, setIsCropping] = useState(false);

  // Everything inside of the page is scaled to fit within the page.
  // These values help manage that process.
  const pageRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [pageScale, setPageScale] = useState<number>();
  const formatSettings = DocumentFormatSettings[document.format];

  // The a base font size is 48px unless adjusted by the format.
  // Within each page, relative units are based on this value.
  const baseFontSizePx = 48 * formatSettings.scaleFactor;
  const pixelsPerEm = baseFontSizePx * (pageScale || 1.0);

  // Calculate the amount of space the page can occupy and scale it
  // accordingly
  useEffect(() => {
    const onResize = () => {
      if (!containerRef.current) {
        return;
      }

      if (formatSettings.fitToArtboardBy === 'width') {
        const width = containerRef.current.clientWidth - 48;
        setPageScale(width / formatSettings.width);
      } else {
        const height = window.innerHeight - (4 * 64);
        setPageScale(height / formatSettings.height);
      }
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [formatSettings, containerRef.current]);

  const canDeletePage = isEditable && formatSettings.canChangePageCount;
  const navigationStyle = formatSettings.pageNavigationStyle;

  return models.isBlockPage(page) ? (
    <PageContextProvider
      value={{
        page,
        pageRef,
        scale: pageScale || 1.0,
        pixelsPerEm,
      }}
    >
      <ImageAdjustContext.Provider value={{ isCropping, setIsCropping }}>
        <Grid
          container
          ref={containerRef}
          sx={{
            padding: 3,
            height: '100%',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            position: 'relative',
          }}
        >
          <Grid
            item
            sx={{
              position: navigationStyle === 'continuous' ? 'fixed' : 'static',
              zIndex: 10,
            }}
          >
            {isEditable && <BlockToolbar document={document} />}
          </Grid>

          <Grid
            item
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: navigationStyle === 'continuous' ? 'flex-start' : 'center',
              justifyContent: 'center',
              marginTop: navigationStyle === 'continuous' ? '64px' : '0px',
              marginBottom: '10px',
            }}
          >
            {pageScale && (
              <Paper
                elevation={4}
                ref={pageRef}
              >
                <Page
                  page={page}
                  format={document.format}
                  isEditable={isEditable}
                  showBlockLabels={showBlockLabels}
                  scale={pageScale}
                />
              </Paper>
            )}
          </Grid>

          {navigationStyle !== 'continuous' && (
            <Grid item>
              <Toolbar
                sx={{
                  gap: 3,
                  justifyContent: 'center',
                }}
              >
                {canDeletePage && (
                <IconButton
                  src={LargeBinImg}
                  onClick={onDelete}
                  alt="Delete page"
                />
                )}
                <PageCounter>
                  <span>{`Page ${page.position + 1} `}</span>
                  {`of ${numberOfPages} `}
                </PageCounter>
                <PageNavigation
                  page={page}
                  numberOfPages={numberOfPages}
                  document={document}
                />
              </Toolbar>
            </Grid>
          )}
        </Grid>
      </ImageAdjustContext.Provider>
    </PageContextProvider>
  ) : null;
}
