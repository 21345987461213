import { ButtonUnstyled } from '@mui/base';
import { lighten } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../utils';
import { ButtonStyleProps } from './types';

export const ButtonComponent = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => prop !== 'large' && prop !== 'outline' && prop !== 'error' && prop !== 'clicking',
})<ButtonStyleProps>(({
  large,
  outline,
  error,
}) => {
  const mainColor = error ? theme.palette.error.main : theme.palette.primary.main;
  const constrastColor = theme.palette.primary.contrastText;
  const hoverColor = error ? '#fb8077' : theme.palette.primary.light;

  return {
    backgroundColor: outline ? 'white' : mainColor,
    color: outline ? mainColor : constrastColor,
    border: outline ? `0.125rem solid ${mainColor}` : '0.125rem solid transparent',
    outline: 'none',
    fontWeight: 700,
    width: large ? '100%' : 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0.875rem 1.5rem',
    transition: 'all 150ms ease',
    position: 'relative',
    overflow: 'hidden',
    gap: '0.5em',

    img: {
      maxHeight: '1.2em',
      overflow: 'visible',
    },

    '&:hover': {
      backgroundColor: outline ? lighten(mainColor, 0.9) : hoverColor,
    },

    '&:disabled': {
      opacity: 0.5,
      backgroundColor: outline ? 'white' : theme.palette.secondary.main,
      cursor: 'not-allowed',
      color: theme.palette.secondary.contrastText,
    },
  };
});
