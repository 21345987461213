import ZineThumbnailImg from 'src/assets/images/formats/zine-thumbnail.png';
import DocThumbnailImg from 'src/assets/images/formats/doc-thumbnail.png';
import StoryThumbnailImg from 'src/assets/images/formats/story-thumbnail.png';
import { DocumentFormat } from '../types/DocumentSettings';

export const DocumentFormatSettings = {
  [DocumentFormat.BOOKLET]: {
    format: DocumentFormat.BOOKLET,
    disabled: false,
    height: 1125,
    width: 675,
    title: 'Zine',
    label: 'Mini 8-page booklet for short form writing',
    image: ZineThumbnailImg,
    startingPages: 8,
    canChangePageCount: false,
    scaleFactor: 1,
    fitToArtboardBy: 'height',
    pageNavigationStyle: 'paginated',
    pagePadding: 0,
    grid: {
      style: 'fixed',
      x: 1,
      y: 2,
    },
  },

  [DocumentFormat.STORY]: {
    format: DocumentFormat.STORY,
    disabled: false,
    height: 1920,
    width: 1080,
    title: 'Story',
    label: 'Online social media post format',
    startingPages: 1,
    canChangePageCount: true,
    scaleFactor: 1080.0 / 675.0,
    fitToArtboardBy: 'height',
    image: StoryThumbnailImg,
    pageNavigationStyle: 'paginated',
    pagePadding: 0,
    grid: {
      style: 'fixed',
      x: 1,
      y: 2,
    },
  },

  // TODO: Adjust the way we talk about how documents are formatted.
  // Doc ("Longform") is different in a few ways: there are no distinct page
  // boundaries, the grid expands to accommodate more blocks, and
  // there is no fixed height. Change the DocumentFormatSettings type
  // to reflect this, and alter dependent code to be more flexible with its
  // type as needed. 1H
  [DocumentFormat.DOC]: {
    format: DocumentFormat.DOC,
    disabled: false,
    height: (11 / 8.5) * 1600,
    width: 1600,
    title: 'Doc',
    label: 'Document for writing of any length',
    startingPages: 1,
    canChangePageCount: false,
    scaleFactor: 1,
    fitToArtboardBy: 'width',
    image: DocThumbnailImg,
    pageNavigationStyle: 'continuous',
    pagePadding: 48,
    grid: {
      style: 'fluid',
      x: 1,
      y: 1,
    },
  },
} as const;

export const SelectableDocumentFormats = [
  DocumentFormat.BOOKLET,
  DocumentFormat.STORY,
  DocumentFormat.DOC,
];
