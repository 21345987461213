import React, { useState } from 'react';
import { SliderContainerProps } from './types';

function SliderContainer({
  onSlideLeft, onSlideRight, disabled = false, ignoreEventOnQuery = [], children, ...rest
}: SliderContainerProps) {
  const [ignoreEvent, setIgnoreEvent] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchEnd = () => {
    if (disabled) return;

    if (!ignoreEvent && touchStart - touchEnd > 200) {
      onSlideLeft();
    }

    if (!ignoreEvent && touchStart - touchEnd < -200) {
      onSlideRight();
    }

    setTouchStart(0);
    setTouchEnd(0);
    setIgnoreEvent(false);
  };

  return (
    <div
      onTouchStart={(e) => {
        // ignoreEventOnQuery is an array of document query where the event should not trigger
        const ignore = ignoreEventOnQuery.some((query) => {
          try {
            const toolbar = document.querySelector(query);
            return toolbar && e.target && toolbar.contains(e.target as Element);
          } catch {
            return false;
          }
        });
        if (ignore) setIgnoreEvent(true);
        setTouchStart(e.targetTouches[0].clientX);
        setTouchEnd(e.targetTouches[0].clientX);
      }}
      onTouchMove={(e) => {
        setTouchEnd(e.targetTouches[0].clientX);
      }}
      onTouchEnd={handleTouchEnd}
      {...rest}
    >
      {children}
    </div>
  );
}

export default SliderContainer;
