import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { theme } from '../../utils';

export const HeaderBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'hideTabs' })(
  ({ hideTabs }: { hideTabs?: boolean }) => ({
    position: 'sticky',
    height: '4.7rem',
    zIndex: 999,

    borderBottom: `0.5px solid ${theme.palette.secondary.light}`,

    [theme.breakpoints.down('md')]: !hideTabs && {
      height: '8rem',
      minHeight: '8rem',
    },
  }),
);
