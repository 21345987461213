import { MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { useEditor } from '../../../../hooks/useEditor';
import { capitalize, getSufix } from '../../../../utils';

const FONTS = {
  TITLE: 'Alfa',
  SUBTITLE: 'Bebas',
  QUOTE: '"Source Serif 4"',
  PARAGRAPH: '"Open Sans"',
  CAPTION: 'Roboto',
  LABEL: 'Bebas',
};

const SelectContainer = styled(Select)(() => ({
  width: '6.25rem',
  fontSize: '.75rem',
  borderRadius: 0,

  div: {
    paddingTop: 0,
    paddingBottom: 0,
  },

  '#font-select': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },

}));

function FontsComboBox(
  { currentFont, handleFontChange }: {
    currentFont: string,
    handleFontChange: (newFont: string) => void
  },
) {
  const { setIsBusy } = useEditor();
  const [currentValue, setCurrentValue] = useState('');

  const { font, size } = useMemo(() => {
    const sufix = getSufix(currentFont);
    if (!sufix) return { font: '', size: 0 };
    const [fontMatch, sizeMatch] = sufix.match(/\D+|\d+/g)!;
    return { font: fontMatch, size: parseInt(sizeMatch, 10) };
  }, [currentFont]);

  useEffect(() => {
    setCurrentValue(font);
  }, [font]);

  const handleItemSelection = (event: SelectChangeEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const newFont = `FONT_STYLE_${event.target.value as string}${size}`;
    handleFontChange(newFont);
  };

  const preventDefault = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <SelectContainer
      id="font-select"
      value={currentValue}
      onChange={handleItemSelection as any}
      onClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
      onOpen={(event) => { event.preventDefault(); event.stopPropagation(); setIsBusy(true); }}
      onClose={() => setIsBusy(false)}
      style={{
        fontFamily: font === 'Sub-Title' ? FONTS.SUBTITLE : FONTS[font.toUpperCase() as keyof typeof FONTS],
      }}
    >
      {
        Object.keys(FONTS).map((fontKey) => (
          <MenuItem
            key={fontKey}
            value={fontKey}
            onMouseDown={preventDefault}
            style={{ fontSize: '.75rem', fontFamily: FONTS[fontKey as keyof typeof FONTS] }}

          >
            {fontKey === 'SUBTITLE' ? 'Sub-Title' : capitalize(fontKey)}
          </MenuItem>
        ))
      }
    </SelectContainer>
  );
}

export default FontsComboBox;
