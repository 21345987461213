import React, { useState, useCallback } from 'react';
import dayjs from 'dayjs';
import {
  Note as NoteIcon,
  Pushpin as PushpinIcon,
} from 'src/assets/icons';
import {
  Box, ButtonBase, Chip, Grid, Input, Typography,
} from '@mui/material';
import { lang } from 'src/lang';
import * as models from 'src/types/models';
import { ContextMenuItemProps, ContextMenuRef } from 'src/components/ContextMenu/types';
import ContextMenu from 'src/components/ContextMenu';
import { useUserStore } from 'src/zustand/user';
import Markdown from 'markdown-to-jsx';
import { ReactionChip } from 'src/components/ReactionChip';
import { CreatableNote } from '../types';
import { Wrapper } from './styles';

// The \uFE0F is a 'variation selector' that asks for the character to be
// rendered as an emoji instead of text when both variations are available.
const REACTIONS = ['❤️\uFE0F', '💡\uFE0F', '🤔\uFE0F', '👀\uFE0F', '✏️\uFE0F'] as const;

export default function Note(props: {
  note: CreatableNote;
  onSave?: (note: CreatableNote) => Promise<void>;
  onDelete?: (note: models.Note) => Promise<void>;

  // The MUI spacing to account for when applying a reverse margin to
  // the text inputs so they appear full width. This should be the same
  // as the padding around the NoteList component.
  spacingOffset?: number;
}) {
  const {
    note, onSave, onDelete, spacingOffset = 3,
  } = props;
  const user = useUserStore((state) => state.user);
  const [isEditing, setIsEditing] = useState(!note.id);
  const [isSaving, setIsSaving] = useState(false);

  // The reaction is just the beginning of the text value, if the beginning
  // matches a valid reaction.
  const reaction = (note.text && REACTIONS.find(
    (r) => note.text.startsWith(r),
  )) || undefined;
  const [inputReaction, setInputReaction] = useState(reaction);

  const text = note.text.slice((reaction || '').length).trim();
  const [inputText, setInputText] = useState(text);

  // Edits are allowed if the current user owns the note or if note is new.
  // Edits of assignment instructions are no longer allowed (in favor of editing
  // the assignment itself).
  const canModify = user.id === note.user?.id && !note.assignmentId;
  const canSubmit = inputText.length > 0;

  const save = useCallback(async () => {
    setIsSaving(true);
    try {
      await onSave!({
        ...note,
        text: `${inputReaction || ''} ${inputText}`.trim(),
      });
      if (note.id) {
        setIsEditing(false);
      } else {
        setInputText('');
        setInputReaction(undefined);
      }
    } catch (e) {
      // TODO: Handle error.
    } finally {
      setIsSaving(false);
    }
  }, [note, onSave, inputText, inputReaction]);

  const deleteNote = useCallback(async () => {
    if (!onDelete || !note.id) {
      return;
    }

    setIsSaving(true);
    try {
      await onDelete(note);
    } finally {
      setIsSaving(false);
    }
  }, [note.id, onDelete]);

  // Define a menu of actions for the note.
  const contextMenuItems = [
    canModify && onSave && !isEditing && note.id ? {
      label: lang('general.edit'),
      onClick: () => setIsEditing(true),
      disabled: isSaving,
    } : null,
    isEditing ? {
      label: lang('general.cancel'),
      onClick: () => setIsEditing(false),
      disabled: isSaving,
    } : null,
    canModify && onDelete && note.id ? {
      label: lang('general.delete'),
      onClick: deleteNote,
      disabled: isSaving,
    } : null,
  ].filter(Boolean) as ContextMenuItemProps[];

  const menuRef = React.useRef<ContextMenuRef>(null);
  const openMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    menuRef.current?.openMenu(event);
  }, [menuRef]);

  return (
    <Wrapper
      // Set the form flush with the bottom when the form is
      // the last child.
      sx={[!note.id && { '&:last-child': { pb: 0 } }]}
      // Cancel editing on escape.
      onKeyDown={(event) => {
        event.stopPropagation();
        if (event.key === 'Escape') {
          setIsEditing(false);
        }
      }}
    >
      <ContextMenu ref={menuRef} menuItems={contextMenuItems}>
        {/* The context menu is manually opened and closed. */}
        <span />
      </ContextMenu>

      {note.id && (
        <Box sx={{ marginBottom: 1 }}>
          <span style={{ whiteSpace: 'nowrap' }}>
            <span style={{ display: 'inline-block', minWidth: '18px', marginLeft: 0 }}>
              {(isEditing && inputReaction) || (!isEditing && reaction) ? (
                // TODO: Factor the style needed to force emoji rendering.
                <span style={{ fontFamily: 'san-serif' }}>
                  {isEditing ? inputReaction : reaction}
                </span>
              ) : (
                <img
                  src={note.assignmentId ? PushpinIcon : NoteIcon}
                  alt="Note:"
                  style={{
                    height: '1.3em',
                    verticalAlign: 'top',
                  }}
                />
              )}
            </span>
            <b style={{ marginLeft: '8px', whiteSpace: 'nowrap' }}>
              {note.user.fullName}
            </b>
          </span>
          <small
            style={{
              opacity: 0.5,
              marginLeft: '8px',
              whiteSpace: 'nowrap',
            }}
          >
            {dayjs(note.createdAt).fromNow()}
          </small>
          {contextMenuItems.length > 0 && (
            <ButtonBase
              type="button"
              disableRipple
              disableTouchRipple
              onClick={openMenu}
              sx={{
                border: 'none',
                background: 'none',
                float: 'right',
                userSelect: 'none',
                cursor: 'pointer',
                borderRadius: '5px',
                display: 'inline-block',
                px: '4px',

                '&:hover': {
                  backgroundColor: 'grey.A100',
                },
              }}
            >
              …
            </ButtonBase>
          )}
        </Box>
      )}

      {!isEditing ? (
        <div>
          <Markdown
            options={{
              disableParsingRawHTML: true,
            }}
          >
            {text}
          </Markdown>
        </div>
      ) : (
        <div>
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              marginBottom: 1.5,
            }}
          >
            {!note.assignmentId && REACTIONS.map((r) => (
              <Grid item key={r}>
                <ReactionChip
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.stopPropagation();
                      if (r !== inputReaction) {
                        setInputReaction(r);
                      } else {
                        setInputReaction(undefined);
                      }
                    }
                  }}
                  onClick={() => {
                    if (r !== inputReaction) {
                      setInputReaction(r);
                    } else {
                      setInputReaction(undefined);
                    }
                  }}
                  sx={{
                    borderColor: inputReaction === r ? 'primary.main' : undefined,
                    backgroundColor: inputReaction === r ? 'primary.main' : undefined,
                  }}
                >
                  <span>{r}</span>
                </ReactionChip>
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              my: 0,
              mx: -spacingOffset,
              padding: spacingOffset,
              backgroundColor: 'grey.A100',
            }}
          >
            <Input
              multiline
              minRows={2}
              value={inputText}
              placeholder={lang('notes.edit.placeholder')}
              onChange={(event) => {
                setInputText(event.target.value);
              }}
              sx={{
                // MUI seems to reset inputs to a font size of 1rem.
                fontSize: '14px',
                width: '100%',
                borderBottom: 'none',
              }}
              disableUnderline
            />
            <div style={{ textAlign: 'right' }}>
              <Chip
                label="→"
                onClick={save}
                disabled={isSaving}
                sx={{
                  px: '2px',
                  backgroundColor: canSubmit ? 'primary.main' : 'secondary.light',
                  color: 'primary.contrastText',
                  fontSize: '14px',

                  // Jog it down a little to the bottom-right corner, per designs.
                  marginBottom: -2,
                  marginRight: -2,

                  // Don't change color on hover.
                  '&:hover': {
                    backgroundColor: canSubmit ? 'primary.main' : 'secondary.light',
                  },
                }}
              />
            </div>
          </Box>
          {note.assignmentId && (
            <Typography
              color="error.main"
              variant="caption"
              component="div"
              sx={{ mt: 1 }}
            >
              {lang('notes.edit.assignment_instructions_warning')}
            </Typography>
          )}
        </div>
      )}
    </Wrapper>
  );
}
