import { TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'TITLE100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 128,
    y: 160,
    height: 734,
    width: 2294,
  } as TextBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 128,
    y: 966,
    height: 2174,
    width: 2294,
  } as TextBoxType,
];
