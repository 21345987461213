/* eslint-disable @typescript-eslint/naming-convention */
export const signin = {
  login: 'Log in',
  register: 'Register',
  greeting: 'Nice to see you again',
  forgot_password: 'I forgot my password',
  new_account: 'New here?',
  signup: 'Create an account',
};

export const signup = {
  signup: 'Sign up',
  role_select: 'What\'s your role?',

  student: {
    label: 'Student or Creator',
    item_1: 'Create Presstos',
    item_2: 'Print your work',
    item_3: 'Publish your work',
  },

  teacher: {
    label: 'Teacher',
    item_1: 'Create Presstos',
    item_2: 'Invite students',
    item_3: 'Get lesson ideas',
  },

  already_have_account: 'Already have an account?',
  login: 'Log in instead',

  joincode_title: 'Do you have a Join Code?',
  joincode_placeholder: 'Join Code',
  no_joincode: 'No, I don\'t have a Join Code',

  start_signup: 'Let\'s get started',
  agree_to_terms: 'By signing up for Pressto, you are agreeing to our',

  birthday_title: 'Enter your birthday',

  age_check_message: {
    line_1: 'We ask for this because users must be at least a',
    line_2: 'certain age to register for Pressto.',
  },

  underage: {
    line_1: 'Ask your parents or teacher',
    line_2: 'to help you sign up',
  },

  parent_message: 'If they need help, they should visit this',
  support_page: 'support page',

  back_to_homepage: 'Back to homepage',

  teacher_signup: {
    title: 'Educator Sign-up',
    subtitle: {
      line_1: 'Access Pressto FREE in 2023 and start making Presstos',
      line_2: 'with your class today!',
    },
  },

  welcome: 'Welcome to Pressto.',

  school_verification: 'Your school verification is on its way',

  wait_validation: {
    line_1: 'Have fun using the app while your school validates you.',
    line_2: 'You\'ll be able to access all the features that Pressto has for you and your students once it\'s done.',
  },

  greeting: ({ name }: { name: string }) => `Hi ${name}`,

  school: {
    signup: 'Sign up',
    find_school_title: 'Find your school',
    school_info_title: 'School information',
    register_school_title: 'Enter your school name',

    subtitle: 'Please confirm your school information',

    confirmation: 'Is this your school?',
    confirm_school: 'Confirm school',
    refuse: {
      line_1: 'This is not my school,',
      line_2: 'let\'s find it.',
    },

    name: 'My school is',
    website: 'School website',
    location: 'It\'s located in',
    confirm_information: 'Confirm information',
    edit: 'Edit information',

    name_placeholder: 'School name',
    website_placeholder: 'Website link (optional)',
    address: 'Address',
    city: 'City',
    zip_code: 'Zip Code',
    state: 'State',
    country: 'Country',

    select_school: 'Select your school from the list',

    select_message: 'Select your school from the list',
    school_fount: ({ count }: { count: number }) => `${count} school${count !== 1 ? 's' : ''} found found in`,
    select_button: 'Select school',

    none: {
      line_1: 'It\'s none of these,',
      line_2: 'let\'s try adding it.',
    },
  },

  account: {
    title: 'Create your account',
    password_min: 'At least 6 characters',
  },

  success: 'You\'re all set',

  verification: {
    title: 'Please verify your email',
    send_message: 'We sent you an email to:',
    description: {
      line_1: 'Click on the link in that email to complete your sign up.',
      line_2: 'If you don\'t see it, you may need to check your spam folder.',
    },
    cant_find_email: 'Still can\'t find the email?',
    resend: 'Resend email',

    help: 'Need help?',
    contact_us: 'Contact us',
  },
};

export const profile = {
  my_profile: 'My Profile',
  student_profile: 'Student Profile',
  mail_or_username: 'Email or Username',
  password_placeholder: 'New Password',
  remove_account: 'Remove Account',
  go_back: 'Go back',

  delete_account: {
    title: 'Are you sure you want to delete this student?',
    subtitle: 'Deleting this student will prevent them from being able to sign in into Pressto and cause them to lose all their work, including previously published work.',
  },
};

export const reset_password = {
  enter_password: 'Enter your new password',
  password_rules: 'At least 6 characters, including letters, symbols and numbers',
  login: 'Login',
  reset: 'Reset password',
};

export const delete_account_dialog = {
  title: 'Delete account',
  subtitle: 'Are you sure you want to delete your account?',
  description: 'This action is permanent and cannot be reverted.',
};

export const commom = {
  name_placeholder: 'First Name',
  last_name_placeholder: 'Last name',

  email_label: 'Other email account',
  email_placeholder: 'Email',

  password_placeholder: 'Password',

  email_or_username_label: 'Other email account or username',
  email_or_username_placeholder: 'Email or User Name',

  privacy_policy: 'Privacy Policy',
  terms_of_use: 'Terms of Use',

  google_button: ({ type }: { type: string }) => `${type === 'signUp' ? 'Sign up ' : 'Sign in '} with Google`,
};

export const errors = {
  unknown: 'There was an error. Please try again.',
  password_too_short: 'Password must have at least 6 characters!',

  invalid_code: {
    line_1: 'Invalid code',
    line_2: 'The code provided in the url is not valid.',
  },

  invalid_joincode: 'This Join Code is not valid.',
  email_is_google_account: 'The email provided is a Google Account.',
  email_not_found: 'That email is not registered in our database. Choose a different email or register.',
  account_already_exists_google: {
    line_1: 'Good news, this email works with Google! Use the button below to',
    line_2: 'save time signing up!',
  },
  invalid_zip: 'This ZIP has not been recognized. Please, try another one.',

  field_required: ({ field }: { field: string }) => `The ${field} field is required.`,
};
