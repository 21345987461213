import React, { useEffect, useState } from 'react';
import {
  CropImg, FlipImg, OkImg, ReplaceImg,
} from '../../../assets/icons';
import { useEditor } from '../../../hooks/useEditor';
import { lang } from '../../../lang';
import { ToolbarContainer } from './styles';
import IconTextButton from './ToolbarComponents/IconTextButton';
import { ImageToolbarProps } from './types';

function ImageBoxToolbar({
  selectedEl,
  handleUploadImage,
  setImgRotation,
  setCroppedAreaPixels,
  setIsCropping,
  setIsSettingUp,
  isCropping,
  isRotating,
}: ImageToolbarProps) {
  const { setIsBusy } = useEditor();

  const [boardPosition, setBoardPosition] = useState<DOMRect | {
    top: number; width: number;
  }>({ top: 0, width: 0 });

  useEffect(() => {
    setBoardPosition(
      document.getElementsByClassName('editor-wrapper')[0]?.getBoundingClientRect() || { top: 0, width: 0 },
    );
  }, [document.getElementsByClassName('editor-wrapper')[0]?.getBoundingClientRect()?.top]);

  const handleCropStart = () => {
    setIsSettingUp(false);
    setIsBusy(true);
    setIsCropping(true);
  };
  const handleCropEnd = () => {
    setIsBusy(false);
    setIsCropping(false);
  };

  const handleRotate = () => {
    setIsSettingUp(false);
    setCroppedAreaPixels(() => null);
    setImgRotation((prev: number) => (prev + 90) % 360);
  };

  const handleReplace = () => {
    handleUploadImage(selectedEl.key);
    setIsBusy(true);
  };

  return (
    <ToolbarContainer
      position="fixed"
      style={{
        top: boardPosition.top + 10,
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      {isCropping && !isRotating
        ? (
          <IconTextButton onClick={handleCropEnd} src={OkImg} alt="confirm" label={lang('general.done')} />
        )
        : (
          <>
            <IconTextButton onClick={handleCropStart} src={CropImg} alt="crop" label={lang('document.artboard.toolbar.adjust')} />
            <IconTextButton onClick={handleRotate} src={FlipImg} alt="rotate" label={lang('document.artboard.toolbar.flip')} />
            <IconTextButton onClick={handleReplace} src={ReplaceImg} alt="replace" label={lang('document.artboard.toolbar.replace')} />
          </>
        )}
    </ToolbarContainer>
  );
}

export default ImageBoxToolbar;
