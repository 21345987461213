import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Theme, useMediaQuery, ClickAwayListener, IconButton,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { EditButtonImg } from '../../assets/icons';

import GalleryTopBar from '../../components/GalleryTopBar';
import { Description, ErrorMessage } from '../../components/typography';
import { Input } from '../../components';
import DeleteAccountDialog from '../../components/dialogs/DeleteAccountDialog';

import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg';
import ContentContainter from './styles';
import DeleteConfirmationDialog from '../../components/dialogs/DeleteConfirmationDialog';
import * as UserAPI from '../../api/User';
import { useUserStore } from '../../zustand/user';
import { Button } from '../../components/buttons';
import { lang } from '../../lang';

function Profile() {
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const tabletMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const { id: studentId } = useParams();

  const navigate = useNavigate();

  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] = useState(false);

  const [userId, setUserId] = useState('');

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');

  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [role, setRole] = useState('');

  const [nameEditValue, setNameEditValue] = useState('');
  const [surnameEditValue, setSurnameEditValue] = useState('');
  const [usernameEditError, setUsernameEditError] = useState('');
  const [isEditingUsername, setIsEditingUsername] = useState(false);

  const [passwordEditValue, setPasswordEditValue] = useState('');
  const [passwordEditError, setPasswordEditError] = useState('');
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  const [providers, setProviders] = useState<string[]>([]);

  const { pathname, state } = useLocation() as { pathname: any, state: any };

  const prevRoute = useMemo(() => {
    if (!pathname.includes('classroom')) {
      return '/gallery';
    }

    const prev = pathname.split('/student/')[0];
    return prev;
  }, [pathname]);

  useEffect(() => {
    async function loadUser(uid: string) {
      const response = await UserAPI.getUser(uid);
      setName(response.data.data.name || '');
      setSurname(response.data.data.surname || '');
      setEmailOrUsername(response.data.data.email || response.data.data.username || '');
      setRole(response.data.data.role);
      setNameEditValue(response.data.data.name || '');
      setSurnameEditValue(response.data.data.surname || '');
      setProviders(response.data.data.providers || []);
    }

    if (pathname.includes('profile')) {
      setUserId(user.id ?? '');
      setName(user.name);
      setSurname(user.surname);
      setEmailOrUsername(user.email || user.username!);
      setRole(user.role!);
      setNameEditValue(user.name);
      setSurnameEditValue(user.surname);
      setProviders(user.providers || []);
    } else if (state?.user) {
      setUserId(state?.user.id);
      setName(state?.user.name);
      setSurname(state?.user.surname);
      setEmailOrUsername(state?.user.email || state?.user.username);
      setRole('student');
      setNameEditValue(state?.user.name);
      setSurnameEditValue(state?.user.surname);
      setProviders(state?.user.providers || []);
    } else {
      if (!studentId) {
        navigate('/');
        return;
      }

      setUserId(studentId);
      loadUser(studentId);
    }
  }, [pathname]);

  const submitUsernameChange = async () => {
    if (!userId || !nameEditValue || !surnameEditValue) {
      setNameEditValue(name ?? '');
      setSurnameEditValue(surname ?? '');
      return;
    }
    setName(nameEditValue);
    setSurname(surnameEditValue);

    try {
      const response = await UserAPI.updateUser(
        { userId, name: nameEditValue, surname: surnameEditValue },
      );

      setIsEditingUsername(false);
      setUser({ ...user, ...response.data.data });
    } catch (e) {
      const err = e as AxiosError;
      setUsernameEditError(err.response?.data.message);
    }
  };

  const submitPasswordChange = async () => {
    if (passwordEditValue.length < 6) {
      setPasswordEditError(lang('auth.errors.password_too_short'));
      return;
    }
    if (!userId) return;
    setPasswordEditValue('');
    try {
      await UserAPI.updateUser({ userId, password: passwordEditValue });
      setIsEditingPassword(false);
    } catch (e) {
      const err = e as AxiosError;
      setPasswordEditError(err.response?.data.message);
    }
  };

  const startUsernameEdit = () => {
    setIsEditingUsername(true);
    setIsEditingPassword(false);
    setPasswordEditError('');
  };

  const startPasswordEdit = () => {
    setIsEditingUsername(false);
    setIsEditingPassword(true);
    setPasswordEditError('');
  };

  const stopEdit = () => {
    setIsEditingUsername(false);
    setIsEditingPassword(false);
    setPasswordEditError('');
  };

  const handleDeleteUser = async () => {
    await UserAPI.deleteUser(userId);

    navigate(prevRoute);
  };

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: '#F6F6FA',
      }}
    >
      {/* TOPBAR */}
      <GalleryTopBar tab="documents" hideTabs />
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        height: 'fill-available',
        paddingTop: tabletMQ ? 0 : '4rem',
        position: 'relative',
      }}
      >
        <ContentContainter>
          <p className="content-title">
            {
              lang(`auth.profile.${prevRoute.includes('gallery') ? 'my_profile' : 'student_profile'}`)
            }
          </p>
          <div className="main-content">
            <div>
              <div style={{ position: 'relative' }}>
                <div className="default-avatar-pic">
                  {name?.slice(0, 1)}
                  {surname?.slice(0, 1)}
                </div>
                {/* <IconButton style={{
                  position: 'absolute', right: 0, bottom: 0, padding: 0,
                }}
                >
                  <img src={EditProfilePicImg} alt="edit profile pic" />
                </IconButton> */}
              </div>
            </div>
            <div className="info-column">
              <div className="info-container name">
                {isEditingUsername ? (
                  <ClickAwayListener onClickAway={stopEdit}>
                    <>
                      <div style={{ display: 'flex', gap: '.5rem' }}>
                        <Input
                          placeholder={lang('auth.commom.name_placeholder')}
                          value={nameEditValue}
                          error={!!usernameEditError}
                          onChange={(e: any) => {
                            setUsernameEditError('');
                            setNameEditValue(e.target.value);
                          }}
                        />
                        <Input
                          placeholder={lang('auth.commom.surname_placeholder')}
                          value={surnameEditValue}
                          error={!!usernameEditError}
                          onChange={(e: any) => {
                            setUsernameEditError('');
                            setSurnameEditValue(e.target.value);
                          }}
                        />
                        <Button outline onClick={submitUsernameChange} label="Save" />
                      </div>
                      <ErrorMessage>{usernameEditError}</ErrorMessage>
                    </>
                  </ClickAwayListener>
                ) : (
                  <Description weight={700} className="user-name">
                    {name}
                    {' '}
                    {surname}
                    <IconButton onClick={startUsernameEdit}>
                      <img src={EditButtonImg} alt="edit name" />
                    </IconButton>
                  </Description>
                )}
                <Description style={{ textTransform: 'capitalize' }}>
                  {role}
                </Description>
              </div>
              <div className="info-container mail">
                <Description size="small">
                  {lang('auth.profile.mail_or_username')}
                </Description>
                <Description>
                  {emailOrUsername}
                </Description>
              </div>
              {(providers.length === 1 && providers.includes('pressto')) && (
                <div className="info-container password">
                  <Description size="small">
                    {lang('auth.commom.password_placeholder')}
                  </Description>
                  {isEditingPassword ? (
                    <ClickAwayListener onClickAway={stopEdit}>
                      <div style={{ display: 'flex', gap: '.5rem' }}>
                        <Input
                          placeholder={lang('auth.profile.password_placeholder')}
                          value={passwordEditValue}
                          error={!!passwordEditError}
                          invalidMessage={passwordEditError}
                          onChange={(e: any) => {
                            setPasswordEditValue(e.target.value);
                            setPasswordEditError('');
                          }}
                        />
                        <Button outline onClick={submitPasswordChange} label="Save" />
                      </div>
                    </ClickAwayListener>
                  ) : (
                    <Description>
                      ***********
                      <IconButton onClick={startPasswordEdit}>
                        <img src={EditButtonImg} alt="edit password" />
                      </IconButton>
                    </Description>
                  )}
                </div>
              )}
              <Box className="remove-account-button" onClick={() => setIsDeleteAccountDialogOpen(true)}>
                <u>{lang('auth.profile.remove_account')}</u>
              </Box>
            </div>
          </div>
        </ContentContainter>
        {!mobileMQ && (
          <Box
            style={{
              display: 'flex',
              position: 'absolute',
              top: '5rem',
              left: '2.5rem',
              gap: '.5rem',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={() => { navigate(prevRoute, { replace: prevRoute.includes('gallery') }); }}
          >
            <BackArrow height={16} width={16} stroke="#999999" />
            <Description size="small">{lang('auth.profile.go_back')}</Description>
          </Box>
        )}
      </div>
      {prevRoute.includes('gallery')
        ? (
          <DeleteAccountDialog
            isOpen={isDeleteAccountDialogOpen}
            onClose={() => setIsDeleteAccountDialogOpen(false)}
          />
        )
        : (
          <DeleteConfirmationDialog
            title={lang('auth.profile.delete_account.title')}
            description={lang('auth.profile.delete_account.subtitle')}
            onSubmit={handleDeleteUser}
            isOpen={isDeleteAccountDialogOpen}
            onClose={() => setIsDeleteAccountDialogOpen(false)}
          />
        )}
    </div>
  );
}

export default Profile;
