import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PrivacyAndTerms from 'src/components/PrivacyAndTerms';

import {
  FlexBox, GradientContainer, Logo, RoleSelector,
} from '../../components';
import { Title, Description } from '../../components/typography';
import { lang } from '../../lang';

/**
 * Users are directed to this screen if they already have an account,
 * are successfully logged in, but need to select a role.
 */
function SelectRole() {
  const navigate = useNavigate();

  const handleSelection = useCallback((role: string) => {
    if (role === 'student') {
      navigate('/code');
    } else {
      navigate('/school-information-form');
    }
  }, []);

  return (
    <GradientContainer marginTop="30px" gap="60px" justifyContent="default">
      <Logo />

      <FlexBox textAlign="center">
        <Title>{lang('auth.signup.signup')}</Title>
        <Description align="center">{lang('auth.signup.role_select')}</Description>
      </FlexBox>

      <RoleSelector onSelect={handleSelection} />

      <PrivacyAndTerms />
    </GradientContainer>
  );
}

export default SelectRole;
