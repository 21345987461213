import { useCallback } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { useUserStore } from 'src/zustand/user';

/**
 * Return a function that redirects to the login page. An optional URL
 * may be supplied to that function to remember which page should be seen
 * after login.
 */
export function useRedirectToLogin(options?: {
  /** If true, the user is not redirected if they are already logged in.
   * Defaults to true. */
  checkAuthentication?: boolean,

  /** A different URL for the login page may be supplied if you want to force,
   * for example, the registration page as a starting point. Sends the user
   * to the login page, by default. */
  loginUrl?: To;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  const {
    checkAuthentication = true,
    loginUrl = '/',
  } = options || {};

  return useCallback((nextUrl: To = location) => {
    if (checkAuthentication && user.id) {
      return;
    }

    let postLoginState;
    try {
      postLoginState = JSON.parse(localStorage.getItem('postLoginState') || '{}');
    } catch (e) {
      postLoginState = {};
    }
    try {
      localStorage.setItem('postLoginState', JSON.stringify({
        ...postLoginState,
        next: nextUrl,
      }));
    } catch (e) {
      // Ignore error. Redirect after login will not happen.
    }

    navigate(loginUrl);
  }, [location, navigate, user, checkAuthentication, loginUrl]);
}
