import { DocumentFormat } from 'src/types/DocumentSettings';
import { SVGs } from 'src/assets/blocks';

const DOCUMENT_TEMPLATES_PRODUCTION = [
  {
    format: DocumentFormat.BOOKLET,
    writingPlan: 'News Article',
    documentId: 'Vv41nGoj0A',
    svg: SVGs.QuoteSVG,
    color: '#EEA1A2',
  },
  {
    format: DocumentFormat.STORY,
    writingPlan: 'Cause and Effect',
    documentId: 'XKj78n8wa2',
    svg: SVGs.EffectSVG,
    color: '#8DC9C3',
  },
  {
    format: DocumentFormat.DOC,
    writingPlan: 'Personal Narrative',
    documentId: 'bOJ0kynwzR',
    svg: SVGs.IntroductionSVG,
    color: '#E84534',
  },
  {
    format: DocumentFormat.DOC,
    writingPlan: 'Story',
    documentId: 'MOJBXWZj8N',
    svg: SVGs.SettingCharacterSVG,
    color: '#FDAD4E',
  },
  {
    format: DocumentFormat.DOC,
    writingPlan: 'Problem and Solution',
    documentId: 'qKwEnY8Jmx',
    svg: SVGs.SolutionPuzzleSVG,
    color: '#52509A',
  },
  {
    format: DocumentFormat.STORY,
    writingPlan: 'Compare and Contrast',
    documentId: 'Z2jnM16498',
    svg: SVGs.ContrastSVG,
    color: '#D2D7D3',
  },
  {
    format: DocumentFormat.BOOKLET,
    writingPlan: 'Opinion',
    documentId: '8zwV6NOw0N',
    svg: SVGs.ClaimSVG,
    color: '#288247',
  },
  {
    format: DocumentFormat.BOOKLET,
    writingPlan: 'Sequence',
    documentId: 'gXJbERgwWD',
    svg: SVGs.EventSVG,
    color: '#F5D62F',
  },
  {
    format: DocumentFormat.STORY,
    writingPlan: 'News Article',
    documentId: 'VG48O8RJNv',
    svg: SVGs.QuoteSVG,
    color: '#EEA1A2',
  },
  {
    format: DocumentFormat.DOC,
    writingPlan: 'Description',
    documentId: 'mRjDkg240r',
    svg: SVGs.MainIdeaSVG,
    color: '#9EC264',
  },
  {
    format: DocumentFormat.DOC,
    writingPlan: 'News Article',
    documentId: 'gNjo6R34YD',
    svg: SVGs.QuoteSVG,
    color: '#EEA1A2',
  },
  {
    format: DocumentFormat.BOOKLET,
    writingPlan: 'Personal Narrative',
    documentId: 'XKj78Ymwa2',
    svg: SVGs.IntroductionSVG,
    color: '#E84534',
  },
  {
    format: DocumentFormat.DOC,
    writingPlan: 'Sequence',
    documentId: '51JqdR5wKA',
    svg: SVGs.EventSVG,
    color: '#F5D62F',
  },
  {
    format: DocumentFormat.BOOKLET,
    writingPlan: 'Story',
    documentId: 'Ld4O56lwO5',
    svg: SVGs.SettingCharacterSVG,
    color: '#FDAD4E',
  },
  {
    format: DocumentFormat.BOOKLET,
    writingPlan: 'Description',
    documentId: 'VG48OpKJNv',
    svg: SVGs.MainIdeaSVG,
    color: '#9EC264',
  },
  {
    format: DocumentFormat.BOOKLET,
    writingPlan: 'Compare and Contrast',
    documentId: '5DwmXAejOY',
    svg: SVGs.ContrastSVG,
    color: '#D2D7D3',
  },
  {
    format: DocumentFormat.BOOKLET,
    writingPlan: 'Problem and Solution',
    documentId: 'Z2jnMRp498',
    svg: SVGs.SolutionPuzzleSVG,
    color: '#52509A',
  },
  {
    format: DocumentFormat.BOOKLET,
    writingPlan: 'Cause and Effect',
    documentId: '9RjkzRW4ry',
    svg: SVGs.EffectSVG,
    color: '#8DC9C3',
  },
  {
    format: DocumentFormat.STORY,
    writingPlan: 'Opinion',
    documentId: 'X8JPxvXJ9W',
    svg: SVGs.ClaimSVG,
    color: '#288247',
  },
  {
    format: DocumentFormat.STORY,
    writingPlan: 'Sequence',
    documentId: 'mRjDkGw04r',
    svg: SVGs.EventSVG,
    color: '#F5D62F',
  },
  {
    format: DocumentFormat.STORY,
    writingPlan: 'Story',
    documentId: 'VG48Op4JNv',
    svg: SVGs.SettingCharacterSVG,
    color: '#FDAD4E',
  },
  {
    format: DocumentFormat.STORY,
    writingPlan: 'Description',
    documentId: '5DwmXA9jOY',
    svg: SVGs.MainIdeaSVG,
    color: '#9EC264',
  },
  {
    format: DocumentFormat.STORY,
    writingPlan: 'Problem and Solution',
    documentId: 'qKwEn0OJmx',
    svg: SVGs.SolutionPuzzleSVG,
    color: '#52509A',
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DOCUMENT_TEMPLATES_TEST = [
  {
    format: DocumentFormat.BOOKLET,
    writingPlan: 'Problem Solution',
    documentId: '0M5jYL24l2',
    svg: SVGs.IntroductionSVG,
    color: '#E84534',
  },
  {
    format: DocumentFormat.STORY,
    writingPlan: 'Description',
    documentId: 'BgXJbQbwWD',
    svg: SVGs.SettingCharacterSVG,
    color: '#FDAD4E',
  },
  {
    format: DocumentFormat.BOOKLET,
    writingPlan: 'News Article',
    documentId: 'p9RjkV64ry',
    svg: SVGs.ClaimSVG,
    color: '#288247',
  },
];

export const DOCUMENT_TEMPLATES = DOCUMENT_TEMPLATES_PRODUCTION;
