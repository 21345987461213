import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateDocument } from '../../hooks/document';
import { DocumentFormat, isValidDocumentFormat } from '../../types/DocumentSettings';

export default function New() {
  const navigate = useNavigate();
  const search = new URLSearchParams(useLocation().search);

  const formatParam = search.get('format');
  const format = isValidDocumentFormat(formatParam) ? formatParam : DocumentFormat.BOOKLET;

  const importantWords = search.get('importantWords')?.trim().split(/,\s*/) || [];
  const writingPlan = search.get('writingPlan');
  const name = search.get('name') || 'Untitled';

  const createDocument = useCreateDocument();

  // Document will be undefined until it is created, at which point,
  // redirect the user to the first page.
  createDocument(
    name,
    format,
    {
      writingPlan,
      importantWords,
      hasImportantWords: true,
      hasSignalWords: true,
      hasWordCount: true,
    },
  ).then((document) => {
    navigate(`/document/${document.id}/pages/1`, { replace: true });
  });

  return <div />;
}
