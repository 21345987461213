import { TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 112,
    y: 112,
    height: 856,
    width: 1696,
  } as TextBoxType,
];
