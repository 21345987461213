import en from './en';

export function lang(path: string, params?: unknown) {
  const pathArray = path.split('.');

  try {
    let current = en as any;
    for (let i = 0; i < pathArray.length; i += 1) {
      current = current[pathArray[i]];
    }

    if (typeof current === 'function') {
      return current(params);
    }

    return current;
  } catch {
    throw new Error(`${path} does not exist`);
  }
}
