import React from 'react';
import IconButton from '../buttons/IconButton';

import { UndoImg, RedoImg } from '../../assets/icons';
import { TopBarButtonContainer } from './styles';
import { useEditor } from '../../hooks/useEditor';

function HistoryButtonGroup() {
  const { undo, redo } = useEditor();

  return (
    <TopBarButtonContainer>
      <IconButton src={UndoImg} alt="undo" onClick={undo} />
      <IconButton src={RedoImg} alt="redo" onClick={redo} />
    </TopBarButtonContainer>
  );
}

export default HistoryButtonGroup;
