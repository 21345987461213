import React, { useEffect } from 'react';
import {
  useSearchParams, useNavigate,
} from 'react-router-dom';
import * as ClassroomAPI from '../../api/Classrooms';
import Loader from '../../components/Artboard/Loader';

function GoogleClassroomRedirect() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const code = searchParams.get('code') || '';
  const error = searchParams.get('error') || '';

  const fetchAllClassrooms = async () => {
    try {
      const response = await ClassroomAPI.getAllGoogle();
      if (localStorage.getItem('doc')) {
        const docId = localStorage.getItem('doc');
        navigate(`/document/${docId}?loadClassrooms=true`, { state: { classroomList: response.data } });
      } else if (localStorage.getItem('assignmentUrl')) {
        navigate(
          `${localStorage.getItem('assignmentUrl')!}?loadClassrooms=true`,
          { state: { classroomList: response.data } },
        );
      } else {
        navigate('/gallery?loadClassrooms=true', { state: { classroomList: response.data } });
      }
    } catch {
      navigate('/gallery?error=true', { state: { tab: 2, permissionError: true } });
    }
  };

  const handleCredentialResponse = async () => {
    const response = await ClassroomAPI.connectGoogle({ code });
    if (response.status === 201) { fetchAllClassrooms(); }
  };

  useEffect(() => {
    if (code) handleCredentialResponse();
    else {
      localStorage.removeItem('assignmentName');
      navigate(`/gallery?error=${error}`);
    }

    return () => {
      localStorage.removeItem('assignmentUrl');
      localStorage.removeItem('doc');
    };
  }, []);

  return (
    <div>
      <Loader size={140} animate message="Preparing your Classroom..." />
    </div>
  );
}

export default GoogleClassroomRedirect;
