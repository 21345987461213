import React, { useMemo } from 'react';
import Masonry from '@mui/lab/Masonry';
import {
  Box,
  SxProps,
  Theme,
  useMediaQuery,
} from '@mui/material';

import { theme } from 'src/utils';

import { Image } from './types';
import ImageElement from './ImageElement';

export default function ImageGrid({
  images,
  isProcessing,
  onScroll,
  onImageChosen,
  sx,
}: {
  images: Image[];
  isProcessing: boolean;
  onScroll: (...args: any[]) => void;
  onImageChosen: (...args: any[]) => void;
  sx?: SxProps<Theme>;
}) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const columns = useMemo(() => {
    if (isMobile) return 2;
    if (isTablet) return 3;
    return 4;
  }, [isMobile, isTablet]);

  return (
    <Box
      sx={{
        opacity: isProcessing ? 0.5 : 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        marginRight: '-12px', // Align scroll bar outside of content
        '.MuiButtonBase-root': {
          padding: 0,
        },
        ...sx,
      }}
      onScroll={onScroll}
    >
      <Masonry columns={columns} spacing={1}>
        {images.map((el) => (
          <Box
            sx={{
              boxShadow: 'none',
              'button, img': {
                width: '100%',
              },
            }}
            key={el.provider_id}
          >
            <ImageElement
              isProcessing={isProcessing}
              element={el}
              onClick={() => { onImageChosen(el.provider_id); }}
            />
          </Box>
        ))}
      </Masonry>
    </Box>
  );
}
