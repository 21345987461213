import { Icons, SVGs } from '../assets/blocks';
import { BlockTypesEnum, BlockType, Block } from '../types/models/Block';

const baseConfiguration = {
  hasText: true,
  hasImage: false,
  hasAdjustableHeight: false,
} as const;
const baseDefaultProperties = {
  text: '',
  html: '',
} as const;
const blockTypeBase = {
  preferredWidth: 1,
  preferredHeight: 1,
  configuration: baseConfiguration,
  defaultProperties: baseDefaultProperties,
} as const;

/**
 * The definitive list of all possible blocks, their default properties,
 * and their configuration.
 */
const blockTypeSpecifications: Record<BlockTypesEnum, Partial<BlockType> & {
  svg: typeof SVGs.TextSVG;
}> = {
  Text: {
    icon: Icons.TextIcon,
    svg: SVGs.TextSVG,
    configuration: {
      placeholder: 'Click to start writing',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Title: {
    icon: Icons.TitleIcon,
    svg: SVGs.TitleSVG,
    configuration: {
      placeholder: 'Click to write a Title',
      hasImage: true,
    },
    defaultProperties: {
      fontSize: '2em',
      fontFamily: 'Alfa',
      fontColor: 'Black',
    },
  },
  Image: {
    icon: Icons.ImgIcon,
    svg: SVGs.ImgSVG,
    label: 'Image',
    configuration: {
      hasText: false,
      hasImage: true,
      hasAdjustableHeight: true,
    },
    defaultProperties: {
      rotation: 0,
      positionX: 0,
      positionY: 0,
      preferredHeight: '10em',
    },
  },
  ImageCaption: {
    icon: Icons.ImgCaptionIcon,
    svg: SVGs.ImgCaptionSVG,
    title: 'Image with Caption',
    label: 'Caption',
    configuration: {
      hasImage: true,
      placeholder: 'Click to add an image with caption (click the image icon to add an image)',
    },
    defaultProperties: {
      fontSize: '0.75em',
      fontFamily: 'Roboto',
      backgroundColor: 'Black',
      fontColor: 'White',
    },
  },
  Introduction: {
    icon: Icons.IntroductionIcon,
    svg: SVGs.IntroductionSVG,
    configuration: {
      placeholder: 'Click to write an Introduction',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Lead: {
    icon: Icons.IntroductionIcon,
    svg: SVGs.IntroductionSVG,
    configuration: {
      placeholder: 'Click to write a Lead',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Quote: {
    icon: Icons.QuoteIcon,
    svg: SVGs.QuoteSVG,
    title: 'Quote',
    label: 'Quote',
    configuration: {
      hasImage: true,
      placeholder: 'Click to add a Quote',
    },
    defaultProperties: {
      fontSize: '1.5em',
      fontFamily: "'Source Serif 4'",
      fontStyle: 'Italic',
      fontColor: 'Black',
    },
  },
  CallOut: {
    icon: Icons.QuoteIcon,
    svg: SVGs.QuoteSVG,
    title: 'Call-out',
    label: 'Call-out',
    configuration: {
      hasImage: true,
      placeholder: 'Click to add a Call-out',
    },
    defaultProperties: {
      fontSize: '1.5em',
      fontFamily: "'Source Serif 4'",
      fontStyle: 'Italic',
      fontColor: 'Black',
    },
  },
  Conclusion: {
    icon: Icons.ConclusionIcon,
    svg: SVGs.ConclusionSVG,
    configuration: {
      placeholder: 'Click to write a Conclusion',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  MainIdea: {
    icon: Icons.MainIdeaIcon,
    svg: SVGs.MainIdeaSVG,
    title: 'Main Idea',
    label: 'Main Idea',
    configuration: {
      placeholder: 'Click to write your Main Idea',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Details: {
    icon: Icons.DetailsIcon,
    svg: SVGs.DetailsSVG,
    label: 'Details',
    title: 'Details',
    configuration: {
      placeholder: 'Click to describe Details',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  SettingAndCharacters: {
    icon: Icons.SettingCharacterIcon,
    svg: SVGs.SettingCharacterSVG,
    title: 'Setting and Characters',
    label: 'Setting and Characters',
    configuration: {
      placeholder: 'Click to describe the Setting and Characters',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  PeopleAndSetting: {
    icon: Icons.SettingCharacterIcon,
    svg: SVGs.SettingCharacterSVG,
    title: 'People and Setting',
    label: 'People and Setting',
    configuration: {
      placeholder: 'Click to describe the People and Setting',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  ProblemOrGoal: {
    icon: Icons.ProblemGoalIcon,
    svg: SVGs.ProblemGoalSVG,
    title: 'Problem or Goal',
    label: 'Problem or Goal',
    configuration: {
      placeholder: 'Click to describe the Problem or Goal',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Attempts: {
    icon: Icons.AttemptsIcon,
    svg: SVGs.AttemptsSVG,
    title: 'Attempts to solve problem or reach goal',
    label: 'Attempts',
    configuration: {
      placeholder: 'Click to describe the Attempts to solve the problem',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  StorySolution: {
    icon: Icons.SolutionIcon,
    svg: SVGs.SolutionSVG,
    title: 'Solution',
    label: 'Solution',
    configuration: {
      placeholder: 'Click to describe the Solution',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  ConsequenceOrResolution: {
    icon: Icons.ConsequenceIcon,
    svg: SVGs.ConsequenceSVG,
    title: 'Consequence or Resolution',
    label: 'Consequence',
    configuration: {
      placeholder: 'Click to describe the Consequence',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Consequence: {
    icon: Icons.ConsequenceIcon,
    svg: SVGs.ConsequenceSVG,
    title: 'Consequence',
    label: 'Consequence',
    configuration: {
      placeholder: 'Click to describe the Consequence',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Resolution: {
    icon: Icons.ResolutionIcon,
    svg: SVGs.ResolutionSVG,
    title: 'Resolution',
    label: 'Resolution',
    configuration: {
      placeholder: 'Click to describe the Resolution',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  PointOfView: {
    icon: Icons.ClaimIcon,
    svg: SVGs.ClaimSVG,
    title: 'Point of View',
    label: 'Point of View',
    configuration: {
      placeholder: 'Click to write your Point of View',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  ClaimOrOpinion: {
    icon: Icons.ClaimIcon,
    svg: SVGs.ClaimSVG,
    title: 'Claim or Opinion',
    label: 'Claim or Opinion',
    configuration: {
      placeholder: 'Click to write your Claim or Opinion',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Evidence: {
    title: 'Reason and Evidence',
    label: 'Reason and Evidence',
    icon: Icons.EvidencesIcon,
    svg: SVGs.EvidencesSVG,
    configuration: {
      placeholder: 'Click to write your Reason and Evidence',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Event: {
    icon: Icons.EventIcon,
    svg: SVGs.EventSVG,
    title: 'Step or Event',
    label: 'Step',
    configuration: {
      placeholder: 'Click to write a Step or Event',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Cause: {
    icon: Icons.CauseIcon,
    svg: SVGs.CauseSVG,
    configuration: {
      placeholder: 'Click to write a Cause',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Effect: {
    icon: Icons.EffectIcon,
    svg: SVGs.EffectSVG,
    configuration: {
      placeholder: 'Click to write an Effect',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Compare: {
    icon: Icons.CompareIcon,
    svg: SVGs.CompareSVG,
    title: 'Similarity (Compare)',
    label: 'Compare',
    configuration: {
      placeholder: 'Click to write a Similarity (Compare)',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Contrast: {
    icon: Icons.ContrastIcon,
    svg: SVGs.ContrastSVG,
    title: 'Difference (Contrast) ',
    label: 'Contrast',
    configuration: {
      placeholder: 'Click to write a Difference (Contrast)',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Problem: {
    icon: Icons.ProblemIcon,
    svg: SVGs.ProblemSVG,
    configuration: {
      placeholder: 'Click to write a Problem',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Solution: {
    icon: Icons.SolutionPuzzleIcon,
    svg: SVGs.SolutionPuzzleSVG,
    configuration: {
      placeholder: 'Click to write a Solution',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  SupportingDetails: {
    icon: Icons.DetailsIcon,
    svg: SVGs.DetailsSVG,
    title: 'Supporting Details',
    label: 'Supporting Details',
    configuration: {
      placeholder: 'Click to write Supporting Details (Who/What/Where/When/Why/How)',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  // These are extra blocks that are not included in default
  // writing plans but which can be added to future custom
  // writing plans.
  Thesis: {
    icon: Icons.MainIdeaLightningIcon,
    svg: SVGs.MainIdeaLightningSVG,
    title: 'Thesis',
    label: 'Thesis',
    configuration: {
      placeholder: 'Click to write a Thesis',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  TopicSentence: {
    icon: Icons.IntroductionIcon,
    svg: SVGs.IntroductionSVG,
    title: 'Topic Sentence',
    label: 'Topic Sentence',
    configuration: {
      placeholder: 'Click to write a Topic Sentence',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  EvidenceAndLinks: {
    icon: Icons.EvidencesIcon,
    svg: SVGs.EvidencesSVG,
    title: 'Evidence and Link(s)',
    label: 'Evidence and Link(s)',
    configuration: {
      placeholder: 'Click to add Evidence and Link(s)',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },

  // Custom Blocks
  // These are extra blocks that are not included in default
  // writing plans but which can be added to future custom
  // writing plans.
  Hook: {
    icon: Icons.IntroductionIcon,
    svg: SVGs.IntroductionSVG,
    title: 'Hook',
    label: 'Hook',
    configuration: {
      placeholder: 'Click to write a Hook',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Background: {
    icon: Icons.DetailsIcon,
    svg: SVGs.DetailsSVG,
    title: 'Background',
    label: 'Background',
    configuration: {
      placeholder: 'Click to add Background information',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  ConcludingSentence: {
    icon: Icons.ProblemGoalIcon,
    svg: SVGs.ProblemGoalSVG,
    title: 'Concluding Sentence',
    label: 'Concluding Sentence',
    configuration: {
      placeholder: 'Click to write a Concluding Sentence',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  ConcludingParagraph: {
    icon: Icons.ConclusionIcon,
    svg: SVGs.ConclusionSVG,
    title: 'Concluding Paragraph',
    label: 'Concluding Paragraph',
    configuration: {
      placeholder: 'Click to write a Concluding Paragraph',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Body: {
    icon: Icons.EventIcon,
    svg: SVGs.EventSVG,
    title: 'Body',
    label: 'Body',
    configuration: {
      placeholder: 'Click to add Body text',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Closing: {
    icon: Icons.ConclusionIcon,
    svg: SVGs.ConclusionSVG,
    title: 'Closing',
    label: 'Closing',
    configuration: {
      placeholder: 'Click to add a Closing',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },

  // Custom blocks for the Toulmin method.
  Claim: {
    icon: Icons.ClaimIcon,
    svg: SVGs.ClaimSVG,
    title: 'Claim',
    label: 'Claim',
    configuration: {
      placeholder: 'Click to write a Claim',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Grounds: {
    icon: Icons.EvidencesIcon,
    svg: SVGs.EvidencesSVG,
    title: 'Grounds',
    label: 'Grounds',
    configuration: {
      placeholder: 'Click to write your Grounds',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Warrant: {
    icon: Icons.MainIdeaLightningIcon,
    svg: SVGs.MainIdeaLightningSVG,
    title: 'Warrant',
    label: 'Warrant',
    configuration: {
      placeholder: 'Click to write a Warrant',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Qualifier: {
    icon: Icons.ConsequenceIcon,
    svg: SVGs.ConsequenceSVG,
    title: 'Qualifier',
    label: 'Qualifier',
    configuration: {
      placeholder: 'Click to write a Qualifier',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Rebuttal: {
    icon: Icons.ResolutionIcon,
    svg: SVGs.ResolutionSVG,
    title: 'Rebuttal',
    label: 'Rebuttal',
    configuration: {
      placeholder: 'Click to write a Rebuttal',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Backing: {
    icon: Icons.DetailsIcon,
    svg: SVGs.DetailsSVG,
    title: 'Backing',
    label: 'Backing',
    configuration: {
      placeholder: 'Click to write your Backing',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
} as const;

// Merge the specification into the the base types to create a
// lookup by BlockTypesEnum.
const blockTypesById = Object.entries(blockTypeSpecifications).reduce(
  (lookup, [typeId, blockType]) => ({
    ...lookup,
    [typeId]: {
      ...blockTypeBase,
      title: blockType.title || typeId,
      label: blockType.label || typeId,
      ...blockType,
      id: typeId as BlockTypesEnum,
      configuration: {
        ...baseConfiguration,
        ...(blockType as Partial<BlockType>).configuration || {},
      },
      defaultProperties: {
        ...baseDefaultProperties,
        ...(blockType as Partial<BlockType>).defaultProperties || {},
      },
    },
  }),
  {},
) as Record<BlockTypesEnum, BlockType>;

export function getBlockType(block: Block): BlockType;
export function getBlockType(id: BlockTypesEnum): BlockType;
export function getBlockType(key: BlockTypesEnum | Block): BlockType {
  if (typeof key === 'string') {
    return blockTypesById[key] || blockTypesById[BlockTypesEnum.Text];
  }
  return blockTypesById[key.typeId] || blockTypesById[BlockTypesEnum.Text];
}

/**
 * Return an SVG component that can be embedded like any other React component.
 * The SVG property is not included in the BlockType interface because it is
 * is not serializable, a property I would like to maintain because so much
 * of document state is serialized.
 */
export function getBlockSVG(blockTypeId: BlockTypesEnum) {
  return blockTypeSpecifications[blockTypeId].svg;
}
