import create from 'zustand';
import { AxiosError } from 'axios';
import * as DocumentsAPI from '../api/Documents';
import * as AssignmentAPI from '../api/Assignments';
import { getTemplateContent } from '../configuration/layout-templates';
import { PresstoDefinition } from '../types/Gallery';
import { DocumentState } from './types';
import { useUserStore } from './user';
import { Element } from '../types/LayoutEditor';
import { LayoutPage as PageType } from '../types/models';

export const useDocumentStore = create<DocumentState>((set, get) => ({
  documents: [],
  sortDocuments: localStorage.getItem('sortDocuments') ?? 'recent',
  saving: false,
  isLoadingDocument: false,
  resetDocuments: () => {
    set(() => ({
      documents: [],
      saving: false,
      isLoadingDocument: false,
    }));
  },
  setSortDocuments: (data) => set(() => ({ sortDocuments: data })),
  setDocuments: (data) => set(() => ({ documents: data })),
  loadDocuments: async (params: { order: string; search?: string; }) => {
    try {
      const response = (await DocumentsAPI.getAll(params)) as unknown as {
        data: {
          data: PresstoDefinition[],
        },
      };
      return set(() => ({ documents: response.data.data }));
    } catch (error) {
      return set(() => ({ documents: [] }));
    }
  },
  setAssignmentStatus: (status: string) => {
    set((prev) => (prev.currentDocument?.submission ? ({
      currentDocument: {
        ...prev.currentDocument,
        submission: {
          ...prev.currentDocument.submission,
          status,
        },
      },
    }) : prev));
  },
  setTryItDocument: (doc: PresstoDefinition) => {
    set(() => ({
      currentDocument: doc,
      selectedPage: doc.version.pages[0],
    }));
  },
  currentDocumentInfo: async (id, isPublished = false) => {
    try {
      set(() => ({ isLoadingDocument: true }));

      const response = isPublished
        ? await DocumentsAPI.getPublished(id)
        : await DocumentsAPI.getById(id);

      if (response.status === 200) {
        // The API has started returning a content blob on this request.
        // Pretend like that never happened, because it throws off the
        // assumption about when that is loaded in the rest of the app.
        response.data.data.version.pages.forEach((page: { content: any }) => {
          delete page.content;
        });

        const currentDocument = {
          ...response.data.data,
          lastSaved: response.data.data.updated_at,
          author: `${response.data.data.user.name} ${response.data.data.user.surname}`,
          isOwner: response.data.data.user.id === useUserStore.getState().user?.id,
          prev: response.data.links?.previous,
          next: response.data.links?.next,
        };

        set(() => ({
          currentDocument,
          selectedPage: response.data.data.version.pages[0],
          isLoadingDocument: false,
        }));

        return response.data.data;
      }
      return null;
    } catch {
      return null;
    } finally {
      set(() => ({ isLoadingDocument: false }));
    }
  },
  changeSelectedPage: (id) => {
    set((prev) => ({
      selectedPage: prev.currentDocument?.version?.pages.find((p) => p.id === id),
    }));
    return Boolean(get().currentDocument?.version?.pages.find((p: any) => p.id === id)?.content);
  },
  deletePage: async (document, version, pageId) => {
    // If document has id, it means it is not a demo
    // So this should request api to delete page before deleting in local array
    if (document) {
      const response = await DocumentsAPI.deletePage(document, version, pageId);
      if (response.status !== 200) {
        return;
      }
    }

    set((prev) => {
      if (!prev.currentDocument) return prev;

      let currentDocumentPage: PageType[];
      if (prev.currentDocument?.version?.pages) {
        currentDocumentPage = prev.currentDocument.version.pages;
      } else {
        currentDocumentPage = get().currentDocument?.version.pages || [];
      }

      const deletedPageIndex = currentDocumentPage.findIndex((el: any) => el.id === pageId);
      currentDocumentPage.splice(deletedPageIndex, 1);
      const updatedPages = currentDocumentPage.map(
        (el: any, i: number) => ({ ...el, position: i }),
      );
      return {
        currentDocument: {
          ...prev.currentDocument,
          version: {
            ...prev.currentDocument.version,
            pages: updatedPages,
          },
        },
        selectedPage: prev.selectedPage?.id === pageId
          ? updatedPages[deletedPageIndex] ?? updatedPages[updatedPages.length - 1]
          : prev.selectedPage,
      };
    });
  },
  duplicatePage: async (id, isTryIt = false) => {
    const page = get().currentDocument?.version?.pages.find((el: any) => el.id === id);

    if (!page) return;

    let newPage: PageType | null = null;
    if (!isTryIt) {
      const response = await DocumentsAPI.duplicatePage(id);

      if (response.status === 201) {
        newPage = response.data.data;
      } else {
        return;
      }
    } else {
      newPage = {
        ...page,
        id: `page-${page.position + 1}`,
        position: page.position + 1,
      };
    }

    if (newPage) {
      set((prev) => {
        if (!prev.currentDocument) return prev;

        let pageContent: Element[];

        if (typeof newPage!.content === 'string'
          || (typeof newPage!.content === 'object' && isTryIt)) {
          pageContent = page.content;
        } else {
          pageContent = getTemplateContent(prev.currentDocument?.format ?? '', newPage!.template!);
        }

        const documentIndex = get().documents.findIndex(
          (el) => el.id === get().currentDocument?.id,
        );

        const currentDocument = structuredClone(prev.currentDocument);

        currentDocument.version?.pages.splice(newPage!.position, 0, {
          ...page,
          id: newPage!.id,
          content: pageContent,
        });

        currentDocument.version.pages = currentDocument.version?.pages.map(
          (el: any, i: number) => ({
            ...el,
            id: isTryIt ? `page-${i}` : el.id,
            position: i,
          }),
        );

        setTimeout(() => {
          set(() => ({
            selectedPage: {
              ...page,
              id: newPage!.id,
              content: pageContent,
            },
          }));
        }, 200);

        if (documentIndex >= 0) {
          const updatedDocuments = [...prev.documents];
          updatedDocuments[documentIndex] = currentDocument;

          return {
            documents: updatedDocuments,
            currentDocument,
          };
        }

        return { currentDocument };
      });
    }
  },
  prevPage: () => {
    const { currentDocument } = get();

    if (!currentDocument) return false;

    const openedDocumentPages = currentDocument?.version?.pages;
    const selectedPageIndex = openedDocumentPages.findIndex(
      (el: any) => el.id === get().selectedPage?.id,
    );
    if (selectedPageIndex > 0) {
      set(() => ({ selectedPage: openedDocumentPages[selectedPageIndex - 1] }));
    }
    return Boolean(openedDocumentPages[selectedPageIndex - 1].content);
  },
  nextPage: () => {
    const { currentDocument } = get();

    if (!currentDocument) return false;

    const openedDocumentPages = currentDocument.version.pages;
    const selectedPageIndex = openedDocumentPages.findIndex(
      (el: any) => el.id === get().selectedPage?.id,
    );
    if (selectedPageIndex >= 0 && selectedPageIndex < openedDocumentPages.length - 1) {
      set(() => ({ selectedPage: openedDocumentPages[selectedPageIndex + 1] }));
    }
    return Boolean(openedDocumentPages[selectedPageIndex + 1].content);
  },
  updatePages: (payload: any) => {
    set((prev) => {
      if (!prev.currentDocument) return prev;

      const documentIndex = prev.documents.findIndex((el) => el.id === prev.currentDocument?.id);
      const currentDocument = {
        ...prev.currentDocument,
        lastSaved: prev.currentDocument.updated_at,
        author: `${prev.currentDocument.user.name} ${prev.currentDocument.user.surname}`,
        isOwner: prev.currentDocument.isTryIt
          || prev.currentDocument.user.id === useUserStore.getState().user?.id,
      };

      currentDocument.version.pages = payload(currentDocument.version.pages);
      if (documentIndex >= 0) {
        const updatedDocumentArray = [...prev.documents];
        updatedDocumentArray[documentIndex] = currentDocument;

        return {
          documents: updatedDocumentArray,
          currentDocument,
        };
      }

      return { currentDocument };
    });
  },
  updatePage: async (id, version, data) => {
    let { currentDocument } = get();

    if ((!id || !version || !data) && !currentDocument?.isTryIt) return;

    set((prev) => {
      if (!prev.currentDocument) return prev;

      let newPages: PageType[];
      if (prev.currentDocument?.version?.pages) {
        newPages = prev.currentDocument.version.pages;
      } else {
        newPages = get().currentDocument?.version.pages || [];
      }

      const pageIndex = newPages.findIndex((el) => el.id === data.id);

      if (pageIndex < 0) {
        return {
          saving: true,
          currentDocument: {
            ...prev.currentDocument,
            lastSaved: null,
          },
        };
      }

      newPages[pageIndex] = { ...newPages[pageIndex], ...data };

      return {
        saving: true,
        currentDocument: {
          ...prev.currentDocument,
          version: {
            ...prev.currentDocument.version,
            pages: newPages,
          },
          lastSaved: null,
        },
      };
    });

    if (!currentDocument?.isTryIt) {
      try {
        const response = await DocumentsAPI.updatePage(
          id,
          version,
          {
            ...data,
            content: JSON.stringify(data.content),
            position: undefined,
          },
        );

        if (response.status === 200) {
          set((prev) => {
            if (!prev.currentDocument) return prev;

            const documentIndex = prev.documents.findIndex((el) => el.id === id);

            currentDocument = {
              ...prev.currentDocument,
              lastSaved: new Date().toString(),
            };
            const pageIndex = currentDocument.version.pages.findIndex(
              (el: any) => el.id === data.id,
            );

            if (pageIndex < 0) return prev;

            const parsedUrl = response.data.data.thumbnail_url?.includes('timestamp') ? response.data.data.thumbnail_url : `${response.data.data.thumbnail_url}?timestamp=${new Date()}`;

            currentDocument.version.pages[pageIndex] = { ...data, thumbnail_url: parsedUrl };

            if (documentIndex < 0) {
              return { currentDocument };
            }

            const documents = [...prev.documents];
            documents[documentIndex] = currentDocument;

            return {
              documents,
              currentDocument,
            };
          });

          return;
        }
        throw new Error('Some error occurred during save');
      } catch (e) {
        set((prev) => ({
          saving: false,
          currentDocument: prev.currentDocument ? {
            ...prev.currentDocument,
            lastSaved: 'error',
          } : null,
        }));
      }
    }
  },
  addPage: async (layout, template) => {
    const { currentDocument } = get();
    if (!currentDocument) return;

    const newPage: any = {
      template: layout,
      position: currentDocument.version.pages.length,
      content: JSON.stringify(template),
      meta: '{}',
    };

    let response: any;
    if (!currentDocument.isTryIt) {
      response = await DocumentsAPI.createPage(
        currentDocument.id,
        currentDocument.version.id,
        newPage,
      );

      if (response.status !== 201) {
        return;
      }
    }

    newPage.id = currentDocument.isTryIt
      ? `page-${currentDocument.version.pages.length}`
      : response.data.data.id;
    set(() => ({
      selectedPage: { ...newPage, content: JSON.parse(newPage.content) },
      currentDocument: {
        ...currentDocument,
        version: {
          ...currentDocument.version,
          pages: [
            ...currentDocument.version.pages,
            currentDocument.isTryIt ? newPage : response.data.data,
          ],
        },
      },
    }));
  },
  getPage: async (id, version, page) => {
    let { currentDocument } = get();
    let response: any;

    if (!currentDocument?.isTryIt) {
      try {
        response = await DocumentsAPI.getPage(id, version, page);
        if (response.status !== 200) {
          return response.status;
        }
      } catch (e) {
        const err = e as AxiosError;
        return err.response?.status;
      }
    }

    set((prev) => {
      if (!prev.currentDocument) return prev;

      const documentIndex = prev.documents.findIndex((el) => el.id === id);
      currentDocument = {
        ...prev.currentDocument,
        lastSaved: prev.currentDocument.lastSaved,
        author: `${prev.currentDocument.user.name} ${prev.currentDocument.user.surname}`,
        isOwner: prev.currentDocument.isOwner
          || prev.currentDocument.user.id === useUserStore.getState().user?.id,
      };

      let pageContent;
      if (prev.currentDocument.isTryIt) {
        pageContent = currentDocument.version.pages.find((p) => p.id === page)?.content;
      } else {
        pageContent = response.data.data.content
          ? JSON.parse(response.data.data.content)
          : getTemplateContent(
            prev.currentDocument?.format,
            response.data.data.template,
          );
      }

      const newPage = prev.currentDocument.isTryIt ? {
        ...currentDocument.version.pages.find((p) => p.id === page),
      } : {
        ...response.data.data,
        position: currentDocument.version.pages.find((el: PageType) => el.id === page)?.position,
        content: pageContent,
      };

      currentDocument.version.pages[newPage.position] = newPage;

      if (documentIndex >= 0) {
        const newDocuments = [...prev.documents];
        newDocuments[documentIndex] = currentDocument;

        return {
          documents: newDocuments,
          currentDocument,
          selectedPage: newPage,
        };
      }

      return {
        currentDocument,
        selectedPage: newPage,
      };
    });

    return 200;
  },
  changeDocumentStatus: (status) => {
    if (!get().currentDocument) return;
    set((prev) => {
      if (!prev.currentDocument) return prev;

      const documentIndex = prev.documents.findIndex((el) => el.id === prev.currentDocument?.id);
      const currentDocument = {
        ...prev.currentDocument,
        version: {
          ...prev.currentDocument.version,
          status,
        },
      };

      if (documentIndex >= 0) {
        const updatedDocumentArray = prev.documents.slice();
        updatedDocumentArray[documentIndex].version.status = status;

        return {
          currentDocument,
          documents: updatedDocumentArray,
        };
      }

      return { currentDocument };
    });
  },
  updateDocument: async (id, name, meta) => {
    const { currentDocument } = get();
    if (currentDocument?.isTryIt) return;

    set(() => ({ saving: true }));
    const response = await DocumentsAPI.updateDocument(id, { name, meta });
    if (response.status === 200) {
      const lastSaved = new Date().toString();

      set((prev) => (prev.currentDocument ? ({
        currentDocument: {
          ...prev.currentDocument,
          lastSaved,
          name,
          meta,
        },
      }) : prev));
    }
    set(() => ({ saving: false }));
  },
  clearSelectedDocument: () => {
    set(() => ({
      currentDocument: null,
      selectedPage: null,
    }));
  },
  submitAssignment: async (aid: string) => {
    const response = await AssignmentAPI.createSubmission(aid!);

    if (response.status === 201) {
      set((prev) => (prev.currentDocument?.submission ? ({
        currentDocument: {
          ...prev.currentDocument,
          submission: {
            ...prev.currentDocument.submission,
            status: 'turnedin',
          },
        },
      }) : prev));
    }

    return response;
  },
}));
