import { TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'LABEL100',
    defaultTextAlign: 'ALIGN_CENTER',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 200,
    height: 153,
    width: 984,
  } as TextBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'TITLE100',
    defaultColor: 'TEXT_COLOR_NBLACK',
    defaultTextBackground: 'TEXT_BACKGROUND_TRANSPARENT',
    defaultTextAlign: 'ALIGN_CENTER',
    defaultBackground: 'transparent',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 385,
    height: 370,
    width: 984,
  } as TextBoxType,
  {
    key: 'textbox3',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    defaultTextAlign: 'ALIGN_CENTER',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 787,
    height: 1069,
    width: 984,
  } as TextBoxType,
];
