import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftImg, ArrowRightImg } from 'src/assets/icons';
import { IconButton } from '../buttons';
import { PageNavigationProps } from './types';

function PageNavigation({
  page,
  numberOfPages,
  document,
}: PageNavigationProps) {
  const navigate = useNavigate();

  const navigateThroughPages = (type: 'next' | 'previous') => {
    let newPosition: number;
    if (type === 'next' && page.position + 1 < numberOfPages) {
      newPosition = page.position + 1;
    } else if (type === 'previous' && page.position > 0) {
      newPosition = page.position - 1;
    } else {
      return;
    }

    navigate({
      pathname: `/document/${document.id}/pages/${newPosition + 1}`,
      search: window.location.search,
    });
  };

  // This will allow changing pages using keyboard.
  const onKeyDown = useCallback((e: KeyboardEvent) => {
    // Avoid changing pages when user has text editor focused.
    const activeElement = window.document.activeElement as HTMLDivElement;

    // Don't navigate if an input has focus.
    if (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA') {
      return;
    }

    if (e.key === 'ArrowRight') {
      navigateThroughPages('next');
    } else if (e.key === 'ArrowLeft') {
      navigateThroughPages('previous');
    }
  }, [page.position]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <IconButton
        src={ArrowLeftImg}
        alt="left arrow"
        onClick={() => navigateThroughPages('previous')}
        disabled={page.position === 0}
      />
      <IconButton
        src={ArrowRightImg}
        alt="right arrow"
        onClick={() => navigateThroughPages('next')}
        disabled={page.position + 1 === numberOfPages}
      />
    </div>
  );
}

export default PageNavigation;
