import React from 'react';
import { Container, ThemeProvider, Grid } from '@mui/material';

import TopBar from 'src/components/shared/TopBar';
import { theme } from 'src/utils';
import { SpeechBubbleOnFireImg } from 'src/assets/images';
import * as Styles from './styles';

import { Logo } from '../../components';

export default function NotAuthorized(props: {
  title: string;
  message: React.ReactNode;
}) {
  const { title, message } = props;
  return (
    <ThemeProvider theme={theme}>
      <TopBar>
        <TopBar.Section>
          <Logo height="2.25rem" variant="responsive" to="/gallery" />
        </TopBar.Section>
        <TopBar.Spacer />
      </TopBar>

      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '80vh',

          a: {
            color: 'primary.main',
            textDecoration: 'none',
            fontSize: '.8em',
            fontWeight: 'bold',
          },
        }}
      >
        <Grid
          container
          spacing={6}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Grid item sm="auto">
            <img src={SpeechBubbleOnFireImg} alt="Speech bubble icon" />
          </Grid>
          <Grid item sm={6}>
            <Styles.AssignmentTitle>{title}</Styles.AssignmentTitle>
            <Styles.AssignmentSubTitle>
              {message}
            </Styles.AssignmentSubTitle>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
