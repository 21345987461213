import React, {
  useCallback, useState,
} from 'react';
import { PopperUnstyled } from '@mui/base';
import { ClickAwayListener, Paper } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import { Note as NoteIcon, DialogCloseImg } from 'src/assets/icons';
import { IconButton } from 'src/components/buttons';
import { Note } from 'src/types/models';
import { CreatableNote } from '../types';
import NotesList from '../NotesList';

export default function NoteButton(props: {
  notes: Note[];
  showCreateForm?: boolean;
  onSave?: (note: CreatableNote) => Promise<void>;
  onDelete?: (note: Note) => Promise<void>;
}) {
  const {
    notes, showCreateForm, onSave, onDelete,
  } = props;

  // This code is based on the example at:
  // https://mui.com/base/react-popper/#basics
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const toggleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  }, [anchor]);

  return (
    <ClickAwayListener
      onClickAway={() => setAnchor(null)}
    >
      <div>
        <IconButton
          id="note-button"
          src={NoteIcon}
          alt="Add Note"
          label={notes.length ? notes.length.toString() : ''}
          onClick={toggleOpen}
        />
        <PopperUnstyled
          open={!!anchor}
          anchorEl={anchor}
          placement="top-end"
          style={{
            zIndex: zIndex.modal,

            // Create some space between the button and the notes list,
            // as well as create some room around the list so that its box shadow
            // has space to appear.
            padding: '6px',
          }}
        // Nudge the popper 6px to the right to compensate for the padding.
          modifiers={[{
            name: 'offset',
            options: { offset: [6, 0] },
          }]}
          keepMounted
        >
          <Paper
            elevation={3}
            sx={{
              overflowY: 'auto',
              width: '300px',
              maxHeight: 'calc(100vh - 100px)',
            }}
          >
            {/* TODO: Return to the right kind of element for the X. */}
            {/* eslint-disable-next-line */}
          <a
            onClick={toggleOpen}
            style={{
              float: 'right',
              cursor: 'pointer',
              margin: '18px',
            }}
          >
            <img src={DialogCloseImg} alt="Close" style={{ height: '15px' }} />
          </a>
            <NotesList
              onSave={onSave}
              onDelete={onDelete}
              notes={notes}
              showCreateForm={showCreateForm}
            />
          </Paper>
        </PopperUnstyled>
      </div>
    </ClickAwayListener>
  );
}
