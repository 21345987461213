import React from 'react';
import {
  alpha,
  Box, Collapse, darken, Grid, lighten, Paper,
} from '@mui/material';
import { Chevron } from 'src/assets/icons/variantComponents';

export type CommonFeedbackCardProps = {
  isOpen?: boolean,
  onOpen?: () => void,
  onClose?: () => void,
};

export default function FeedbackCard(props: CommonFeedbackCardProps & {
  title: string,
  accentColor: string,
  icon: string,
  children: React.ReactNode,
}) {
  const {
    title, accentColor, icon, children,
    isOpen = false,
    onOpen = () => {},
    onClose = () => {},
  } = props;

  return (
    <Paper
      elevation={0}
      sx={[
        {
          transition: 'opacity 0.2s',
          marginBottom: 2,
          borderColor: 'primary.secondary',
          backgroundColor: 'background.default',

          '& p': {
            my: '.5em',
            fontSize: '0.8em',
          },

          'b, strong': {
            fontWeight: 'bold',
            color: darken(accentColor, 0.5),
          },

          small: {
            fontSize: '0.8em',
            textTransform: 'uppercase',
            color: '#999',
          },

          // Style MUI chips
          '.MuiChip-root': {
            backgroundColor: lighten(accentColor, 0.85),
            borderColor: lighten(accentColor, 0.5),
            color: darken(accentColor, 0.2),
            borderRadius: '3px',
            margin: '0.2em',
            fontSize: '0.75rem',

            '.MuiSvgIcon-root path': {
              fill: alpha(darken(accentColor, 0.1), 0.5),
            },

            '&.active': {
              backgroundColor: darken(accentColor, 0.1),
              borderColor: accentColor,
              color: 'white',
              fontWeight: 'bold',

              '.MuiSvgIcon-root path': {
                fill: lighten(accentColor, 0.7),
              },
            },
          },

          // If disabled, place Card at the bottom of the grid.
          order: 3,

          '.MuiCheckbox-root': { },
        },
      ]}
    >
      <Grid
        container
        alignItems="center"
        gap={1}
        onClick={() => (isOpen ? onClose() : onOpen())}
        sx={{
          cursor: 'pointer',
          padding: 1,
          backgroundColor: lighten(accentColor, 0.95),

          '&:hover': {
            backgroundColor: lighten(accentColor, 0.95),
          },
        }}
      >
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={icon}
            alt=""
            style={{
              width: '2.2em',
            }}
          />
        </Grid>
        <Grid item flexGrow={1}>
          <h4 style={{ fontSize: '14px' }}>{title}</h4>
        </Grid>
        <Grid item>
          <Chevron direction={isOpen ? 'down' : 'right'} />
        </Grid>
      </Grid>

      <Collapse in={isOpen}>
        <Box
          sx={{
            margin: '10px',
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '30vh',
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Paper>
  );
}
