import { Card, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../utils';

export const ButtonContainer = styled(Card, { shouldForwardProp: (prop) => prop !== 'isTeacher' })<any>(() => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  minHeight: '18rem',
  position: 'relative',

  '&:hover': {
    '.info-container': {
      backgroundColor: '#FFF',
    },
  },

  '.image-container': {
    minHeight: '14rem',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',

    '& > img': {
      position: 'absolute',
      height: '100%',
      top: '1.875rem',
      left: '50%',
      transform: 'translate(-50%, 0%)',
    },

    '& > div': {
      position: 'absolute',
      height: '100%',
      top: '1.875rem',
      left: '50%',
      transform: 'translate(-25%, 0%)',
    },
  },

  '.info-container': {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: '#F0F0F4',
    transition: 'background-color .15s ease-out',
    minHeight: '12rem',
    borderTopRightRadius: '50px',
    padding: '1rem',
    display: 'flex',
    gap: '.5rem',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '.title': {
      color: '#000',
      fontWeight: '700',
      fontFamily: 'Inter !important',
    },

    '.secondary-info': {
      display: 'flex',
      justifyContent: 'space-between',
      opacity: 0.5,

      'p:first-of-type': {
        fontWeight: '400',
      },
    },

    '.tag': {
      fontSize: '.75rem',
      padding: '.25rem .5rem',
      fontWeight: 700,
      // height: '1.5rem',
      textTransform: 'capitalize',
      borderRadius: 4,
      width: 'fit-content',
    },

    '.is-assigned': {
      color: 'white',
      background: '#3E827C',
    },

    '.new': {
      color: '#FB8800',
      background: 'rgba(253, 173, 78, 0.1)',
    },

    '.draft': {
      color: '#999999',
      background: '#E3E3E3',
    },
  },

  '.pressto-button-actions': {
    position: 'absolute',
    top: '.75rem',
    right: '.75rem',
    display: 'grid',
    cursor: 'pointer',
  },

  '.row': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const CardNumber = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  fontSize: '1.5rem',
  lineHeight: '120%',
  fontWeight: 600,
}));

export const CardDescription = styled(Typography)(() => ({
  color: theme.palette.secondary.main,
  fontSize: '.75rem',
  fontWeight: 'normal',
}));
