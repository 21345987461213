import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Thumbnail from 'src/components/Block/Thumbnail';
import * as models from 'src/types/models';
import NewBlockFluid from 'src/components/Block/NewBlockFluid';
import FluidGridCell from './FluidGridCell';

export default function FluidPageGrid(props: {
  page: models.BlockPage;
  grid: models.Grid;
  isEditable: boolean;
  isThumbnail: boolean;
  pixelsPerEm: number;
}) {
  const {
    page,
    grid,
    isEditable = false,
    isThumbnail = false,
    pixelsPerEm,
  } = props;

  const blocks = [...page.grid.blocks];
  blocks.sort((a, b) => a.y - b.y);

  return (
    <Droppable
      droppableId={`page-${page.id}`}
      type="page"
      isDropDisabled={!isEditable}
    >
      {(dropProvided) => (
        <div
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
        >
          {blocks.map((block, index) => (
            isThumbnail ? (
              <Thumbnail key={block.id} block={block || null} />
            ) : (
              <FluidGridCell
                key={block.id}
                block={block}
                index={index}
                isEditable={isEditable}
                pixelsPerEm={pixelsPerEm}
              />
            )
          ))}

          {isEditable && (
            <NewBlockFluid
              x={1}
              y={grid.rows + 1}
              width={1}
              height={1}
            />
          )}
        </div>
      )}
    </Droppable>
  );
}
