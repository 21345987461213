import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container, ThemeProvider, Grid, Box,
} from '@mui/material';

import TopBar from 'src/components/shared/TopBar';
import { Eye } from 'src/assets/icons';
import * as AssignmentsAPI from 'src/api/Assignments';
import { theme } from 'src/utils';
import { Button, IconButton } from 'src/components/buttons';
import { useAssignment } from 'src/hooks/assignment';
import Markdown from 'markdown-to-jsx';
import { PaperButton } from 'src/components/AssignmentAssistant/PaperButton';
import { getWritingPlan } from 'src/configuration/writing-plans';
import { useUserStore } from '../../zustand/user';
import * as Styles from './styles';

import { Logo } from '../../components';
import BaseDocumentForm from './BaseDocumentForm';
import { FormSection } from './FormSection';
import WritingPlanBlockTypes from './WritingPlanBlockTypes';

export default function ViewAssignment() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  // Load the assignment in the URL. This will be undefined until it is loaded
  // and null if there is an error.
  const { id: assignmentId } = useParams();
  const assignment = useAssignment(assignmentId);
  const document = assignment?.baseDocument;

  // Students have a more restricted experience than teachers and
  // unauthenticated users. To avoid confusion, hide the assignment if the
  // student is not the recipient.
  const isAssignedToStudentInClassroom = user.classrooms?.some((c1) => (
    assignment?.classrooms?.some((c2) => c1.id === c2.id)
  ));
  const isAssignedToUser = (
    user.role === 'student' && (
      assignment?.students?.some((s) => s.id === user.id) || isAssignedToStudentInClassroom
    )
  );

  // Redirect students to start or continue the assignment.
  const startAssignment = useCallback(async () => {
    if (isAssignedToUser && assignment) {
      if (!assignment.submission?.id) {
        const response = await AssignmentsAPI.createSubmission(assignment.id);
        navigate({
          pathname: `/document/${response.data.data.document_id}`,
          search: `?${assignment.instructions ? 'notes=true' : ''}`,
        });
      } else if (assignment.submission?.documentId) {
        navigate(`/document/${assignment.submission?.documentId}`);
      }
    }
  }, [assignment, isAssignedToUser]);

  // Set document title to assignment name.
  useEffect(() => {
    window.document.title = assignment?.name || 'Assignment';
  }, [assignment?.name]);

  // Upon button press, direct to the assignment editing page with fields
  // pre-filled based on this assignment.
  const duplicateAssignment = useCallback(() => {
    navigate(`/assignment/${assignment?.id}/duplicate`);
  }, [assignment, navigate]);

  const writingPlan = document?.meta?.writingPlan
    ? getWritingPlan(document.meta?.writingPlan)
    : null;

  return (
    <ThemeProvider theme={theme}>
      <TopBar>
        <TopBar.Section>
          <Logo height="2.25rem" variant="responsive" to="/gallery" />
        </TopBar.Section>
        <TopBar.Spacer />
        <TopBar.Section>
          {assignment && user.role === 'teacher' && (
            <IconButton
              src={Eye}
              alt=""
              label="Enter Student Preview"
              disabled={!document?.id}
              onClick={() => {
                // Open a new tab
                navigate({
                  pathname: `/document/${document?.id}`,
                  search: `?assignmentPreview=${assignment.id}&notes=1`,
                });
              }}
            />
          )}
        </TopBar.Section>
      </TopBar>

      {assignment && (
        <Container sx={{
          marginTop: '2rem',

          '& .form-section-name': {
            // The default font for this is bold. When viewing, soften the presentation.
            fontWeight: 'normal',
          },
        }}
        >
          <Grid container spacing={6}>
            <Grid item display="flex" flexDirection="column" sm={6} gap={3}>
              <div>
                <Styles.AssignmentTitle>
                  {assignment.name}
                </Styles.AssignmentTitle>
                <Styles.AssignmentSubTitle>
                  Created by
                  {' '}
                  {assignment.teacher.name}
                  {' '}
                  {assignment.teacher.surname}
                </Styles.AssignmentSubTitle>
              </div>

              <FormSection
                name="Instructions / Prompts"
              >
                <Box
                  sx={{
                    fontSize: '1.5em',

                    '& p': {
                      margin: '0.5em 0',
                    },

                    li: {
                      margin: '0 2em',
                    },
                  }}
                >
                  <Markdown>
                    {assignment.instructions}
                  </Markdown>
                </Box>
              </FormSection>

              {isAssignedToUser && (
                <Button
                  label={
                    assignment.submission?.documentId
                      ? 'Continue Assignment'
                      : 'Start Writing'
                  }
                  onClick={startAssignment}
                />
              )}
            </Grid>

            <Grid
              item
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
                gap: 3,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                {user.role !== 'student' && (
                  <Button
                    label="Adapt for my students"
                    disabled={!assignment.name}
                    onClick={duplicateAssignment}
                  />
                )}
              </div>

              {user.role !== 'student' ? (
                <BaseDocumentForm
                  document={document}
                  assignment={assignment}
                  isEditable={false}
                />
              ) : (
                // Don't display all information to students, only the
                // writing plan.
                writingPlan && (
                  <div>
                    <FormSection name="Writing Plan">
                      <PaperButton
                        isSelected
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {writingPlan.title}
                      </PaperButton>
                    </FormSection>
                    <FormSection name="Planning Blocks">
                      {writingPlan && (
                        <WritingPlanBlockTypes writingPlan={writingPlan} />
                      )}
                    </FormSection>
                  </div>
                )
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </ThemeProvider>
  );
}
