import React from 'react';
import { Box } from '@mui/material';

import { lang } from 'src/lang';
import { Description } from 'src/components/typography';

function ErrorMessage({ error }: { error: string }) {
  return (
    <Description size="small" style={{ color: 'red' }}>
      {error}
    </Description>
  );
}

export default function UploadFileMessage({
  id,
  error,
  isProcessing,
  handleSubmit,
}: {
  id: string;
  error: string | string[];
  isProcessing: boolean;
  handleSubmit: (...args: any[]) => void;
}) {
  return (
    <Box
      sx={{
        marginTop: '.5rem',
        fontSize: '.75rem',
        color: '#999999',

        label: {
          color: '#163567',
          textDecoration: 'underline',
        },
      }}
    >
      {lang('document.upload_dialog.upload_message.line_1')}
      {' '}
      <label
        id="upload-button"
        style={{ cursor: 'pointer' }}
        htmlFor={`upload-input-${id}`}
      >
        {lang('document.upload_dialog.upload_message.line_2')}
        <input
          id={`upload-input-${id}`}
          type="file"
          onChange={handleSubmit}
          hidden
          accept="image/*"
          disabled={isProcessing}
        />
      </label>
      <br />
      {error && Array.isArray(error) ? (
        error.map((e) => <ErrorMessage error={e} />)
      ) : (
        <ErrorMessage error={error} />
      )}
    </Box>
  );
}
