import { Box, styled } from '@mui/system';
import { lang } from '../../../lang';
import { TextBoxContainerProps } from './types';
import { getBorder } from './utils';

const shouldForwardProp = (prop: PropertyKey) => prop !== 'isActive' && prop !== 'isEmpty' && prop !== 'isViewMode';

export const TextBoxContainer = styled(Box, { shouldForwardProp })<TextBoxContainerProps>(({
  isActive, isFocused, isEmpty, hasOverflow, isViewMode,
}) => ({
  '&:hover': !isViewMode && {
    boxShadow: !isActive ? '0px 0px 5.21073px rgba(82, 80, 154, 0.4)' : '',
  },

  outlineWidth: '0 !important',

  overflowWrap: 'break-word',

  boxSizing: 'border-box',
  padding: '.125rem',
  wordSpacing: '2px',
  whiteSpace: 'pre-wrap',

  border: getBorder(isActive, isFocused, hasOverflow ?? false),

  position: 'absolute',
  '.show-text-container': {
    overflow: 'hidden',
    cursor: isFocused ? 'text' : 'default !important',
  },

  '.DraftEditor-editorContainer': {
    borderLeft: 0,
    cursor: isFocused ? 'text' : 'default',
  },

  '.text-editor': {
    overflow: 'visible',
  },

  '.text-editor-wrapper': {
    height: '100%',

    '.public-DraftStyleDefault-block': {
      margin: 0,
    },
  },

  '&::before': !isViewMode && !isFocused && isEmpty && {
    content: lang('document.artboard.elements.text_placeholder'),
    position: 'absolute',
    left: '0',
    width: '100%',
    color: '#999999',
  },

  '&::after': hasOverflow && {
    content: '"Text overflow"',
    width: 'calc(100% - 1.5rem)',
    position: 'absolute',
    left: -2,
    bottom: 0,
    transform: 'translateY(100%)',
    background: '#FF5252',
    border: '2px solid #FF5252',
    padding: '.125rem .75rem',
    borderRadius: '0 0 .25rem .25rem',
    fontWeight: 700,
    fontSize: '.875rem',
    color: 'white',
    fontFamily: 'Inter',
    zIndex: 999,
  },

  p: {
    marginTop: 0,
  },
}));
