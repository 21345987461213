import React from 'react';
import { DocumentFormat } from 'src/types/DocumentSettings';
import { DocumentFormatSettings } from 'src/configuration/documents';

export function FormatLabel(props: {
  format: DocumentFormat;
  hideImage?: boolean;
}) {
  const { format, hideImage = false } = props;
  const { label, image, title } = DocumentFormatSettings[format];
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      width: '100%',
    }}
    >
      {!hideImage && (
        <img
          style={{
            width: '40px',
            marginRight: '16px',
          }}
          src={image}
          alt="icon"
        />
      )}
      <div>
        <b>{title}</b>
        <br />
        <small style={{ color: '#596F93' }}>{label}</small>
      </div>
    </div>
  );
}
