import { styled } from '@mui/system';

function getStatusStyle(status: string) {
  if (status === 'new' || status === 'not-started') {
    return {
      color: '#FB8800',
      background: 'rgba(253, 173, 78, 0.1)',
    };
  }

  if (status === 'assigned' || status === 'turnedin') {
    return {
      color: '#FFFFFF',
      background: '#288247',
      border: '1px solid #288247',
    };
  }

  if (status === 'draft') {
    return {
      color: '#999999',
      background: '#E3E3E3',
      border: '1px solid #E3E3E3',
    };
  }

  if (status === 'published' || status === 'unpublished') {
    return {
      color: '#163567',
      background: '#596F934D',
      border: '1px solid #596F93',
    };
  }

  if (status === 'returned') {
    return {
      color: '#288247',
      background: '#FFFFFF',
      border: '1px solid #288247',
    };
  }

  return {};
}

export const Tag = styled('div')(({ status }: { status: string }) => ({
  fontSize: '.75rem',
  padding: '.25rem .5rem',
  fontWeight: 700,
  height: '1.5rem',
  textTransform: 'capitalize',
  borderRadius: '4px',
  width: 'fit-content',

  img: {
    marginRight: '.5rem',
  },

  ...getStatusStyle(status),

}));
