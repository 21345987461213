import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/system';
import { IconButton } from '@mui/material';
import { Description } from '../../typography';
import { DialogCloseImg } from '../../../assets/icons';
import { useEditor } from '../../../hooks/useEditor';
import { layoutsByFormat } from '../../../configuration/layout-templates';
import { Element } from '../../../types/LayoutEditor';
import { LayoutDialogContainer, LayoutDialogContent, LayoutDialogTitle } from './styles';
import { LayoutDialogProps } from './types';
import { lang } from '../../../lang';

function LayoutDialog({
  handleLayoutSelect, isOpen, onOpen, onClose,
}: LayoutDialogProps) {
  const { board, handleSelectEl } = useEditor();

  useEffect(() => {
    if (isOpen) {
      handleSelectEl(null);
      if (onOpen) onOpen();
    }
  }, [isOpen]);

  const handleItemClick = (layoutKey: string, template: Element[]) => {
    handleLayoutSelect(layoutKey, template);
    onClose();
  };

  const layouts = useMemo(() => {
    if (!board) return [];

    return layoutsByFormat[board.format];
  }, [board?.format]);

  return (
    <LayoutDialogContainer onClose={onClose} open={isOpen}>
      <LayoutDialogTitle>
        <Description size="small">{lang('document.choose_layout_dialog.title.line_1')}</Description>
        <Description weight={700}>{lang('document.choose_layout_dialog.title.line_2')}</Description>
      </LayoutDialogTitle>
      <LayoutDialogContent>
        <div className="layouts-grid">
          {layouts.map(({
            key, label, img, template,
          }) => (
            <Box key={key} className="layout-item" onClick={() => handleItemClick(key, template)}>
              <div>
                <Description>{label}</Description>
              </div>
              <div>
                {img && <img src={img} alt={label} />}
              </div>
            </Box>
          ))}
        </div>
      </LayoutDialogContent>

      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
    </LayoutDialogContainer>
  );
}

export default LayoutDialog;
