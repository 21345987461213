import React, { ReactNode, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { Page } from 'src/types/models';

/**
 * Each thumbnail is wrapped by a drop target. When a page is dragged over it,
 * a reorder operation is applied.
 */
export default function PageDropTarget(props: {
  // page: Page;
  index: number;
  onPageOver: (draggedPage: Page, dropIndex: number) => void;
  onPageDrop: (droppedPage: Page, dropIndex: number) => void;
  children: ReactNode;
}) {
  const {
    // page,
    index, onPageOver, onPageDrop, children,
  } = props;

  const [{ isOver, draggedPage }, drop] = useDrop({
    accept: 'page',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      draggedPage: monitor.getItem(),
    }),
    drop: (droppedPage: Page) => {
      onPageDrop(droppedPage, index);
    },
  });

  useEffect(() => {
    // Send a signal to our parent component when the drag state changes.
    // This will visually reorder the pages.
    if (draggedPage !== null && isOver) {
      if (draggedPage !== null) {
        onPageOver(draggedPage, index);
      }
    }
  }, [isOver]);

  return (
    <div ref={drop}>
      {children}
    </div>
  );
}
