/* eslint-disable react/prop-types */
import React, {
  forwardRef, ReactElement, useImperativeHandle, useState,
} from 'react';
import { useMediaQuery } from '@mui/material';
import {
  BranchMenuItem,
  MenuProps,
  MenuRefType,
  LeafMenuItem,
  MenuItemType,
} from './types';
import { Description } from '../typography';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuContainer,
  MenuButton,
  MobileMenuButton,
  StyledDialog,
  ButtonDescription,
} from './styles';
import { IconButton } from '../buttons';
import { LargeBackArrowImg } from '../../assets/icons';
import { deepFind } from './utils';
import { theme } from '../../utils';

const DropdownMenu = forwardRef<MenuRefType, MenuProps>(
  ({ items, buttonStyles, ignoreResponsiveness }, ref) => {
    const mobileMQ = useMediaQuery(() => theme.breakpoints.down('sm'));

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const [expandedItem, setExpandedItem] = useState<string | null>(null);

    const open = Boolean(anchorEl);

    const handleClose = () => {
      setAnchorEl(null);
      setTimeout(() => setExpandedItem(null), 350);
    };

    useImperativeHandle(ref, () => ({
      open(event) {
        setAnchorEl(event.currentTarget);
      },
      close() {
        handleClose();
      },
      anchorEl,
      isOpen: Boolean(anchorEl),
    }));

    const toggleSelectedItem = (key: string) => {
      if (expandedItem === key) {
        setExpandedItem(null);
      } else {
        setExpandedItem(key);
      }
    };

    if (!ignoreResponsiveness && mobileMQ) {
      const renderSubmenu = (el: BranchMenuItem | LeafMenuItem): ReactElement | null => (
        <MobileMenuButton
          key={el.key}
          label={el.label}
          image={el.icon ? <img src={el.icon.src} alt={el.icon.alt} /> : null}
          onClick={'submenus' in el ? () => toggleSelectedItem(el.key) : el.onClick}
          style={buttonStyles}
        />
      );

      const expandedMenu = expandedItem ? deepFind(items, (el) => el.key === expandedItem) : null;

      const itemsToRender = expandedMenu
        ? (expandedMenu as BranchMenuItem).submenus
        : items;

      return (
        <StyledDialog open={open} onClose={handleClose} aria-labelledby="custom-menu">
          {expandedMenu && (
            <div className="menu-header">
              <IconButton src={LargeBackArrowImg} alt="back button" onClick={() => setExpandedItem(null)} />
              <Description highlight weight={700} className="submenu-title">
                {expandedMenu.label}
              </Description>
            </div>
          )}
          <div className="menu-content">
            {itemsToRender.map(renderSubmenu)}
          </div>
        </StyledDialog>
      );
    }

    const renderSubmenu = (
      el: BranchMenuItem | LeafMenuItem,
      hasParent?: boolean,
    ): JSX.Element | null => {
      if ('submenus' in el) {
        return (
          <Accordion key={el.key}>
            <AccordionSummary
              id="panel1a-header"
              aria-controls="panel1a-content"
              className={el.key === expandedItem ? 'accordion-active' : ''}
              onClick={() => toggleSelectedItem(el.key)}
            >
              <Description highlight size="small" weight={700} style={{ textAlign: 'center' }}>
                {el.label}
              </Description>
              {el.key === expandedItem ? <div className="button-arrow" /> : null}
            </AccordionSummary>
            <AccordionDetails>
              {(el as BranchMenuItem).submenus.map((sm) => renderSubmenu(sm, true))}
            </AccordionDetails>
          </Accordion>
        );
      }

      return (
        <MenuButton
          key={el.key}
          onClick={!el.disabled && el.onClick}
          hasParent={hasParent}
          style={buttonStyles}
          disabled={el.disabled}
          hasDescription={!!el.description}
        >
          {el.icon && (
            <div>
              <img src={el.icon.src} alt={el.icon.alt} />
              <br />
            </div>
          )}
          <Description highlight size="small" style={{ textAlign: 'center' }}>
            {el.label}
          </Description>
          {
            el.description && (
              <ButtonDescription size="small">
                {el.description}
              </ButtonDescription>
            )
          }
        </MenuButton>
      );
    };

    return (
      <MenuContainer
        id="avatar-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {expandedItem
          ? renderSubmenu(deepFind(items, (el) => el.key === expandedItem)!, true)
          : items.map((el: MenuItemType) => renderSubmenu(el))}
      </MenuContainer>
    );
  },
);

export default DropdownMenu;
