import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  FlexBox, Form, GradientContainer, Input, Logo, SelectList,
} from '../../components';
import { Button } from '../../components/buttons';
import { Title, Description, Link } from '../../components/typography';
import { lang } from '../../lang';

const {
  Marker, OptionLabel, ListOption, Option,
} = SelectList;

function FindSchool() {
  const [zip, setZip] = useState('');
  const [error, setError] = useState('');
  const [schoolList, setSchoolList] = useState<any>([]);
  const [selectedSchool, setSelectedSchool] = useState<any>(null);

  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (zip.length < 6) {
      return setError(lang('auth.errors.invalid_zip'));
    }

    setSchoolList([
      { name: 'CANYON CREST ACADEMY', id: 1, address: 'San Diego, CA 92130' },
      { name: 'CARMEL VALLEY MIDDLE SCHOOL', id: 2, address: 'San Diego, CA 92130' },
      { name: 'CANYON CREST ACADEMY', id: 3, address: 'San Diego, CA 92130' },
      { name: 'CARMEL VALLEY MIDDLE SCHOOL', id: 4, address: 'San Diego, CA 92130' },
      { name: 'CANYON CREST ACADEMY', id: 5, address: 'San Diego, CA 92130' },
      { name: 'CARMEL VALLEY MIDDLE SCHOOL', id: 6, address: 'San Diego, CA 92130' },
      { name: 'CANYON CREST ACADEMY', id: 7, address: 'San Diego, CA 92130' },
      { name: 'CARMEL VALLEY MIDDLE SCHOOL', id: 8, address: 'San Diego, CA 92130' },
      { name: 'CANYON CREST ACADEMY', id: 9, address: 'San Diego, CA 92130' },
      { name: 'CARMEL VALLEY MIDDLE SCHOOL', id: 10, address: 'San Diego, CA 92130' },
    ]);

    return true;
  };

  const schoolSelection = (e: any) => {
    e.preventDefault();
    navigate('/school-information');
  };

  const renderFindSchool = () => (
    <FlexBox marginTop={10} textAlign="center">
      <Title>
        {lang('auth.signup.find_school_title')}
      </Title>
      <FlexBox marginTop={5}>
        <Form onSubmit={handleSubmit}>
          <Description>
            {lang('auth.signup.school_info.location')}
          </Description>
          <Input
            placeholder={lang('auth.signup.school_info.zip_code')}
            type="number"
            aria-label="zip"
            onChange={(e: any) => setZip(e.target.value)}
            error={!!error}
            invalidMessage={error}
          />
          <Button type="submit" label={lang('general.next')} aria-label="submit" large disabled={!zip} />
        </Form>
      </FlexBox>
    </FlexBox>
  );

  const renderSchools = () => (
    <FlexBox marginTop={10} textAlign="center">
      <Title>
        {lang('auth.signup.school.select_message')}
      </Title>
      <FlexBox marginTop={5}>
        <Description>
          {lang('auth.signup.school.school_fount', { count: schoolList.length })}
          {' '}
          <b>92130</b>
        </Description>
        <Form onSubmit={schoolSelection}>
          <ListOption>
            {
              schoolList.map((item: any) => (
                <Option key={item.id} onClick={() => setSelectedSchool(item.id)}>
                  <Marker selected={selectedSchool === item.id} />
                  <OptionLabel>
                    <Title size="small" lineHeight="20px">{item.name}</Title>
                    <Description>{item.address}</Description>
                  </OptionLabel>
                </Option>
              ))
            }
          </ListOption>
          <FlexBox width="80%" alignSelf="center">
            <Button
              type="submit"
              label={lang('auth.signup.school.select_button')}
              large
              disabled={!selectedSchool}
            />
          </FlexBox>
        </Form>
        <Description align="center" marginTop="20px">
          {lang('auth.signup.school.none.line_1')}
          {' '}
          <Link href="#addschool" underline fontSize="14px">
            {lang('auth.signup.school.none.line_2')}
          </Link>
        </Description>
      </FlexBox>
    </FlexBox>
  );

  return (
    <GradientContainer justifyContent="center">
      <Logo />

      {schoolList.length > 0 ? renderSchools() : renderFindSchool()}

    </GradientContainer>
  );
}

export default FindSchool;
