import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { Editor as WYSIWYG } from 'react-draft-wysiwyg';
import {
  ContentState,
  convertFromRaw, convertToRaw, EditorState, Modifier, RichUtils,
} from 'draft-js';

import { useEditor } from '../../../hooks/useEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { TextBoxProps } from './types';
import {
  draft2html, generateStyleMap, getSelection, getSmallestFont, setStyle,
} from './utils';
import { getSufix } from '../../../utils';
import { TextBoxContainer } from './styles';
import TextBoxToolbar from '../../Artboard/Toolbar/TextBoxToolbar';
import { TextBoxType } from '../../../types/LayoutEditor';

function TextBox({
  name,
  value,
  height,
  width,
  x,
  y,
  defaultBackground,
  defaultFont,
  defaultColor,
  defaultTextBackground,
  defaultTextAlign,
  styleMap,
}: TextBoxProps) {
  const {
    selectedEl, handleSelectEl, handleElChange, isViewMode, setSelectedString,
  } = useEditor();

  const [editorState, setEditorState] = useState(() => {
    if (value) { return EditorState.createWithContent(convertFromRaw(value)); }
    return EditorState.createEmpty();
  });

  const [containerBackground, setContainerBackground] = useState(defaultBackground ?? 'transparent');
  const [containerFont, setContainerFont] = useState(`FONT_STYLE_${defaultFont}`);

  const [isFocused, setIsFocused] = useState(false);
  const [isCSSApplied, setIsCSSApplied] = useState(false);

  const isEmpty = useMemo(() => {
    const content = convertToRaw(editorState.getCurrentContent());

    if (content.blocks.length === 0) return true;

    if (content.blocks.length === 1 && content.blocks[0].text === '') return true;

    return false;
  }, [editorState]);

  const handleEditorChange = (newVal: EditorState) => {
    setEditorState(newVal);
  };

  const handleEditorStyles = (
    rawState: Draft.DraftModel.Encoding.RawDraftContentState,
    setup = false,
  ) => {
    if (setup && isCSSApplied) return;

    rawState.blocks.forEach((block, i) => {
      const element = document.querySelector<HTMLDivElement>(`#${name} > .text-editor-wrapper > .text-editor > .DraftEditor-root > .DraftEditor-editorContainer > .public-DraftEditor-content > div > div:nth-child(${i + 1}) > div`);
      if (!element) return;

      if (block.text.length === 0 && block.inlineStyleRanges.length === 0) {
        const prevElement = document.querySelector<HTMLDivElement>(`#${name} > .text-editor-wrapper > .text-editor > .DraftEditor-root > .DraftEditor-editorContainer > .public-DraftEditor-content > div > div:nth-child(${i}) > div`);
        const lastSpan = prevElement?.lastChild as HTMLSpanElement;

        if (!prevElement || !lastSpan) {
          const currentStyle = editorState.getCurrentInlineStyle().toArray();
          const align = currentStyle.find((el) => el.startsWith('ALIGN'));
          const font = currentStyle.find((el) => el.startsWith('FONT_STYLE'));

          if (align && font) {
            element.style.fontSize = styleMap[font].fontSize;
            element.style.fontFamily = styleMap[font].fontFamily;
            element.style.textAlign = styleMap[align].textAlign;
            return;
          }

          element.style.fontSize = styleMap[containerFont].fontSize;
          element.style.fontFamily = styleMap[containerFont].fontFamily;
          element.style.textAlign = styleMap[defaultTextAlign ?? 'ALIGN_LEFT'].textAlign;
          return;
        }

        element.style.fontSize = lastSpan.style.fontSize || prevElement.style.fontSize;
        element.style.fontFamily = lastSpan.style.fontFamily || prevElement.style.fontFamily;
        element.style.textAlign = prevElement.style.textAlign;
        return;
      }

      const smallestFont = getSmallestFont(block.inlineStyleRanges.filter((el) => el.style.startsWith('FONT')).map((fontStyle) => fontStyle.style));
      if (smallestFont) {
        element.style.fontSize = styleMap[smallestFont].fontSize;
        element.style.fontFamily = styleMap[smallestFont].fontFamily;
      }

      const alignStyle = block.inlineStyleRanges.find((el) => el.style.startsWith('ALIGN'))?.style ?? 'left';
      if (!alignStyle) return;
      element.style.textAlign = getSufix(alignStyle).toLowerCase();
    });
    setIsCSSApplied(true);

    // if (updateState) {
    //   rawState.blocks = newState;
    //   setEditorState(EditorState.createWithContent(convertFromRaw(rawState)));
    // }
  };

  const handleBackgroundColorChange = (color: string) => {
    setContainerBackground(color);
    handleElChange(name, 'defaultBackground', color);
  };

  const handleContainerFontChange = (font: string) => {
    setContainerFont(font);
    handleElChange(name, 'defaultFont', getSufix(font));
  };

  // const handleContainerAlignChange = (align: string) => {
  //   setContainerAlign(align);
  //   handleElChange(name, 'defaultTextAlign', align);
  // };

  const setDefaultStyles = () => {
    setEditorState((prev) => {
      const selection = getSelection(prev, false, '');
      let next = EditorState.forceSelection(prev, selection);

      if (value.blocks.length > 0 && value.blocks.some((el: any) => el.text.length > 0)) {
        next = EditorState.push(
          next,
          ContentState.createFromBlockArray(
            convertFromRaw(value).getBlocksAsArray(),
          ),
          'apply-entity',
        );
      }

      next = setStyle(next, defaultColor ?? 'TEXT_COLOR_NBLACK', false);
      next = setStyle(next, defaultTextBackground ?? 'TEXT_BACKGROUND_TRANSPARENT', false);
      next = setStyle(next, defaultTextAlign ?? 'ALIGN_LEFT', false);

      next = RichUtils.toggleInlineStyle(next, defaultColor ?? 'TEXT_COLOR_NBLACK');
      next = RichUtils.toggleInlineStyle(next, defaultTextBackground ?? 'TEXT_BACKGROUND_TRANSPARENT');
      next = RichUtils.toggleInlineStyle(next, defaultTextAlign ?? 'ALIGN_LEFT');

      handleElChange(name, 'loaded', true);
      return next;
    });
  };

  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    if (shouldUpdate) {
      handleElChange(name, 'value', convertToRaw(editorState.getCurrentContent()));
    } else {
      setShouldUpdate(true);
    }
  }, [editorState?.getCurrentContent()]);

  useEffect(() => {
    handleElChange(name, 'ref', { isFocused, setIsFocused });
  }, [isFocused]);

  useEffect(() => {
    setDefaultStyles();
  }, []);

  useEffect(() => {
    setEditorState((prev) => {
      if (isEmpty && prev.getCurrentInlineStyle().toArray().length === 0) {
        let next = setStyle(prev, defaultColor ?? 'TEXT_COLOR_NBLACK', false);
        next = setStyle(next, defaultTextBackground ?? 'TEXT_BACKGROUND_TRANSPARENT', false);
        next = setStyle(next, defaultTextAlign ?? 'ALIGN_LEFT', false);
        next = setStyle(next, containerFont, false);

        handleElChange(name, 'loaded', true);
        return next;
      }
      return prev;
    });
  }, [isEmpty]);

  useEffect(() => {
    setStyle(editorState, containerFont);
  }, [containerFont]);

  const textComponent = useMemo(
    () => {
      if (!editorState) return '';
      const htmlValue = draft2html(
        convertToRaw(editorState.getCurrentContent()),
        styleMap,
      );
      return htmlValue;
    },
    [editorState?.getCurrentContent(), styleMap],
  );

  useEffect(() => {
    const originalFontStyleMap = generateStyleMap();
    const htmlValue = draft2html(
      convertToRaw(editorState.getCurrentContent()),
      originalFontStyleMap,
      {
        backgroundColor: containerBackground,
        ...originalFontStyleMap[containerFont],
        ...originalFontStyleMap[defaultTextAlign ?? 'ALIGN_LEFT'],
      },
    ).replace(/<br></g, '<br>&nbsp<');
    handleElChange(name, 'html', htmlValue);
  }, [textComponent]);

  const hasOverflow = useMemo(() => {
    const child = document.querySelector(`#${name} > div`);

    if (!child) return false;
    return child.scrollHeight > height + 10;
  }, [selectedEl]);

  useEffect(() => {
    if (selectedEl?.key !== name) {
      setIsFocused(false);
      setIsCSSApplied(false);
    } else {
      handleEditorStyles(convertToRaw(editorState.getCurrentContent()));
    }
  }, [selectedEl]);

  useEffect(() => {
    (document as any).querySelector('#board').addEventListener('touchend', () => {
      const selection: any = (window as any).getSelection().toString();
      if (selection) setSelectedString(selection);
    });
  }, []);

  return (
    <TextBoxContainer
      id={name}
      style={{
        height,
        width,
        left: x,
        top: y,
        backgroundColor: containerBackground,
        ...styleMap[defaultTextAlign ?? 'ALIGN_LEFT'],
        ...styleMap[containerFont],
      }}
      isActive={selectedEl?.key === name}
      isFocused={isFocused}
      isEmpty={isEmpty}
      hasOverflow={selectedEl?.key !== name && hasOverflow}
      isViewMode={isViewMode}
      onClick={(e: any) => {
        if (!isViewMode) {
          e.preventDefault();
          e.stopPropagation();
          if (selectedEl?.key !== name) {
            handleSelectEl(name);
          } else {
            handleEditorStyles(convertToRaw(editorState.getCurrentContent()), true);
          }
        }
      }}
    >
      {selectedEl?.key === name ? (
        <>
          <TextBoxToolbar
            selectedEl={selectedEl as TextBoxType}
            editorState={editorState}
            setEditorState={setEditorState}
            isFocused={isFocused}
            handleBackgroundColorChange={handleBackgroundColorChange}
            handleContainerFontChange={handleContainerFontChange}
            styleMap={styleMap}
          />
          <WYSIWYG
            toolbarHidden
            editorState={editorState}
            wrapperClassName="text-editor-wrapper"
            editorClassName="text-editor"
            onEditorStateChange={handleEditorChange}
            onFocus={() => setIsFocused(true)}
            customStyleMap={styleMap}
            handlePastedText={(text) => {
              const newContent = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                text,
              );

              setEditorState((prev: any) => EditorState.push(
                prev,
                newContent,
                'insert-characters',
              ));

              return true;
            }}
          />
        </>
      ) : (
        <Box
          className="show-text-container"
          style={{ height: '100%' }}
          dangerouslySetInnerHTML={{ __html: textComponent }}
        />
      )}
    </TextBoxContainer>
  );
}

export default TextBox;
