import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

import * as ClassroomsAPI from 'src/api/Classrooms';
import { useUserStore } from 'src/zustand/user';
import { useClassroomStore } from 'src/zustand/classrooms';
import { Description, Title } from 'src/components/typography';
import { SelectList } from 'src/components';
import Input from 'src/components/inputs/Input';
import { Button } from 'src/components/buttons';
import { GoogleClassroomImg } from 'src/assets/icons';
import { ImportGoogleClassImg, LogoBlackImg } from 'src/assets/images';
import { lang } from 'src/lang';

import ClickToCopy from 'src/components/ClickToCopy';
import BaseDialog, { DialogContent, DialogTitle } from '../BaseDialog';
import {
  CreateClassroomBox,
  OptionImport,
  OptionImportList,
} from './styles';
import JoinCodeDialog from './JoinCodeDialog';

const {
  Marker,
  OptionLabel,
  ListOption,
  Option,
} = SelectList;

const GOOGLE_SCOPES = 'https://www.googleapis.com/auth/classroom.courses.readonly'
  + ' https://www.googleapis.com/auth/classroom.profile.emails'
  + ' https://www.googleapis.com/auth/classroom.rosters.readonly';

function CreateClassroomDialog({
  hasGoogleRedirect,
  setTab,
  isOpen,
  onOpen,
  onClose,
  fromAssignment = false,
  onCloseCallback,
}: {
  hasGoogleRedirect?: boolean,
  setTab?: (value: number) => void,
  isOpen: boolean;
  onOpen: () => void,
  onClose: (...args: any[]) => void;
  fromAssignment?: boolean,
  onCloseCallback?: () => void,
}) {
  const user = useUserStore((state) => state.user);
  const loadClassrooms = useClassroomStore((state) => state.loadClassrooms);

  const [searchParams, setSearchParams] = useSearchParams();

  // Used to navigate to the classroom screen after import from google
  const [newClassroomId, setNewClassroomId] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);

  const [selectedClassrooms, setSelectedClassrooms] = useState<any>([]);
  const [classroomList, setClassroomList] = useState<any>([]);
  const [classroom, setClassroom] = useState('');
  const [subject, setSubject] = useState('');
  const [section, setSection] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [code, setCode] = useState('');
  const [classroomId, setClassroomId] = useState<string | null>(null);

  const { state, pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen || !!state) return;
    setTimeout(() => {
      setCurrentStep(0);
      setSelectedClassrooms([]);
      setClassroomList([]);
      setClassroom('');
      setSubject('');
      setSection('');
    }, 100);
  }, [isOpen]);

  const handleClose = () => {
    if (setTab) setTab(2);
    loadClassrooms();
    if (fromAssignment && onCloseCallback) {
      onCloseCallback();
    }

    if (currentStep === 4) navigate(`/classroom/${newClassroomId}`);
    onClose();
  };

  const importData = async () => {
    setLoading(true);
    try {
      const response = await ClassroomsAPI.saveFromGoogle({
        classrooms: selectedClassrooms.map((e: any) => e.id),
      });
      if (response.status === 200) {
        const listOfNewClassrooms = response.data.data.map((c: any) => c.id);
        const responseClassrooms = await ClassroomsAPI.getAll();
        if (response.status === 200) {
          // Get first classroom imported to navigate when click "View your class"
          setNewClassroomId(listOfNewClassrooms[0]);
          setClassroomList(
            responseClassrooms.data.data.map((c: any) => ({
              ...c,
              isSyncing: listOfNewClassrooms.includes(c.id),
            })),
          );
        }
        response.data.data.forEach((e: any) => (
          ClassroomsAPI.syncWithGoogle(e.id)
        ));
        // If the classroom creation was triggered from assignment creation,
        // the last step doesn't need to appear, as it shows a link to open classroom screen
        if (!pathname.includes('/assignment')) setCurrentStep(4);
        else handleClose();
      }
    } catch (error) { }
    setLoading(false);
  };

  const getGoogleClassrooms = useGoogleLogin({
    flow: 'auth-code',
    scope: GOOGLE_SCOPES,
    ux_mode: 'redirect',
    redirect_uri: `${window.location.origin}/classrooms/providers/oauth/google`,
    select_account: false,
    hint: user.email!,
  });

  useEffect(() => {
    setErrorMessage(false);
    if (searchParams.get('error') && hasGoogleRedirect) {
      onOpen();
      if (state?.permissionError) {
        setCurrentStep(3);
        setErrorMessage(true);
      }
      setSearchParams('');
    } else if (searchParams.get('loadClassrooms') && hasGoogleRedirect) {
      onOpen();
      if (state?.classroomList) {
        setCurrentStep(3);
        setClassroomList(state?.classroomList || []);
      }
      if (!fromAssignment) setSearchParams('');
    }
  }, []);

  const handleSelect = (item: any) => {
    if (selectedClassrooms.find((e: any) => item.id === e.id)) {
      setSelectedClassrooms(
        selectedClassrooms.filter((e: any) => item.id !== e.id),
      );
    } else {
      setSelectedClassrooms([...selectedClassrooms, item]);
    }
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const response = await ClassroomsAPI.createClassroom({
      name: classroom,
      section: section || undefined,
      subject: subject || undefined,
    });
    if (response.status === 201) {
      setCode(response.data.data.join_code);
      setClassroomId(response.data.data.id);
      setCurrentStep(2);
      loadClassrooms();
    }
    setLoading(false);
  };

  const enableNextStep = useMemo(() => classroom.length > 0, [classroom]);

  const handleBackButton = () => {
    setCurrentStep(0);
    setClassroom('');
    setSubject('');
    setSection('');
  };

  const getDialogTitle = () => {
    if (errorMessage) {
      return lang('classroom.create_classroom_dialog.error.title');
    }

    if (currentStep === 3) {
      if (loading) {
        return lang('classroom.create_classroom_dialog.step_3.loading.title');
      }
      if (classroomList?.length === 0) {
        return lang('classroom.create_classroom_dialog.step_3.no_classroom');
      }

      return lang('classroom.create_classroom_dialog.step_3.choose_classroom');
    }

    if (currentStep === 4) {
      return lang('classroom.create_classroom_dialog.step_4.title');
    }

    return lang(`classroom.create_classroom_dialog.step_${currentStep}.title`);
  };

  if (currentStep === 2) {
    return (
      <JoinCodeDialog
        onClose={handleClose}
        isOpen={isOpen}
        code={code}
        buttonLabel={
          fromAssignment
            ? lang('classroom.create_classroom_dialog.step_2.submit.from_assignment')
            : lang('classroom.create_classroom_dialog.step_2.submit.from_classroom')
        }
        classroomId={classroomId}
        showBottomAction
        fromAssignment={fromAssignment}
      />
    );
  }

  return (
    <BaseDialog
      size="medium"
      onClose={onClose}
      open={isOpen}
      showBackButton={currentStep > 0 && currentStep < 4 && !loading}
      onBackButton={handleBackButton}
    >
      <DialogContent>
        {currentStep === 3 && loading && (
          <div style={{ display: 'flex', alignItems: 'center', paddingBlock: '40px' }}>
            <img style={{ margin: 'auto' }} src={ImportGoogleClassImg} alt="" />
          </div>
        )}
        <DialogTitle>{getDialogTitle()}</DialogTitle>

        {currentStep === 0 && (
          <OptionImportList
            sx={{
              margin: '2rem 2rem 1rem 4rem',
            }}
          >
            <OptionImport
              onClick={() => {
                if (!loading) {
                  // Store assignment url with it's id in order to go back to this
                  // screen after importing the classrooms
                  if (pathname.includes('/assignment')) {
                    localStorage.setItem('assignmentUrl', pathname);
                  }
                  getGoogleClassrooms();
                }
              }}
              disabled={loading}
            >
              <img src={GoogleClassroomImg} alt="" height={50} />
              <Title size="small" lineHeight="30px">
                {lang('classroom.create_classroom_dialog.step_0.import')}
              </Title>
              <Description>
                {lang('classroom.create_classroom_dialog.step_0.sync')}
              </Description>
            </OptionImport>
            <OptionImport
              onClick={() => !loading && setCurrentStep(1)}
              disabled={loading}
            >
              <img src={LogoBlackImg} alt="" height={50} />
              <Title size="small" lineHeight="30px" whiteSpace="pre-line">
                {lang('classroom.create_classroom_dialog.step_0.create')}
              </Title>
              <Description>
                {lang('classroom.create_classroom_dialog.step_0.invite')}
              </Description>
            </OptionImport>
          </OptionImportList>
        )}

        {currentStep === 1 && (
          <CreateClassroomBox>
            <Input
              placeholder={lang(
                'classroom.create_classroom_dialog.step_1.classroom_placeholder',
              )}
              value={classroom}
              required
              onChange={(event: any) => setClassroom(event.target.value)}
              maxLength={25}
              disabled={loading}
            />
            <Input
              placeholder={lang(
                'classroom.create_classroom_dialog.step_1.subject_placeholder',
              )}
              value={subject}
              onChange={(event: any) => setSubject(event.target.value)}
              maxLength={25}
              disabled={loading}
            />
            <Input
              placeholder={lang(
                'classroom.create_classroom_dialog.step_1.section_placeholder',
              )}
              value={section}
              onChange={(event: any) => setSection(event.target.value)}
              maxLength={25}
              disabled={loading}
            />
            <Button
              disabled={!enableNextStep || loading}
              label={lang('classroom.create_classroom_dialog.step_1.submit')}
              onClick={submitForm}
            />
          </CreateClassroomBox>
        )}

        {currentStep === 3 && loading && (
          <Description>
            {lang('classroom.create_classroom_dialog.step_3.loading.description')}
          </Description>
        )}

        {currentStep === 3 && errorMessage && !loading && (
          <>
            <Description align="center">
              {lang('classroom.create_classroom_dialog.error.subtitle')}
            </Description>
            <Button
              label={lang('classroom.create_classroom_dialog.go_back')}
              onClick={handleBackButton}
              style={{
                margin: '3rem auto 0',
              }}
            />
          </>
        )}

        {currentStep === 3 && !errorMessage && classroomList?.length === 0 && !loading && (
          <>
            <Description>
              {lang('classroom.create_classroom_dialog.step_3.create')}
            </Description>
            <Button
              label={lang('classroom.create_classroom_dialog.go_back')}
              onClick={handleBackButton}
              style={{
                margin: '3rem auto 0',
              }}
            />
          </>
        )}

        {currentStep === 3 && !errorMessage && classroomList?.length > 0 && !loading && (
          <>
            <Description align="center">
              {lang(
                'classroom.create_classroom_dialog.step_3.classroom_count',
                { count: classroomList?.length ?? 0 },
              )}
            </Description>
            <ListOption>
              {classroomList?.map((item: any) => (
                <Option
                  key={item.id}
                  onClick={() => !item.imported_at && handleSelect(item)}
                  disabled={item.imported_at}
                >
                  <Marker
                    selected={
                      selectedClassrooms.find((e: any) => e.id === item.id)
                      || item.imported_at
                    }
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '0 .5rem',
                    }}
                  >
                    <img src={GoogleClassroomImg} alt="" width={40} />
                  </div>
                  <OptionLabel>
                    <Title size="small" lineHeight="20px">
                      {item.name}
                      {item.imported_at && (
                        <span
                          style={{
                            marginLeft: 15,
                            fontWeight: 'normal',
                            fontSize: '0.75rem',
                          }}
                        >
                          {lang(
                            'classroom.create_classroom_dialog.step_3.imported',
                            { date: dayjs(item.imported_at).fromNow() },
                          )}
                        </span>
                      )}
                    </Title>
                    <Description>{item.section}</Description>
                  </OptionLabel>
                </Option>
              ))}
            </ListOption>
            <Button
              label={lang(
                `classroom.create_classroom_dialog.step_3.${fromAssignment ? 'submit_from_assignment' : 'submit'
                }`,
              )}
              onClick={importData}
              disabled={selectedClassrooms.length === 0 || loading}
              style={{
                margin: '3rem auto 0',
              }}
            />
          </>
        )}

        {currentStep === 4 && (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <Description>
              {lang('classroom.create_classroom_dialog.step_4.description.line_1')}
            </Description>
            <Description>
              {lang('classroom.create_classroom_dialog.step_4.description.line_2.part_1')}
              <ClickToCopy text={window.location.origin}>
                <b>
                  {window.location.origin}
                </b>
              </ClickToCopy>
              {lang('classroom.create_classroom_dialog.step_4.description.line_2.part_2')}
            </Description>
            <Button
              label={lang('classroom.create_classroom_dialog.step_4.submit')}
              onClick={handleClose}
              style={{
                margin: '1rem auto 0',
                padding: '10px 80px',
              }}
            />
          </div>
        )}
      </DialogContent>
    </BaseDialog>
  );
}

export default CreateClassroomDialog;
