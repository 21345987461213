import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const BlocksItemCard = styled(Box)(() => ({
  textAlign: 'center',

  flex: '0 0 4.15rem',
  maxWidth: '4.15rem',
  color: '#163567',
  cursor: 'pointer',

  '> .block-title': {

    fontFamily: 'Inter',
    fontSize: '0.625rem',
    lineHeight: '0.9375rem',
  },
}));

export const BlockIcon = styled(Box)(() => ({
  height: '3.75rem',
  color: '#163567',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: '0.75rem 1.25rem',

  lineHeight: '1.125rem',
  fontSize: '0.75rem',
  fontWeight: 700,
}));
