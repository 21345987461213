import { ImageBoxType, TextBoxType } from '../../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 0,
    height: 3300,
    width: 2550,
  } as ImageBoxType,
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'QUOTE100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 128,
    y: 160,
    height: 2980,
    width: 702,
  } as TextBoxType,
];
