import React, { useEffect, useState } from 'react';

import {
  Circle, FlexBox, GradientContainer, Logo,
} from '../../components';
import { Button } from '../../components/buttons';
import { Title, Description } from '../../components/typography';
import { lang } from '../../lang';

function SchoolValidation() {
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    // After checking if this school's verification is done
    // Update this boolean to show the corresponding message
    setValidation(false);
  }, []);

  return (
    <GradientContainer justifyContent="center">
      <Logo />

      <FlexBox marginTop={5} textAlign="center" width="50%">
        <FlexBox marginBottom={5}>
          <Circle size="128px" />
        </FlexBox>
        <Title>
          {lang(`auth.signup.${validation ? 'success' : 'school_verification'}`)}
        </Title>
        <FlexBox marginTop={5} alignSelf="center" width="50%">
          <Description align="center">
            {validation && lang('auth.signup.welcome')}
            {!validation && lang('auth.signup.wait_validation.line_1')}
            {!validation && lang('auth.signup.wait_validation.line_2')}
          </Description>
          <FlexBox textAlign="center" marginTop={5}>
            <Button label={lang('general.go')} large />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </GradientContainer>
  );
}

export default SchoolValidation;
