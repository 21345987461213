import { BranchMenuItem, MenuItemType } from './types';

export function deepFind(list: MenuItemType[], cb: (el: MenuItemType) => boolean): MenuItemType {
  const item = list.find(cb);

  if (item) {
    return item;
  }

  const sublists = Object.values(list.filter((el) => 'submenus' in el)).map((el) => (el as BranchMenuItem).submenus).flat();
  return deepFind(sublists, cb);
}
