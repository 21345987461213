import React, { useMemo } from 'react';

import { TitleLengthImg } from '../../../assets/images';
import { useEditor } from '../../../hooks/useEditor';
import { lang } from '../../../lang';
import { BorderLinearProgress, CardContainer } from '../styles';
import { CardProps } from '../types';

function TitleLengthCard({ expanded }: CardProps) {
  const { titleLength: x } = useEditor();

  const getProgressValue = useMemo(
    () => Math.min(100, Math.max(0, -0.5 * x * x + 20 * x - 87.5)),
    [x],
  );

  const renderProgress = () => (
    <div className="progress-wrapper">
      <BorderLinearProgress
        variant="determinate"
        value={getProgressValue}
      />
    </div>
  );

  return (
    <CardContainer
      backgroundColor="#FDF6F6"
      mainTextColor="#793133"
    >
      <div className="header">
        <img
          className={`card-icon ${expanded ? 'expanded' : 'visible'}`}
          src={TitleLengthImg}
          alt="Title Length"
        />
        <div className={expanded ? 'title-open' : ''}>
          {lang('document.side_bar.title_length.title')}
        </div>
      </div>
      {
        expanded && (
          <div className="card-description">
            {x < 15 && lang('document.side_bar.title_length.default_message')}
            {x > 25 && lang('document.side_bar.title_length.too_much_message')}
            {x >= 15 && x <= 25 && lang('document.side_bar.title_length.good_message')}
          </div>
        )
      }
      {expanded && renderProgress()}
    </CardContainer>
  );
}

export default TitleLengthCard;
