import React, { useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useLocation, useSearchParams } from 'react-router-dom';

import { GoogleButtonProps } from './types';
import { ErrorMessage } from '../typography';
import GoogleImg from '../../assets/images/google.png';
import { useUserStore } from '../../zustand/user';
import { Container, LogoContainer } from './styles';
import { lang } from '../../lang';

function GoogleButton({ type }: GoogleButtonProps) {
  const { error, message } = useUserStore((state) => state.userError);

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const userType = location.pathname.split('-')[1];
  let role = '';
  if (type !== 'signIn') {
    if ([searchParams.get('userType'), userType].includes('teacher')) {
      role = 'teacher';
    } else if (searchParams.get('join_code')) {
      role = 'student';
    } else {
      role = 'individual';
    }
  }

  const login = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${window.location.origin}/oauth/google`,
    state: new URLSearchParams({
      role,
    }).toString(),
  });

  useEffect(() => {
    localStorage.setItem('registerFlow', type || 'signIn');
    localStorage.setItem('joinCode', searchParams.get('join_code') || '');
  }, []);

  return (
    <>
      <Container onClick={login}>
        <LogoContainer>
          <img
            src={GoogleImg}
            alt=""
            height={18}
          />
        </LogoContainer>
        <div>
          {lang('auth.commom.google_button', { type })}
        </div>
      </Container>
      <div style={{ textAlign: 'left' }}>
        {error && <ErrorMessage>{message || lang('auth.errors.unknown')}</ErrorMessage>}
      </div>
    </>
  );
}

export default GoogleButton;
