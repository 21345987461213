import { AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../utils';
import Input from '../inputs/Input';

export const TopBarContainer = styled(Toolbar)(() => ({
  flexGrow: 1,
  display: 'flex',

  '.topbar-center': {
    textAlign: 'center',
    justifyContent: 'center',

    '& > div': {
      display: 'flex',
      '&:first-of-type': { justifyContent: 'center' },
      '&:last-of-type': { justifyContent: 'space-between' },

      '& > div > div:not(.disabled)': {
        cursor: 'pointer',
      },
    },

    p: {
      lineHeight: '150%',

      '&:first-of-type': { fontSize: '.875rem' },
      '&:last-of-type': { fontSize: '.75rem' },
    },

    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },

  '.topbar-back-button': {
    display: 'grid',
    gridGap: '.5rem',
    gridTemplateColumns: 'repeat(2, auto)',
    cursor: 'pointer',
    textDecoration: 'none',
    border: '1px solid #163567',
    padding: 'calc(.5rem - 1px) .75rem',
    alignItems: 'center',
    background: '#ffffff',

    p: {
      fontSize: '.875rem',
      fontWeight: 700,
      color: '#163567',
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, auto)',
    },
  },

  '.topbar-column': {
    display: 'grid',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    color: 'black',
    gridGap: '2rem',
    gridTemplateColumns: 'repeat(3, auto)',
    justifyContent: 'flex-start',

    '&:last-of-type': { justifyContent: 'flex-end', flexBasis: '50%' },

    '.topbar-project-info': {
      maxWidth: 'calc(100vw - 18rem)',
    },

    [theme.breakpoints.down('md')]: {
      '&:last-of-type': {
        flexBasis: '35%',
      },

      '.topbar-project-info': {
        maxWidth: 'calc(50vw - 15rem)',
      },
    },

    [theme.breakpoints.down('sm')]: {
      gap: '1rem',

      '.center-container': {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
      },

      '&:last-of-type': {
        flexBasis: '25%',
      },

      '.topbar-project-info': {
        maxWidth: 'calc(100vw - 20rem)',
      },
    },

    '.topbar-back-button:not(.no-hover):hover': {
      opacity: 0.8,
    },

    '.topbar-project-info > :first-of-type': {
      color: theme.palette.secondary.dark,
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

export const TopBarButtonContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, auto)',
  flexDirection: 'row',
}));

export const TopBarWrapper = styled(AppBar)(() => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: 'none',
  height: '4.625rem',
}));

export const TopBarInput = styled(Input)(() => ({
  maxWidth: '11rem',
  width: 'fill-available',
  height: '3rem',

  flex: 'none',
  order: 1,
  flexGrow: 0,
  borderRadius: 0,

  padding: '0 1.5rem',

  [theme.breakpoints.down('sm')]: {
    padding: '0 1rem',
  },
}));
