import { Box, styled } from '@mui/system';
import { theme } from '../../utils';

export const PageCounter = styled(Box)(() => ({
  color: theme.palette.primary.light,
  fontSize: '0.85rem',

  '> span': {
    fontWeight: 'bold',
  },
}));
